import {
  CheckOutlined,
  CloseOutlined,
  DownloadOutlined,
  QuestionCircleOutlined,
  UpOutlined,
} from '@ant-design/icons';
import { Collapse, Form, Popover, Radio, Switch } from 'antd';
import React from 'react';
import { uploadImageNormalize } from '../../../../../../common/utils';
import DraggerUploadComponent from '../../../../../../components/DraggerUploadComponent';
import InputComponent from '../../../../../../components/InputComponent';
import PanelHeader from '../../../previewComponents/globalComponents/PanelHeader';

const QuoteWidgetForm = ({
  form,
  setSelectedTab,
  selectedTab = '',
  formValues = null,
  setFormValues,
}) => {
  const handleChangeDefault = (costOption = 'recommendedOption') => {
    switch (costOption) {
      case 'lowCostOption':
        form?.setFieldsValue({
          widgetConfiguration: {
            recommendedOption: {
              default: false,
            },
            premiumOption: {
              default: false,
            },
          },
        });
        setFormValues({
          ...formValues,
          widgetConfiguration: {
            ...formValues?.widgetConfiguration,
            lowCostOption: {
              ...formValues?.widgetConfiguration?.lowCostOption,
              default: true,
            },
            recommendedOption: {
              ...formValues?.widgetConfiguration?.recommendedOption,
              default: false,
            },
            premiumOption: {
              ...formValues?.widgetConfiguration?.premiumOption,
              default: false,
            },
          },
        });
        break;

      case 'recommendedOption':
        form?.setFieldsValue({
          widgetConfiguration: {
            lowCostOption: {
              default: false,
            },
            premiumOption: {
              default: false,
            },
          },
        });
        setFormValues({
          ...formValues,
          widgetConfiguration: {
            ...formValues?.widgetConfiguration,
            lowCostOption: {
              ...formValues?.widgetConfiguration?.lowCostOption,
              default: false,
            },
            recommendedOption: {
              ...formValues?.widgetConfiguration?.recommendedOption,
              default: true,
            },
            premiumOption: {
              ...formValues?.widgetConfiguration?.premiumOption,
              default: false,
            },
          },
        });
        break;

      case 'premiumOption':
        form?.setFieldsValue({
          widgetConfiguration: {
            recommendedOption: {
              default: false,
            },
            lowCostOption: {
              default: false,
            },
          },
        });
        setFormValues({
          ...formValues,
          widgetConfiguration: {
            ...formValues?.widgetConfiguration,
            recommendedOption: {
              ...formValues?.widgetConfiguration?.recommendedOption,
              default: false,
            },
            lowCostOption: {
              ...formValues?.widgetConfiguration?.lowCostOption,
              default: false,
            },
            premiumOption: {
              ...formValues?.widgetConfiguration?.premiumOption,
              default: true,
            },
          },
        });
        break;

      default:
        break;
    }
  };

  const showLowPriceDescription = Form?.useWatch(
    ['widgetConfiguration', 'lowCostOption', 'price', 'descriptionCheck'],
    form,
  );

  const showLowPriceTooltip = Form?.useWatch(
    ['widgetConfiguration', 'lowCostOption', 'price', 'toolTipCheck'],
    form,
  );

  const showLowPriceTooltipDescription = Form?.useWatch(
    [
      'widgetConfiguration',
      'lowCostOption',
      'price',
      'tooltipDescriptionCheck',
    ],
    form,
  );

  const showRecommendPriceDescription = Form?.useWatch(
    ['widgetConfiguration', 'recommendedOption', 'price', 'descriptionCheck'],
    form,
  );

  const showRecommendPriceTooltip = Form?.useWatch(
    ['widgetConfiguration', 'recommendedOption', 'price', 'toolTipCheck'],
    form,
  );

  const showRecommendPriceTooltipDescription = Form?.useWatch(
    [
      'widgetConfiguration',
      'recommendedOption',
      'price',
      'tooltipDescriptionCheck',
    ],
    form,
  );

  const showPremiumPriceDescription = Form?.useWatch(
    ['widgetConfiguration', 'premiumOption', 'price', 'descriptionCheck'],
    form,
  );

  const showPremiumPriceTooltip = Form?.useWatch(
    ['widgetConfiguration', 'premiumOption', 'price', 'toolTipCheck'],
    form,
  );

  const showPremiumPriceTooltipDescription = Form?.useWatch(
    [
      'widgetConfiguration',
      'premiumOption',
      'price',
      'tooltipDescriptionCheck',
    ],
    form,
  );

  const showCompare = Form?.useWatch(
    ['widgetConfiguration', 'compareCheck'],
    form,
  );

  const showCompareDescription = Form?.useWatch(
    ['widgetConfiguration', 'compareSection', 'descriptionCheck'],
    form,
  );

  const showLowCost = Form?.useWatch(
    ['widgetConfiguration', 'lowCostOption', 'enable'],
    form,
  );

  const showRecommend = Form?.useWatch(
    ['widgetConfiguration', 'recommendedOption', 'enable'],
    form,
  );

  const showPremium = Form?.useWatch(
    ['widgetConfiguration', 'premiumOption', 'enable'],
    form,
  );

  const showPackageTitle = Form?.useWatch(
    ['widgetConfiguration', 'packageSettings', 'packageTitle'],
    form,
  );

  const showProducts = Form?.useWatch(
    ['widgetConfiguration', 'packageSettings', 'showProducts'],
    form,
  );

  const goodPricingAndMarketingBlock = [
    {
      forceRender: true,
      key: 'PRICE',
      label: 'Price',
      children: (
        <>
          <Form.Item
            name={['widgetConfiguration', 'lowCostOption', 'price', 'icon']}
            className="mb-0"
            normalize={uploadImageNormalize}
            valuePropName="fileList"
            label="Icon"
          >
            <DraggerUploadComponent className="common-upload">
              {' '}
              <p className="icon-header">
                <DownloadOutlined />
              </p>
              <span className="upload-title">
                Drag image or browse your files
              </span>{' '}
              <br />
              <span className="upload-description">JPG, PNG, SVG, JPEG</span>
            </DraggerUploadComponent>
          </Form.Item>
          <div className="d-flex justify-between align-center">
            <span className="switch-logo">Cost per Day</span>
            <Form.Item
              name={[
                'widgetConfiguration',
                'lowCostOption',
                'price',
                'costPerDay',
              ]}
              className="mb-0"
              valuePropName="checked"
            >
              <Switch
                className="common-switch"
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Form.Item>
          </div>
          <div className="d-flex justify-between align-center">
            <span className="switch-logo">Description</span>
            <Form.Item
              name={[
                'widgetConfiguration',
                'lowCostOption',
                'price',
                'descriptionCheck',
              ]}
              className="mb-0"
              valuePropName="checked"
            >
              <Switch
                className="common-switch"
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Form.Item>
          </div>
          <Form.Item
            name={[
              'widgetConfiguration',
              'lowCostOption',
              'price',
              'descriptionText',
            ]}
            hidden={!showLowPriceDescription}
            rules={[
              {
                required: showLowPriceDescription,
                message: 'Please Enter Description',
              },
            ]}
          >
            <InputComponent placeholder="Enter Description" />
          </Form.Item>
          <div className="d-flex justify-between align-center">
            <span className="switch-logo">Tooltip</span>
            <Form.Item
              name={[
                'widgetConfiguration',
                'lowCostOption',
                'price',
                'toolTipCheck',
              ]}
              className="mb-0"
              valuePropName="checked"
            >
              <Switch
                className="common-switch"
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Form.Item>
          </div>
          <Form.Item
            name={[
              'widgetConfiguration',
              'lowCostOption',
              'price',
              'toolTipText',
            ]}
            hidden={!showLowPriceTooltip}
            rules={[
              {
                required: showLowPriceTooltip,
                message: 'Please Enter Tooltip Text',
              },
            ]}
          >
            <InputComponent placeholder="Enter Tooltip Text" />
          </Form.Item>
          <div className="d-flex justify-between align-center">
            <span className="switch-logo">Tooltip Description</span>
            <Form.Item
              name={[
                'widgetConfiguration',
                'lowCostOption',
                'price',
                'tooltipDescriptionCheck',
              ]}
              className="mb-0"
              valuePropName="checked"
            >
              <Switch
                className="common-switch"
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Form.Item>
          </div>
          <Form.Item
            name={[
              'widgetConfiguration',
              'lowCostOption',
              'price',
              'tooltipDescriptionText',
            ]}
            hidden={!showLowPriceTooltipDescription}
            rules={[
              {
                required: showLowPriceTooltipDescription,
                message: 'Please Enter Tooltip Description',
              },
            ]}
          >
            <InputComponent placeholder="Enter Tooltip Description" />
          </Form.Item>
        </>
      ),
    },
    {
      key: 'MARKETING',
      forceRender: true,
      label: 'Marketing Block',
      children: (
        <>
          <Form.Item
            name={[
              'widgetConfiguration',
              'lowCostOption',
              'marketingBlock',
              'icon',
            ]}
            normalize={uploadImageNormalize}
            valuePropName="fileList"
            label="Icon"
          >
            <DraggerUploadComponent className="common-upload">
              {' '}
              <p className="icon-header">
                <DownloadOutlined />
              </p>
              <span className="upload-title">
                Drag image or browse your files
              </span>{' '}
              <br />
              <span className="upload-description">JPG, PNG, SVG, JPEG</span>
            </DraggerUploadComponent>
          </Form.Item>
          <Form.Item
            name={[
              'widgetConfiguration',
              'lowCostOption',
              'marketingBlock',
              'title',
            ]}
            label="Title"
          >
            <InputComponent placeholder="Enter Title" />
          </Form.Item>
          <Form.Item
            name={[
              'widgetConfiguration',
              'lowCostOption',
              'marketingBlock',
              'description',
            ]}
            label="Description"
          >
            <InputComponent placeholder="Enter Description" />
          </Form.Item>
        </>
      ),
    },
  ];

  const betterPricingAndMarketingBlock = [
    {
      forceRender: true,
      key: 'PRICE',
      label: 'Price',
      children: (
        <>
          <Form.Item
            name={['widgetConfiguration', 'recommendedOption', 'price', 'icon']}
            className="mb-0"
            normalize={uploadImageNormalize}
            valuePropName="fileList"
            label="Icon"
          >
            <DraggerUploadComponent className="common-upload">
              {' '}
              <p className="icon-header">
                <DownloadOutlined />
              </p>
              <span className="upload-title">
                Drag image or browse your files
              </span>{' '}
              <br />
              <span className="upload-description">JPG, PNG, SVG, JPEG</span>
            </DraggerUploadComponent>
          </Form.Item>
          <div className="d-flex justify-between align-center">
            <span className="switch-logo">Cost per Day</span>
            <Form.Item
              name={[
                'widgetConfiguration',
                'recommendedOption',
                'price',
                'costPerDay',
              ]}
              className="mb-0"
              valuePropName="checked"
            >
              <Switch
                className="common-switch"
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Form.Item>
          </div>
          <div className="d-flex justify-between align-center">
            <span className="switch-logo">Description</span>
            <Form.Item
              name={[
                'widgetConfiguration',
                'recommendedOption',
                'price',
                'descriptionCheck',
              ]}
              className="mb-0"
              valuePropName="checked"
            >
              <Switch
                className="common-switch"
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Form.Item>
          </div>
          <Form.Item
            name={[
              'widgetConfiguration',
              'recommendedOption',
              'price',
              'descriptionText',
            ]}
            hidden={!showRecommendPriceDescription}
            rules={[
              {
                required: showRecommendPriceDescription,
                message: 'Please Enter Description',
              },
            ]}
          >
            <InputComponent placeholder="Enter Description" />
          </Form.Item>
          <div className="d-flex justify-between align-center">
            <span className="switch-logo">Tooltip</span>
            <Form.Item
              name={[
                'widgetConfiguration',
                'recommendedOption',
                'price',
                'toolTipCheck',
              ]}
              className="mb-0"
              valuePropName="checked"
            >
              <Switch
                className="common-switch"
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Form.Item>
          </div>
          <Form.Item
            name={[
              'widgetConfiguration',
              'recommendedOption',
              'price',
              'toolTipText',
            ]}
            hidden={!showRecommendPriceTooltip}
            rules={[
              {
                required: showRecommendPriceTooltip,
                message: 'Please Enter Tooltip Text',
              },
            ]}
          >
            <InputComponent placeholder="Enter Tooltip Text" />
          </Form.Item>
          <div className="d-flex justify-between align-center">
            <span className="switch-logo">Tooltip Description</span>
            <Form.Item
              name={[
                'widgetConfiguration',
                'recommendedOption',
                'price',
                'tooltipDescriptionCheck',
              ]}
              className="mb-0"
              valuePropName="checked"
            >
              <Switch
                className="common-switch"
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Form.Item>
          </div>
          <Form.Item
            name={[
              'widgetConfiguration',
              'recommendedOption',
              'price',
              'tooltipDescriptionText',
            ]}
            hidden={!showRecommendPriceTooltipDescription}
            rules={[
              {
                required: showRecommendPriceTooltipDescription,
                message: 'Please Enter Tooltip Description',
              },
            ]}
          >
            <InputComponent placeholder="Enter Tooltip Description" />
          </Form.Item>
        </>
      ),
    },
    {
      key: 'MARKETING',
      forceRender: true,
      label: 'Marketing Block',
      children: (
        <>
          <Form.Item
            name={[
              'widgetConfiguration',
              'recommendedOption',
              'marketingBlock',
              'icon',
            ]}
            normalize={uploadImageNormalize}
            valuePropName="fileList"
            label="Icon"
          >
            <DraggerUploadComponent className="common-upload">
              {' '}
              <p className="icon-header">
                <DownloadOutlined />
              </p>
              <span className="upload-title">
                Drag image or browse your files
              </span>{' '}
              <br />
              <span className="upload-description">JPG, PNG, SVG, JPEG</span>
            </DraggerUploadComponent>
          </Form.Item>
          <Form.Item
            name={[
              'widgetConfiguration',
              'recommendedOption',
              'marketingBlock',
              'title',
            ]}
            label="Title"
          >
            <InputComponent placeholder="Enter Title" />
          </Form.Item>
          <Form.Item
            name={[
              'widgetConfiguration',
              'recommendedOption',
              'marketingBlock',
              'description',
            ]}
            label="Description"
          >
            <InputComponent placeholder="Enter Description" />
          </Form.Item>
        </>
      ),
    },
  ];

  const bestPricingAndMarketingBlock = [
    {
      forceRender: true,
      key: 'PRICE',
      label: 'Price',
      children: (
        <>
          <Form.Item
            name={['widgetConfiguration', 'premiumOption', 'price', 'icon']}
            className="mb-0"
            normalize={uploadImageNormalize}
            valuePropName="fileList"
            label="Icon"
          >
            <DraggerUploadComponent className="common-upload">
              {' '}
              <p className="icon-header">
                <DownloadOutlined />
              </p>
              <span className="upload-title">
                Drag image or browse your files
              </span>{' '}
              <br />
              <span className="upload-description">JPG, PNG, SVG, JPEG</span>
            </DraggerUploadComponent>
          </Form.Item>
          <div className="d-flex justify-between align-center">
            <span className="switch-logo">Cost per Day</span>
            <Form.Item
              name={[
                'widgetConfiguration',
                'premiumOption',
                'price',
                'costPerDay',
              ]}
              className="mb-0"
              valuePropName="checked"
            >
              <Switch
                className="common-switch"
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Form.Item>
          </div>
          <div className="d-flex justify-between align-center">
            <span className="switch-logo">Description</span>
            <Form.Item
              name={[
                'widgetConfiguration',
                'premiumOption',
                'price',
                'descriptionCheck',
              ]}
              className="mb-0"
              valuePropName="checked"
            >
              <Switch
                className="common-switch"
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Form.Item>
          </div>
          <Form.Item
            name={[
              'widgetConfiguration',
              'premiumOption',
              'price',
              'descriptionText',
            ]}
            hidden={!showPremiumPriceDescription}
            rules={[
              {
                required: showPremiumPriceDescription,
                message: 'Please Enter Description',
              },
            ]}
          >
            <InputComponent placeholder="Enter Description" />
          </Form.Item>
          <div className="d-flex justify-between align-center">
            <span className="switch-logo">Tooltip</span>
            <Form.Item
              name={[
                'widgetConfiguration',
                'premiumOption',
                'price',
                'toolTipCheck',
              ]}
              className="mb-0"
              valuePropName="checked"
            >
              <Switch
                className="common-switch"
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Form.Item>
          </div>
          <Form.Item
            name={[
              'widgetConfiguration',
              'premiumOption',
              'price',
              'toolTipText',
            ]}
            hidden={!showPremiumPriceTooltip}
            rules={[
              {
                required: showPremiumPriceTooltip,
                message: 'Please Enter Tooltip Text',
              },
            ]}
          >
            <InputComponent placeholder="Enter Tooltip Text" />
          </Form.Item>
          <div className="d-flex justify-between align-center">
            <span className="switch-logo">Tooltip Description</span>
            <Form.Item
              name={[
                'widgetConfiguration',
                'premiumOption',
                'price',
                'tooltipDescriptionCheck',
              ]}
              className="mb-0"
              valuePropName="checked"
            >
              <Switch
                className="common-switch"
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Form.Item>
          </div>
          <Form.Item
            name={[
              'widgetConfiguration',
              'premiumOption',
              'price',
              'tooltipDescriptionText',
            ]}
            hidden={!showPremiumPriceTooltipDescription}
            rules={[
              {
                required: showPremiumPriceTooltipDescription,
                message: 'Please Enter Tooltip Description',
              },
            ]}
          >
            <InputComponent placeholder="Enter Tooltip Description" />
          </Form.Item>
        </>
      ),
    },
    {
      key: 'MARKETING',
      forceRender: true,
      label: 'Marketing Block',
      children: (
        <>
          <Form.Item
            name={[
              'widgetConfiguration',
              'premiumOption',
              'marketingBlock',
              'icon',
            ]}
            normalize={uploadImageNormalize}
            valuePropName="fileList"
            label="Icon"
          >
            <DraggerUploadComponent className="common-upload">
              {' '}
              <p className="icon-header">
                <DownloadOutlined />
              </p>
              <span className="upload-title">
                Drag image or browse your files
              </span>{' '}
              <br />
              <span className="upload-description">JPG, PNG, SVG, JPEG</span>
            </DraggerUploadComponent>
          </Form.Item>
          <Form.Item
            name={[
              'widgetConfiguration',
              'premiumOption',
              'marketingBlock',
              'title',
            ]}
            label="Title"
          >
            <InputComponent placeholder="Enter Title" />
          </Form.Item>
          <Form.Item
            name={[
              'widgetConfiguration',
              'premiumOption',
              'marketingBlock',
              'description',
            ]}
            label="Description"
          >
            <InputComponent placeholder="Enter Description" />
          </Form.Item>
        </>
      ),
    },
  ];
  const quoteVariantCollapseItems = [
    {
      forceRender: true,
      key: 'LOW_COST',
      label: (
        <PanelHeader
          title="Good"
          formSelectedKey="lowCostOption"
          previewSelectedKey={selectedTab}
          setPreviewSelectedKey={setSelectedTab}
        />
      ),
      children: (
        <>
          <div className="d-flex justify-between align-center">
            <span className="switch-logo">Enable</span>
            <Form.Item
              name={['widgetConfiguration', 'lowCostOption', 'enable']}
              className="mb-0"
              valuePropName="checked"
            >
              <Switch
                className="common-switch"
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Form.Item>
          </div>
          <Form.Item
            name={['widgetConfiguration', 'lowCostOption', 'title']}
            rules={[
              {
                required: showLowCost,
                message: 'Please Enter Title',
              },
            ]}
            label="Title"
          >
            <InputComponent placeholder="Enter Title" />
          </Form.Item>
          <Form.Item
            name={['widgetConfiguration', 'lowCostOption', 'default']}
            valuePropName="checked"
          >
            <Radio
              className="common-radio"
              value="default"
              onChange={() => handleChangeDefault('lowCostOption')}
            >
              Default
            </Radio>
          </Form.Item>
          <Form.Item
            name={['widgetConfiguration', 'lowCostOption', 'quoteVariantIcon']}
            className="mb-0"
            normalize={uploadImageNormalize}
            valuePropName="fileList"
            label="Quote Variant Icon"
          >
            <DraggerUploadComponent className="common-upload">
              {' '}
              <p className="icon-header">
                <DownloadOutlined />
              </p>
              <span className="upload-title">
                Drag image or browse your files
              </span>{' '}
              <br />
              <span className="upload-description">JPG, PNG, SVG, JPEG</span>
            </DraggerUploadComponent>
          </Form.Item>
          <Collapse
            className="common-collapse questionnaire-collapse"
            expandIconPosition="end"
            defaultActiveKey={['PRICE', 'MARKETING']}
            expandIcon={({ isActive }) => (
              <UpOutlined rotate={isActive ? 0 : 180} />
            )}
            ghost
            items={goodPricingAndMarketingBlock}
          />
        </>
      ),
    },
    {
      forceRender: true,
      key: 'RECOMMEND',
      label: (
        <PanelHeader
          title="Better"
          formSelectedKey="recommendedOption"
          previewSelectedKey={selectedTab}
          setPreviewSelectedKey={setSelectedTab}
        />
      ),
      children: (
        <>
          <div className="d-flex justify-between align-center">
            <span className="switch-logo">Enable</span>
            <Form.Item
              name={['widgetConfiguration', 'recommendedOption', 'enable']}
              className="mb-0"
              valuePropName="checked"
            >
              <Switch
                className="common-switch"
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Form.Item>
          </div>
          <Form.Item
            name={['widgetConfiguration', 'recommendedOption', 'title']}
            rules={[
              {
                required: showRecommend,
                message: 'Please Enter Title',
              },
            ]}
            label="Title"
          >
            <InputComponent placeholder="Enter Title" />
          </Form.Item>
          <Form.Item
            name={['widgetConfiguration', 'recommendedOption', 'default']}
            valuePropName="checked"
          >
            <Radio
              className="common-radio"
              value="default"
              onChange={() => handleChangeDefault('recommendedOption')}
            >
              Default
            </Radio>
          </Form.Item>
          <Form.Item
            name={[
              'widgetConfiguration',
              'recommendedOption',
              'quoteVariantIcon',
            ]}
            className="mb-0"
            normalize={uploadImageNormalize}
            valuePropName="fileList"
            label="Quote Variant Icon"
          >
            <DraggerUploadComponent className="common-upload">
              {' '}
              <p className="icon-header">
                <DownloadOutlined />
              </p>
              <span className="upload-title">
                Drag image or browse your files
              </span>{' '}
              <br />
              <span className="upload-description">JPG, PNG, SVG, JPEG</span>
            </DraggerUploadComponent>
          </Form.Item>
          <Collapse
            className="common-collapse questionnaire-collapse"
            expandIconPosition="end"
            expandIcon={({ isActive }) => (
              <UpOutlined rotate={isActive ? 0 : 180} />
            )}
            defaultActiveKey={['PRICE', 'MARKETING']}
            ghost
            items={betterPricingAndMarketingBlock}
          />
        </>
      ),
    },
    {
      forceRender: true,
      key: 'PREMIUM',
      label: (
        <PanelHeader
          title="Best"
          formSelectedKey="premiumOption"
          previewSelectedKey={selectedTab}
          setPreviewSelectedKey={setSelectedTab}
        />
      ),
      children: (
        <>
          <div className="d-flex justify-between align-center">
            <span className="switch-logo">Enable</span>
            <Form.Item
              name={['widgetConfiguration', 'premiumOption', 'enable']}
              className="mb-0"
              valuePropName="checked"
            >
              <Switch
                className="common-switch"
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Form.Item>
          </div>
          <Form.Item
            name={['widgetConfiguration', 'premiumOption', 'title']}
            rules={[
              {
                required: showPremium,
                message: 'Please Enter Title',
              },
            ]}
            label="Title"
          >
            <InputComponent placeholder="Enter Title" />
          </Form.Item>
          <Form.Item
            name={['widgetConfiguration', 'premiumOption', 'default']}
            valuePropName="checked"
            onChange={() => handleChangeDefault('premiumOption')}
          >
            <Radio className="common-radio" value="default">
              Default
            </Radio>
          </Form.Item>
          <Form.Item
            name={['widgetConfiguration', 'premiumOption', 'quoteVariantIcon']}
            className="mb-0"
            normalize={uploadImageNormalize}
            valuePropName="fileList"
            label="Quote Variant Icon"
          >
            <DraggerUploadComponent className="common-upload">
              {' '}
              <p className="icon-header">
                <DownloadOutlined />
              </p>
              <span className="upload-title">
                Drag image or browse your files
              </span>{' '}
              <br />
              <span className="upload-description">JPG, PNG, SVG, JPEG</span>
            </DraggerUploadComponent>
          </Form.Item>
          <Collapse
            className="common-collapse questionnaire-collapse"
            expandIconPosition="end"
            expandIcon={({ isActive }) => (
              <UpOutlined rotate={isActive ? 0 : 180} />
            )}
            defaultActiveKey={['PRICE', 'MARKETING']}
            ghost
            items={bestPricingAndMarketingBlock}
          />
        </>
      ),
    },
  ];
  return (
    <>
      <div className="section-header mt-16">
        <span className="section-title">Package Settings</span>
      </div>
      <div className="d-flex justify-between align-center">
        <span className="switch-logo">Package Image</span>
        <Form.Item
          name={['widgetConfiguration', 'packageSettings', 'packageImage']}
          className="mb-0"
          valuePropName="checked"
        >
          <Switch
            className="common-switch"
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </Form.Item>
      </div>
      <div className="d-flex justify-between align-center">
        <span className="switch-logo">Package Title</span>
        <Form.Item
          name={['widgetConfiguration', 'packageSettings', 'packageTitle']}
          className="mb-0"
          valuePropName="checked"
        >
          <Switch
            className="common-switch"
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            onChange={(value) => {
              if (!value) {
                form?.setFieldsValue({
                  widgetConfiguration: {
                    packageSettings: {
                      showProducts: true,
                      itemizedPricing: true,
                      productQuantityCheck: true,
                    },
                  },
                });
                setFormValues({
                  ...formValues,
                  widgetConfiguration: {
                    ...formValues?.widgetConfiguration,
                    packageSettings: {
                      ...formValues?.widgetConfiguration?.packageSettings,
                      showProducts: true,
                      itemizedPricing: true,
                      productQuantityCheck: true,
                    },
                  },
                });
              }
            }}
          />
        </Form.Item>
      </div>
      <div className="d-flex justify-between align-center">
        <span className="switch-logo required-mark">
          Show Products &nbsp;
          <Popover
            content="Show the products within a package on your customers quote page."
            overlayClassName="common-tooltip"
          >
            <QuestionCircleOutlined />
          </Popover>
        </span>
        <Form.Item
          name={['widgetConfiguration', 'packageSettings', 'showProducts']}
          className="mb-0"
          valuePropName="checked"
        >
          <Switch
            onChange={(value) => {
              if (!value) {
                form?.setFieldValue(
                  ['widgetConfiguration', 'packageSettings', 'itemizedPricing'],
                  false,
                );
                form?.setFieldValue(
                  [
                    'widgetConfiguration',
                    'packageSettings',
                    'productQuantityCheck',
                  ],
                  false,
                );
              }
            }}
            className="common-switch"
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            disabled={!showPackageTitle}
          />
        </Form.Item>
      </div>

      <div className="d-flex justify-between align-center">
        <span className="switch-logo">Itemized Pricing</span>
        <Form.Item
          name={['widgetConfiguration', 'packageSettings', 'itemizedPricing']}
          className="mb-0"
          valuePropName="checked"
        >
          <Switch
            disabled={!showProducts}
            className="common-switch"
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </Form.Item>
      </div>
      <div className="d-flex justify-between align-center">
        <span className="switch-logo">Product Quantity</span>
        <Form.Item
          name={[
            'widgetConfiguration',
            'packageSettings',
            'productQuantityCheck',
          ]}
          className="mb-0"
          valuePropName="checked"
        >
          <Switch
            disabled={!showProducts}
            className="common-switch"
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </Form.Item>
      </div>

      <div className="section-header mt-16">
        <span className="section-title">Quote Variants</span>
      </div>
      <div className="d-flex justify-between align-center">
        <span className="switch-logo">Icons</span>
        <Form.Item
          name={['widgetConfiguration', 'icons']}
          className="mb-0"
          valuePropName="checked"
        >
          <Switch
            className="common-switch"
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </Form.Item>
      </div>

      <div className="d-flex justify-between align-center">
        <span className="switch-logo">Compare</span>
        <Form.Item
          name={['widgetConfiguration', 'compareCheck']}
          className="mb-0"
          valuePropName="checked"
        >
          <Switch
            disabled
            className="common-switch"
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </Form.Item>
      </div>

      <Form.Item
        name={['widgetConfiguration', 'compareSection', 'inputButton']}
        hidden={!showCompare}
        rules={[
          {
            required: showCompare,
            message: 'Please Enter Input Button Text',
          },
        ]}
        label="Input Button"
      >
        <InputComponent placeholder="Enter Input Button Text" />
      </Form.Item>
      <Form.Item
        name={['widgetConfiguration', 'compareSection', 'title']}
        hidden={!showCompare}
        rules={[
          {
            required: showCompare,
            message: 'Please Enter Title',
          },
        ]}
        label="Title"
      >
        <InputComponent placeholder="Enter Title" />
      </Form.Item>
      <Form.Item
        name={['widgetConfiguration', 'compareSection', 'closeButton']}
        hidden={!showCompare}
        rules={[
          {
            required: showCompare,
            message: 'Please Enter Close Button Text',
          },
        ]}
        label="Close Button"
      >
        <InputComponent placeholder="Enter Close Button Text" />
      </Form.Item>
      <div className="d-flex justify-between align-center">
        {showCompare && <span className="switch-logo">Description</span>}
        <Form.Item
          name={['widgetConfiguration', 'compareSection', 'descriptionCheck']}
          className="mb-0"
          valuePropName="checked"
          hidden={!showCompare}
        >
          <Switch
            className="common-switch"
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </Form.Item>
      </div>
      <Form.Item
        name={['widgetConfiguration', 'compareSection', 'descriptionText']}
        hidden={!(showCompare && showCompareDescription)}
        rules={[
          {
            required: showCompareDescription,
            message: 'Please Enter Description',
          },
        ]}
      >
        <InputComponent placeholder="Enter Description" />
      </Form.Item>
      <Collapse
        className="common-collapse questionnaire-collapse"
        expandIconPosition="end"
        expandIcon={({ isActive }) => (
          <UpOutlined rotate={isActive ? 0 : 180} />
        )}
        ghost
        items={quoteVariantCollapseItems}
      />
    </>
  );
};

export default QuoteWidgetForm;
