import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  MenuOutlined,
} from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { InputNumber, Popconfirm, Table } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import deleteIcon from '../../../assets/delete-red.svg';
import { checkPermissions, formatPrice } from '../../../common/utils';
import TableComponent from '../../../components/TableComponent';
import {
  DELETE_PRODUCT_SUB_ITEM,
  UPDATE_PRODUCT_SUB_ITEM,
} from '../../productItems/graphql/Mutations';
import { CHANGE_ITEM_RANKING } from '../graphql/Mutations';

function DraggableWrapper(props) {
  const { children, ...restProps } = props;
  /**
   * 'children[1]` is `dataSource`
   * Check if `children[1]` is an array
   * because antd gives 'No Data' element when `dataSource` is an empty array
   */
  return (
    <SortableContext
      key={children?.[0]?.key}
      items={
        children?.[1] instanceof Array
          ? children?.[1]?.map((child) => child?.id)
          : []
      }
      strategy={verticalListSortingStrategy}
      {...restProps}
    >
      <tbody {...restProps}>
        {
          // This invokes `Table.components.body.row` for each element of `children`.
          children
        }
      </tbody>
    </SortableContext>
  );
}

function DraggableRow(props) {
  const {
    attributes,
    listeners,
    setNodeRef,
    isDragging,
    transition,
    transform,
  } = useSortable({
    // eslint-disable-next-line react/destructuring-assignment
    id: props?.['data-row-key'],
    // eslint-disable-next-line react/destructuring-assignment
    key: props?.['data-row-key'],
  });
  const { children, ...restProps } = props;
  const style = transform
    ? {
        opacity: isDragging ? 0.4 : undefined,
        transform: CSS?.Translate?.toString(transform),
        transition,
      }
    : undefined;
  return (
    <tr ref={setNodeRef} {...attributes} {...restProps} style={style}>
      {children instanceof Array
        ? children?.map((child) => {
            const { key, ...rest } = child;
            return key === 'dragHandle' ? (
              <td {...listeners} {...rest}>
                {child}
              </td>
            ) : (
              <td {...rest}>{child}</td>
            );
          })
        : children}
    </tr>
  );
}

const ProductItemTable = ({
  selectedKeys,
  loading,
  productItemData,
  setProductItemData,
  fetchProductItemData,
}) => {
  const location = useLocation();
  const { id } = useParams();

  const {
    state: { pageSize, permissions },
  } = useContext(AppContext);

  const initialProductItemFilter = {
    sortOn: 'order',
    sortBy: 'ASC',
    productId: id,
  };

  const [activeId, setActiveId] = useState(null);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragStart = (event) => {
    const { active } = event;
    setActiveId(active?.id);
  };

  const [sortedInfo, setSortedInfo] = useState({});
  const [productItemFilter, setProductItemFilter] = useState(
    initialProductItemFilter,
  );
  const [showPopConfirmCheckBtn, setShowPopConfirmCheckBtn] = useState(false);
  const [dragLoading, setDragLoading] = useState(false);

  const [deleteProductSubItem] = useMutation(DELETE_PRODUCT_SUB_ITEM, {
    onError() {},
  });

  const [updateProductSubItem] = useMutation(UPDATE_PRODUCT_SUB_ITEM, {
    onCompleted: () => {
      fetchProductItemData({
        variables: { filter: productItemFilter },
      });
    },
    onError: () => {},
  });

  const [changeItemRanking] = useMutation(CHANGE_ITEM_RANKING, {
    onError: () => {},
  });

  useEffect(() => {
    fetchProductItemData({
      variables: { filter: productItemFilter },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const rowSelection = {
    columnTitle: 'PRIMARY',
    type: 'radio',
    getCheckboxProps: () => ({
      disabled: location?.pathname?.includes('/view'),
    }),
    fixed: 'left',
    columnWidth: 90,
    selectedRowKeys: selectedKeys,
    onChange: async (selectedRowKeys) => {
      await updateProductSubItem({
        variables: {
          data: {
            isPrimary: true,
          },
          where: {
            id: selectedRowKeys?.[0],
          },
        },
      });
    },
    renderCell(checked, record, index, node) {
      return React.cloneElement(node, {
        'aria-label': record?.id,
      });
    },
  };

  const handleDragEnd = async (event) => {
    setDragLoading(true);
    const { active, over } = event;

    if (active?.id !== over?.id) {
      try {
        const oldIndex = productItemData?.findIndex(
          (item) => item?.id === active?.id,
        );
        const newIndex = productItemData?.findIndex(
          (item) => item?.id === over?.id,
        );
        const response = await changeItemRanking({
          variables: {
            data: {
              oldIndex: productItemData?.[oldIndex]?.order,
              newIndex: productItemData?.[newIndex]?.order,
            },
            where: {
              id,
            },
          },
        });
        if (response) {
          setProductItemData((items) => arrayMove(items, oldIndex, newIndex));
          setDragLoading(false);
        }
      } catch (error) {
        return error;
      }
    }
  };

  const handleDeleteProductSubItem = async (editData) => {
    const response = await deleteProductSubItem({
      variables: { where: { id: editData?.id } },
    });
    if (response?.data?.deleteProductSubItem) {
      fetchProductItemData({
        variables: { filter: productItemFilter },
      });
    }
  };

  const handleTableChange = (pagination, tableFilter, sorter) => {
    setSortedInfo(sorter);
    if (sorter?.column) {
      setProductItemFilter({
        ...productItemFilter,
        sortOn: sorter?.field,
        sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC',
      });
      fetchProductItemData({
        variables: {
          filter: {
            ...productItemFilter,
            sortOn: sorter?.field,
            sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC',
          },
        },
      });
    } else {
      setProductItemFilter({
        ...productItemFilter,
        sortOn: 'createdAt',
        sortBy: 'DESC',
      });
      fetchProductItemData({
        variables: {
          filter: {
            ...productItemFilter,
            sortOn: 'createdAt',
            sortBy: 'DESC',
          },
        },
      });
    }
  };

  const handleEdit = (index) => {
    const dataCopy = [...productItemData];
    dataCopy[index].editable = true;
    setProductItemData(dataCopy);
  };

  const handleChangeQuantity = async (record) => {
    await updateProductSubItem({
      variables: {
        data: {
          quantity: record?.tempQuantity,
        },
        where: {
          id: record?.id,
        },
      },
    });
    setShowPopConfirmCheckBtn(false);
  };

  const handleChangeQuantityValue = (value, index) => {
    setShowPopConfirmCheckBtn(true);
    if (!value) {
      return;
    }
    const dataCopy = [...productItemData];
    dataCopy[index].tempQuantity = value;
    setProductItemData(dataCopy);
  };

  const handleCloseEdit = (record, index) => {
    setShowPopConfirmCheckBtn(false);
    const dataCopy = [...productItemData];
    dataCopy[index].editable = false;
    dataCopy[index].tempQuantity = record?.quantity;
    setProductItemData(dataCopy);
  };

  const showDrag = () => {
    let sorterLength;

    if (!Object?.keys(sortedInfo)?.length || !sortedInfo?.order) {
      sorterLength = false;
    } else {
      sorterLength = true;
    }

    if (
      !sorterLength &&
      !location?.pathname?.includes('/view') &&
      checkPermissions(permissions, ['FET_PRODUCT_UPDATE'])
    ) {
      return true;
    }

    return false;
  };

  const columns = [
    showDrag() && {
      key: 'dragHandle',
      dataIndex: 'dragHandle',
      fixed: 'left',
      columnWidth: 50,
      width: 10,
      className: 'drag-visible',
      render: () => <MenuOutlined className="drag-row" />,
    },
    {
      title: 'SKU',
      dataIndex: 'sku',
      key: 'sku',
      ellipsis: true,
      className: 'max-width-column',
      width: 120,
      render: (sku, record) => <span>{record?.productItems?.sku}</span>,
    },
    {
      title: 'PRODUCT',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      className: 'max-width-column',
      render: (name, record) => <span>{record?.productItems?.name}</span>,
    },
    {
      title: 'MANUFACTURER',
      dataIndex: 'manufacturer',
      key: 'manufacturer',
      ellipsis: true,
      width: 180,
      render: (manufacturer) => {
        const { name } = manufacturer;
        return <span title={name}>{name}</span>;
      },
    },
    {
      title: 'INDUSTRY',
      dataIndex: 'industry',
      key: 'industry',
      ellipsis: true,
      width: 190,
      render: (industry) => {
        const { label } = industry;
        return <span title={label}>{label}</span>;
      },
    },
    {
      title: 'LINE OF BUSINESS',
      dataIndex: 'lineOfBusiness',
      key: 'lineOfBusiness',
      ellipsis: true,
      width: 190,
      render: (lineOfBusiness) => {
        const { label } = lineOfBusiness;
        return <span title={label}>{label}</span>;
      },
    },
    {
      title: 'SERVICE TYPE',
      dataIndex: 'subArea',
      key: 'subArea',
      ellipsis: true,
      width: 140,
      render: (subArea) => {
        const { label } = subArea;
        return <span title={label}>{label}</span>;
      },
    },
    {
      title: 'UNIT OF MEASURE',
      dataIndex: 'unitOfMeasure',
      key: 'unitOfMeasure',
      ellipsis: true,
      width: 140,
      render: (unitOfMeasure) => (
        <span title={unitOfMeasure?.title || '-'}>
          {unitOfMeasure?.title || '-'}
        </span>
      ),
    },
    {
      title: 'CONVERSION PARAMETER',
      dataIndex: 'conversionParameter',
      key: 'conversionParameter',
      ellipsis: true,
      width: 140,
      render: (conversionParameter) => (
        <span title={conversionParameter?.name || '-'}>
          {conversionParameter?.name || '-'}
        </span>
      ),
    },
    {
      title: 'QTY',
      dataIndex: 'quantity',
      key: 'quantity',
      fixed: 'right',
      sorter: true,
      ellipsis: true,
      sortOrder: sortedInfo?.columnKey === 'quantity' && sortedInfo?.order,
      width: 130,
      render: (quantity = 1, record = {}, index = 0) => {
        if (!record?.editable) {
          return (
            <div>
              <span className="quantity-text">{quantity}</span>
              {!location?.pathname?.includes('/view') && (
                <EditOutlined
                  className="edit-icon"
                  onClick={() => handleEdit(index)}
                />
              )}
            </div>
          );
        }
        return (
          <div>
            <InputNumber
              type="number"
              className="quantity-component"
              value={record?.tempQuantity}
              onChange={(value) => handleChangeQuantityValue(value, index)}
              min={1}
              precision={0}
            />
            {!showPopConfirmCheckBtn ? (
              <CheckOutlined
                disabled={record?.tempQuantity < 1}
                className="check-icon"
                onClick={() => handleChangeQuantity(record, index)}
              />
            ) : (
              <Popconfirm
                title=" This change will affect the package price; do you like to save these changes?"
                onConfirm={() => handleChangeQuantity(record, index)}
                onCancel={() => handleCloseEdit(record, index)}
                okText="Yes"
                cancelText="No"
              >
                <CheckOutlined
                  disabled={record?.tempQuantity < 1}
                  className="check-icon"
                />
              </Popconfirm>
            )}
            <CloseOutlined
              className="close-icon"
              onClick={() => handleCloseEdit(record, index)}
            />
          </div>
        );
      },
    },
    {
      title: 'PRICE',
      dataIndex: 'defaultPrice',
      key: 'defaultPrice',
      sorter: true,
      ellipsis: true,
      align: 'right',
      fixed: 'right',
      className: 'max-width-column',
      sortOrder: sortedInfo?.columnKey === 'defaultPrice' && sortedInfo?.order,
      width: 130,
      render: (defaultPrice = 0, record = null) => (
        <span title={formatPrice(defaultPrice / record?.quantity)}>
          {formatPrice(defaultPrice / record?.quantity)}
        </span>
      ),
    },
    {
      title: 'TOTAL PRICE',
      dataIndex: 'defaultPrice',
      key: 'defaultPrice',
      ellipsis: true,
      align: 'right',
      fixed: 'right',
      width: 140,
      render: (defaultPrice = null) => (
        <span title={formatPrice(defaultPrice)}>
          {formatPrice(defaultPrice)}
        </span>
      ),
    },
    {
      dataIndex: 'id',
      align: 'right',
      width: 10,
      fixed: 'right',
      render: (action, record = {}) => (
        <Popconfirm
          title="Are you sure to delete?"
          onConfirm={() => handleDeleteProductSubItem(record)}
          okText="Yes"
          cancelText="No"
        >
          <img
            src={deleteIcon}
            alt="delete-icon"
            className={
              !location?.pathname?.includes('/view')
                ? 'product-item-delete'
                : 'display-none'
            }
          />
        </Popconfirm>
      ),
    },
  ];

  return (
    <div className="common-table product-sub-item-table">
      {pageSize && (
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
        >
          <TableComponent
            isSearch={false}
            fullHeight={false}
            scroll={{ x: 'max-content' }}
            loadingData={loading || dragLoading}
            columns={[...columns?.filter((item) => item !== false)]}
            data={productItemData || []}
            onChange={handleTableChange}
            rowSelection={
              checkPermissions(permissions, ['FET_PRODUCT_UPDATE']) &&
              rowSelection
            }
            components={
              productItemData?.length > 0
                ? {
                    body: {
                      wrapper: DraggableWrapper,
                      row: DraggableRow,
                    },
                  }
                : {}
            }
            rowKey="id"
            summary={(pageData) => {
              let totalPrice = 0;
              let price = 0;
              let qty = 0;
              pageData?.forEach(({ defaultPrice, quantity }) => {
                totalPrice += defaultPrice;
                price += defaultPrice / quantity;
                qty += quantity;
              });
              if (productItemData?.length > 0) {
                return (
                  <Table.Summary fixed="bottom">
                    <Table.Summary.Row>
                      {/* need to test this below condition once overlay issue fix */}
                      <Table.Summary.Cell colSpan={showDrag() ? 9 : 6} />{' '}
                      <Table.Summary.Cell>
                        <span>Total</span>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <span>{qty}</span>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <span>{formatPrice(price)}</span>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <span>{formatPrice(totalPrice)}</span>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </Table.Summary>
                );
              }
            }}
          />
          <DragOverlay>
            <TableComponent
              fullHeight={false}
              columns={columns}
              showHeader={false}
              data={
                activeId
                  ? [
                      productItemData?.[
                        productItemData?.findIndex(
                          (item) => item?.id === activeId,
                        )
                      ],
                    ]
                  : []
              }
            />
          </DragOverlay>
        </DndContext>
      )}
    </div>
  );
};

export default ProductItemTable;
