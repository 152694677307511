import { useLazyQuery } from '@apollo/client';
import { Card } from 'antd';
import React, { useEffect } from 'react';
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import GoBackButton from '../../../components/GoBackButton';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import history from '../../../historyData';
import ApiLogDetails from '../component/ApiLogDetails';
import { GET_API_LOG } from '../graphql/Queries';

const ApiLogView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [getApiLog, { loading, data }] = useLazyQuery(GET_API_LOG, {
    onCompleted: () => {},
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    getApiLog({
      variables: {
        where: {
          id,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const unListen = history?.listen((props) => {
      if (props?.action === 'POP') {
        navigate(props?.location?.pathname, { state: { ...location?.state } });
      }
    });
    return () => {
      if (unListen) {
        unListen();
      }
    };
  }, []);

  const filterRequestUrl = (requestApi) => {
    const queryStringIndex = requestApi?.search(/\?/);
    if (queryStringIndex > 0) {
      return requestApi?.substring(0, queryStringIndex);
    }
    return requestApi;
  };

  if (!id) {
    return <Navigate to={ROUTES?.REST_API_LOGS} replace />;
  }

  return (
    <Card className="full-height-card card-body-padding">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={`${ROUTES?.REST_API_LOGS}`} />
          <span className="portal-header">
            {data && filterRequestUrl(data?.restApiLog?.requestApi)}
          </span>
        </div>
      </Portal>
      {loading ? (
        <LoaderComponent setHeight={80} />
      ) : (
        <ApiLogDetails logData={data?.restApiLog} />
      )}
    </Card>
  );
};
export default ApiLogView;
