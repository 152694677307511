import { CheckOutlined, CloseOutlined, UpOutlined } from '@ant-design/icons';
import { Checkbox, Collapse, Form, Switch } from 'antd';
import React from 'react';
import { formValidatorRules } from '../../../../../../common/utils';
import EditorComponent from '../../../../../../components/EditorComponent';
import InputComponent from '../../../../../../components/InputComponent';

const { required } = formValidatorRules;

const SupportWidgetForm = ({ form }) => {
  const urgencyCheckboxLabelCheck = Form?.useWatch(
    ['widgetConfiguration', 'supportFromSettings', 'urgencyCheckboxLabelCheck'],
    form,
  );
  const sendConfirmationEmailCheckboxCheck = Form?.useWatch(
    [
      'widgetConfiguration',
      'supportFromSettings',
      'sendConfirmationEmailCheckboxCheck',
    ],
    form,
  );

  const showErrorMessage = Form?.useWatch(
    ['widgetConfiguration', 'supportFromSettings', 'errorMessage', 'check'],
    form,
  );

  const items = [
    {
      forceRender: true,
      label: 'Name',
      key: '1',
      children: (
        <>
          <div className="d-flex justify-between align-center">
            <span className="switch-logo">First Name</span>
          </div>
          <Form.Item
            name={[
              'widgetConfiguration',
              'supportFromSettings',
              'firstName',
              'label',
            ]}
            rules={[
              {
                required: true,
                message: 'Please Enter Label',
              },
            ]}
            className="mb-0"
          >
            <InputComponent placeholder="Enter Label" />
          </Form.Item>
          <Form.Item
            name={[
              'widgetConfiguration',
              'supportFromSettings',
              'firstName',
              'required',
            ]}
            className="mb-0"
            valuePropName="checked"
          >
            <Checkbox disabled className="common-checkbox">
              Required
            </Checkbox>
          </Form.Item>
          <div className="d-flex justify-between align-center">
            <span className="switch-logo">Last Name</span>
          </div>
          <Form.Item
            name={[
              'widgetConfiguration',
              'supportFromSettings',
              'lastName',
              'label',
            ]}
            rules={[
              {
                required: true,
                message: 'Please Enter Label',
              },
            ]}
            className="mb-0"
          >
            <InputComponent placeholder="Enter Label" />
          </Form.Item>
          <Form.Item
            name={[
              'widgetConfiguration',
              'supportFromSettings',
              'lastName',
              'required',
            ]}
            className="mb-0"
            valuePropName="checked"
          >
            <Checkbox disabled className="common-checkbox">
              Required
            </Checkbox>
          </Form.Item>
        </>
      ),
    },
    {
      forceRender: true,
      label: 'Email',
      key: '2',
      children: (
        <>
          {/* Email */}
          <Form.Item
            name={[
              'widgetConfiguration',
              'supportFromSettings',
              'email',
              'labelText',
            ]}
            rules={[
              {
                required: true,
                message: 'Please Enter Label',
              },
            ]}
            className="mb-0"
          >
            <InputComponent placeholder="Enter Label" />
          </Form.Item>
        </>
      ),
    },
    {
      forceRender: true,
      label: 'Phone',
      key: '3',
      children: (
        <>
          <Form.Item
            name={[
              'widgetConfiguration',
              'supportFromSettings',
              'phone',
              'labelText',
            ]}
            rules={[
              {
                required: true,
                message: 'Please Enter Label',
              },
            ]}
            className="mb-0"
          >
            <InputComponent placeholder="Enter Label" />
          </Form.Item>
        </>
      ),
    },
  ];

  return (
    <div>
      <div className="section-header mt-16">
        <span className="section-title">Contact form Settings</span>
      </div>
      <Form.Item
        name={[
          'widgetConfiguration',
          'supportFromSettings',
          'optionalComments',
          'labelText',
        ]}
        rules={[
          {
            ...required,
            message: 'Please Enter Optional Comments Text',
          },
        ]}
        label="Optional Comments"
        className="mb-0"
      >
        <InputComponent placeholder="Enter Optional Comments Text" />
      </Form.Item>
      {/* Urgency Checkbox Label */}
      <div className="d-flex justify-between align-center">
        <span className="switch-logo">Urgency Checkbox Label</span>
        <Form.Item
          name={[
            'widgetConfiguration',
            'supportFromSettings',
            'urgencyCheckboxLabelCheck',
          ]}
          className="mb-0"
          valuePropName="checked"
        >
          <Switch
            className="common-switch"
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </Form.Item>
      </div>
      <Form.Item
        name={[
          'widgetConfiguration',
          'supportFromSettings',
          'urgencyCheckboxLabelText',
        ]}
        hidden={!urgencyCheckboxLabelCheck}
        rules={[
          {
            required: urgencyCheckboxLabelCheck,
            message: 'Please Enter Urgency Checkbox Label',
          },
        ]}
        className="mb-0"
      >
        <InputComponent placeholder="Enter Urgency Checkbox Label" />
      </Form.Item>
      <Form.Item
        name={[
          'widgetConfiguration',
          'supportFromSettings',
          'urgencyCheckboxLabelSelected',
        ]}
        hidden={!urgencyCheckboxLabelCheck}
        className="mb-0"
        valuePropName="checked"
      >
        <Checkbox className="common-checkbox">Selected</Checkbox>
      </Form.Item>
      {/* Send Confirmation Email Checkbox */}
      <div className="d-flex justify-between align-center">
        <span className="switch-logo">Send Confirmation Email Checkbox</span>
        <Form.Item
          name={[
            'widgetConfiguration',
            'supportFromSettings',
            'sendConfirmationEmailCheckboxCheck',
          ]}
          className="mb-0"
          valuePropName="checked"
        >
          <Switch
            className="common-switch"
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </Form.Item>
      </div>
      <Form.Item
        name={[
          'widgetConfiguration',
          'supportFromSettings',
          'sendConfirmationEmailCheckboxText',
        ]}
        hidden={!sendConfirmationEmailCheckboxCheck}
        rules={[
          {
            required: sendConfirmationEmailCheckboxCheck,
            message: 'Please Enter Send Confirmation Email Checkbox',
          },
        ]}
        className="mb-0"
      >
        <InputComponent placeholder="Enter Send Confirmation Email Checkbox" />
      </Form.Item>
      <Form.Item
        name={[
          'widgetConfiguration',
          'supportFromSettings',
          'sendConfirmationEmailCheckboxSelected',
        ]}
        hidden={!sendConfirmationEmailCheckboxCheck}
        className="mb-0"
        valuePropName="checked"
      >
        <Checkbox className="common-checkbox">Selected</Checkbox>
      </Form.Item>
      <div className="d-flex justify-between align-center">
        <span className="switch-logo">Enter Error Message</span>
        <Form.Item
          name={[
            'widgetConfiguration',
            'supportFromSettings',
            'errorMessage',
            'check',
          ]}
          className="mb-0"
          valuePropName="checked"
        >
          <Switch
            className="common-switch"
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </Form.Item>
      </div>
      <Form.Item
        name={[
          'widgetConfiguration',
          'supportFromSettings',
          'errorMessage',
          'text',
        ]}
        hidden={!showErrorMessage}
        rules={[
          {
            required: showErrorMessage,
            message: 'Please Enter Error Message',
          },
        ]}
        className="mb-0"
      >
        <EditorComponent placeholder="Enter Error Message" />
      </Form.Item>
      <Collapse
        className="common-collapse questionnaire-collapse"
        expandIconPosition="end"
        expandIcon={({ isActive }) => (
          <UpOutlined rotate={isActive ? 0 : 180} />
        )}
        defaultActiveKey={['1', '2', '3']}
        ghost
        items={items}
      />
    </div>
  );
};

export default SupportWidgetForm;
