import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Form, Layout, Menu } from 'antd';
import {
  flattenDeep,
  forIn,
  isArray,
  isEmpty,
  kebabCase,
  map,
  omit,
  trim,
} from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { AppContext } from '../../../AppContext';
import { messageContext } from '../../../app/components/AppContextHolder';
import {
  CONDITIONS_KEYS,
  DYNAMIC_FIELDS_DATA_KEYS,
  MODAL_WIDTH,
  QUESTIONNAIRE_TYPE,
  ROUTES,
  SYSTEM_FIELDS_MODULE_OBJECT,
  SYSTEM_FIELDS_TYPES_OBJECT,
} from '../../../common/constants';
import LoaderComponent from '../../../components/LoaderComponent';
import ModalComponent from '../../../components/ModalComponent';
import {
  CREATE_QUESTIONNAIRES,
  UPDATE_QUESTIONNAIRES,
} from '../graphql/Mutations';
import {
  CPQ_DYNAMIC_FIELDS,
  CPQ_INDUSTRIES,
  FETCH_ALL_STATIC_FIELDS,
  GET_QUESTIONNAIRE,
  GET_SUGGESTED_PRODUCT,
  GET_USED_SYSTEM_FIELDS,
} from '../graphql/Queries';
import CompareQuoteVariants from './CompareQuoteVariants';
import GlobalConditionsForm from './GlobalConditionsForm';
import SecondaryGeneralForm from './SecondaryGeneralForm';
import ProductAddOn from './productAddon/ProductAddOn';

const initialPaginationValue = {
  total: 0,
  skip: 0,
  pageSize: 10,
  search: '',
  products: [],
};

const { Sider, Footer } = Layout;

const SecondarySettingModal = ({
  showModal,
  setShowModal,
  refetchData,
  refetchQuestionnaire,
  setRefetchSystemFields,
}) => {
  const [form] = Form?.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const isCreate = location?.pathname?.includes('create');
  const compareConfig = Form?.useWatch(['compareConfig'], form);
  const { dispatch } = useContext(AppContext);
  const [activeKey, setActiveKey] = useState('general');
  const [industries, setIndustries] = useState([]);
  const [industriesLoading, setIndustriesLoading] = useState(true);
  const [isFormValuesChanged, setIsFormValuesChanged] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [dynamicFieldsData, setDynamicFieldsData] = useState([]);
  const [dynamicFieldDataClone, setDynamicFieldDataClone] = useState(
    dynamicFieldsData,
  );
  const [selectedListOptions, setSelectedListOptions] = useState(null);
  const [optionsData, setOptionsData] = useState({});
  const [checkedFields, setCheckedFields] = useState([]);
  const [validationTriggered, setValidationTriggered] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dynamicFieldEditData, setDynamicFieldEditData] = useState({});
  const [initialValueSet, setInitialValueSet] = useState(true);
  const [productItemStaticFieldSet, setProductItemStaticFieldSet] = useState(
    true,
  );
  const [packageItemStaticFieldSet, setPackageItemStaticFieldSet] = useState(
    true,
  );
  const [dynamicFieldsLoading, setDynamicFieldsLoading] = useState(true);
  const [disabledFields, setDisabledFields] = useState([]);
  const [productTabSearch, setProductTabSearch] = useState(null);

  const [
    productItemStaticFieldsData,
    setProductItemStaticFieldsData,
  ] = useState([]);
  const [
    productItemStaticFieldDataClone,
    setProductItemStaticFieldDataClone,
  ] = useState(productItemStaticFieldsData);
  const [
    productItemStaticFieldOptionData,
    setProductItemStaticFieldOptionData,
  ] = useState({});
  const [
    productItemStaticFieldSelectedOption,
    setProductItemStaticFieldSelectedOption,
  ] = useState(null);
  const [productItemStaticEditData, setProductItemStaticEditData] = useState(
    {},
  );

  // product Tab Static Fields
  const [productStaticFieldsData, setProductStaticFieldsData] = useState([]);
  const [
    productStaticFieldDataClone,
    setProductStaticFieldDataClone,
  ] = useState(productStaticFieldsData);
  const [
    productStaticFieldOptionData,
    setProductStaticFieldOptionData,
  ] = useState({});
  const [productStaticFieldEditData, setProductStaticFieldEditData] = useState(
    {},
  );
  const [
    productStaticFieldSelectedOption,
    setProductStaticFieldSelectedOption,
  ] = useState(null);

  const [initialValue, setInitialValue] = useState({
    isActive: true,
    categoryCondition: CONDITIONS_KEYS?.EQUAL_TO,
    manufacturerCondition: CONDITIONS_KEYS?.EQUAL_TO,
  });
  const [industryLobSaData, setIndustryLobSaData] = useState({
    industryId: null,
    lineOfBusinessId: null,
    subAreaId: null,
  });
  const [compareConfigObj, setCompareConfig] = useState(null);
  const [selectedTab, setSelectedTab] = useState('ProductItem');

  const generateErrorMessage = (entityName, fieldCategory) => {
    messageContext?.destroy();
    messageContext?.error(
      <span className="d-flex align-center">
        Provide proper values for global conditions. check &nbsp;
        <b>{entityName === 'ProductItem' ? 'Product Tab' : 'Package Tab'}</b>
        {entityName === 'ProductItem' && (
          <>
            &rarr;
            <b>{fieldCategory}</b>
          </>
        )}
      </span>,
    );
  };
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [
    addOnProductSuggestionsData,
    setAddOnProductSuggestionsData,
  ] = useState([]);
  const [paginationAddOnProp, setPaginationAddOnProp] = useState(
    initialPaginationValue,
  );

  const {
    pageSize,
    current,
    products,
    search,
    sortBy = 'DESC',
    sortOn,
    where,
    skip,
  } = paginationAddOnProp;

  const onMenuSelect = (e) => {
    setActiveKey(e?.key);
  };

  const onCancel = () => {
    setShowModal(false);
    setActiveKey('general');
  };

  const handleSubmit = () => {
    form?.submit();
  };

  const [fetchUsedSystemFields] = useLazyQuery(GET_USED_SYSTEM_FIELDS, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      setDisabledFields(res?.usedSystemField?.systemField);
      setLoading(false);
    },
    onError() {},
  });

  const [getQuestionnaire] = useLazyQuery(GET_QUESTIONNAIRE, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const initialValueObj = {
        title: res?.questionnaire?.title,
        description: res?.questionnaire?.description,
        isActive: res?.questionnaire?.isActive,
      };
      const questionObj = {
        industry: res?.questionnaire?.industry?.label,
        lineOfBusinesses: res?.questionnaire?.lineOfBusinesses?.label,
      };

      setSelectedProduct(
        res?.questionnaire?.addOnProducts?.data?.map((item) => ({
          id: item?.productItem?.id,
          sku: item?.productItem?.sku,
          name: item?.productItem?.name,
          min: item?.minimumQuantity,
          max: item?.maximumQuantity,
          qualities: item?.qualities,
          industry: item?.productItem?.industry,
          lineOfBusiness: item?.productItem?.lineOfBusiness,
          subArea: item?.productItem?.subArea,
        })) || [],
      );

      setPaginationAddOnProp((prev) => ({
        ...prev,
        skip: 0,
        products: res?.questionnaire?.addOnProducts?.data?.map((item) => ({
          id: item?.productItem?.id,
          sku: item?.productItem?.sku,
          name: item?.productItem?.name,
          min: item?.minimumQuantity,
          max: item?.maximumQuantity,
          qualities: item?.qualities,
        })),
      }));

      dispatch({ type: 'SET_CPQ_QUESTION_OBJECT', data: questionObj });

      setInitialValue(initialValueObj);
      setCompareConfig(res?.questionnaire?.compareConfig);
      setIndustryLobSaData({
        industryId: res?.questionnaire?.industryId,
        lineOfBusinessId: res?.questionnaire?.lineOfBusinessId,
        subAreaId: res?.questionnaire?.subAreaId,
      });
      const checkedIds = [];
      let listOptions = {};
      let productStaticListOption = {};
      let productItemStaticListOption = {};
      map(res?.questionnaire?.globalCondition?.dynamicFields, (field) => {
        if (field?.check === true) {
          checkedIds?.push(field?.identifier);
        }
        if (field?.type === DYNAMIC_FIELDS_DATA_KEYS?.TEXT) {
          form?.setFieldsValue({
            dynamicFields: {
              textCondition: {
                [field?.identifier]: field?.condition,
                value: {
                  [field?.identifier]: field?.value,
                },
              },
            },
          });
        }
        if (field?.type === DYNAMIC_FIELDS_DATA_KEYS?.BOOLEAN) {
          form?.setFieldsValue({
            dynamicFields: {
              booleanCondition: {
                [field?.identifier]: field?.condition,
                value: {
                  [field?.identifier]: field?.value,
                },
              },
            },
          });
        }
        if (field?.type === DYNAMIC_FIELDS_DATA_KEYS?.PICK_LIST) {
          form?.setFieldsValue({
            dynamicFields: {
              listCondition: {
                [field?.identifier]: field?.condition,
              },
            },
          });
          if (!isEmpty(field?.value)) {
            if (
              field?.entityName ===
                SYSTEM_FIELDS_MODULE_OBJECT?.PRODUCT?.value &&
              field?.fieldCategory ===
                SYSTEM_FIELDS_TYPES_OBJECT?.DYNAMIC?.value
            ) {
              setSelectedListOptions((prevStateSelectedList) => ({
                ...prevStateSelectedList,
                [field?.identifier]: field?.value,
              }));
            }
            if (
              field?.entityName ===
                SYSTEM_FIELDS_MODULE_OBJECT?.PRODUCT?.value &&
              field?.fieldCategory === SYSTEM_FIELDS_TYPES_OBJECT?.STATIC?.value
            ) {
              setProductItemStaticFieldSelectedOption((prevState) => ({
                ...prevState,
                [field?.identifier]: field?.value,
              }));
            }
            if (
              field?.entityName ===
                SYSTEM_FIELDS_MODULE_OBJECT?.PACKAGE?.value &&
              field?.fieldCategory === SYSTEM_FIELDS_TYPES_OBJECT?.STATIC?.value
            ) {
              setProductStaticFieldSelectedOption({
                ...productStaticFieldSelectedOption,
                [field?.identifier]: field?.value,
              });
            }
          }
          if (
            (field?.condition === CONDITIONS_KEYS?.EQUAL_TO ||
              field?.condition === CONDITIONS_KEYS?.NOT_EQUAL_TO) &&
            !isEmpty(field?.value)
          ) {
            if (
              field?.entityName ===
                SYSTEM_FIELDS_MODULE_OBJECT?.PRODUCT?.value &&
              field?.fieldCategory ===
                SYSTEM_FIELDS_TYPES_OBJECT?.DYNAMIC?.value
            ) {
              listOptions = {
                ...listOptions,
                [field?.identifier]: field?.value,
              };
            }
            if (
              field?.entityName ===
                SYSTEM_FIELDS_MODULE_OBJECT?.PRODUCT?.value &&
              field?.fieldCategory === SYSTEM_FIELDS_TYPES_OBJECT?.STATIC?.value
            ) {
              productItemStaticListOption = {
                ...productItemStaticListOption,
                [field?.identifier]: field?.value,
              };
            }
            if (
              field?.entityName ===
                SYSTEM_FIELDS_MODULE_OBJECT?.PACKAGE?.value &&
              field?.fieldCategory === SYSTEM_FIELDS_TYPES_OBJECT?.STATIC?.value
            ) {
              productStaticListOption = {
                ...productStaticListOption,
                [field?.identifier]: field?.value,
              };
            }
          }
        }
        if (field?.type === DYNAMIC_FIELDS_DATA_KEYS?.NUMBER) {
          form?.setFieldsValue({
            dynamicFields: {
              numberCondition: {
                [field?.identifier]: field?.condition,
                ...(field?.condition !==
                  CONDITIONS_KEYS?.GREATER_LESS_RANGE && {
                  value: {
                    [field?.identifier]: field?.value,
                  },
                }),
                ...(field?.condition ===
                  CONDITIONS_KEYS?.GREATER_LESS_RANGE && {
                  check: {
                    [field?.identifier]: {
                      lessThan: field?.lessThan?.check,
                      greaterThan: field?.greaterThan?.check,
                    },
                  },
                  value: {
                    [field?.identifier]: {
                      lessThan: field?.lessThan?.value,
                      greaterThan: field?.greaterThan?.value,
                    },
                  },
                }),
              },
            },
          });
        }
      });

      setDynamicFieldEditData(listOptions);
      setProductItemStaticEditData(productItemStaticListOption);
      setProductStaticFieldEditData(productStaticListOption);
      setCheckedFields(checkedIds);
      fetchUsedSystemFields({
        variables: {
          where: {
            questionnaireId: id,
          },
        },
      });
    },
    onError: () => {
      setLoading(false);
    },
  });

  useEffect(() => {
    if (!isEmpty(optionsData) && id && initialValueSet) {
      map(dynamicFieldEditData, (value, key) => {
        const newListOptions = map(optionsData?.[key], (item) => {
          if (item?.key !== value?.[0]) {
            return { ...item, disabled: true };
          }
          return item;
        });
        setOptionsData((prevOptionData) => ({
          ...prevOptionData,
          [key]: newListOptions,
        }));
        setInitialValueSet(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsData, dynamicFieldEditData]);

  useEffect(() => {
    if (
      !isEmpty(productItemStaticFieldOptionData) &&
      id &&
      productItemStaticFieldSet
    ) {
      map(productItemStaticEditData, (value, key) => {
        const newListOptions = map(
          productItemStaticFieldOptionData?.[key],
          (item) => {
            if (item?.key !== value?.[0]) {
              return { ...item, disabled: true };
            }
            return item;
          },
        );
        setProductItemStaticFieldOptionData(
          (prevProductItemStaticFieldOptionData) => ({
            ...prevProductItemStaticFieldOptionData,
            [key]: newListOptions,
          }),
        );
        setProductItemStaticFieldSet(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productItemStaticFieldOptionData, productItemStaticEditData]);

  useEffect(() => {
    if (
      !isEmpty(productStaticFieldOptionData) &&
      id &&
      packageItemStaticFieldSet
    ) {
      map(productStaticFieldEditData, (value, key) => {
        const newListOptions = map(
          productStaticFieldOptionData?.[key],
          (item) => {
            if (item?.key !== value?.[0]) {
              return { ...item, disabled: true };
            }
            return item;
          },
        );
        setProductStaticFieldOptionData((prevProductStaticFieldOptionData) => ({
          ...prevProductStaticFieldOptionData,
          [key]: newListOptions,
        }));
        setPackageItemStaticFieldSet(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productStaticFieldOptionData, productStaticFieldEditData]);

  useEffect(() => {
    setDynamicFieldDataClone(dynamicFieldsData);
  }, [dynamicFieldsData]);

  useEffect(() => {
    if (industryLobSaData?.industryId && !industryLobSaData?.lineOfBusinessId) {
      setDynamicFieldsData([]);
    }
  }, [industryLobSaData]);

  const [fetchCpqStaticFields] = useLazyQuery(FETCH_ALL_STATIC_FIELDS, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      setDynamicFieldsLoading(true);
      if (res?.productItem) {
        const newStaticFieldsArray = res?.productItem?.map(
          (staticFieldObj) => ({
            ...staticFieldObj,
            fieldCategory: SYSTEM_FIELDS_TYPES_OBJECT?.STATIC?.value,
          }),
        );
        setProductItemStaticFieldsData(newStaticFieldsArray);
        setProductItemStaticFieldDataClone(newStaticFieldsArray);
        const optionsList = {};
        const labels = [];
        map(newStaticFieldsArray, (item) => {
          labels?.push(item?.label);
          if (item?.fieldType === DYNAMIC_FIELDS_DATA_KEYS?.PICK_LIST) {
            map(item?.staticFieldListItems, (option) => {
              optionsList[item?.id] = flattenDeep([
                flattenDeep(optionsList[item?.id]),
                {
                  key: option?.id,
                  label: option?.label,
                  fieldId: option?.id,
                },
              ]);
            });
          }
        });
        setProductItemStaticFieldOptionData(optionsList);
      }

      if (res?.product) {
        const newStaticFieldsArray = res?.product?.map((staticFieldObj) => ({
          ...staticFieldObj,
          fieldCategory: SYSTEM_FIELDS_TYPES_OBJECT?.STATIC?.value,
        }));
        setProductStaticFieldsData(newStaticFieldsArray);
        setProductStaticFieldDataClone(newStaticFieldsArray);
        const optionsList = {};
        const labels = [];

        map(newStaticFieldsArray, (item) => {
          labels?.push(item?.label);
          if (
            item?.fieldType === DYNAMIC_FIELDS_DATA_KEYS?.PICK_LIST ||
            item?.fieldType === DYNAMIC_FIELDS_DATA_KEYS?.ENUM_PICK_LIST
          ) {
            map(item?.staticFieldListItems, (option) => {
              optionsList[item?.id] = flattenDeep([
                flattenDeep(optionsList[item?.id]),
                {
                  key: option?.id || option?.key, // added option?.key for ENUM_PICK_LIST as we don't have a id for enum types e.x -> package quality ,status
                  label: option?.label,
                  fieldId: option?.id,
                },
              ]);
            });
          }
        });
        setProductStaticFieldOptionData(optionsList);
      }
      setDynamicFieldsLoading(false);
    },
    onError() {
      setDynamicFieldsLoading(false);
    },
  });
  const [fetchCpqDynamicFields] = useLazyQuery(CPQ_DYNAMIC_FIELDS, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      const newDynamicFieldsArray = res?.dynamicFieldsWithListItems?.map(
        (dynamicFieldObj) => ({
          ...dynamicFieldObj,
          fieldCategory: SYSTEM_FIELDS_TYPES_OBJECT?.DYNAMIC?.value,
          entityName: SYSTEM_FIELDS_MODULE_OBJECT?.PRODUCT?.value,
        }),
      );
      setDynamicFieldsData(newDynamicFieldsArray);
      setDynamicFieldDataClone(newDynamicFieldsArray);
      const optionsList = {};
      const labels = [];
      map(newDynamicFieldsArray, (item) => {
        labels?.push(item?.label);
        if (item?.fieldType === DYNAMIC_FIELDS_DATA_KEYS?.PICK_LIST) {
          map(item?.dynamicFieldListItems, (option) => {
            optionsList[item?.id] = flattenDeep([
              flattenDeep(optionsList[item?.id]),
              {
                key: option?.id,
                label: option?.label,
                fieldId: option?.fieldId,
              },
            ]);
          });
        }
      });
      setOptionsData(optionsList);
      setDynamicFieldsLoading(false);
    },
    onError() {
      setDynamicFieldsLoading(false);
    },
  });

  const [fetchCpqAddonProductFields, { loading: addOnLoading }] = useLazyQuery(
    GET_SUGGESTED_PRODUCT,
    {
      fetchPolicy: 'network-only',
      onError() {
        setDynamicFieldsLoading(false);
      },
      onCompleted(res) {
        const pagination = {
          ...paginationAddOnProp,
          total: res?.addOnProductSuggestions?.count,
        };
        setPaginationAddOnProp(pagination);

        setAddOnProductSuggestionsData(res?.addOnProductSuggestions?.data);
      },
    },
  );
  const [fetchCpqIndustries] = useLazyQuery(CPQ_INDUSTRIES, {
    variables: {
      filter: {
        skip: 0,
        justShow: true,
      },
      where: {
        isActive: [true],
      },
    },
    fetchPolicy: 'network-only',
    onCompleted(res) {
      const industry = res?.industries?.data?.[0];
      const lob = industry?.lobSaData?.[0];
      const sa = lob?.subAreas?.[0];
      setIndustries(res?.industries?.data);
      setIndustryLobSaData({
        industryId: industry?.id,
        lineOfBusinessId: lob?.id,
        subAreaId: sa?.id,
      });
      if (id) {
        setLoading(true);
        getQuestionnaire({
          variables: {
            id,
          },
        });
      }
      setIndustriesLoading(false);
    },
    onError() {
      setIndustriesLoading(false);
    },
  });

  const [createQuestionnaire] = useMutation(CREATE_QUESTIONNAIRES, {
    onError: () => {
      setSubmitLoading(false);
    },
  });

  const [updateQuestionnaire] = useMutation(UPDATE_QUESTIONNAIRES, {
    onError: () => {
      setSubmitLoading(false);
    },
  });

  const handleProductTabSearch = (value) => {
    if (value) {
      setProductTabSearch(value);
      const filteredFields = [];
      const productItemFilteredStaticFields = [];
      const productFilteredStaticFields = [];

      setDynamicFieldsLoading(true);
      map(dynamicFieldsData, (data) => {
        if (data?.label?.toLowerCase()?.includes(trim(value)?.toLowerCase())) {
          filteredFields?.push(data);
        }
      });
      map(productItemStaticFieldsData, (data) => {
        if (data?.label?.toLowerCase()?.includes(trim(value)?.toLowerCase())) {
          productItemFilteredStaticFields?.push(data);
        }
      });
      map(productStaticFieldsData, (data) => {
        if (data?.label?.toLowerCase()?.includes(trim(value)?.toLowerCase())) {
          productFilteredStaticFields?.push(data);
        }
      });
      setDynamicFieldDataClone(filteredFields);
      setProductItemStaticFieldDataClone(productItemFilteredStaticFields);
      setProductStaticFieldDataClone(productFilteredStaticFields);

      setDynamicFieldsLoading(false);
    } else {
      setProductTabSearch('');
      setProductItemStaticFieldDataClone([]);
      setDynamicFieldDataClone([]);
      setDynamicFieldsLoading(true);
      fetchCpqDynamicFields({
        variables: {
          where: {
            fieldType: [
              DYNAMIC_FIELDS_DATA_KEYS?.PICK_LIST,
              DYNAMIC_FIELDS_DATA_KEYS?.TEXT,
              DYNAMIC_FIELDS_DATA_KEYS?.BOOLEAN,
              DYNAMIC_FIELDS_DATA_KEYS?.NUMBER,
            ],
            industry: [industryLobSaData?.industryId],
            lineOfBusiness: [industryLobSaData?.lineOfBusinessId],
          },
        },
      });
      setProductStaticFieldDataClone([]);
      fetchCpqStaticFields();
    }
  };

  useEffect(() => {
    fetchCpqIndustries();
    fetchCpqStaticFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (industryLobSaData?.industryId && industryLobSaData?.lineOfBusinessId) {
      fetchCpqDynamicFields({
        variables: {
          where: {
            fieldType: [
              DYNAMIC_FIELDS_DATA_KEYS?.PICK_LIST,
              DYNAMIC_FIELDS_DATA_KEYS?.TEXT,
              DYNAMIC_FIELDS_DATA_KEYS?.BOOLEAN,
              DYNAMIC_FIELDS_DATA_KEYS?.NUMBER,
            ],
            industry: [industryLobSaData?.industryId],
            lineOfBusiness: [industryLobSaData?.lineOfBusinessId],
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [industryLobSaData]);

  useEffect(() => {
    if (
      industryLobSaData?.industryId &&
      industryLobSaData?.lineOfBusinessId &&
      industryLobSaData?.subAreaId
    ) {
      const { name = [] } = where ?? {};
      const existingIds = products?.map((item) => item?.id);

      fetchCpqAddonProductFields({
        variables: {
          filter: {
            skip,
            limit: pageSize,
            ...(search && { search }),
            ...(sortOn && { sortOn }),
            ...(sortBy && { sortBy }),
          },
          where: {
            industryId: industryLobSaData?.industryId,
            lineOfBusinessId: industryLobSaData?.lineOfBusinessId,
            subAreaId: industryLobSaData?.subAreaId,
            ...(existingIds?.length > 0 && {
              existingAddOnProductIds: existingIds,
            }),
            ...(id && { questionnaireId: id }),
            ...(name?.length > 0 && {
              name,
            }),
          },
        },
      });
    }
  }, [
    industryLobSaData,
    pageSize,
    current,
    products,
    search,
    sortBy,
    sortOn,
    where,
    fetchCpqAddonProductFields,
    id,
    skip,
  ]);

  const onFinish = async (formValues) => {
    let hasError = false;
    const allFields = [
      ...dynamicFieldsData,
      ...productItemStaticFieldsData,
      ...productStaticFieldsData,
    ];

    if (industryLobSaData?.industryId && !industryLobSaData?.lineOfBusinessId) {
      messageContext?.error('Please provide Line of Business');
      return;
    }
    setSubmitLoading(true);
    const uuid = formValues?.uuid || uuidv4();
    const getFieldValues = {};
    const finalDynamicFields = [];
    const getCheckedValues = {};
    let fieldType;

    map(formValues?.dynamicFields, (item, fieldKey) => {
      if (fieldKey === 'listCondition') {
        fieldType = DYNAMIC_FIELDS_DATA_KEYS?.PICK_LIST;
      } else if (fieldKey === 'numberCondition') {
        fieldType = DYNAMIC_FIELDS_DATA_KEYS?.NUMBER;
      } else if (fieldKey === 'booleanCondition') {
        fieldType = DYNAMIC_FIELDS_DATA_KEYS?.BOOLEAN;
      } else {
        fieldType = DYNAMIC_FIELDS_DATA_KEYS?.TEXT;
      }

      if (item?.value) {
        forIn(item?.value, (value, key) => {
          getFieldValues[key] = value;
        });
      }
      if (item?.check) {
        forIn(item?.check, (value, key) => {
          getCheckedValues[key] = value;
        });
      }

      map(item, (data, itemKey) => {
        const entityName = allFields?.find((field) => field?.id === itemKey)
          ?.entityName;
        const fieldCategory = allFields?.find((field) => field?.id === itemKey)
          ?.fieldCategory;
        if (
          checkedFields?.includes(itemKey) &&
          fieldKey !== 'listCondition' &&
          (!getFieldValues?.[itemKey] ||
            isEmpty(getFieldValues?.[itemKey]) ||
            (data === CONDITIONS_KEYS?.GREATER_LESS_RANGE &&
              !getCheckedValues?.[itemKey]?.greaterThan &&
              !getCheckedValues?.[itemKey]?.lessThan))
        ) {
          generateErrorMessage(entityName, fieldCategory);
          hasError = true;
          return;
        }

        if (checkedFields?.includes(itemKey) && fieldKey === 'listCondition') {
          if (
            entityName === SYSTEM_FIELDS_MODULE_OBJECT?.PRODUCT?.value &&
            fieldCategory === SYSTEM_FIELDS_TYPES_OBJECT?.DYNAMIC?.value
          ) {
            if (
              !selectedListOptions?.[itemKey] ||
              isEmpty(selectedListOptions?.[itemKey])
            ) {
              generateErrorMessage(entityName, fieldCategory);
              hasError = true;
              return;
            }
          }
          if (
            entityName === SYSTEM_FIELDS_MODULE_OBJECT?.PRODUCT?.value &&
            fieldCategory === SYSTEM_FIELDS_TYPES_OBJECT?.STATIC?.value
          ) {
            if (
              !productItemStaticFieldSelectedOption?.[itemKey] ||
              isEmpty(productItemStaticFieldSelectedOption?.[itemKey])
            ) {
              generateErrorMessage(entityName, fieldCategory);
              hasError = true;
              return;
            }
          }
          if (
            entityName === SYSTEM_FIELDS_MODULE_OBJECT?.PACKAGE?.value &&
            fieldCategory === SYSTEM_FIELDS_TYPES_OBJECT?.STATIC?.value
          ) {
            if (
              !productStaticFieldSelectedOption?.[itemKey] ||
              isEmpty(productStaticFieldSelectedOption?.[itemKey])
            ) {
              generateErrorMessage(entityName, fieldCategory);
              hasError = true;
              return;
            }
          }
        }

        if (
          (getCheckedValues?.[itemKey]?.greaterThan &&
            !getFieldValues?.[itemKey]?.greaterThan) ||
          (getCheckedValues?.[itemKey]?.lessThan &&
            !getFieldValues?.[itemKey]?.lessThan)
        ) {
          generateErrorMessage(entityName, fieldCategory);
          hasError = true;
        }
        let fieldValue;
        if (getFieldValues[itemKey]) {
          if (isArray(getFieldValues[itemKey])) {
            fieldValue = getFieldValues?.[itemKey];
          } else {
            fieldValue = [getFieldValues?.[itemKey]];
          }
        } else if (fieldKey === 'listCondition') {
          if (selectedListOptions?.[itemKey]) {
            fieldValue = selectedListOptions?.[itemKey];
          }
          if (productItemStaticFieldSelectedOption?.[itemKey]) {
            fieldValue = productItemStaticFieldSelectedOption?.[itemKey];
          }
          if (productStaticFieldSelectedOption?.[itemKey]) {
            fieldValue = productStaticFieldSelectedOption?.[itemKey];
          }
        } else {
          fieldValue = [];
        }
        if (itemKey !== 'value' && itemKey !== 'check') {
          finalDynamicFields?.push({
            identifier: itemKey,
            condition: data,
            ...(!(data === CONDITIONS_KEYS?.GREATER_LESS_RANGE) && {
              value: fieldValue,
            }),
            check: checkedFields?.includes(itemKey),
            ...(data === CONDITIONS_KEYS?.GREATER_LESS_RANGE && {
              greaterThan: {
                check: getCheckedValues?.[itemKey]?.greaterThan,
                value: getFieldValues?.[itemKey]?.greaterThan,
              },
              lessThan: {
                check: getCheckedValues?.[itemKey]?.lessThan,
                value: getFieldValues?.[itemKey]?.lessThan,
              },
            }),
            type: fieldType,
            fieldCategory,
            entityName,
            isFieldQueryable: true,
          });
        }
      });
    });
    if (hasError) {
      setSubmitLoading(false);
      return;
    }

    let variables = {
      ...formValues,
      industryId: industryLobSaData?.industryId,
      lineOfBusinessId: industryLobSaData?.lineOfBusinessId,
      subAreaId: industryLobSaData?.subAreaId,
      uuid,
      type: QUESTIONNAIRE_TYPE?.ROQ_PATH,
      globalCondition: {
        dynamicFields: finalDynamicFields,
      },
      addOnProducts: {
        data: selectedProduct?.map((item) => ({
          productItemId: item?.id,
          maximumQuantity: item?.max,
          minimumQuantity: item?.min,
          qualities: item?.qualities,
        })),
      },
    };
    variables = omit(variables, [
      'categoryCondition',
      'manufacturerCondition',
      'numberCondition',
      'dynamicFields',
    ]);
    try {
      if (id) {
        const response = await updateQuestionnaire({
          variables: {
            data: variables,
            where: {
              id,
            },
          },
        });
        if (response?.data?.updateQuestionnaire) {
          if (isCreate) {
            setRefetchSystemFields(true);
          }
          setShowModal(false);
          setActiveKey('general');
          setSubmitLoading(false);
          if (refetchData) {
            refetchData();
          }
          if (refetchQuestionnaire && id) {
            refetchQuestionnaire();
          }
        }
      } else {
        const response = await createQuestionnaire({
          variables: {
            data: variables,
          },
        });
        if (response?.data?.createQuestionnaire) {
          setSubmitLoading(false);
          navigate(
            `${ROUTES?.QUESTIONNAIRES_SECONDARY}/${
              response?.data?.createQuestionnaire?.data?.id
            }${ROUTES?.PAGE}/${
              response?.data?.createQuestionnaire?.data?.pageKey
                ? kebabCase(response?.data?.createQuestionnaire?.data?.pageKey)
                : 'create'
            }?sp=false`,
          );
        }
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };

  const onFinishFailed = (values) => {
    setActiveKey(
      values?.errorFields?.[0].name?.[0] !== 'compareConfig'
        ? 'general'
        : 'compare-quote-variants',
    );
    setValidationTriggered(true);
  };

  return (
    <ModalComponent
      width={MODAL_WIDTH}
      open={showModal}
      onCancel={onCancel}
      footer={null}
      destroyOnClose
      wrapClassName="setting-modal"
    >
      <Layout>
        <Sider className="setting-layout">
          <div className="sider-header">
            <span>SETTINGS</span>
          </div>
          <Menu
            items={[
              { key: 'general', label: 'General' },
              { key: 'global-conditions', label: 'Global Conditions' },
              {
                key: 'compare-quote-variants',
                label: 'Compare Quote Variants',
              },
              { key: 'product-add-on', label: 'Add on Products' },
            ]}
            theme="light"
            selectedKeys={activeKey}
            onSelect={onMenuSelect}
          />
        </Sider>
        <Layout className="setting-modal-content">
          <div>
            <div className="content-wrapper">
              {industriesLoading || loading ? (
                <LoaderComponent setHeight={75} />
              ) : (
                <Form
                  layout="vertical"
                  onFinish={onFinish}
                  form={form}
                  onValuesChange={() => {
                    setIsFormValuesChanged(true);
                  }}
                  validateTrigger={
                    validationTriggered ? 'onChange' : 'onSubmit'
                  }
                  onFinishFailed={onFinishFailed}
                  initialValues={initialValue}
                >
                  <div className={activeKey === 'general' ? '' : 'hide-form'}>
                    <SecondaryGeneralForm
                      industriesData={industries}
                      industryLobSaData={industryLobSaData}
                      setIndustryLobSaData={setIndustryLobSaData}
                      setIsFormValuesChanged={setIsFormValuesChanged}
                      setCheckedFields={setCheckedFields}
                      id={id}
                    />
                  </div>
                  <div
                    className={
                      activeKey === 'global-conditions' ? '' : 'hide-form'
                    }
                  >
                    <GlobalConditionsForm
                      form={form}
                      setIsFormValuesChanged={setIsFormValuesChanged}
                      dynamicFieldsData={dynamicFieldsData}
                      selectedListOptions={selectedListOptions}
                      setSelectedListOptions={setSelectedListOptions}
                      checkedFields={checkedFields}
                      setCheckedFields={setCheckedFields}
                      optionsData={optionsData}
                      setOptionsData={setOptionsData}
                      industryLobSaData={industryLobSaData}
                      dynamicFieldDataClone={dynamicFieldDataClone}
                      setDynamicFieldDataClone={setDynamicFieldDataClone}
                      dynamicFieldsLoading={dynamicFieldsLoading}
                      setDynamicFieldsLoading={setDynamicFieldsLoading}
                      disabledFields={disabledFields}
                      // search...
                      productTabSearch={productTabSearch}
                      setProductTabSearch={setProductTabSearch}
                      handleProductTabSearch={handleProductTabSearch}
                      // product item tab static fields
                      productItemStaticFieldDataClone={
                        productItemStaticFieldDataClone
                      }
                      setProductItemStaticFieldDataClone={
                        setProductItemStaticFieldDataClone
                      }
                      setStaticFieldOptionData={
                        setProductItemStaticFieldOptionData
                      }
                      staticFieldOptionData={productItemStaticFieldOptionData}
                      staticFieldSelectedOption={
                        productItemStaticFieldSelectedOption
                      }
                      setStaticFieldSelectedOption={
                        setProductItemStaticFieldSelectedOption
                      }
                      // product tabs static fields
                      productStaticFieldDataClone={productStaticFieldDataClone}
                      setProductStaticFieldsData={setProductStaticFieldsData}
                      productStaticFieldOptionData={
                        productStaticFieldOptionData
                      }
                      setProductStaticFieldOptionData={
                        setProductStaticFieldOptionData
                      }
                      productStaticFieldSelectedOption={
                        productStaticFieldSelectedOption
                      }
                      setProductStaticFieldSelectedOption={
                        setProductStaticFieldSelectedOption
                      }
                      // tabs state
                      setSelectedTab={setSelectedTab}
                      selectedTab={selectedTab}
                    />
                  </div>
                  <div
                    className={
                      activeKey === 'compare-quote-variants' ? '' : 'hide-form'
                    }
                  >
                    <CompareQuoteVariants
                      fetchDynamicFields={fetchCpqDynamicFields}
                      industryLobSaData={industryLobSaData}
                      dynamicFieldsData={dynamicFieldsData}
                      compareConfig={compareConfig}
                      setValidationTriggered={setValidationTriggered}
                      form={form}
                      compareConfigObj={compareConfigObj}
                    />
                  </div>
                  <div
                    className={
                      activeKey === 'product-add-on' ? '' : 'hide-form'
                    }
                  >
                    <ProductAddOn
                      selectedProduct={selectedProduct}
                      setSelectedProduct={setSelectedProduct}
                      addOnProductSuggestions={addOnProductSuggestionsData}
                      paginationProp={paginationAddOnProp}
                      setPaginationProp={setPaginationAddOnProp}
                      questionnaireId={id}
                      setIsFormValuesChanged={setIsFormValuesChanged}
                      addOnLoading={addOnLoading}
                      industries={{
                        industryId: industryLobSaData?.industryId,
                        lineOfBusinessId: industryLobSaData?.lineOfBusinessId,
                        subAreaId: industryLobSaData?.subAreaId,
                      }}
                    />
                  </div>
                </Form>
              )}
            </div>
          </div>
          <Footer>
            <Button
              className="setting-btn fill-width"
              size="small"
              htmlType="submit"
              id="setting-save-btn"
              loading={submitLoading}
              type="primary"
              onClick={handleSubmit}
              disabled={!isFormValuesChanged}
            >
              {id ? 'Update Questionnaire' : 'Create Questionnaire'}
            </Button>
          </Footer>
        </Layout>
      </Layout>
    </ModalComponent>
  );
};

export default SecondarySettingModal;
