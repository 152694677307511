import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import {
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Form,
  Row,
  Switch,
  TimePicker,
} from 'antd';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { DAYS_OF_WEEK, ROUTES } from '../../../common/constants';
import { formValidatorRules, minutesOfDay } from '../../../common/utils';
import InputComponent from '../../../components/InputComponent';
import SelectComponent from '../../../components/SelectComponent';

const { required, requiredWhiteSpaceAllowed } = formValidatorRules;

const CutoffTimeForm = ({
  isAppointmentEnabled,
  setIsAppointmentEnabled,
  form,
}) => {
  const slots = form?.getFieldValue(['appointmentWindow', 'slots']);
  useEffect(() => {
    if (isAppointmentEnabled && isEmpty(slots)) {
      form?.setFieldsValue({
        appointmentWindow: {
          slots: [{}],
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAppointmentEnabled]);

  const isMonChecked = Form?.useWatch(
    ['cutOffTime', 'monday', 'checked'],
    form,
  );
  const isTueChecked = Form?.useWatch(
    ['cutOffTime', 'tuesday', 'checked'],
    form,
  );
  const isWedChecked = Form?.useWatch(
    ['cutOffTime', 'wednesday', 'checked'],
    form,
  );
  const isThuChecked = Form?.useWatch(
    ['cutOffTime', 'thursday', 'checked'],
    form,
  );
  const isFriChecked = Form?.useWatch(
    ['cutOffTime', 'friday', 'checked'],
    form,
  );
  const isSatChecked = Form?.useWatch(
    ['cutOffTime', 'saturday', 'checked'],
    form,
  );
  const isSunChecked = Form?.useWatch(
    ['cutOffTime', 'sunday', 'checked'],
    form,
  );
  return (
    <Card className="card-wrapper">
      <div className="setting-content-heading">
        <h5>DAYS OFF</h5>
        <span className="panel-description">Define days off of the week</span>
      </div>
      <Row className="setting-content">
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Form.Item name={['daysOff', 'days']}>
            <SelectComponent
              aria-label="Days Off"
              options={DAYS_OF_WEEK}
              mode="multiple"
              placeholder="Add Days Off"
              showArrow
              showSearch={false}
            />
          </Form.Item>
        </Col>
        <Form.Item
          name={['daysOff', 'allowBookingOnHolidays']}
          valuePropName="checked"
        >
          <Checkbox className="common-checkbox">
            Allow booking on days off and
            <Link
              to={ROUTES?.HOURS_OF_OPERATION}
              target="_blank"
              className="ml-4 select-all-text font-size-14"
            >
              Holidays
            </Link>
          </Checkbox>
        </Form.Item>
      </Row>
      <Divider className="mb-12 mt-0" />
      <div className="setting-content-heading">
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <h5 className="d-flex align-baseline m-0">
              APPOINTMENT WINDOWS{' '}
              <Form.Item name={['appointmentWindow', 'active']} className="m-0">
                <Switch
                  className="common-switch ml-8 m-0"
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  onChange={(e) => {
                    setIsAppointmentEnabled(e);
                  }}
                  checked={isAppointmentEnabled}
                />
              </Form.Item>
            </h5>
          </Col>
        </Row>
        <span className="panel-description">
          Define time ranges for appointments
        </span>
      </div>
      <Row
        className={`setting-content ${
          isAppointmentEnabled ? '' : 'display-none'
        }`}
      >
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Form.Item
            name={['appointmentWindow', 'showLabel']}
            valuePropName="checked"
            hidden
          >
            <Checkbox className="common-checkbox">Show Label</Checkbox>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Form.Item
            name={['appointmentWindow', 'showTime']}
            valuePropName="checked"
            hidden={!isAppointmentEnabled}
          >
            <Checkbox className="common-checkbox">Show Time in ROQ</Checkbox>
          </Form.Item>
        </Col>
        <Form.List name={['appointmentWindow', 'slots']}>
          {(fields, { add, remove }) => (
            <Row className="fill-width appointment-window">
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                {fields?.map(({ key, name, fieldKey, ...restField }, index) => (
                  <div
                    key={key}
                    className="d-flex align-center mb-12 fill-width"
                  >
                    <Row gutter={[16, 16]} className="fill-width">
                      <Col span={11}>
                        <Form.Item
                          className="fill-width"
                          {...restField}
                          name={[name, 'label']}
                          fieldKey={[fieldKey, 'label']}
                          label={index === 0 && 'Label'}
                          hidden={!isAppointmentEnabled}
                          rules={
                            isAppointmentEnabled
                              ? [
                                  {
                                    ...required,
                                    message: 'Please Enter Label',
                                  },
                                ]
                              : []
                          }
                        >
                          <InputComponent placeholder="Add label" />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          className="fill-width error-border"
                          {...restField}
                          name={[name, 'from']}
                          fieldKey={[fieldKey, 'from']}
                          label={index === 0 && 'From'}
                          hidden={!isAppointmentEnabled}
                          rules={
                            isAppointmentEnabled
                              ? [
                                  {
                                    ...requiredWhiteSpaceAllowed,
                                    message: 'Please Enter From time',
                                  },
                                  ({ getFieldValue }) => ({
                                    validator(_, value) {
                                      if (index === 0 || !value) {
                                        return Promise?.resolve();
                                      }
                                      if (
                                        getFieldValue([
                                          'appointmentWindow',
                                          'slots',
                                        ])?.[index - 1]?.to
                                      ) {
                                        if (
                                          minutesOfDay(value) >=
                                          minutesOfDay(
                                            getFieldValue([
                                              'appointmentWindow',
                                              'slots',
                                            ])?.[index - 1]?.to,
                                          )
                                        ) {
                                          return Promise?.resolve();
                                        }
                                      }

                                      return Promise?.reject(
                                        new Error(
                                          'Start time can not be less than previous end time',
                                        ),
                                      );
                                    },
                                  }),
                                ]
                              : []
                          }
                        >
                          <TimePicker
                            className="common-time-picker appointment-picker"
                            use12Hours
                            format="h:mm a"
                            allowClear={false}
                            placeholder="Select time"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          className="fill-width error-border"
                          {...restField}
                          name={[name, 'to']}
                          fieldKey={[fieldKey, 'to']}
                          label={index === 0 && 'To'}
                          hidden={!isAppointmentEnabled}
                          dependencies={[
                            ['appointmentWindow', 'slots', name, 'from'],
                          ]}
                          rules={
                            isAppointmentEnabled
                              ? [
                                  {
                                    ...requiredWhiteSpaceAllowed,
                                    message: 'Please Enter To time',
                                  },
                                  ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                      if (!value) {
                                        return Promise?.resolve();
                                      }
                                      let startTime;
                                      if (
                                        getFieldValue([
                                          'appointmentWindow',
                                          'slots',
                                        ])?.[index]?.from
                                      ) {
                                        startTime = getFieldValue([
                                          'appointmentWindow',
                                          'slots',
                                        ])?.[index]?.from;
                                        const endTime = value;
                                        if (endTime?.isBefore(startTime)) {
                                          return Promise?.reject(
                                            new Error(
                                              'To time must be after From time',
                                            ),
                                          );
                                        }
                                      }
                                      return Promise?.resolve();
                                    },
                                  }),
                                ]
                              : []
                          }
                        >
                          <TimePicker
                            className="common-time-picker appointment-picker"
                            use12Hours
                            format="h:mm a"
                            allowClear={false}
                            placeholder="Select time"
                          />
                        </Form.Item>
                      </Col>
                      {fields?.length > 1 && (
                        <Col span={1}>
                          <Form.Item
                            label={index === 0 && ' '}
                            hidden={!isAppointmentEnabled}
                          >
                            <DeleteOutlined
                              className="delete-icon"
                              onClick={() => remove(name)}
                            />
                          </Form.Item>
                        </Col>
                      )}
                    </Row>
                  </div>
                ))}
              </Col>
              <Form.Item hidden={!isAppointmentEnabled}>
                <Button
                  icon={<PlusCircleOutlined />}
                  onClick={() => {
                    add();
                  }}
                  className="add-link-btn discard-button common-button"
                >
                  Add New
                </Button>
              </Form.Item>
            </Row>
          )}
        </Form.List>
      </Row>

      <Divider />
      <div className="setting-content-heading">
        <h5>CUT-OFF TIMES</h5>
        <span className="panel-description">
          Set specific times for selected days of the week. When this feature is
          enabled, it activates the "Same Day Installation Timer" in the ROQ
          builder.
        </span>
      </div>
      <div className="setting-content">
        <div className="cut-off-form">
          <Form.Item
            name={['cutOffTime', 'monday', 'checked']}
            valuePropName="checked"
          >
            <Checkbox className="common-checkbox">Monday</Checkbox>
          </Form.Item>
          <span>
            <Form.Item
              name={['cutOffTime', 'monday', 'time']}
              hidden={!isMonChecked}
            >
              <TimePicker
                className="common-time-picker"
                use12Hours
                format="h:mm a"
                allowClear={false}
              />
            </Form.Item>
          </span>
        </div>
        <div className="cut-off-form">
          <Form.Item
            name={['cutOffTime', 'tuesday', 'checked']}
            valuePropName="checked"
          >
            <Checkbox className="common-checkbox">Tuesday</Checkbox>
          </Form.Item>
          <span>
            <Form.Item
              name={['cutOffTime', 'tuesday', 'time']}
              hidden={!isTueChecked}
            >
              <TimePicker
                className="common-time-picker"
                use12Hours
                format="h:mm a"
                allowClear={false}
              />
            </Form.Item>
          </span>
        </div>
        <div className="cut-off-form">
          <Form.Item
            name={['cutOffTime', 'wednesday', 'checked']}
            valuePropName="checked"
          >
            <Checkbox className="common-checkbox">Wednesday</Checkbox>
          </Form.Item>
          <span>
            <Form.Item
              name={['cutOffTime', 'wednesday', 'time']}
              hidden={!isWedChecked}
            >
              <TimePicker
                className="common-time-picker"
                use12Hours
                format="h:mm a"
                allowClear={false}
              />
            </Form.Item>
          </span>
        </div>
        <div className="cut-off-form">
          <Form.Item
            name={['cutOffTime', 'thursday', 'checked']}
            valuePropName="checked"
          >
            <Checkbox className="common-checkbox">Thursday</Checkbox>
          </Form.Item>
          <span>
            <Form.Item
              name={['cutOffTime', 'thursday', 'time']}
              hidden={!isThuChecked}
            >
              <TimePicker
                className="common-time-picker"
                use12Hours
                format="h:mm a"
                allowClear={false}
              />
            </Form.Item>
          </span>
        </div>
        <div className="cut-off-form">
          <Form.Item
            name={['cutOffTime', 'friday', 'checked']}
            valuePropName="checked"
          >
            <Checkbox className="common-checkbox">Friday</Checkbox>
          </Form.Item>
          <span>
            <Form.Item
              name={['cutOffTime', 'friday', 'time']}
              hidden={!isFriChecked}
            >
              <TimePicker
                className="common-time-picker"
                use12Hours
                format="h:mm a"
                allowClear={false}
              />
            </Form.Item>
          </span>
        </div>
        <div className="cut-off-form">
          <Form.Item
            name={['cutOffTime', 'saturday', 'checked']}
            valuePropName="checked"
          >
            <Checkbox className="common-checkbox">Saturday</Checkbox>
          </Form.Item>
          <span>
            <Form.Item
              name={['cutOffTime', 'saturday', 'time']}
              hidden={!isSatChecked}
            >
              <TimePicker
                className="common-time-picker"
                use12Hours
                format="h:mm a"
                allowClear={false}
              />
            </Form.Item>
          </span>
        </div>
        <div className="cut-off-form">
          <Form.Item
            name={['cutOffTime', 'sunday', 'checked']}
            valuePropName="checked"
          >
            <Checkbox className="common-checkbox">Sunday</Checkbox>
          </Form.Item>
          <span>
            <Form.Item
              name={['cutOffTime', 'sunday', 'time']}
              hidden={!isSunChecked}
            >
              <TimePicker
                className="common-time-picker"
                use12Hours
                format="h:mm a"
                allowClear={false}
              />
            </Form.Item>
          </span>
        </div>
      </div>
    </Card>
  );
};

export default CutoffTimeForm;
