import { MoreOutlined } from '@ant-design/icons';
import React, { useContext, useState } from 'react';
import { AppContext } from '../../../AppContext';
import AccessControl from '../../../components/AccessControl';
import TableComponent from '../../../components/TableComponent';

const initialPaginationValue = {
  total: 0,
  current: 1,
};

const data = [];

const EntityFilterTable = () => {
  // will remove this in future
  // eslint-disable-next-line no-unused-vars
  const [sortedInfo, setSortedInfo] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);

  const {
    state: { pageSize },
  } = useContext(AppContext);

  const columns = [
    {
      title: 'ENTITY',
      dataIndex: 'entity',
      key: 'entity',
      sorter: true,
      ellipsis: true,
      className: 'max-width-column',
      sortOrder: sortedInfo?.columnKey === 'entity' && sortedInfo?.order,
    },
    {
      title: 'FILTERS',
      dataIndex: 'filters',
      key: 'filters',
      sorter: true,
      ellipsis: true,
      className: 'max-width-column',
      sortOrder: sortedInfo?.columnKey === 'filters' && sortedInfo?.order,
    },
    {
      title: 'TOTAL RECORDS',
      dataIndex: 'totalRecords',
      key: 'totalRecords',
      sorter: true,
      ellipsis: true,
      className: 'max-width-column',
      sortOrder: sortedInfo?.columnKey === 'totalRecords' && sortedInfo?.order,
    },
    {
      title: 'PROCEED RECORDS',
      dataIndex: 'proceedRecords',
      key: 'proceedRecords',
      sorter: true,
      ellipsis: true,
      className: 'max-width-column',
      sortOrder:
        sortedInfo?.columnKey === 'proceedRecords' && sortedInfo?.order,
    },
    {
      title: 'FAILED RECORDS',
      dataIndex: 'failedRecords',
      key: 'failedRecords',
      sorter: true,
      ellipsis: true,
      className: 'max-width-column',
      sortOrder: sortedInfo?.columnKey === 'failedRecords' && sortedInfo?.order,
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      ellipsis: true,
      className: 'max-width-column',
      sortOrder: sortedInfo?.columnKey === 'status' && sortedInfo?.order,
    },
    /* kep for future use
     checkPermissions(permissions, [
      'FET_BRAND_UPDATE',
      'FET_BRAND_DELETE',
      'FET_BRAND_VIEW',
    ]) &&  */ {
      dataIndex: 'id',
      align: 'right',
      width: 10,
      fixed: 'right',
      render: () => <MoreOutlined />,
    },
  ];

  return (
    <div>
      <AccessControl allowedPermissions={['FET_PRODUCT_LIST']} showNoAccess>
        <div className="common-table">
          {pageSize && (
            <TableComponent
              columns={[...columns?.filter((item) => item !== false)]}
              data={data}
              paginationConfig={paginationProp}
              rowKey={(obj) => obj?.id}
            />
          )}
        </div>
      </AccessControl>
    </div>
  );
};

export default EntityFilterTable;
