import { WarningOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Alert, Button, Layout } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AppContext } from '../AppContext';
import { ROUTES } from '../common/constants';
import { UPDATE_VERIFY_EMAIL } from '../components/graphql/Mutation';
import HotKeysModal from '../components/HotKeys/HotKeysModal';
import './App.css';
import HeaderWrapper from './components/header';
import Sidebar from './components/sidebar/Sidebar';
import SidePanel from './components/sidepanel';
import ContentRoutes from './ContentRoutes';

const { Content } = Layout;

const App = () => {
  const {
    // commented as not working properly
    state: { currentUser /* showPrompt */ },
  } = useContext(AppContext);

  const [visibleAlert, setVisibleAlert] = useState(
    !currentUser?.emailVerified || false,
  );

  const [emailLoading, setEmailLoading] = useState(false);

  useEffect(() => {
    setVisibleAlert(!currentUser?.emailVerified);
  }, [currentUser]);

  const [updateBrandLob] = useMutation(UPDATE_VERIFY_EMAIL, {
    onCompleted: () => {
      setEmailLoading(false);
    },
    onError: () => {
      setEmailLoading(false);
    },
  });

  const location = useLocation();
  const { pathname = '' } = location;

  const handleCloseAlert = () => {
    setVisibleAlert(false);
  };

  const handleResendEmail = () => {
    setEmailLoading(true);
    updateBrandLob();
  };

  return (
    <>
      {!pathname?.includes(ROUTES?.ONBOARDING) && visibleAlert && (
        <Alert
          type="error"
          icon={<WarningOutlined />}
          message={
            <div className="d-flex">
              <span>Verify Your Email Address.</span>
              <Button
                size="small"
                onClick={handleResendEmail}
                loading={emailLoading}
                type="link"
                className="resend-verification-btn"
              >
                Resend Verification Email
              </Button>
            </div>
          }
          className="email-verify-alert"
          onClose={handleCloseAlert}
          banner
          closable
        />
      )}
      {/* commented as not working properly */}
      {/* <RouterPrompt when={showPrompt} /> */}
      <Layout id="sidePanelToggleStatus" className="gx-app-layout">
        {!pathname?.includes(ROUTES?.ONBOARDING) && <Sidebar />}
        <Layout>
          <HeaderWrapper />
          <SidePanel />
          <Content className="gx-layout-content">
            <div className="gx-main-content-wrapper">
              <ContentRoutes />
            </div>
          </Content>
        </Layout>
      </Layout>
      <HotKeysModal />
    </>
  );
};

export default App;
