import { MoreOutlined, PlusOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  Checkbox,
  Divider,
  Dropdown,
  Empty,
  Popconfirm,
  Popover,
} from 'antd';
import { capitalize, debounce, filter, forEach, isEmpty, map } from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import FilterSelectedIconComponent from '../../../app/components/iconComponents/FilterSelectedComponent';
import ExportIcon from '../../../assets/export.svg';
import FilterIcon from '../../../assets/filter.svg';
import ImportIcon from '../../../assets/import.svg';
import { ROUTES, SKIP_RECORD, exportOptions } from '../../../common/constants';
import {
  checkPermissions,
  formatPrice,
  handleExportCommon,
} from '../../../common/utils';
import AccessControl from '../../../components/AccessControl';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import SearchComponent from '../../../components/SearchComponent';
import TableComponent from '../../../components/TableComponent';
import ImportModal from '../../imports/components/ImportModal';
import { DELETE_CAMPAIGN } from '../graphql/Mutations';
import {
  GET_CAMPAIGNS,
  GET_SPONSOR_FILTER,
  INDUSTRY_FILTER,
  LINE_OF_BUSINESS_FILTER,
  SUB_AREA_FILTER,
} from '../graphql/Queries';

const initialPaginationValue = {
  total: 0,
  current: 1,
};
let scrollDebounce = null;

const CampaignTable = () => {
  const {
    state: { pageSize, filterData, permissions, isSponsor },
  } = useContext(AppContext);

  const navigate = useNavigate();
  const location = useLocation();

  const initialCampaignFilter = useMemo(
    () => ({
      skip: 0,
      limit: pageSize,
      sortOn: 'name',
      sortBy: 'DESC',
    }),
    [pageSize],
  );

  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [showImportModal, setShowImportModal] = useState(false);
  const [sortedInfo, setSortedInfo] = useState({});
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [campaignFilter, setCampaignFilter] = useState(initialCampaignFilter);
  const [filters, setFilters] = useState(filterData);
  const [exportLoading, setExportLoading] = useState(false);
  const [searchValue, setSearchValue] = useState(null);
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterIndex, setFilterIndex] = useState(null);
  const [filterIsEnd, setFilterIsEnd] = useState(false);
  const [scrollFlag, setScrollFlag] = useState(false);
  const [filterList, setFilterList] = useState([]);
  const [filterSearch, setFilterSearch] = useState('');
  const [filtersCopyState, setFiltersCopyState] = useState(null);

  const [deleteCampaign, { loading: deleteCampaignLoading }] = useMutation(
    DELETE_CAMPAIGN,
    {
      onError() {},
    },
  );

  const [fetchCampaignData, { loading, data }] = useLazyQuery(GET_CAMPAIGNS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const pagination = {
        ...paginationProp,
        defaultPageSize: pageSize,
        total: res?.campaigns?.count,
      };
      setPaginationProp(pagination);
    },
    onError() {},
  });

  const [industryFilters] = useLazyQuery(INDUSTRY_FILTER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (scrollFlag) {
        const optionsCopy = [...filterList];
        forEach(res?.industryFilters?.data, (item) =>
          optionsCopy?.push(item?.label),
        );
        setFilterList(optionsCopy);
        setScrollFlag(false);
      } else {
        const optionsCopy = [];
        forEach(res?.industryFilters?.data, (item) =>
          optionsCopy?.push(item?.label),
        );
        setFilterList(optionsCopy);
      }
      setFilterIsEnd(res?.industryFilters?.data?.length < SKIP_RECORD);
      setFilterLoading(false);
    },
    onError: () => {
      setFilterLoading(false);
    },
  });

  const [lineOfBusinessFilters] = useLazyQuery(LINE_OF_BUSINESS_FILTER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (scrollFlag) {
        const optionsCopy = [...filterList];
        forEach(res?.lineOfBusinessFilters?.data, (item) =>
          optionsCopy?.push(item?.label),
        );
        setFilterList(optionsCopy);
        setScrollFlag(false);
      } else {
        const optionsCopy = [];
        forEach(res?.lineOfBusinessFilters?.data, (item) =>
          optionsCopy?.push(item?.label),
        );
        setFilterList(optionsCopy);
      }
      setFilterIsEnd(res?.lineOfBusinessFilters?.data?.length < SKIP_RECORD);
      setFilterLoading(false);
    },
    onError: () => {
      setFilterLoading(false);
    },
  });

  const [subAreaFilters] = useLazyQuery(SUB_AREA_FILTER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (scrollFlag) {
        const optionsCopy = [...filterList];
        forEach(res?.subAreaFilters?.data, (item) =>
          optionsCopy?.push(item?.label),
        );
        setFilterList(optionsCopy);
        setScrollFlag(false);
      } else {
        const optionsCopy = [];
        forEach(res?.subAreaFilters?.data, (item) =>
          optionsCopy?.push(item?.label),
        );
        setFilterList(optionsCopy);
      }
      setFilterIsEnd(res?.subAreaFilters?.data?.length < SKIP_RECORD);
      setFilterLoading(false);
    },
    onError: () => {
      setFilterLoading(false);
    },
  });

  const [sponsorFilter] = useLazyQuery(GET_SPONSOR_FILTER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (scrollFlag) {
        const optionsCopy = [...filterList];
        forEach(res?.sponsorFilters?.data, (item) =>
          optionsCopy?.push({
            label: item?.businessName,
            key: item?.businessName,
          }),
        );
        setFilterList(optionsCopy);
        setScrollFlag(false);
      } else {
        const optionsCopy = [];
        forEach(res?.sponsorFilters?.data, (item) =>
          optionsCopy?.push({
            label: item?.businessName,
            key: item?.businessName,
          }),
        );
        setFilterList(optionsCopy);
      }
      setFilterIsEnd(res?.sponsorFilters?.data?.length < SKIP_RECORD);
      setFilterLoading(false);
    },
    onError: () => {
      setFilterLoading(false);
    },
  });

  useEffect(() => {
    if (filterVisible) {
      setFilterList([]);
      setFilterLoading(true);
      switch (filterIndex) {
        case 'industry':
          industryFilters({
            variables: {
              filter: {
                sortOn: 'label',
                sortBy: 'ASC',
                skip: 0,
                limit: 20,
                search: filterSearch,
                getDBField: 'label',
                justShow: true,
              },
            },
          });
          break;

        case 'lineOfBusiness':
          lineOfBusinessFilters({
            variables: {
              filter: {
                sortOn: 'label',
                sortBy: 'ASC',
                skip: 0,
                limit: 20,
                search: filterSearch,
                justShow: true,
                distinct: true,
                getDBField: 'label',
              },
            },
          });
          break;

        case 'subArea':
          subAreaFilters({
            variables: {
              filter: {
                sortOn: 'label',
                sortBy: 'ASC',
                skip: 0,
                limit: 20,
                search: filterSearch,
                justShow: true,
                distinct: true,
                getDBField: 'label',
              },
            },
          });
          break;

        case 'sponsorName':
          sponsorFilter({
            variables: {
              filter: {
                sortOn: 'businessName',
                sortBy: 'ASC',
                skip: 0,
                limit: 20,
                search: filterSearch,
                distinct: true,
                getDBField: 'businessName',
              },
            },
          });
          break;
        default:
          break;
      }
    }
    if (!filterVisible) {
      setFilterSearch('');
      setFilterIsEnd(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterVisible]);

  useEffect(() => {
    const permitted = checkPermissions(permissions, ['FET_CAMPAIGN_LIST']);
    const whereFilter = location?.state;
    let parsedObject;

    if (!isEmpty(whereFilter)) {
      parsedObject = JSON?.parse(whereFilter?.stringifyObject);
    }

    if (permitted) {
      fetchCampaignData({
        variables: {
          filter: parsedObject?.campaignFilter ?? campaignFilter,
          ...(parsedObject && { where: parsedObject?.filters }),
        },
      });
      if (!isEmpty(parsedObject)) {
        setFilters({ ...parsedObject?.filters });
        setSearchValue(parsedObject?.campaignFilter?.search);
        const sorter = {
          order:
            parsedObject?.campaignFilter?.sortBy === 'ASC'
              ? 'ascend'
              : 'descend',
          columnKey: parsedObject?.campaignFilter?.sortOn,
        };
        setSortedInfo({ ...sorter });
        setCampaignFilter({ ...parsedObject?.campaignFilter });
        setPaginationProp({ ...parsedObject?.paginationProp });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    window?.addEventListener('beforeunload', () => {
      // eslint-disable-next-line no-undef
      if (window?.location?.pathname === ROUTES?.CAMPAIGNS)
        // eslint-disable-next-line no-undef
        window?.history?.pushState({}, '', window?.location?.pathname);
    });
    return () => {
      // eslint-disable-next-line no-undef
      window?.removeEventListener('beforeunload', () => {
        // eslint-disable-next-line no-undef
        window?.history?.pushState({}, '', window?.location?.pathname);
      });
    };
  }, []);

  const handleShowImportModal = (visible) => {
    setShowImportModal(visible);
  };

  const rowSelection = {
    fixed: 'left',
    columnWidth: 50,
    selectedRowKeys: selectedKeys,
    onChange: (selectedRowKeys) => {
      setSelectedKeys(selectedRowKeys);
    },
    renderCell(checked, record, index, node) {
      return React.cloneElement(node, {
        'aria-label': record?.id,
      });
    },
  };

  const handleAddEditCampaign = (record) => {
    const otherFilters = { campaignFilter, paginationProp, filters };
    const stringifyObject = JSON?.stringify(otherFilters);
    if (record?.id) {
      navigate(`${ROUTES?.CAMPAIGNS}/edit/${record?.id}`, {
        state: {
          stringifyObject,
        },
      });
    } else {
      navigate(`${ROUTES?.CAMPAIGNS}/add`, {
        state: {
          stringifyObject,
        },
      });
    }
  };

  const importCallback = () => {
    setPaginationProp(initialPaginationValue);
    setCampaignFilter(initialCampaignFilter);
    setSortedInfo({});
    fetchCampaignData({
      variables: { filter: initialCampaignFilter },
    });
  };

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination?.pageSize;
    setSortedInfo(sorter);
    setPaginationProp({ ...paginationProp, ...pagination });
    if (sorter?.column) {
      setCampaignFilter({
        ...campaignFilter,
        skip,
        limit: pagination?.pageSize,
        sortOn: sorter?.field,
        sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC',
      });
      fetchCampaignData({
        variables: {
          filter: {
            ...campaignFilter,
            skip,
            limit: pagination?.pageSize,
            sortOn: sorter?.field,
            sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC',
          },
          ...(filters && { where: filters }),
        },
      });
    } else {
      setCampaignFilter({
        ...campaignFilter,
        skip,
        limit: pagination?.pageSize,
        sortOn: 'createdAt',
        sortBy: 'DESC',
      });
      fetchCampaignData({
        variables: {
          filter: {
            ...campaignFilter,
            skip,
            limit: pagination?.pageSize,
            sortOn: 'createdAt',
            sortBy: 'DESC',
          },
          ...(filters && { where: filters }),
        },
      });
    }
  };

  const handleDeleteCampaign = async (editCampaignData) => {
    const response = await deleteCampaign({
      variables: { where: { id: editCampaignData?.id } },
    });
    if (response?.data?.deleteCampaign) {
      fetchCampaignData({
        variables: {
          filter: campaignFilter,
          ...(filters && { where: filters }),
        },
      });
    }
  };

  const renderActionButtons = (editCampaignData) => (
    <div className="d-flex flex-vertical">
      <AccessControl
        allowedPermissions={['FET_CAMPAIGN_UPDATE', 'FET_CAMPAIGN_VIEW']}
      >
        <Button
          id="productCategory-table-edit-btn"
          className="b-0"
          onClick={() => handleAddEditCampaign(editCampaignData)}
        >
          Edit
        </Button>
      </AccessControl>
      <AccessControl allowedPermissions={['FET_CAMPAIGN_DELETE']}>
        <Popconfirm
          title="Are you sure to delete?"
          onConfirm={() => handleDeleteCampaign(editCampaignData)}
          okText="Yes"
          cancelText="No"
        >
          <Button id="productCategory-table-status-btn" className="b-0">
            Delete
          </Button>
        </Popconfirm>
      </AccessControl>
    </div>
  );

  const onScroll = (event, dataIndex) => {
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    if (scrollDebounce) {
      scrollDebounce?.cancel();
      scrollDebounce = null;
    }
    scrollDebounce = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (
        scrolledToBottom &&
        filterIndex === dataIndex &&
        filterVisible &&
        !filterIsEnd
      ) {
        setScrollFlag(true);
        switch (filterIndex) {
          case 'industry':
            industryFilters({
              variables: {
                filter: {
                  sortOn: 'label',
                  sortBy: 'ASC',
                  skip: filterList?.length,
                  limit: 20,
                  search: filterSearch,
                  getDBField: 'label',
                  justShow: true,
                },
              },
            });
            break;

          case 'lineOfBusiness':
            lineOfBusinessFilters({
              variables: {
                filter: {
                  sortOn: 'label',
                  sortBy: 'ASC',
                  skip: filterList?.length,
                  limit: 20,
                  search: filterSearch,
                  justShow: true,
                  distinct: true,
                  getDBField: 'label',
                },
              },
            });
            break;

          case 'subArea':
            subAreaFilters({
              variables: {
                filter: {
                  sortOn: 'label',
                  sortBy: 'ASC',
                  skip: filterList?.length,
                  limit: 20,
                  search: filterSearch,
                  distinct: true,
                  justShow: true,
                  getDBField: 'label',
                },
              },
            });
            break;

          case 'sponsorName':
            sponsorFilter({
              variables: {
                filter: {
                  sortOn: 'businessName',
                  sortBy: 'ASC',
                  skip: filterList?.length,
                  limit: 20,
                  search: filterSearch,
                  distinct: true,
                  getDBField: 'businessName',
                },
              },
            });
            break;

          default:
            break;
        }
      }
    }, 500);
    scrollDebounce();
  };

  const handleReset = (clearFilters, dataIndex) => {
    const filtersCopy = {
      ...filters,
      [dataIndex]: [],
    };
    if (dataIndex === 'costPrice' || dataIndex === 'defaultPrice') {
      delete filtersCopy?.[dataIndex];
    }
    setFilters(filtersCopy);
    setFiltersCopyState(filtersCopy);

    fetchCampaignData({
      variables: {
        filter: { ...campaignFilter, skip: 0 },
        ...(filtersCopy && { where: filtersCopy }),
      },
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    clearFilters();
    setFilterIndex(dataIndex);
    setFilterVisible(false);
  };

  const changeFilter = (e, dataIndex) => {
    const {
      target: { value = '' },
    } = e;
    let filtersCopy = [];
    if (filtersCopyState?.[dataIndex]?.includes(value)) {
      filtersCopy = {
        ...filtersCopyState,
        [dataIndex]: filter(
          filtersCopyState?.[dataIndex],
          (item) => item !== value,
        ),
      };
    } else {
      filtersCopy = {
        ...filtersCopyState,
        [dataIndex]: filtersCopyState?.[dataIndex]
          ? [...filtersCopyState?.[dataIndex], value]
          : [value],
      };
    }
    setFiltersCopyState(filtersCopy);
  };

  const getFilterData = (confirm) => {
    setFilters(filtersCopyState);
    fetchCampaignData({
      variables: {
        filter: { ...campaignFilter, skip: 0 },
        ...(filtersCopyState && { where: filtersCopyState }),
      },
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    if (confirm) {
      confirm();
    }
  };

  const filterPopup = (dataIndex, isPrice = false) => ({
    filterDropdown: ({ confirm, clearFilters }) => (
      <div className="custom-filter-dropdown">
        <LoaderComponent spinning={filterLoading} setHeight={35}>
          <div
            className="filter-checkboxes"
            onScroll={(e) => onScroll(e, dataIndex)}
          >
            {filterList?.length > 0 ? (
              map(filterList, (item) => (
                <div
                  className="filter-checkbox-section"
                  key={item?.key || item}
                >
                  <Checkbox
                    value={item?.key || item}
                    checked={filtersCopyState?.[dataIndex]?.includes(
                      item?.key || item,
                    )}
                    key={item?.key || item}
                    onChange={(e) => changeFilter(e, dataIndex)}
                    className="common-checkbox"
                  >
                    {dataIndex === 'isActive' ? (
                      <span title={item === true ? 'Active' : 'Inactive'}>
                        {item === true ? 'Active' : 'Inactive'}
                      </span>
                    ) : (
                      <span
                        title={
                          item?.label || isPrice
                            ? formatPrice(item)
                            : item?.toString()
                        }
                      >
                        {item?.label || isPrice
                          ? formatPrice(item)
                          : item?.toString()}
                      </span>
                    )}
                  </Checkbox>
                </div>
              ))
            ) : (
              <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
            )}
          </div>
        </LoaderComponent>
        <Divider className="divider-filter" />
        <div className="d-flex justify-center">
          <Button
            size="small"
            className="common-button discard-button filter-button"
            id="roles-filter-reset"
            onClick={() => handleReset(clearFilters, dataIndex)}
          >
            Reset
          </Button>
          <Button
            size="small"
            className="common-button filter-button"
            id="roles-filter-ok"
            type="primary"
            onClick={() => getFilterData(confirm, dataIndex)}
          >
            Ok
          </Button>
        </div>
      </div>
    ),
    filterIcon: () =>
      filters?.[dataIndex]?.length > 0 || !isEmpty(filters?.[dataIndex]) ? (
        <FilterSelectedIconComponent className="primary-color" />
      ) : (
        <img src={FilterIcon} alt="filter-icon" width={16} />
      ),
    onFilterDropdownOpenChange: (visible) => {
      setFilterIndex(dataIndex);
      setFilterVisible(visible);
      if (visible) {
        setFiltersCopyState(filters);
      }
    },
  });

  const columns = [
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      ellipsis: true,
      sortOrder: sortedInfo?.columnKey === 'name' && sortedInfo?.order,
      width: 120,
      className: 'max-width-column',
    },
    !isSponsor && {
      title: 'SPONSOR',
      dataIndex: 'sponsorName',
      key: 'sponsorName',
      sorter: true,
      ellipsis: true,
      sortOrder: sortedInfo?.columnKey === 'sponsorName' && sortedInfo?.order,
      width: 140,
      ...filterPopup('sponsorName'),
      render: (sponsorName) => <span>{sponsorName || '-'}</span>,
    },
    {
      title: 'INDUSTRY',
      dataIndex: 'industryId',
      key: 'industryId',
      sorter: true,
      ellipsis: true,
      sortOrder: sortedInfo?.columnKey === 'industryId' && sortedInfo?.order,
      width: 190,
      ...filterPopup('industry'),
      render: (industryId, record = {}) => (
        <span>{record?.campaignIndustry?.label}</span>
      ),
    },
    {
      title: 'LINE OF BUSINESS',
      dataIndex: 'lineOfBusinessId',
      key: 'lineOfBusinessId',
      sorter: true,
      ellipsis: true,
      sortOrder:
        sortedInfo?.columnKey === 'lineOfBusinessId' && sortedInfo?.order,
      width: 190,
      ...filterPopup('lineOfBusiness'),
      render: (lineOfBusinessId, record) => (
        <span title={record?.campaignLob?.label}>
          {record?.campaignLob?.label}
        </span>
      ),
    },
    {
      title: 'SERVICE TYPE',
      dataIndex: 'subAreaId',
      key: 'subAreaId',
      sorter: true,
      ellipsis: true,
      sortOrder: sortedInfo?.columnKey === 'subAreaId' && sortedInfo?.order,
      width: 160,
      ...filterPopup('subArea'),
      render: (subAreaId, record) => (
        <span title={record?.campaignSubArea?.label}>
          {record?.campaignSubArea?.label || '-'}
        </span>
      ),
    },
    {
      title: 'CODE',
      dataIndex: 'code',
      key: 'code',
      ellipsis: true,
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      ellipsis: true,
      sortOrder: sortedInfo?.columnKey === 'status' && sortedInfo?.order,
      render: (status) => (
        <span title={capitalize(status)}>{capitalize(status)}</span>
      ),
    },
    checkPermissions(permissions, [
      'FET_CAMPAIGN_UPDATE',
      'FET_CAMPAIGN_DELETE',
      'FET_CAMPAIGN_VIEW',
    ]) && {
      dataIndex: 'id',
      align: 'right',
      width: 30,
      fixed: 'right',
      render: (id, record) => (
        <Popover
          placement="bottom"
          overlayClassName="action-button"
          content={renderActionButtons(record)}
        >
          <MoreOutlined />
        </Popover>
      ),
    },
  ];

  const onSearchChange = (value) => {
    setCampaignFilter({
      ...campaignFilter,
      skip: value ? 0 : campaignFilter?.limit * (paginationProp?.current - 1),
      search: value,
    });
    fetchCampaignData({
      variables: {
        filter: {
          ...campaignFilter,
          skip: value
            ? 0
            : campaignFilter?.limit * (paginationProp?.current - 1),
          search: value,
        },
        ...(filters && { where: filters }),
      },
    });
  };

  const handleExport = async (e) => {
    const { key } = e;
    setExportLoading(true);
    await handleExportCommon({
      filters,
      key,
      selectedKeys,
      module: 'CAMPAIGN',
    });
    setExportLoading(false);
  };

  return (
    <div>
      <ImportModal
        showImportModal={showImportModal}
        setShowImportModal={setShowImportModal}
        callback={importCallback}
        module="campaigns"
        folder="CAMPAIGN"
      />
      <AccessControl allowedPermissions={['FET_CAMPAIGN_CREATE']}>
        <Portal portalId="header-right-content">
          <Button
            className="common-button"
            icon={<PlusOutlined />}
            size="small"
            id="productCategory-table-add-btn"
            type="primary"
            onClick={handleAddEditCampaign}
          >
            Add Campaign
          </Button>
        </Portal>
      </AccessControl>
      <div className="mb-16 d-flex justify-between align-center">
        <div className="fill-width search-checkbox">
          <AccessControl allowedPermissions={['FET_CAMPAIGN_LIST']}>
            <SearchComponent
              className="list-search-box"
              id="search-container-id"
              placeholder="Campaigns name or other detail.."
              name="ProductCategories"
              getData={onSearchChange}
              value={searchValue}
              setSearchValue={setSearchValue}
            />
          </AccessControl>
        </div>
        <div className="header-buttons">
          <AccessControl allowedPermissions={['FET_CAMPAIGN_IMPORT']}>
            <Button
              size="small"
              className="common-button import-button"
              icon={<img src={ImportIcon} alt="import-icon" width={11} />}
              id="productCategory-table-import-btn"
              type="primary"
              onClick={() => handleShowImportModal(true)}
            >
              Import
            </Button>
          </AccessControl>
          <AccessControl allowedPermissions={['FET_CAMPAIGN_EXPORT']}>
            <Dropdown
              overlayClassName="export-btn-dropdown"
              menu={{ items: exportOptions, onClick: handleExport }}
              placement="bottom"
            >
              <Button
                className="common-button export-button"
                size="small"
                icon={<img src={ExportIcon} alt="export-icon" width={11} />}
                id="productCategory-table-export-btn"
                loading={exportLoading}
                type="primary"
              >
                Export
              </Button>
            </Dropdown>
          </AccessControl>
        </div>
      </div>
      <AccessControl allowedPermissions={['FET_CAMPAIGN_LIST']} showNoAccess>
        <div className="common-table product-sub-item-table">
          {pageSize && (
            <TableComponent
              loadingData={loading || deleteCampaignLoading}
              columns={[...columns?.filter((item) => item !== false)]}
              data={data?.campaigns?.data || []}
              onChange={handleTableChange}
              paginationConfig={paginationProp}
              rowSelection={rowSelection}
              rowKey={(obj) => obj?.id}
            />
          )}
        </div>
      </AccessControl>
    </div>
  );
};

export default CampaignTable;
