import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import EntityFilter from './components/EntityFilterTable';
import './dataAssignment.less';
import AssignmentJobsList from './pages/AssignmentJobsList';

const AssignmentJobsWrapper = () => (
  <div className="assignment-jobs-wrapper">
    <Routes>
      <Route path={ROUTES?.MAIN} element={<AssignmentJobsList />} />
      <Route
        path={`/edit${ROUTES?.ENTITY_FILTERS}`}
        element={<EntityFilter />}
      />
    </Routes>
  </div>
);

export default AssignmentJobsWrapper;
