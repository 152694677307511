import { useLazyQuery } from '@apollo/client';
import { Button, Col, Divider, Empty, Form, Row } from 'antd';
import { debounce, map, uniqBy } from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import DeleteIconComponent from '../../../app/components/iconComponents/DeleteIconComponent';
import SaveIcon from '../../../assets/save.svg';
import { ROUTES, SKIP_RECORD, STATUS_ENUM } from '../../../common/constants';
import { checkPermissions, formValidatorRules } from '../../../common/utils';
import AccessControl from '../../../components/AccessControl';
import InputComponent from '../../../components/InputComponent';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import SelectComponent from '../../../components/SelectComponent';
import {
  GET_INDUSTRIES,
  LINE_OF_BUSINESS,
  SUB_AREA,
} from '../../products/graphql/Queries';

const { required, requiredWhiteSpaceAllowed } = formValidatorRules;

const { Option } = SelectComponent;

let industryScrollDebounceJob;
let lineOfBusinessScrollDebounceJob;
let subAreaScrollDebounceJob;

const industryFilter = {
  sortOn: 'label',
  sortBy: 'ASC',
  skip: 0,
  limit: 20,
  search: '',
  justShow: true,
};

const lineOfBusinessFilter = {
  sortOn: 'label',
  sortBy: 'ASC',
  skip: 0,
  limit: 20,
  search: '',
  justShow: true,
};

const subAreaFilter = {
  sortOn: 'label',
  sortBy: 'ASC',
  skip: 0,
  limit: 20,
  search: '',
  justShow: true,
};

const CampaignForm = (props) => {
  const [industryLoading, setIndustryLoading] = useState(false);
  const [industrySearchFlag, setIndustrySearchFlag] = useState(false);
  const [industriesData, setIndustriesData] = useState([]);
  const [industryIsEnd, setIndustryIsEnd] = useState(false);
  const [industryDebounceCall, setIndustryDebounceCall] = useState(0);
  const [lineOfBusinessLoading, setLineOfBusinessLoading] = useState(false);
  const [lineOfBusinessSearchFlag, setLineOfBusinessSearchFlag] = useState(
    false,
  );
  const [lineOfBusinessesData, setLineOfBusinessesData] = useState([]);
  const [lineOfBusinessIsEnd, setLineOfBusinessIsEnd] = useState(false);
  const [lineOfBusinessDebounceCall, setLineOfBusinessDebounceCall] = useState(
    0,
  );

  const [subAreaLoading, setSubAreaLoading] = useState(false);
  const [subAreaSearchFlag, setSubAreaSearchFlag] = useState(false);
  const [subAreasData, setSubAreasData] = useState([]);
  const [disableLob, setDisableLob] = useState(true);
  const [disableSubArea, setDisableSubArea] = useState(true);
  const [subAreaIsEnd, setSubAreaIsEnd] = useState(false);
  const [subAreaDebounceCall, setSubAreaDebounceCall] = useState(0);

  const [initialSubAreaRender, setInitialSubAreaRender] = useState(true);
  const [initialLobRender, setInitialLobRender] = useState(true);

  const [searchValue, setSearchValue] = useState('');

  const {
    state: { permissions, isSponsor },
    dispatch,
  } = useContext(AppContext);
  const {
    campaignData = null,
    handleSubmit,
    isSubmit = false,
    isEdit = false,
  } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const [validationTriggered, setValidationTriggered] = useState(false);

  const [form] = Form?.useForm();

  const onFinishFailed = () => {
    setValidationTriggered(true);
  };

  const [industries] = useLazyQuery(GET_INDUSTRIES, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setIndustryIsEnd(res?.industries?.data?.length < SKIP_RECORD);
      if (industrySearchFlag) {
        setIndustriesData([...res?.industries?.data]);
      } else {
        if (isEdit) {
          const tempIndustry = [
            campaignData?.industry,
            ...res?.industries?.data,
          ];
          setIndustriesData(uniqBy([...industriesData, ...tempIndustry], 'id'));
          return;
        }
        setIndustriesData([...industriesData, ...res?.industries?.data]);
      }
      setIndustryLoading(false);
    },
    onError() {
      setIndustryLoading(false);
    },
  });

  const [lineOfBusinesses] = useLazyQuery(LINE_OF_BUSINESS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setLineOfBusinessIsEnd(res?.lineOfBusinesses?.data?.length < SKIP_RECORD);
      if (lineOfBusinessSearchFlag) {
        setLineOfBusinessesData([...res?.lineOfBusinesses?.data]);
      } else {
        if (isEdit) {
          if (initialLobRender) {
            const tempLineOfBusiness = [
              campaignData?.lineOfBusiness,
              ...res?.lineOfBusinesses?.data,
            ];
            setLineOfBusinessesData(
              uniqBy([...lineOfBusinessesData, ...tempLineOfBusiness], 'id'),
            );
          } else {
            setLineOfBusinessesData([...res?.lineOfBusinesses?.data]);
          }
          setInitialLobRender(false);
          return;
        }
        setLineOfBusinessesData([
          ...lineOfBusinessesData,
          ...res?.lineOfBusinesses?.data,
        ]);
      }
      setLineOfBusinessLoading(false);
    },
    onError() {
      setLineOfBusinessLoading(false);
    },
  });

  const [subAreas] = useLazyQuery(SUB_AREA, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setSubAreaIsEnd(res?.subAreas?.data?.length < SKIP_RECORD);
      if (subAreaSearchFlag) {
        setSubAreasData([...res?.subAreas?.data]);
      } else {
        if (isEdit) {
          if (initialSubAreaRender && campaignData?.subArea) {
            const tempSubAreas = [
              campaignData?.subArea,
              ...res?.subAreas?.data,
            ];
            setSubAreasData(uniqBy([...subAreasData, ...tempSubAreas], 'id'));
          } else {
            setSubAreasData([...res?.subAreas?.data]);
          }
          setInitialSubAreaRender(false);
          return;
        }
        setSubAreasData(
          uniqBy([...subAreasData, ...res?.subAreas?.data], 'id'),
        );
      }
      setSubAreaLoading(false);
    },
    onError() {
      setSubAreaLoading(false);
    },
  });

  const onIndustryScroll = (event) => {
    setIndustrySearchFlag(false);
    if (industryScrollDebounceJob) {
      industryScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    industryScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !industryIsEnd) {
        setIndustryLoading(true);
        setIndustryDebounceCall((prevState) => prevState + 1);
        industries({
          variables: {
            filter: {
              ...industryFilter,
              skip: (industryDebounceCall + 1) * SKIP_RECORD,
              search: searchValue,
            },
            where: {
              isActive: true,
            },
          },
        });
      }
    }, 500);

    industryScrollDebounceJob();
  };

  const handleIndustrySelect = (value) => {
    setDisableLob(false);
    setLineOfBusinessesData([]);
    setDisableSubArea(true);
    if (form?.getFieldValue('lineOfBusinessId')) {
      form?.setFieldsValue({
        lineOfBusinessId: null,
      });
    }
    if (form?.getFieldValue('subAreaId')) {
      form?.setFieldsValue({
        subAreaId: null,
      });
    }
    lineOfBusinesses({
      variables: {
        filter: { ...lineOfBusinessFilter, industryId: value },
        where: {
          isActive: true,
        },
      },
    });
  };

  const handleIndustryChange = (value) => {
    setIndustrySearchFlag(true);
    setSearchValue(value);
    setIndustryLoading(true);
    industries({
      variables: {
        filter: {
          ...industryFilter,
          search: value,
        },
        where: {
          isActive: true,
        },
      },
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedIndustryHandler = useCallback(
    debounce(handleIndustryChange, 500),
    [],
  );

  const handleIndustryClear = () => {
    form?.setFieldsValue({
      industryId: null,
      lineOfBusinessId: null,
      subAreaId: null,
    });
    setDisableLob(true);
    setDisableSubArea(true);
    setSubAreaSearchFlag(true);
    setIndustriesData([]);
    setLineOfBusinessesData([]);
    setSubAreasData([]);
    industries({
      variables: {
        filter: industryFilter,
        where: {
          isActive: true,
        },
      },
    });
  };

  const handleLineOfBusinessChange = (value) => {
    setLineOfBusinessSearchFlag(true);
    setSearchValue(value);
    setLineOfBusinessLoading(true);
    lineOfBusinesses({
      variables: {
        filter: {
          ...lineOfBusinessFilter,
          industryId: form?.getFieldValue('industryId'),
          search: value,
        },
        where: {
          isActive: true,
        },
      },
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedLineOfBusinessHandler = useCallback(
    debounce(handleLineOfBusinessChange, 500),
    [],
  );

  const handleSubAreaChange = (value) => {
    setSubAreaSearchFlag(true);
    setSearchValue(value);
    setSubAreaLoading(true);
    subAreas({
      variables: {
        filter: {
          ...subAreaFilter,
          search: value,
          lobId: form?.getFieldValue('lineOfBusinessId'),
          industryId: form?.getFieldValue('industryId'),
        },
        where: {
          isActive: true,
        },
      },
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSubAreaHandler = useCallback(
    debounce(handleSubAreaChange, 500),
    [],
  );

  const handleLineOfBusinessBlur = () => {
    setSearchValue('');
    setLineOfBusinessDebounceCall(0);
    setLineOfBusinessIsEnd(false);
  };

  const handleIndustryBlur = () => {
    setSearchValue('');
    setIndustryDebounceCall(0);
    setIndustryIsEnd(false);
  };

  const handleSubAreaBlur = () => {
    setSearchValue('');
    setSubAreaDebounceCall(0);
    setSubAreaIsEnd(false);
  };

  const handleSubAreaClear = () => {
    form?.setFieldsValue({
      subAreaId: null,
    });
    setSubAreasData([]);
    subAreas({
      variables: {
        filter: {
          ...subAreaFilter,
          lobId: form?.getFieldValue('lineOfBusinessId'),
          industryId: form?.getFieldValue('industryId'),
        },
        where: { isActive: true },
      },
    });
  };

  const onSubAreaScroll = (event) => {
    setSubAreaSearchFlag(false);
    if (subAreaScrollDebounceJob) {
      subAreaScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    subAreaScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !subAreaIsEnd) {
        setSubAreaLoading(true);
        setSubAreaDebounceCall((prevState) => prevState + 1);
        subAreas({
          variables: {
            filter: {
              ...subAreaFilter,
              skip: (subAreaDebounceCall + 1) * SKIP_RECORD,
              search: searchValue,
              lobId: form?.getFieldValue('lineOfBusinessId'),
              industryId: form?.getFieldValue('industryId'),
            },
            where: {
              isActive: true,
            },
          },
        });
      }
    }, 500);

    subAreaScrollDebounceJob();
  };

  const onLineOfBusinessScroll = (event) => {
    setLineOfBusinessSearchFlag(false);
    if (lineOfBusinessScrollDebounceJob) {
      lineOfBusinessScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    lineOfBusinessScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !lineOfBusinessIsEnd) {
        setLineOfBusinessLoading(true);
        setLineOfBusinessDebounceCall((prevState) => prevState + 1);
        lineOfBusinesses({
          variables: {
            filter: {
              ...lineOfBusinessFilter,
              skip: (lineOfBusinessDebounceCall + 1) * SKIP_RECORD,
              search: searchValue,
              industryId: form?.getFieldValue('industryId'),
            },
            where: {
              isActive: true,
            },
          },
        });
      }
    }, 500);

    lineOfBusinessScrollDebounceJob();
  };

  const handleLineOfBusinessClear = () => {
    form?.setFieldsValue({
      lineOfBusinessId: null,
      subAreaId: null,
    });
    setDisableSubArea(true);
    setSubAreaSearchFlag(true);
    setLineOfBusinessesData([]);
    setSubAreasData([]);
    lineOfBusinesses({
      variables: {
        filter: {
          ...lineOfBusinessFilter,
          industryId: form?.getFieldValue('industryId'),
        },
        where: {
          isActive: true,
        },
      },
    });
  };

  const handleLobSelect = (value) => {
    setDisableSubArea(false);
    setSubAreasData([]);
    if (form?.getFieldValue('subAreaId')) {
      form?.setFieldsValue({
        subAreaId: null,
      });
    }

    subAreas({
      variables: {
        filter: {
          ...subAreaFilter,
          lobId: value,
          industryId: form?.getFieldValue('industryId'),
        },
        where: {
          isActive: true,
        },
      },
    });
  };
  useEffect(() => {
    if (location?.pathname?.includes('/edit')) {
      setDisableSubArea(false);
      setDisableLob(false);
    }

    if (campaignData?.industry?.id) {
      lineOfBusinesses({
        variables: {
          filter: {
            ...lineOfBusinessFilter,
            industryId: campaignData?.industry?.id,
          },
          where: {
            isActive: true,
          },
        },
      });
    }
    if (campaignData?.lineOfBusiness?.id) {
      subAreas({
        variables: {
          filter: {
            ...subAreaFilter,
            lobId: campaignData?.lineOfBusiness?.id,
            industryId: campaignData?.industry?.id,
          },
          where: {
            isActive: true,
          },
        },
      });
    }
    industries({
      variables: {
        filter: industryFilter,
        where: {
          isActive: true,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="content-section">
      <AccessControl
        allowedPermissions={
          isEdit ? ['FET_CAMPAIGN_UPDATE'] : ['FET_CAMPAIGN_CREATE']
        }
      >
        <Portal portalId="header-right-content">
          <Button
            className="common-button discard-button"
            icon={<DeleteIconComponent />}
            size="small"
            id="productCategory-table-discard-btn"
            onClick={() =>
              navigate(ROUTES?.CAMPAIGNS, { state: { ...location?.state } })
            }
          >
            {isEdit ? 'Discard Changes' : 'Cancel'}
          </Button>
          <Button
            className="common-button"
            icon={<img src={SaveIcon} alt="save-icon" width={12} />}
            size="small"
            htmlType="submit"
            id="productcategory-table-save-btn"
            loading={isSubmit}
            type="primary"
            onClick={form?.submit}
          >
            Save
          </Button>
        </Portal>
      </AccessControl>
      <Form
        form={form}
        initialValues={campaignData}
        onValuesChange={() => dispatch({ type: 'SET_SHOW_PROMPT', data: true })}
        layout="vertical"
        validateTrigger={validationTriggered ? 'onChange' : 'onSubmit'}
        onFinish={(values) => {
          dispatch({ type: 'SET_SHOW_PROMPT', data: false });
          handleSubmit(values);
        }}
        disabled={!isSponsor && campaignData?.sponsorName}
        onFinishFailed={onFinishFailed}
        scrollToFirstError={{ behavior: 'smooth', block: 'end' }}
      >
        <fieldset
          disabled={
            !checkPermissions(
              permissions,
              isEdit ? ['FET_CAMPAIGN_UPDATE'] : ['FET_CAMPAIGN_CREATE'],
            )
          }
        >
          <span className="form-divider-text">MANDATORY</span>
          <Divider className="form-divider " />
          <Row gutter={16} className="required-row">
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                rules={[{ ...required, message: 'Please Enter Name' }]}
                name="name"
                label="Name"
              >
                <InputComponent allowClear placeholder="Enter Name" />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                name="type"
                label="Type"
                rules={[
                  {
                    ...requiredWhiteSpaceAllowed,
                    message: 'Please Select Type',
                  },
                ]}
              >
                <InputComponent allowClear placeholder="Enter Type" />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                name="status"
                label="Status"
                rules={[
                  {
                    ...requiredWhiteSpaceAllowed,
                    message: 'Please Select Status',
                  },
                ]}
              >
                <SelectComponent placeholder="Select Status" allowClear>
                  {map(STATUS_ENUM, (item) => (
                    <Option key={item?.label} value={item?.value}>
                      {item?.label}
                    </Option>
                  ))}
                </SelectComponent>
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                rules={[{ ...required, message: 'Please Enter Campaign Code' }]}
                name="code"
                label="Campaign Code"
              >
                <InputComponent allowClear placeholder="Enter Campaign Code" />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                name="industryId"
                label="Industry"
                rules={[
                  {
                    required,
                    message: 'Please Select Industry',
                  },
                ]}
              >
                <SelectComponent
                  placeholder="Select Industry"
                  allowClear
                  notFoundContent={
                    industryLoading ? (
                      <LoaderComponent size="small" setHeight={10} />
                    ) : (
                      <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                    )
                  }
                  onBlur={handleIndustryBlur}
                  onSearch={debouncedIndustryHandler}
                  onClear={handleIndustryClear}
                  onPopupScroll={onIndustryScroll}
                  onSelect={handleIndustrySelect}
                >
                  {map(industriesData, (item) => (
                    <Option key={item?.id} value={item?.id}>
                      {item?.label}
                    </Option>
                  ))}
                </SelectComponent>
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                name="lineOfBusinessId"
                label="Line of Business"
                rules={[
                  {
                    required,
                    message: 'Please Select Line Of Business',
                  },
                ]}
              >
                <SelectComponent
                  placeholder="Select Line Of Business"
                  allowClear
                  disabled={disableLob}
                  notFoundContent={
                    lineOfBusinessLoading ? (
                      <LoaderComponent size="small" setHeight={10} />
                    ) : (
                      <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                    )
                  }
                  onBlur={handleLineOfBusinessBlur}
                  onSearch={debouncedLineOfBusinessHandler}
                  onClear={handleLineOfBusinessClear}
                  onPopupScroll={onLineOfBusinessScroll}
                  onSelect={handleLobSelect}
                >
                  {map(lineOfBusinessesData, (item) => (
                    <Option key={item?.id} value={item?.id}>
                      {item?.label}
                    </Option>
                  ))}
                </SelectComponent>
              </Form.Item>
            </Col>
          </Row>
          <span className="form-divider-text">OPTIONAL</span>
          <Divider className="form-divider optional-divider" />
          <Row gutter={16}>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item name="subAreaId" label="Service Type">
                <SelectComponent
                  placeholder="Select Service Type"
                  allowClear
                  disabled={disableSubArea}
                  notFoundContent={
                    subAreaLoading ? (
                      <LoaderComponent size="small" setHeight={10} />
                    ) : (
                      <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                    )
                  }
                  onBlur={handleSubAreaBlur}
                  onSearch={debouncedSubAreaHandler}
                  onClear={handleSubAreaClear}
                  onPopupScroll={onSubAreaScroll}
                >
                  {map(subAreasData, (item) => (
                    <Option key={item?.id} value={item?.id}>
                      {item?.label}
                    </Option>
                  ))}
                </SelectComponent>
              </Form.Item>
            </Col>
            {!isSponsor && campaignData?.sponsorName && (
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item name="sponsorName" label="Sponsor">
                  <InputComponent disabled />
                </Form.Item>
              </Col>
            )}
          </Row>
        </fieldset>
      </Form>
    </div>
  );
};

export default CampaignForm;
