import { CloseOutlined, PlusOutlined, UpOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  Checkbox,
  Col,
  Collapse,
  Divider,
  Empty,
  Form,
  List,
  Popconfirm,
  Popover,
  Radio,
  Row,
  Tag,
} from 'antd';
import { findIndex, forEach, isEmpty, map, set, sortBy } from 'lodash';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import DeleteIconComponent from '../../../app/components/iconComponents/DeleteIconComponent';
import deleteIcon from '../../../assets/delete-red.svg';
import SaveIcon from '../../../assets/save.svg';
import {
  QUOTE_MODIFIER_CONDITION_ATTRIBUTE,
  ROUTES,
} from '../../../common/constants';
import {
  checkPermissions,
  createUnitInputParameterMessage,
  dateFormatWithoutTime,
  formValidatorRules,
} from '../../../common/utils';
import AccessControl from '../../../components/AccessControl';
import DatePickerComponent from '../../../components/DatePickerComponent';
import GoBackButton from '../../../components/GoBackButton';
import InputComponent from '../../../components/InputComponent';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import PriceComponent from '../../../components/PriceComponent';
import TextAreaComponent from '../../../components/TextAreaComponent';
import history from '../../../historyData';
import {
  CREATE_PRICING_MODIFIER,
  UPDATE_PRICING_MODIFIER,
} from '../graphql/Mutations';
import {
  GET_INDUSTRIES,
  GET_PRICING_MODIFIER,
  PRICING_MODIFIER_CONDITIONS,
  PRICING_MODIFIER_STATUS,
} from '../graphql/Queries';
import PricingConditionModal from './PricingConditionModal';
import {
  addItemAsOptions,
  billingAsOptions,
  modifierOnOptions,
  pricingOperationOptions,
  pricingValueTypeOptions,
} from './radioOptions';

const { required, startDate, endDate } = formValidatorRules;

let initialValues;

const attributeFilter = {
  sortOn: 'order',
  sortBy: 'ASC',
  skip: 0,
  limit: 20,
  search: '',
};

const PricingModifierForm = ({ isEdit = false }) => {
  const {
    state: { globalDateFormat, permissions, isSponsor },
    dispatch,
  } = useContext(AppContext);
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [validationTriggered, setValidationTriggered] = useState(false);
  const [selectedCondition, setSelectedCondition] = useState(null);
  const [conditionData, setConditionData] = useState({
    brands: [],
    regions: [],
    manufacturers: [],
    unitInputParameter: null,
  });
  const [pricingValue, setPricingValue] = useState('');
  const [pricingValueType, setPricingValueType] = useState('$');
  const [changeAddItemAs, setChangeAddItemAs] = useState('MAIN');
  const [status, setStatus] = useState('PM_ACTIVE');

  const [startDateForm, setStartDate] = useState(null);
  const [endDateForm, setEndDate] = useState(null);

  const [statusOptions, setStatusOptions] = useState([]);

  const [collapseActiveKeys, setCollapseActiveKeys] = useState([
    '1',
    '2',
    '3',
    '4',
  ]);

  const [conditionValidation, setConditionValidation] = useState(false);

  const [submitLoading, setSubmitLoading] = useState(false);
  const [loading, setLoading] = useState(true);

  const [attributeLoading, setAttributeLoading] = useState(false);
  const [attributesData, setAttributesData] = useState([]);

  const [industryDataClone, setIndustryDataClone] = useState([]);
  const [industryLoading, setIndustryLoading] = useState(true);
  const [hasAttribute, setHasAttribute] = useState(false);
  const [showPackageModifierOption, setShowPackageModifierOption] = useState(
    true,
  );
  const [errorMessage, setErrorMessage] = useState(null);
  const [pricingValueTypes, setPricingValueTypes] = useState([
    ...pricingValueTypeOptions,
  ]);
  const [showPopover, setShowPopover] = useState(false);

  const [form] = Form?.useForm();

  useEffect(() => {
    if (validationTriggered) {
      if (
        conditionData?.brands?.length > 0 ||
        conditionData?.manufacturers?.length > 0 ||
        conditionData?.regions?.length > 0 ||
        isEmpty(conditionData?.unitInputParameter)
      ) {
        setConditionValidation(false);
      } else {
        setConditionValidation(true);
      }
    }

    // code for hide % option and Base Product option when UNIT INPUT PARAMETER is added in condition
    if (!isEmpty(conditionData?.unitInputParameter)) {
      setShowPackageModifierOption(false);
      const filteredPricingValueTypes = pricingValueTypeOptions?.filter(
        (option) => option.value !== '%',
      );

      form?.setFieldsValue({
        pricingValueType: '$',
        modifierOn: null,
      });
      setPricingValueType('$');
      setPricingValueTypes([...filteredPricingValueTypes]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conditionData]);

  useEffect(() => {
    const unListen = history?.listen((props) => {
      if (props?.action === 'POP') {
        navigate(props?.location?.pathname, { state: { ...location?.state } });
      }
    });
    return () => {
      if (unListen) {
        unListen();
      }
    };
  }, []);

  const [pricingModifierConditions] = useLazyQuery(
    PRICING_MODIFIER_CONDITIONS,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: (res) => {
        setAttributesData([...res.pricingModifierConditions?.data]);
        setAttributeLoading(false);
      },
      onError() {
        setAttributeLoading(false);
      },
    },
  );

  const [industries, { data: industryData }] = useLazyQuery(GET_INDUSTRIES, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const defaultIndustry = {
        id: res?.industries?.data?.[0]?.id,
        lineOfBusiness: [
          {
            all: true,
            id: res?.industries?.data?.[0]?.lobSaData?.[0]?.id,
            subArea: [
              {
                id:
                  res?.industries?.data?.[0]?.lobSaData?.[0]?.subAreas?.[0]?.id,
              },
              {
                id:
                  res?.industries?.data?.[0]?.lobSaData?.[0]?.subAreas?.[1]?.id,
              },
            ],
          },
        ],
      };
      const industry = [{ ...defaultIndustry }];
      if (!isEdit) {
        setIndustryDataClone(industry);
      }
      setIndustryLoading(false);
    },

    onError: () => {
      setIndustryLoading(false);
    },
  });

  const [createPricingModifier] = useMutation(CREATE_PRICING_MODIFIER, {
    onError: () => {
      setSubmitLoading(false);
    },
  });

  const [updatePricingModifier] = useMutation(UPDATE_PRICING_MODIFIER, {
    onError: () => {
      setSubmitLoading(false);
    },
  });

  const [pricingModifier, { data }] = useLazyQuery(GET_PRICING_MODIFIER, {
    onCompleted: () => {
      initialValues = {
        ...data?.pricingModifier,
        pricingValue: data?.pricingModifier?.pricingValue?.toString(),
        pricingValueBetter: data?.pricingModifier?.pricingValueBetter?.toString(),
        pricingValueBest: data?.pricingModifier?.pricingValueBest?.toString(),
        ...(data?.pricingModifier?.startDate && {
          startDate: moment(data?.pricingModifier?.startDate),
        }),
        ...(data?.pricingModifier?.endDate && {
          endDate: moment(data?.pricingModifier?.endDate),
        }),
      };
      const conditionObject = {
        brands: [],
        regions: [],
        manufacturers: [],
        unitInputParameter: null,
      };
      const industry = [];

      forEach(data?.pricingModifier?.brands, (item) =>
        conditionObject?.brands?.push({ id: item?.id, label: item?.name }),
      );
      industry?.push({
        id: data?.pricingModifier?.industry,
        lineOfBusiness: [
          {
            all: true,
            id: data?.pricingModifier.lineOfBusiness,
            subArea: map(data?.pricingModifier?.subArea, (subArea) => ({
              id: subArea,
            })),
          },
        ],
      });
      forEach(data?.pricingModifier?.regions, (item) =>
        conditionObject?.regions?.push({
          id: item?.id,
          label: item?.name,
          all: item?.all,
          zipCodes: map(item?.zipCodes, (zipCode) => zipCode),
        }),
      );
      forEach(data?.pricingModifier?.manufacturers, (item) =>
        conditionObject?.manufacturers?.push({
          id: item?.id,
          label: item?.name,
        }),
      );
      if (!isEmpty(data?.pricingModifier?.conditions?.unitInputParameter)) {
        conditionObject.unitInputParameter = {
          ...data?.pricingModifier?.conditions?.unitInputParameter,
        };
      }
      setConditionData(conditionObject);
      setIndustryDataClone([...industry]);
      setPricingValueType(data?.pricingModifier?.pricingValueType);
      setPricingValue(data?.pricingModifier?.pricingValue?.toString());
      setChangeAddItemAs(data?.pricingModifier?.addItemAs);
      setStatus(data?.pricingModifier?.status);
      setLoading(false);
    },
    fetchPolicy: 'network-only',
    onError: () => {
      setLoading(false);
      setSubmitLoading(false);
    },
  });

  const onChangeIndustry = (e, label = '') => {
    const industryCopy = [{ id: e?.target?.value, label, lineOfBusiness: [] }];

    setIndustryDataClone([...industryCopy]);
    let conditionsCopy = { ...conditionData };

    conditionsCopy = {
      ...conditionsCopy,
      unitInputParameter: null,
    };
    setConditionData({ ...conditionsCopy });
    setErrorMessage(null);
  };

  const onChangeLob = (e, lob) => {
    const industryCopy = [...industryDataClone];

    if (industryCopy?.[0]?.lineOfBusiness?.length > 0) {
      industryCopy[0].lineOfBusiness = [];
    }
    const selectedSubareas = [];
    forEach(lob?.subAreas, (item) =>
      selectedSubareas?.push({
        id: item?.id,
        label: item?.label,
      }),
    );

    industryCopy?.[0]?.lineOfBusiness?.push({
      id: lob?.id,
      label: lob?.label,
      all: true,
      subArea: selectedSubareas,
    });

    let conditionsCopy = { ...conditionData };

    conditionsCopy = {
      ...conditionsCopy,
      unitInputParameter: null,
    };
    setConditionData({ ...conditionsCopy });
    setIndustryDataClone([...industryCopy]);
    setErrorMessage(null);
  };

  const onChangeSubArea = (e, subArea, lob) => {
    const industryCopy = [...industryDataClone];
    const lobIndex = findIndex(
      industryCopy?.[0]?.lineOfBusiness,
      (item) => item?.id === lob?.id,
    );

    if (e?.target?.checked) {
      if (lobIndex > -1) {
        industryCopy?.[0]?.lineOfBusiness?.[lobIndex]?.subArea?.push({
          id: subArea?.id,
          label: subArea?.label,
        });
        if (
          industryCopy?.[0]?.lineOfBusiness?.[lobIndex]?.subArea?.length ===
          lob?.subAreas?.length
        ) {
          set(industryCopy?.[0]?.lineOfBusiness?.[lobIndex], 'all', true);
        }
      } else {
        industryCopy?.[0]?.lineOfBusiness?.push({
          id: lob?.id,
          label: lob?.label,
          subArea: [{ id: subArea?.id, label: subArea?.label }],
          all: lob?.subAreas?.length?.length === 1,
        });
      }
    } else {
      const subAreaIndex = findIndex(
        industryCopy?.[0]?.lineOfBusiness?.[lobIndex]?.subArea,
        (item) => item?.id === subArea?.id,
      );
      industryCopy?.[0]?.lineOfBusiness?.[lobIndex]?.subArea?.splice(
        subAreaIndex,
        1,
      );
      set(industryCopy?.[0]?.lineOfBusiness?.[lobIndex], 'all', false);
      if (!industryCopy?.[0]?.lineOfBusiness?.[lobIndex]?.subArea?.length) {
        industryCopy?.[0]?.lineOfBusiness?.splice(lobIndex, 1);
      }
    }
    setIndustryDataClone([...industryCopy]);
    setErrorMessage(null);
  };

  const handleSubmit = async (formValues) => {
    let industry = null;
    let lineOfBusiness = null;
    const subArea = [];
    if (
      !conditionData?.brands?.length &&
      !conditionData?.manufacturers?.length &&
      !conditionData?.regions?.length &&
      isEmpty(conditionData?.unitInputParameter)
    ) {
      setConditionValidation(true);
      return;
    }

    setSubmitLoading(true);
    const conditions = {};
    if (conditionData?.brands?.length > 0) {
      set(
        conditions,
        'brands',
        conditionData?.brands?.map((item) => item?.id),
      );
    }
    if (conditionData?.regions?.length > 0) {
      set(
        conditions,
        'regions',
        conditionData?.regions?.map((item) => ({
          id: item?.id || null,
          all: item?.all || false,
          zipCodes: item?.all ? [] : item?.zipCodes,
        })),
      );
    }
    if (conditionData?.manufacturers?.length > 0) {
      set(
        conditions,
        'manufacturers',
        conditionData?.manufacturers?.map((item) => item?.id),
      );
    }
    if (!isEmpty(conditionData?.unitInputParameter)) {
      set(conditions, 'unitInputParameter', conditionData?.unitInputParameter);
    }
    industry = industryDataClone?.[0]?.id;
    lineOfBusiness = industryDataClone?.[0]?.lineOfBusiness?.[0]?.id;

    if (!industry) {
      setErrorMessage(
        'Please select an Industry, Line of Business and Service Type.',
      );
      setSubmitLoading(false);
      return;
    }
    if (!lineOfBusiness) {
      setErrorMessage('Please select Line of Business and Service Type.');
      setSubmitLoading(false);
      return;
    }
    if (industryDataClone?.[0]?.lineOfBusiness?.[0]?.subArea?.length > 0) {
      forEach(
        industryDataClone?.[0]?.lineOfBusiness?.[0]?.subArea,
        (subAreaItem) => {
          subArea?.push(subAreaItem?.id);
        },
      );
    } else {
      setErrorMessage('Please select a Service Type.');
      setSubmitLoading(false);
      return;
    }

    if (!isEdit) {
      const newFormValues = {
        ...formValues,
        modifierOn: formValues.modifierOn ? formValues.modifierOn : null,
        pricingValue: Number(formValues?.pricingValue),
        pricingValueBest: Number(formValues?.pricingValueBest),
        pricingValueBetter: Number(formValues?.pricingValueBetter),
        conditions,
        industry,
        lineOfBusiness,
        subArea,
      };

      const variables = newFormValues;

      try {
        const response = await createPricingModifier({
          variables: { data: { ...variables } },
        });
        if (response?.data?.createPricingModifier) {
          navigate(ROUTES?.PRICING_MODIFIERS);
        }
      } catch (error) {
        setSubmitLoading(false);
        return error;
      }
    } else {
      const newFormValues = {
        ...formValues,
        modifierOn: formValues.modifierOn ? formValues.modifierOn : null,
        pricingValue: Number(formValues?.pricingValue),
        pricingValueBest: Number(formValues?.pricingValueBest),
        pricingValueBetter: Number(formValues?.pricingValueBetter),
        conditions,
        industry,
        lineOfBusiness,
        subArea,
      };
      const variables = {
        data: newFormValues,
        where: { id: data?.pricingModifier?.id },
      };

      try {
        const response = await updatePricingModifier({
          variables: { ...variables },
        });
        if (response?.data?.updatePricingModifier) {
          setSubmitLoading(false);
          navigate(ROUTES?.PRICING_MODIFIERS);
        }
      } catch (error) {
        return error;
      }
    }
  };

  const onFinishFailed = () => {
    setCollapseActiveKeys(['1', '2', '3', '4']);
    if (
      !conditionData?.brands?.length &&
      !conditionData?.manufacturers?.length &&
      !conditionData?.regions?.length &&
      isEmpty(conditionData?.unitInputParameter)
    ) {
      setConditionValidation(true);
    }
    setValidationTriggered(true);
  };

  const [pricingModifierStatuses] = useLazyQuery(PRICING_MODIFIER_STATUS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const statusOptionsCopy = [];
      forEach(res?.pricingModifierStatuses?.data, (item) =>
        statusOptionsCopy?.push({ value: item?.key, label: item?.label }),
      );
      setStatusOptions(statusOptionsCopy);
    },
    onError() {},
  });

  useEffect(() => {
    pricingModifierStatuses({
      variables: {
        filter: {
          sortOn: 'key',
          sortBy: 'ASC',
        },
        where: {
          isActive: true,
        },
      },
    });
    pricingModifierConditions({
      variables: {
        filter: attributeFilter,
        where: { isActive: true },
      },
    });
    industries({
      variables: {
        filter: {
          sortOn: 'label',
          sortBy: 'DESC',
          justShow: true,
        },
        where: { isActive: true },
      },
    });
    if (isEdit) {
      pricingModifier({ variables: { id } });
    } else {
      initialValues = {
        pricingOperation: 'ADD',
        pricingValueType: '$',
        modifierOn: 'BASE',
        addItemAs: 'MAIN',
        billingAs: 'PRODUCT',
        status: 'PM_ACTIVE',
      };
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      industryDataClone?.[0]?.id &&
      industryDataClone?.[0]?.lineOfBusiness?.[0]?.id
    ) {
      setShowPopover(false);
    }
  }, [industryDataClone]);

  const handleDeleteBrands = () => {
    setConditionData({ ...conditionData, brands: [] });
  };
  const handleDeleteManufacturers = () => {
    setConditionData({ ...conditionData, manufacturers: [] });
  };
  const handleDeleteRegions = () => {
    setConditionData({ ...conditionData, regions: [] });
  };

  const handleDeleteUnitInputParameters = () => {
    setConditionData({ ...conditionData, unitInputParameter: {} });
    form?.setFieldsValue({
      pricingValueType: '$',
      modifierOn:
        isEdit && data?.pricingModifier?.modifierOn
          ? data?.pricingModifier?.modifierOn
          : 'BASE',
    });
    setShowPackageModifierOption(true);
    setPricingValueTypes([...pricingValueTypeOptions]);
  };
  const handleDeleteTagBrands = (brandIndex) => {
    const brandsCopy = [...conditionData?.brands];
    brandsCopy?.splice(brandIndex, 1);
    setConditionData({ ...conditionData, brands: brandsCopy });
  };

  const handleDeleteTagManufacturers = (brandIndex) => {
    const manufacturersCopy = [...conditionData?.manufacturers];
    manufacturersCopy.splice(brandIndex, 1);
    setConditionData({ ...conditionData, manufacturers: manufacturersCopy });
  };

  const handleDeleteTag = (regionIndex, zipIndex) => {
    const regionCopy = [...conditionData?.regions];
    if (zipIndex) {
      regionCopy?.[regionIndex]?.zipCodes?.splice(zipIndex, 1);
      if (!regionCopy?.[regionIndex]?.zipCodes?.length) {
        regionCopy?.splice(regionIndex, 1);
      }
    } else {
      regionCopy?.splice(regionIndex, 1);
    }
    setConditionData({ ...conditionData, regions: regionCopy });
  };

  const handleOpenModal = (event, visible = false, record = null) => {
    if (event) {
      event?.stopPropagation();
    }

    if (
      !industryDataClone?.[0]?.id ||
      !industryDataClone?.[0]?.lineOfBusiness?.[0]?.id
    ) {
      setShowPopover(true);
    } else {
      setShowModal(visible);
      setSelectedCondition(record);
    }
  };

  const handlePricingValueChange = (value) => {
    if (value) {
      setPricingValue(value?.toString());
    } else {
      setPricingValue('');
    }
  };

  const handleChangePricingValueType = (e) => {
    setPricingValueType(e?.target?.value);
    if (Number(form?.getFieldValue('pricingValue')) > 100) {
      setPricingValue('100');
      form?.setFieldsValue({ pricingValue: '100' });
    }
  };

  const handleChangeAddItemAs = (e) => {
    setChangeAddItemAs(e?.target?.value);
    form?.setFieldsValue({
      billingAs: 'PRODUCT',
    });
  };

  const handleChangeStatus = (e) => {
    setStatus(e?.target?.value);
  };

  const handleChangeStartDate = (value) => {
    if (!value) {
      setStartDate(null);
      return;
    }
    setStartDate(moment(value)?.startOf('day'));
  };

  const handleChangeEndDate = (value) => {
    if (!value) {
      setEndDate(null);
      return;
    }
    setEndDate(moment(value)?.endOf('day'));
  };

  if (loading || industryLoading) {
    return <LoaderComponent spinning={loading || industryLoading} />;
  }

  const getPopupContainer = () =>
    // eslint-disable-next-line no-undef
    document.getElementById('container');
  const getDeletePopContainer = (condition = null) => {
    let popUp = null;
    switch (condition) {
      case QUOTE_MODIFIER_CONDITION_ATTRIBUTE?.PMC_BRAND:
        // eslint-disable-next-line no-undef
        popUp = document.getElementById('brand-delete-condition-popover');
        break;

      case QUOTE_MODIFIER_CONDITION_ATTRIBUTE?.PMC_MANUFACTURER:
        // eslint-disable-next-line no-undef
        popUp = document.getElementById('manufacture-delete-condition-popover');
        break;

      case QUOTE_MODIFIER_CONDITION_ATTRIBUTE?.PMC_REGION:
        // eslint-disable-next-line no-undef
        popUp = document.getElementById('region-delete-condition-popover');
        break;

      case QUOTE_MODIFIER_CONDITION_ATTRIBUTE?.PMC_UNIT_INPUT_PARAMETER:
        // eslint-disable-next-line no-undef
        popUp = document.getElementById('uim-delete-condition-popover');
        break;
      default:
        // eslint-disable-next-line no-undef
        popUp = document.getElementById('brand-delete-condition-popover');
        break;
    }

    return popUp;
  };

  const pricingModifierCollapseItems = [
    {
      key: '1',
      label: 'Basic Information *',
      forceRender: true,
      children: (
        <>
          <Row gutter={16}>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                rules={[{ ...required, message: 'Please Enter Title' }]}
                name="title"
                label="Title"
              >
                <InputComponent allowClear placeholder="Enter Title" />
              </Form.Item>
            </Col>
            {initialValues?.sponsorName && !isSponsor && (
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item name="sponsorName" label="Sponsor">
                  <InputComponent disabled />
                </Form.Item>
              </Col>
            )}
          </Row>

          <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Form.Item name="description" label="Description">
                <TextAreaComponent
                  className="common-textarea"
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  placeholder="Description is optional..."
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <div className="field-detail">
              <span className="field-label">
                Industry, Line of Business and Service Type
              </span>
              <Radio.Group
                value={industryDataClone?.[0]?.id}
                className="fill-width"
              >
                {industryData?.industries?.data?.length > 0 ? (
                  <div className="d-flex flex-wrap block-parent">
                    {map(industryData?.industries?.data, (industry) => {
                      const industryCopy = [...industryDataClone];
                      return (
                        <div className="lob-block" key={industry?.id}>
                          <Radio
                            className="common-radio lob-section"
                            value={industry?.id}
                            onChange={(e) =>
                              onChangeIndustry(e, industry?.label)
                            }
                          >
                            <span className="bold-label">
                              {industry?.label}
                            </span>
                          </Radio>
                          <Divider />
                          <div className="d-flex">
                            {map(industry?.lobSaData, (lob) => {
                              const lobIndex = findIndex(
                                industryCopy?.[0]?.lineOfBusiness,
                                (item) => item?.id === lob?.id,
                              );
                              let lobCopy;

                              if (!isEmpty(industryCopy?.[0]?.lineOfBusiness)) {
                                lobCopy = [
                                  ...industryCopy?.[0]?.lineOfBusiness,
                                ];
                              }
                              return (
                                <div key={lob?.id} className="fill-width">
                                  <Radio.Group
                                    className="common-radio lob-section fill-width"
                                    value={
                                      industryDataClone?.[0]
                                        ?.lineOfBusiness?.[0]?.id
                                    }
                                    disabled={
                                      industryCopy?.[0]?.id !== industry?.id
                                    }
                                  >
                                    <Radio
                                      value={lob?.id}
                                      onChange={(e) => onChangeLob(e, lob)}
                                    >
                                      {lob?.label}
                                    </Radio>
                                  </Radio.Group>
                                  <Divider />
                                  <Checkbox.Group
                                    className="fill-width"
                                    disabled={lobCopy?.[0]?.id !== lob?.id}
                                    value={map(
                                      industryCopy?.[0]?.lineOfBusiness?.[
                                        lobIndex
                                      ]?.subArea,
                                      (item) => item?.id,
                                    )}
                                  >
                                    {map(
                                      sortBy(lob?.subAreas, 'label'),
                                      (subArea) => (
                                        <div key={subArea?.id}>
                                          <Checkbox
                                            className="common-checkbox subarea-section"
                                            value={subArea?.id}
                                            onChange={(e) =>
                                              onChangeSubArea(
                                                e,
                                                subArea,
                                                lob,
                                                industry,
                                              )
                                            }
                                          >
                                            <span className="line-clamp">
                                              {subArea?.label}
                                            </span>
                                          </Checkbox>
                                        </div>
                                      ),
                                    )}
                                  </Checkbox.Group>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <Empty
                    className="no-data-image"
                    image={Empty?.PRESENTED_IMAGE_SIMPLE}
                  />
                )}
              </Radio.Group>
            </div>
          </Row>
          {!isEmpty(errorMessage) && (
            <span className="custom-error-message ">{errorMessage}</span>
          )}
        </>
      ),
    },
    {
      key: '2',
      label: 'Conditions *',
      forceRender: true,
      extra: (conditionData?.brands?.length > 0 ||
        conditionData?.manufacturers?.length > 0 ||
        conditionData?.regions?.length > 0 ||
        !isEmpty(conditionData?.unitInputParameter)) && (
        <Popover
          content="Select Industry, Line of Business and Service Type"
          trigger="click"
          getPopupContainer={getPopupContainer}
          overlayClassName="industry-popover"
          open={
            !(
              industryDataClone?.[0]?.id &&
              industryDataClone?.[0]?.lineOfBusiness?.[0]?.id
            )
          }
        >
          <Button
            className="common-button"
            onClick={(e) => handleOpenModal(e, true, null)}
            icon={<PlusOutlined />}
            size="small"
            type="primary"
          >
            Add Condition
          </Button>
        </Popover>
      ),

      children: (
        <>
          {!conditionData?.brands?.length &&
          !conditionData?.manufacturers?.length &&
          !conditionData?.regions?.length &&
          isEmpty(conditionData?.unitInputParameter) ? (
            <Empty
              image={Empty?.PRESENTED_IMAGE_SIMPLE}
              description="Add at least one Condition."
              className="no-conditions-found"
            >
              <div id="container" className="d-flex justify-center">
                <Popover
                  content="Select Industry, Line of Business and Service Type"
                  overlayClassName="industry-popover"
                  trigger="click"
                  open={showPopover}
                  getPopupContainer={getPopupContainer}
                >
                  <Button
                    id="area"
                    className="common-button"
                    onClick={() => handleOpenModal(null, true, null)}
                    icon={<PlusOutlined />}
                    size="small"
                    type="primary"
                  >
                    Add Condition
                  </Button>
                </Popover>
              </div>
            </Empty>
          ) : (
            <>
              {conditionData?.brands?.length > 0 && (
                <List
                  className="pricing-list"
                  bordered
                  dataSource={[conditionData]}
                  renderItem={(item) => {
                    if (item?.brands?.length > 0) {
                      return (
                        <div className="single-row" key={item?.id}>
                          <div className="pricing-head-section">
                            <span className="pricing-title">Retailers</span>
                          </div>

                          <div className="pricing-middle-section">
                            {map(item?.brands, (singleData, tagIndex) => (
                              <Tag
                                className="single-item"
                                title={singleData?.label}
                                key={singleData?.id}
                              >
                                <span className="content-text">
                                  {singleData?.label}
                                  <CloseOutlined
                                    className="close-img"
                                    onClick={() =>
                                      handleDeleteTagBrands(tagIndex)
                                    }
                                  />
                                </span>
                              </Tag>
                            ))}
                            <Tag
                              className="tag-plus"
                              onClick={() =>
                                handleOpenModal(null, true, 'PMC_BRAND')
                              }
                            >
                              <span className="content-text">
                                <PlusOutlined /> Add Retailers
                              </span>
                            </Tag>
                          </div>
                          <div
                            id="brand-delete-condition-popover"
                            className="pricing-end-section"
                          >
                            <Popconfirm
                              title="Are you sure to delete?"
                              onConfirm={() => handleDeleteBrands(item)}
                              okText="Yes"
                              cancelText="No"
                              getPopupContainer={() =>
                                getDeletePopContainer(
                                  QUOTE_MODIFIER_CONDITION_ATTRIBUTE?.PMC_BRAND,
                                )
                              }
                              placement="topLeft"
                            >
                              <img
                                src={deleteIcon}
                                alt="delete-icon"
                                className="pricing-delete"
                              />
                            </Popconfirm>
                          </div>
                        </div>
                      );
                    }
                  }}
                />
              )}
              {conditionData?.manufacturers?.length > 0 && (
                <List
                  className="pricing-list"
                  bordered
                  dataSource={[conditionData]}
                  renderItem={(item) => {
                    if (item?.manufacturers?.length > 0) {
                      return (
                        <div className="single-row" key={item?.id}>
                          <div className="pricing-head-section">
                            <span className="pricing-title">Manufacturers</span>
                          </div>

                          <div className="pricing-middle-section">
                            {map(
                              item?.manufacturers,
                              (singleData, tagIndex) => (
                                <Tag
                                  className="single-item"
                                  title={singleData?.label}
                                  key={singleData?.id}
                                >
                                  <span className="content-text">
                                    {singleData?.label}
                                    <CloseOutlined
                                      className="close-img"
                                      onClick={() =>
                                        handleDeleteTagManufacturers(tagIndex)
                                      }
                                    />
                                  </span>
                                </Tag>
                              ),
                            )}
                            <Tag
                              className="tag-plus"
                              onClick={() =>
                                handleOpenModal(null, true, 'PMC_MANUFACTURER')
                              }
                            >
                              <span className="content-text">
                                <PlusOutlined /> Add Manufacturers
                              </span>
                            </Tag>
                          </div>
                          <div
                            className="pricing-end-section"
                            id="manufacture-delete-condition-popover"
                          >
                            <Popconfirm
                              title="Are you sure to delete?"
                              onConfirm={() => handleDeleteManufacturers(item)}
                              okText="Yes"
                              cancelText="No"
                              getPopupContainer={() =>
                                getDeletePopContainer(
                                  QUOTE_MODIFIER_CONDITION_ATTRIBUTE?.PMC_MANUFACTURER,
                                )
                              }
                              placement="topLeft"
                            >
                              <img
                                src={deleteIcon}
                                alt="delete-icon"
                                className="pricing-delete"
                              />
                            </Popconfirm>
                          </div>
                        </div>
                      );
                    }
                  }}
                />
              )}
              {conditionData?.regions?.length > 0 && (
                <List
                  className="pricing-list"
                  bordered
                  dataSource={[conditionData]}
                  renderItem={(item) => {
                    if (item?.regions?.length > 0) {
                      return (
                        <div className="single-row" key={item?.id}>
                          <div className="pricing-head-section">
                            <span className="pricing-title">Regions</span>
                          </div>

                          <div className="pricing-middle-section">
                            {map(
                              conditionData?.regions,
                              (singleData, tagIndex) => {
                                if (singleData?.all) {
                                  return (
                                    <Tag
                                      className="single-item region-tags"
                                      title={singleData?.label}
                                      key={singleData?.id}
                                    >
                                      <span className="content-text">
                                        <span className="region-only">
                                          {singleData?.label}
                                        </span>
                                        <CloseOutlined
                                          className="close-img"
                                          onClick={() =>
                                            handleDeleteTag(tagIndex)
                                          }
                                        />
                                      </span>
                                    </Tag>
                                  );
                                }
                                return map(
                                  singleData?.zipCodes,
                                  (singleZip, singleIndex) => (
                                    <Tag
                                      className="single-item region-tags"
                                      title={`${
                                        singleData?.label
                                          ? `${singleData?.label} - `
                                          : ''
                                      }${singleZip}`}
                                      key={`${singleZip}${tagIndex}`}
                                    >
                                      <span className="content-text">
                                        <span>{`${
                                          singleData?.label
                                            ? `${singleData?.label} - `
                                            : ''
                                        }${singleZip}`}</span>
                                        <CloseOutlined
                                          className="close-img"
                                          onClick={() =>
                                            handleDeleteTag(
                                              tagIndex,
                                              singleIndex,
                                            )
                                          }
                                        />
                                      </span>
                                    </Tag>
                                  ),
                                );
                              },
                            )}
                            <Tag
                              className="tag-plus"
                              onClick={() =>
                                handleOpenModal(null, true, 'PMC_REGION')
                              }
                            >
                              <span className="content-text">
                                <PlusOutlined /> Add Regions
                              </span>
                            </Tag>
                          </div>
                          <div
                            className="pricing-end-section"
                            id="region-delete-condition-popover"
                          >
                            <Popconfirm
                              title="Are you sure to delete?"
                              onConfirm={() => handleDeleteRegions(item)}
                              okText="Yes"
                              cancelText="No"
                              placement="topLeft"
                              getPopupContainer={() =>
                                getDeletePopContainer(
                                  QUOTE_MODIFIER_CONDITION_ATTRIBUTE?.PMC_REGION,
                                )
                              }
                            >
                              <img
                                src={deleteIcon}
                                alt="delete-icon"
                                className="pricing-delete"
                              />
                            </Popconfirm>
                          </div>
                        </div>
                      );
                    }
                  }}
                />
              )}
              {!isEmpty(conditionData?.unitInputParameter) && (
                <List
                  className="pricing-list"
                  bordered
                  dataSource={[conditionData]}
                  renderItem={(item) => {
                    if (!isEmpty(item?.unitInputParameter)) {
                      return (
                        <div className="single-row" key={item?.id}>
                          <div className="pricing-head-section">
                            <span className="pricing-title">
                              Unit Input Parameters
                            </span>
                          </div>
                          <div className="pricing-middle-section">
                            <Tag
                              className="single-item region-tags"
                              title="singleData?.label"
                            >
                              <span>
                                {createUnitInputParameterMessage(
                                  item?.unitInputParameter,
                                )}
                              </span>
                            </Tag>
                            <Tag
                              className="tag-plus"
                              onClick={() => {
                                setHasAttribute(true);
                                handleOpenModal(
                                  null,
                                  true,
                                  'PMC_UNIT_INPUT_PARAMETER',
                                );
                              }}
                            >
                              <span className="content-text">
                                <PlusOutlined /> Edit Unit Parameters
                              </span>
                            </Tag>
                          </div>
                          <div
                            className="pricing-end-section"
                            id="uim-delete-condition-popover"
                          >
                            <Popconfirm
                              title="Are you sure to delete?"
                              onConfirm={handleDeleteUnitInputParameters}
                              okText="Yes"
                              cancelText="No"
                              placement="topLeft"
                              getPopupContainer={() =>
                                getDeletePopContainer(
                                  QUOTE_MODIFIER_CONDITION_ATTRIBUTE?.PMC_UNIT_INPUT_PARAMETER,
                                )
                              }
                              po
                            >
                              <img
                                src={deleteIcon}
                                alt="delete-icon"
                                className="pricing-delete"
                              />
                            </Popconfirm>
                          </div>
                        </div>
                      );
                    }
                  }}
                />
              )}
            </>
          )}
          {conditionValidation && (
            <span className="error-text">
              Please Add at least one Condition
            </span>
          )}
        </>
      ),
    },
    {
      key: '3',
      label: 'Price *',
      forceRender: true,
      children: (
        <>
          <Row gutter={16} className="top-margin">
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <div className="radio-description">
                Select type of price change
              </div>
              <Form.Item name="pricingOperation" noStyle>
                <Radio.Group
                  className="common-radio"
                  options={pricingOperationOptions}
                />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <div className="radio-description">
                {isEmpty(conditionData?.unitInputParameter)
                  ? 'Select dollar or percent price change'
                  : 'Select dollar price change'}
              </div>
              <Form.Item name="pricingValueType" noStyle>
                <Radio.Group
                  onChange={handleChangePricingValueType}
                  className="common-radio"
                  options={pricingValueTypes}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} className="mt-24">
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                label="Good"
                name="pricingValue"
                rules={[
                  {
                    ...required,
                    message: `Please Enter Good ${
                      pricingValueType === '%' ? 'Percentage' : 'Amount'
                    }`,
                  },
                ]}
                normalize={(value) => {
                  if (value) {
                    return value?.toString();
                  }
                  return '';
                }}
              >
                <PriceComponent
                  placeholder={`Enter Good ${
                    pricingValueType === '$' ? 'Price' : 'Percentage'
                  }`}
                  onChange={handlePricingValueChange}
                  isPrice
                  prefix={pricingValueType === '$' ? '$' : ''}
                  suffix={pricingValueType === '%' ? '%' : ''}
                />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                label="Better"
                name="pricingValueBetter"
                rules={[
                  {
                    ...required,
                    message: `Please  Enter Better ${
                      pricingValueType === '%' ? 'Percentage' : 'Amount'
                    }`,
                  },
                ]}
                normalize={(value) => {
                  if (value) {
                    return value?.toString();
                  }
                  return '';
                }}
              >
                <PriceComponent
                  placeholder={`Enter Better ${
                    pricingValueType === '$' ? 'Price' : 'Percentage'
                  }`}
                  onChange={handlePricingValueChange}
                  isPrice
                  prefix={pricingValueType === '$' ? '$' : ''}
                  suffix={pricingValueType === '%' ? '%' : ''}
                />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                label="Best"
                name="pricingValueBest"
                rules={[
                  {
                    ...required,
                    message: `Please Enter Best ${
                      pricingValueType === '%' ? 'Percentage' : 'Amount'
                    }`,
                  },
                ]}
                normalize={(value) => {
                  if (value) {
                    return value?.toString();
                  }
                  return '';
                }}
              >
                <PriceComponent
                  placeholder={`Enter Best ${
                    pricingValueType === '$' ? 'Price' : 'Percentage'
                  }`}
                  onChange={handlePricingValueChange}
                  isPrice
                  prefix={pricingValueType === '$' ? '$' : ''}
                  suffix={pricingValueType === '%' ? '%' : ''}
                />
              </Form.Item>
            </Col>
          </Row>

          {pricingValue?.length > 0 && (
            <>
              {showPackageModifierOption && (
                <>
                  <div className="top-margin radio-description">
                    Apply price change only to the base package or base package
                    and add-ons
                  </div>
                  <Form.Item name="modifierOn" noStyle>
                    <Radio.Group
                      className="common-radio"
                      options={modifierOnOptions}
                    />
                  </Form.Item>
                </>
              )}
              <div
                className={`${
                  showPackageModifierOption ? 'top-margin' : ''
                } radio-description`}
              >
                Apply price change to Base Package or list Add-Ons on the
                separate line item
              </div>
              <Form.Item name="addItemAs" noStyle>
                <Radio.Group
                  onChange={handleChangeAddItemAs}
                  className="common-radio radio-description"
                  options={addItemAsOptions}
                />
              </Form.Item>
              {changeAddItemAs === 'OTHER' && (
                <Row gutter={16}>
                  <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                    <Form.Item
                      rules={[
                        {
                          ...required,
                          message: 'Please Enter Line Item Name',
                        },
                      ]}
                      name="lineItemName"
                      label="Line Item Name"
                    >
                      <InputComponent
                        allowClear
                        placeholder="Enter Line Item Name"
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={18}
                    xxl={18}
                    className="d-flex align-center"
                  >
                    <Form.Item name="billingAs" noStyle>
                      <Radio.Group
                        className="common-radio"
                        options={billingAsOptions}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </>
          )}
        </>
      ),
    },
    {
      key: '4',
      label: 'Status *',
      forceRender: true,
      children: (
        <>
          {statusOptions && (
            <>
              <div className="radio-description">
                Would you like to mark this Pricing Modifier as Active or
                schedule it for a time period?
              </div>
              <Form.Item name="status" noStyle>
                <Radio.Group
                  className="common-radio radio-description"
                  onChange={handleChangeStatus}
                  options={statusOptions}
                />
              </Form.Item>
            </>
          )}
          {status === 'PM_SCHEDULE' && (
            <Row gutter={16}>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item
                  name="startDate"
                  label="Start Date"
                  normalize={(value) => {
                    if (value) {
                      return moment(value)?.startOf('day');
                    }
                  }}
                  rules={[
                    {
                      ...startDate(endDateForm),
                    },
                  ]}
                >
                  <DatePickerComponent
                    disabledDate
                    onChange={handleChangeStartDate}
                    placeholder="Start Date"
                    format={dateFormatWithoutTime(globalDateFormat)}
                  />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item
                  name="endDate"
                  label="Expire Date"
                  normalize={(value) => {
                    if (value) {
                      return moment(value)?.endOf('day');
                    }
                    return null;
                  }}
                  rules={[
                    {
                      ...endDate(startDateForm),
                    },
                  ]}
                >
                  <DatePickerComponent
                    disabledDate
                    onChange={handleChangeEndDate}
                    placeholder="Expire Date"
                    format={dateFormatWithoutTime(globalDateFormat)}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
        </>
      ),
    },
  ];
  return (
    <div className="content-section">
      {showModal && (
        <PricingConditionModal
          isEdit={false}
          showModal={showModal}
          setShowModal={setShowModal}
          selectedCondition={selectedCondition}
          setConditionDataCopy={setConditionData}
          conditionDataCopy={conditionData}
          setCollapseActiveKeys={setCollapseActiveKeys}
          collapseActiveKeys={collapseActiveKeys}
          attributeLoading={attributeLoading}
          setAttributeLoading={setAttributeLoading}
          attributesData={attributesData}
          selectedIndustryData={industryDataClone}
          hasAttribute={hasAttribute}
          setHasAttribute={setHasAttribute}
        />
      )}
      {isEdit && (
        <Portal portalId="header-left-content">
          <div className="d-flex align-center">
            <GoBackButton customLink={ROUTES?.PRICING_MODIFIERS} />
            {data?.pricingModifier?.title && (
              <span className="portal-header">
                {data?.pricingModifier?.title}
              </span>
            )}
          </div>
        </Portal>
      )}
      <AccessControl
        allowedPermissions={
          isEdit
            ? ['FET_PRICING_MODIFIER_UPDATE']
            : ['FET_PRICING_MODIFIER_CREATE']
        }
      >
        <Portal portalId="header-right-content">
          <Button
            className="common-button discard-button"
            icon={<DeleteIconComponent />}
            size="small"
            id="pricingModifier-table-discard-btn"
            onClick={() =>
              navigate(ROUTES?.PRICING_MODIFIERS, {
                state: { ...location?.state },
              })
            }
          >
            {isEdit ? 'Discard Changes' : 'Cancel'}
          </Button>
          <Button
            className="common-button"
            icon={<img src={SaveIcon} alt="save-icon" width={12} />}
            size="small"
            htmlType="submit"
            id="pricingmodifier-table-save-btn"
            loading={submitLoading}
            type="primary"
            onClick={form?.submit}
          >
            Save
          </Button>
        </Portal>
      </AccessControl>
      <Form
        form={form}
        initialValues={initialValues}
        layout="vertical"
        validateTrigger={validationTriggered ? 'onChange' : 'onSubmit'}
        onValuesChange={() => dispatch({ type: 'SET_SHOW_PROMPT', data: true })}
        onFinish={(values) => {
          dispatch({ type: 'SET_SHOW_PROMPT', data: false });
          handleSubmit(values);
        }}
        disabled={initialValues?.sponsorName && !isSponsor}
        onFinishFailed={onFinishFailed}
        scrollToFirstError={{ behavior: 'smooth', block: 'end' }}
      >
        <fieldset
          disabled={
            !checkPermissions(
              permissions,
              isEdit
                ? ['FET_PRICING_MODIFIER_UPDATE']
                : ['FET_PRICING_MODIFIER_CREATE'],
            )
          }
        >
          <Collapse
            bordered={false}
            activeKey={collapseActiveKeys}
            onChange={(keys) => setCollapseActiveKeys(keys)}
            expandIconPosition="end"
            expandIcon={({ isActive }) => (
              <UpOutlined rotate={isActive ? 0 : 180} />
            )}
            className="common-collapse"
            items={pricingModifierCollapseItems}
          />
        </fieldset>
      </Form>
    </div>
  );
};

export default PricingModifierForm;
