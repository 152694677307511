import { Button, Col, Form, message, Row, Switch } from 'antd';
import React, { useState } from 'react';
import deleteIcon from '../../../assets/delete-red.svg';
import { formValidatorRules } from '../../../common/utils';
import InputComponent from '../../../components/InputComponent';
import TableComponent from '../../../components/TableComponent';

const { requiredWhiteSpaceAllowed, email } = formValidatorRules;

const MAX_EMAIL_LIMIT = 5;

const EmailTable = ({ form }) => {
  const [emailData, setEmailData] = useState([]);
  const [emailInput, setEmailInput] = useState(null);

  const removeEmail = (emailToBeRemoved) => {
    const newEmailData = emailData?.filter(
      (item) => item.email !== emailToBeRemoved,
    );
    setEmailData(newEmailData);
  };
  const columns = [
    {
      title: 'EMAIL',
      dataIndex: 'email',
      key: 'email',
      className: 'max-width-column',
    },
    {
      title: 'FAILED NOTIFICATION',
      width: 200,
      align: 'center',
      dataIndex: 'failedNotification',
      key: 'email',
      className: 'max-width-column',
      render: () => <Switch />,
    },
    {
      title: 'SUCCESS NOTIFICATION',
      width: 200,
      align: 'center',
      dataIndex: 'successNotification',
      key: 'successNotification',
      className: 'max-width-column',
      render: () => <Switch />,
    },
    {
      title: 'ACTION',
      align: 'center',

      width: 200,
      render: (action, record = {}) => (
        <img
          onClick={() => removeEmail(record?.email)}
          src={deleteIcon}
          className="remove-email-btn"
          alt="delete-icon"
        />
      ),
    },
  ];

  const addEmail = () => {
    const isAlreadyAdded = emailData?.find(
      (item) => item?.email === emailInput,
    );

    if (!isAlreadyAdded) {
      setEmailData([
        ...emailData,
        {
          email: emailInput,
          failedNotification: false,
          successNotification: false,
        },
      ]);
      setEmailInput(null);
      form?.setFieldValue('email', null);
    } else {
      message.destroy();
      message?.error('Email already added');
    }
  };

  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={20} className="mt-10">
          <Form.Item
            validateTrigger="onChange"
            name="email"
            rules={[requiredWhiteSpaceAllowed, email]}
          >
            <InputComponent
              placeholder="Email"
              aria-label="Email"
              onChange={(e) => {
                setEmailInput(e?.target?.value);
              }}
            />
          </Form.Item>
        </Col>
        <Col span={4} className="mt-10">
          <Button
            className="common-button login-button fill-width font-size-14"
            onClick={addEmail}
            disabled={!emailInput || emailData?.length === MAX_EMAIL_LIMIT}
          >
            ADD
          </Button>
        </Col>
      </Row>
      <TableComponent data={emailData} columns={columns} />
    </>
  );
};

export default EmailTable;
