import { UpOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Checkbox, Col, Collapse, Empty, Form, Row, Space } from 'antd';
import { debounce, map, uniq } from 'lodash';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { messageContext } from '../../../app/components/AppContextHolder';
import { AppContext } from '../../../AppContext';
import copyIcon from '../../../assets/copy-dark.svg';
import SaveIcon from '../../../assets/save.svg';
import { REGEX, SKIP_RECORD } from '../../../common/constants';
import {
  checkPermissions,
  cpqRetailUrl,
  formValidatorRules,
  formatPhoneNumberWithoutMask,
} from '../../../common/utils';
import AccessControl from '../../../components/AccessControl';
import InputComponent from '../../../components/InputComponent';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import SelectComponent from '../../../components/SelectComponent';
import { FETCH_STATE_CITY } from '../../signup/graphql/Query';
import { UPDATE_SPONSOR, UPDATE_TENANT } from '../graphql/Mutations';
import { GET_DATE_FORMATS } from '../graphql/Queries';

let stateScrollDebounce;
let cityScrollDebounceJob;
let dateFormatDebounceJob;

const { Option } = SelectComponent;

const { required, email, subDomain, url: urlCheck } = formValidatorRules;

const stateCityFilter = {
  skip: 0,
  limit: 20,
  type: 'STATE',
  search: '',
  sortOn: 'name',
  sortBy: 'ASC',
};

const initialDateFormatFilter = {
  skip: 0,
  limit: 20,
};

const TenantSettingForm = ({ initialValue }) => {
  const {
    state: { permissions, isSponsor },
  } = useContext(AppContext);
  const [form] = Form?.useForm();
  const { dispatch } = useContext(AppContext);
  const [disableBtn, setDisableBtn] = useState(true);

  const [fetchCity, setFetchCity] = useState(false);
  const [citySearchFlag, setCitySearchFlag] = useState(false);
  const [stateSearchFlag, setStateSearchFlag] = useState(false);
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const [cityLoading, setCityLoading] = useState(false);
  const [stateLoading, setStateLoading] = useState(false);
  const [selectedState, setSelectedState] = useState('');
  const [disableCity, setDisableCity] = useState(true);
  const [callAsync, setCallAsync] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [stateIsEnd, setStateIsEnd] = useState(false);
  const [cityIsEnd, setCityIsEnd] = useState(false);

  const [dateFormatLoading, setDateFormatLoading] = useState(false);
  const [dateFormatSearchFlag, setDateFormatSearchFlag] = useState(false);
  const [dateFormatIsEnd, setDateFormatIsEnd] = useState(false);
  const [dateFormatDebounceCall, setDateFormatDebounceCall] = useState(0);
  const [dateFormats, setDateFormats] = useState([]);

  const [collapseActiveKeys, setCollapseActiveKeys] = useState([
    'general',
    'businessInfo',
    'contactInfo',
    'subDomainInfo',
    'dateFormat',
  ]);

  const [fetchStateAndCity] = useLazyQuery(FETCH_STATE_CITY, {
    fetchPolicy: 'network-only',
    onCompleted(response) {
      const moreData = response?.getLocationType?.data;
      if (fetchCity) {
        setCityIsEnd(moreData?.length < SKIP_RECORD);
        if (citySearchFlag) {
          setCities([...moreData]);
        } else {
          setCities([...cities, ...moreData]);
        }
        setCityLoading(false);
      } else {
        setStateIsEnd(moreData?.length < SKIP_RECORD);
        if (stateSearchFlag) {
          setStates([...moreData]);
        } else {
          setStates([...states, ...moreData]);
        }
        setStateLoading(false);
        setCallAsync(false);
      }
    },
    onError() {
      setStateLoading(false);
      setCityLoading(false);
    },
  });
  const [updateSponsor, { updateSponsorLoading }] = useMutation(
    UPDATE_SPONSOR,
    {
      onError: () => {},
    },
  );

  const [updateTenant, { loading }] = useMutation(UPDATE_TENANT, {
    onError: () => {},
  });

  const [fetchDateFormats] = useLazyQuery(GET_DATE_FORMATS, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      setDateFormatIsEnd(res?.timeFormats?.data?.length < SKIP_RECORD);
      if (dateFormatSearchFlag) {
        setDateFormats([...res?.timeFormats?.data]);
      } else {
        setDateFormats(uniq([...dateFormats, ...res?.timeFormats?.data]));
      }
      setDateFormatLoading(false);
    },
    onError() {
      setDateFormatLoading(false);
    },
  });

  useEffect(() => {
    if (!callAsync && states?.length > 0) {
      setFetchCity(true);
      fetchStateAndCity({
        variables: {
          filter: {
            ...stateCityFilter,
            type: 'CITY',
            state: initialValue?.state,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callAsync]);

  useEffect(() => {
    if (!initialValue?.state) {
      fetchStateAndCity({
        variables: {
          filter: stateCityFilter,
        },
      });
    }
    if (initialValue?.state) {
      setDisableCity(false);
      setCitySearchFlag(true);
      setCallAsync(true);
      setSelectedState(initialValue?.state);
      fetchStateAndCity({
        variables: {
          filter: stateCityFilter,
        },
      });
    }

    fetchDateFormats({
      variables: {
        filter: initialDateFormatFilter,
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCityBlur = () => {
    setSearchValue('');
    setCityIsEnd(false);
  };

  const handleStateBlur = () => {
    setStateIsEnd(false);
  };

  const handleCityClear = () => {
    setFetchCity(true);
    fetchStateAndCity({
      variables: {
        filter: {
          ...stateCityFilter,
          type: 'CITY',
          state: selectedState,
        },
      },
    });
  };

  const onStateScroll = (event) => {
    setFetchCity(false);
    setStateSearchFlag(false);
    if (stateScrollDebounce) {
      stateScrollDebounce?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    stateScrollDebounce = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !stateIsEnd) {
        setStateLoading(true);
        fetchStateAndCity({
          variables: {
            filter: {
              ...stateCityFilter,
              skip: states?.length,
              search: searchValue,
              type: 'STATE',
            },
          },
        });
      }
    }, 500);

    stateScrollDebounce();
  };

  const onCityScroll = (event) => {
    setCitySearchFlag(false);
    setFetchCity(true);
    if (cityScrollDebounceJob) {
      cityScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    cityScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !cityIsEnd) {
        setCityLoading(true);
        fetchStateAndCity({
          variables: {
            filter: {
              ...stateCityFilter,
              skip: cities?.length,
              type: 'CITY',
              search: searchValue,
              state: selectedState,
            },
          },
        });
      }
    }, 500);

    cityScrollDebounceJob();
  };

  const handleStateChange = (value) => {
    setFetchCity(false);
    setSearchValue(value);
    setStateSearchFlag(true);
    const state = form?.getFieldValue('state');
    if (value) {
      setStateLoading(true);
      setCities([]);
      setDisableCity(false);
      fetchStateAndCity({
        variables: {
          filter: {
            ...stateCityFilter,
            type: 'STATE',
            search: value,
          },
        },
      });
    } else {
      if (!state?.length) {
        setStateLoading(true);
        setDisableCity(true);
        fetchStateAndCity({
          variables: {
            filter: stateCityFilter,
          },
        });
      }
      if (state?.length > 0) {
        setDisableCity(false);
        setCitySearchFlag(true);
        setCallAsync(true);
        fetchStateAndCity({
          variables: {
            filter: stateCityFilter,
          },
        });
      }
    }
  };

  const handleStateSelect = (value) => {
    if (value) {
      form?.setFieldsValue({
        city: null,
      });
      setSelectedState(value);
      setDisableCity(false);
      setCitySearchFlag(true);
      setFetchCity(true);
      fetchStateAndCity({
        variables: {
          filter: {
            ...stateCityFilter,
            type: 'CITY',
            state: value,
          },
        },
      });
      setStateLoading(false);
    } else {
      setCitySearchFlag(true);
      setStateLoading(false);
      setDisableCity(true);
      fetchStateAndCity({
        variables: {
          filter: {
            ...stateCityFilter,
            type: 'STATE',
          },
        },
      });
    }
  };

  const handleCityChange = (value) => {
    setFetchCity(true);
    setCitySearchFlag(true);
    setSearchValue(value);
    if (value) {
      setCityLoading(true);
      fetchStateAndCity({
        variables: {
          filter: {
            ...stateCityFilter,
            type: 'CITY',
            search: value,
            state: form?.getFieldValue('state'),
          },
        },
      });
    } else {
      setCityLoading(false);
      fetchStateAndCity({
        variables: {
          filter: {
            ...stateCityFilter,
            type: 'CITY',
            state: form?.getFieldValue('state'),
          },
        },
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedStateHandler = useCallback(
    debounce(handleStateChange, 500),
    [],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedCityHandler = useCallback(debounce(handleCityChange, 500), []);

  const handleStateClear = () => {
    form?.setFieldsValue({
      state: null,
      city: null,
    });
    setFetchCity(false);
    fetchStateAndCity({
      variables: {
        filter: stateCityFilter,
      },
    });
    setSelectedState('');
    setCities([]);
    setDisableCity(true);
  };

  const onDateFormatScroll = (event) => {
    setDateFormatSearchFlag(false);
    if (dateFormatDebounceJob) {
      dateFormatDebounceJob?.cancel();
    }

    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    dateFormatDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !dateFormatIsEnd) {
        setDateFormatLoading(true);
        setDateFormatDebounceCall((prevState) => prevState + 1);
        fetchDateFormats({
          variables: {
            filter: {
              ...initialDateFormatFilter,
              skip: (dateFormatDebounceCall + 1) * SKIP_RECORD,
              search: searchValue,
            },
          },
        });
      }
    }, 500);

    dateFormatDebounceJob();
  };

  const handleDateFormatChange = (value) => {
    setDateFormatSearchFlag(true);
    setSearchValue(value);
    if (value) {
      setDateFormatLoading(true);
      fetchDateFormats({
        variables: {
          filter: {
            ...initialDateFormatFilter,
            search: value,
          },
        },
      });
    } else {
      setDateFormatLoading(true);
      fetchDateFormats({
        variables: {
          filter: {
            ...initialDateFormatFilter,
            search: value,
          },
        },
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedDateFormatHandler = useCallback(
    debounce(handleDateFormatChange, 500),
    [],
  );

  const handleDateFormatClear = () => {
    form.setFieldsValue({
      timeFormat: null,
    });
    setDateFormats([]);
    fetchDateFormats({
      variables: {
        filter: initialDateFormatFilter,
      },
    });
  };

  const handleDateFormatBlur = () => {
    setSearchValue('');
    setDateFormatDebounceCall(0);
    setDateFormatIsEnd(false);
    setDateFormatSearchFlag(true);
    fetchDateFormats({
      variables: {
        filter: initialDateFormatFilter,
      },
    });
  };

  const handleFinish = (values) => {
    dispatch({ type: 'SET_SHOW_PROMPT', data: false });
    setDisableBtn(true);
    if (dispatch) {
      dispatch({
        type: 'SET_GLOBAL_DATE_FORMAT',
        data: values?.timeFormat,
      });
    }
    const socialMediaLinks = [
      {
        title: 'twitter',
        url: values?.twitter,
      },
      {
        title: 'faceBook',
        url: values?.faceBook,
      },
      {
        title: 'linkedin',
        url: values?.linkedin,
      },
    ];
    const formInput = {
      phoneNo: formatPhoneNumberWithoutMask(values?.phoneNumber),
      email: values?.email,
      helpPage: {
        key: values?.helpPage,
        checked: values?.helpPageChecked,
      },
      liveChat: {
        key: values?.liveChat,
        checked: values?.liveChatChecked,
      },
      subDomain: values?.subDomain,
      timeFormat: values?.timeFormat,
      tagLine: values?.tagLine,
      socialMediaLinks,
    };

    const locationInput = {
      addressLine1: values?.street,
      city: values?.city,
      state: values?.state,
      zipCode: values?.zipCode,
    };

    if (isSponsor) {
      updateSponsor({
        variables: {
          data: {
            sponsorInput: {
              ...formInput,
              firstName: values?.firstName,
              lastName: values?.lastName,
            },
            locationInput: {
              ...locationInput,
            },
          },
        },
      });
    } else {
      updateTenant({
        variables: {
          data: {
            tenantInput: {
              ...formInput,
              name: values?.name,
            },
            locationInput: {
              ...locationInput,
            },
          },
        },
      });
    }
  };

  const onValuesChange = () => {
    setDisableBtn(false);
    dispatch({ type: 'SET_SHOW_PROMPT', data: true });
    // custom date format code commented for now
    // if (form.getFieldError('customFormat')) {
    //   setShowDatePreview(true);
    // } else {
    //   setShowDatePreview(false);
    // }
  };

  const copyToClipboard = () => {
    messageContext?.success('SubDomain Copied successfully');
    // eslint-disable-next-line no-undef
    navigator?.clipboard?.writeText(
      cpqRetailUrl(form?.getFieldValue('subDomain'), isSponsor),
    );
  };

  // custom date format code commented for now
  // const handleCustomDateChange = (e) => {
  //   const {
  //     target: { value }
  //   } = e;
  //   if (value) {
  //     setCustomDateFormat(value);
  //   } else {
  //     setShowDatePreview(false);
  //     setCustomDateFormat('YYYY-MM-DD');
  //   }
  // };

  const items = [
    {
      forceRender: true,
      label: 'GENERAL',
      key: 'general',
      children: (
        <>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                name="name"
                label="Business Name"
                rules={[{ ...required, message: 'Please Enter Business Name' }]}
              >
                <InputComponent allowClear placeholder="Enter Business Name" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={12}>
              <Form.Item name="tagLine" label="Tagline">
                <InputComponent allowClear placeholder="Enter Tagline" />
              </Form.Item>
            </Col>
          </Row>
        </>
      ),
    },
    {
      forceRender: true,
      label: 'BUSINESS ADDRESS *',
      key: 'businessInfo',
      children: (
        <>
          <Row gutter={16}>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                name="street"
                label="Street"
                rules={[{ ...required, message: 'Please Enter Street' }]}
              >
                <InputComponent allowClear placeholder="Enter Street " />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                name="state"
                label="State"
                rules={[{ ...required, message: 'Please Select State' }]}
              >
                <SelectComponent
                  placeholder="Select State"
                  allowClear
                  notFoundContent={
                    stateLoading ? (
                      <LoaderComponent size="small" setHeight={10} />
                    ) : (
                      <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                    )
                  }
                  onSearch={debouncedStateHandler}
                  onClear={handleStateClear}
                  onSelect={handleStateSelect}
                  onPopupScroll={onStateScroll}
                  onBlur={handleStateBlur}
                >
                  {map(states, (state) => (
                    <Option key={state?.id} value={state?.name}>
                      {state?.name}
                    </Option>
                  ))}
                </SelectComponent>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                name="city"
                label="City"
                rules={[{ ...required, message: 'Please Select City' }]}
              >
                <SelectComponent
                  placeholder="Select City"
                  disabled={disableCity}
                  notFoundContent={
                    cityLoading ? (
                      <LoaderComponent size="small" setHeight={10} />
                    ) : (
                      <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                    )
                  }
                  onSearch={debouncedCityHandler}
                  onPopupScroll={onCityScroll}
                  onClear={handleCityClear}
                  onBlur={handleCityBlur}
                >
                  {cities?.map((city) => (
                    <Option key={city?.id} value={city?.name}>
                      {city?.name}
                    </Option>
                  ))}
                </SelectComponent>
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                name="zipCode"
                label="Zip Code"
                rules={[{ ...required, message: 'Please Enter Zip Code' }]}
              >
                <InputComponent allowClear placeholder="Enter Zip Code " />
              </Form.Item>
            </Col>
          </Row>
        </>
      ),
    },
    {
      forceRender: true,
      label: 'CONTACT INFORMATION',
      key: 'contactInfo',
      children: (
        <>
          <Row gutter={16}>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                label="Business Phone Number"
                name="phoneNumber"
                rules={[
                  {
                    ...required,
                    message: 'Please Enter Business Phone Number',
                  },
                  () => ({
                    validator(rule, value) {
                      if (value) {
                        // eslint-disable-next-line no-param-reassign
                        value = value?.split(' ')?.join('');
                        const numberPattern = REGEX?.PHONE;
                        if (!numberPattern?.test(value)) {
                          // eslint-disable-next-line prefer-promise-reject-errors
                          return Promise?.reject(
                            'should be a valid Business Phone Number',
                          );
                        }
                      }
                      return Promise?.resolve();
                    },
                  }),
                ]}
              >
                <PhoneInput
                  inputProps={{
                    name: 'phoneNo',
                    required: true,
                    placeholder: 'Business Phone Number',
                    'aria-label': 'Business Phone Number',
                  }}
                  disableCountryCode
                  disableDropdown
                  onlyCountries={['us']}
                  country="us"
                  buttonClass="custom-dropdown"
                />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                name="email"
                label="Business Email"
                rules={[
                  email,
                  { ...required, message: 'Please Enter Business Email' },
                ]}
              >
                <InputComponent placeholder="Business Email" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={6}
              xxl={6}
              className="d-flex flex-vertical"
            >
              <span className="setting-form-label mb-4">Help Page</span>
              <Space.Compact>
                <Row>
                  <Col>
                    <Form.Item valuePropName="checked" name="helpPageChecked">
                      <Checkbox className="tenant-setting-checkbox" />
                    </Form.Item>
                  </Col>
                  <Col xs={20} sm={20} md={20} lg={20} xl={20} xxl={20}>
                    <Form.Item
                      className="ml-6"
                      name="helpPage"
                      dependencies={['helpPageChecked']}
                      rules={[
                        urlCheck,
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value && getFieldValue('helpPageChecked')) {
                              return Promise?.reject(
                                new Error('Please Enter Help Page URL!'),
                              );
                            }
                            return Promise?.resolve();
                          },
                        }),
                      ]}
                    >
                      <InputComponent
                        aria-label="Help Page"
                        placeholder="Help Page"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Space.Compact>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={6}
              xxl={6}
              className="d-flex flex-vertical"
            >
              <span className="setting-form-label mb-4">Live Chat</span>
              <Space.Compact>
                <Row>
                  <Col>
                    <Form.Item valuePropName="checked" name="liveChatChecked">
                      <Checkbox className="tenant-setting-checkbox" />
                    </Form.Item>
                  </Col>
                  <Col xs={20} sm={20} md={20} lg={20} xl={20} xxl={20}>
                    <Form.Item
                      className="ml-6"
                      name="liveChat"
                      dependencies={['liveChatChecked']}
                      rules={[
                        urlCheck,
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value && getFieldValue('liveChatChecked')) {
                              return Promise?.reject(
                                new Error('Please Enter Live Chat URL!'),
                              );
                            }
                            return Promise?.resolve();
                          },
                        }),
                      ]}
                    >
                      <InputComponent
                        aria-label="Live Chat"
                        placeholder="Live Chat"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Space.Compact>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={24} lg={12} xl={6} xxl={6}>
              <Form.Item
                className="ml-6"
                label="FaceBook"
                name="faceBook"
                rules={[urlCheck]}
              >
                <InputComponent placeholder="FaceBook" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={6} xxl={6}>
              <Form.Item
                className="ml-6"
                label="Twitter"
                name="twitter"
                rules={[urlCheck]}
              >
                <InputComponent placeholder="Twitter" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={6} xxl={6}>
              <Form.Item
                className="ml-6"
                label="LinkedIn"
                name="linkedin"
                rules={[urlCheck]}
              >
                <InputComponent placeholder="LinkedIn" />
              </Form.Item>
            </Col>
          </Row>
        </>
      ),
    },
    {
      label: 'SUBDOMAIN *',
      key: 'subDomainInfo',
      children: (
        <Row gutter={16}>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item
              name="subDomain"
              label="Subdomain"
              rules={[
                { ...required, message: 'Please Enter SubDomain' },
                subDomain,
              ]}
            >
              <InputComponent
                suffix={
                  <img
                    src={copyIcon}
                    alt="copy"
                    className="copy-icon"
                    onClick={copyToClipboard}
                  />
                }
                allowClear={false}
                placeholder="Enter SubDomain"
              />
            </Form.Item>
          </Col>
        </Row>
      ),
    },
    {
      label: 'DATE FORMAT SETTING',
      key: 'dateFormat',
      children: (
        <Row gutter={16}>
          <Col xs={16} sm={16} md={16} lg={12} xl={12} xxl={8}>
            <Form.Item
              name="timeFormat"
              label="Format"
              rules={[{ ...required, message: 'Please Select Format' }]}
            >
              <SelectComponent
                filterOption={(input, option) =>
                  option?.value?.toLowerCase().indexOf(input?.toLowerCase()) >=
                  0
                }
                notFoundContent={
                  dateFormatLoading ? (
                    <LoaderComponent size="small" setHeight={10} />
                  ) : (
                    <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                  )
                }
                placeholder="Select Format"
                allowClear
                onBlur={handleDateFormatBlur}
                onSearch={debouncedDateFormatHandler}
                onClear={handleDateFormatClear}
                onPopupScroll={onDateFormatScroll}
              >
                {map(dateFormats, (customFormat, index) => (
                  <Option key={index} value={customFormat}>
                    <div key={index} className="date-format">
                      <span>{customFormat}</span>
                      <span className="format-example">
                        {moment(Date?.now())?.format(customFormat)}
                      </span>
                    </div>
                  </Option>
                ))}
              </SelectComponent>
            </Form.Item>

            {/** custom date format code commented for now */}

            {/* <Form.Item>
        <span className="setting-form-label">Custom Format</span>
        <Row>
          <Col>
            <Form.Item valuePropName="checked" name="customChecked">
              <Checkbox className="tenant-setting-checkbox" />
            </Form.Item>
          </Col>
          <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
            <Form.Item
              className="ml-6"
              name="customFormat"
              onChange={handleCustomDateChange}
              dependencies={['customChecked']}
              rules={[
                dateFormat,
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value && getFieldValue('customChecked')) {
                      return Promise?.reject(
                        new Error('Please Enter Custom Format!')
                      );
                    }
                    return Promise?.resolve();
                  }
                })
              ]}
            >
              <InputComponent placeholder="Custom Format" />
            </Form.Item>
          </Col>
        </Row>
      </Form.Item> */}
          </Col>
        </Row>
      ),
    },
  ];
  return (
    <div className="content-section">
      <AccessControl allowedPermissions={['FET_TENANT_SETTINGS_UPDATE']}>
        <Portal portalId="header-right-content">
          <Button
            className="common-button save-btn"
            icon={<img src={SaveIcon} alt="save-icon" width={12} />}
            size="small"
            type="primary"
            id="tenant-setting-save-btn"
            loading={loading || updateSponsorLoading}
            disabled={disableBtn}
            onClick={form?.submit}
          >
            Save
          </Button>
        </Portal>
      </AccessControl>
      <Form
        form={form}
        initialValues={initialValue}
        name="tenantSettingForm"
        layout="vertical"
        onValuesChange={onValuesChange}
        onFinish={handleFinish}
      >
        <fieldset
          disabled={
            !checkPermissions(permissions, ['FET_TENANT_SETTINGS_UPDATE'])
          }
        >
          <Collapse
            bordered={false}
            activeKey={collapseActiveKeys}
            onChange={(keys) => setCollapseActiveKeys(keys)}
            defaultActiveKey={['general1']}
            expandIconPosition="end"
            expandIcon={({ isActive }) => (
              <UpOutlined rotate={isActive ? 0 : 180} />
            )}
            className="common-collapse"
            items={items}
          />
        </fieldset>
      </Form>
    </div>
  );
};

export default TenantSettingForm;
