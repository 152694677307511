import { DownloadOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { Card, Col, Divider, Form, Radio, Row } from 'antd';
import { filter } from 'lodash';
import React from 'react';
import { messageContext } from '../../../app/components/AppContextHolder';
import placeholderImage from '../../../assets/images/place-holder-image-master.svg';
import { ALLOW_IMAGE_TYPES } from '../../../common/constants';
import DraggerUploadComponent from '../../../components/DraggerUploadComponent';
import { GET_TENANT_BRAND } from '../../brandSetting/graphql/Queries';

const BrandingForm = ({ form, brandsData, fileList, setFileList }) => {
  const getSelectedRetailerId = form?.getFieldValue('brandId');
  const getRetailer = filter(
    brandsData?.brands?.data,
    (item) => item?.id === getSelectedRetailerId,
  );
  const { data: TenantData } = useQuery(GET_TENANT_BRAND, {
    fetchPolicy: 'network-only',
    onError() {},
  });

  const onChangeUpload = (info) => {
    const {
      file: { name = '', url },
    } = info;
    if (url) {
      setFileList([]);
      form?.setFieldsValue({
        headerImage: null,
      });
      return;
    }
    const ext = name?.substring(name?.lastIndexOf('.') + 1);
    if (ALLOW_IMAGE_TYPES?.includes(ext) && !url) {
      if (info?.file?.status === 'removed') {
        form?.setFieldsValue({
          headerImage: null,
        });
      } else {
        form?.setFieldsValue({
          headerImage: info?.file,
        });
      }
      setFileList([...info?.fileList]);
    } else {
      setFileList([]);
      form?.setFieldsValue({
        headerImage: null,
      });
      messageContext?.destroy();
      messageContext?.error(`${info?.file?.name} file is not image file.`);
    }
  };

  return (
    <Card className="card-wrapper">
      <div className="setting-content-heading">
        <h5>LOGO</h5>
        <span className="panel-description">
          Customers will see the selected logo on this questionnaire
        </span>
      </div>
      <Row className="setting-content">
        <Form.Item name="logo">
          <Radio.Group>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Radio value="TENANT">
                <img
                  src={
                    TenantData?.fetchTenantBrand?.logo?.url || placeholderImage
                  }
                  alt="brand-img"
                  onError={(e) => {
                    e.target.src = placeholderImage;
                  }}
                  className="list-img image-contain"
                  width={24}
                  height={24}
                />
                {TenantData?.fetchTenantBrand?.name} (Tenant)
              </Radio>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Radio value="BRAND">
                <img
                  src={getRetailer?.[0]?.logo?.url || placeholderImage}
                  alt="brand-img"
                  onError={(e) => {
                    e.target.src = placeholderImage;
                  }}
                  className="list-img image-contain"
                  width={24}
                  height={24}
                />
                {getRetailer?.[0]?.name}
              </Radio>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Radio value="BOTH">Both</Radio>
            </Col>
          </Radio.Group>
        </Form.Item>
      </Row>
      <Divider />
      <div className="setting-content-heading">
        <h5>HEADER IMAGE</h5>
        <span className="panel-description">
          Default header image of this questionnaire
        </span>
      </div>
      <Row className="setting-content">
        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
          <Form.Item name="headerImage" label="Header Image">
            <DraggerUploadComponent
              showUploadList={{
                showDownloadIcon: false,
                showPreviewIcon: true,
              }}
              fileList={fileList}
              className="mb-8"
              onChange={onChangeUpload}
            >
              <p className="icon-header">
                <DownloadOutlined />
              </p>
              <p className="upload-title">
                Click or drag a file here to Upload
              </p>
              <p className="upload-description">
                64x64 px minimum | JPG, PNG, SVG
              </p>
            </DraggerUploadComponent>
          </Form.Item>
          <span className="font-size-12">
            Please upload image with dimensions of 768 x 250 pixels for the best
            visual experience.
          </span>
        </Col>
      </Row>
      <Divider />
    </Card>
  );
};

export default BrandingForm;
