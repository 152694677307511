import { useMutation, useQuery } from '@apollo/client';
import { Card } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  Navigate,
  useLocation,
  useNavigate,
  useNavigationType,
  useParams,
} from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import GoBackButton from '../../../components/GoBackButton';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import ProductForm from '../components/ProductForm';
import CardWrapper from '../components/ProductTabs';
import { UPDATE_PRODUCT } from '../graphql/Mutations';
import { GET_PRODUCT } from '../graphql/Queries';

function ProductEdit() {
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const navigateType = useNavigationType();

  const { data: { product } = {} } = useQuery(GET_PRODUCT, {
    variables: { id },
    onCompleted: () => {
      setLoading(false);
    },
    fetchPolicy: 'network-only',
    onError: () => {
      setLoading(false);
      setSubmitLoading(false);
    },
  });

  useEffect(
    () => {
      if (navigateType === 'POP') {
        navigate(location?.pathname, {
          state: location?.state,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigate, location],
  );

  const [updateProduct] = useMutation(UPDATE_PRODUCT, {
    onError: () => {
      setSubmitLoading(false);
    },
  });

  const handleSubmit = async (formValues) => {
    setSubmitLoading(true);

    const newFormValues = {
      ...formValues,
      costPerDay: formValues?.costPerDay || null,
      uuid: product?.uuid,
    };

    const variables = { data: newFormValues, where: { id: product?.id } };

    try {
      const response = await updateProduct({
        variables: { ...variables },
      });
      if (response?.data?.updateProduct) {
        setSubmitLoading(false);
        navigate(ROUTES?.PACKAGES, { state: { ...location?.state } });
      }
    } catch (error) {
      return error;
    }
  };

  if (!id) {
    return <Navigate to={ROUTES?.PACKAGES} replace />;
  }

  const initialValues = {
    ...product,
    isActive: product?.isActive,
    description: product?.description || '',
    startData: product?.startData ? moment(product?.startData) : undefined,
    endDate: product?.endDate ? moment(product?.endDate) : undefined,
  };

  return (
    <Card className="full-height-card card-body-padding">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={ROUTES?.PACKAGES} />
          {product && <span className="portal-header">{product?.title}</span>}
        </div>
      </Portal>
      <CardWrapper>
        {loading ? (
          <LoaderComponent setHeight={80} />
        ) : (
          <ProductForm
            isEdit
            productData={initialValues}
            handleSubmit={handleSubmit}
            isSubmit={submitLoading}
          />
        )}
      </CardWrapper>
    </Card>
  );
}

export default ProductEdit;
