import {
  FileExcelOutlined,
  FileOutlined,
  FilePdfOutlined,
  FilePptOutlined,
  FileTextOutlined,
  FileWordOutlined,
  InfoCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Form, Progress, Upload } from 'antd';
import React, { useEffect } from 'react';
import { formValidatorRules, getBase64 } from '../../../../common/utils';
import './renderUpload.less';

const { dynamicFieldsUpload } = formValidatorRules;

const RenderUpload = ({
  uploadData = null,
  setPreviewVisible,
  setPreviewTitle,
  setPreviewImage,
  form,
  editData = null,
}) => {
  const { config, label = '', tooltip = '', key = '' } = uploadData;
  const {
    UPLOAD: {
      allowMultiple = false,
      fileType: { value: fileValue = [], key: fileKey } = null,
      limitFileSize = false,
      maxFile = 0,
      size = 50,
      rules: {
        hidden,
        hideLabel,
        readOnly: fieldReadOnly,
        required: fieldRequired,
      } = null,
    } = {},
  } = config || null;

  const fileListLength = Form.useWatch(['dynamicFields', key, 'upload'])
    ?.length;

  const customOrigin = (originNode, file) => {
    const {
      props: nodeProps,
      props: { children },
    } = originNode;
    const ext = file?.name?.substring(file?.name?.lastIndexOf('.') + 1);
    const newChildren = [children?.[1], children?.[3]];
    let icon = '';
    switch (ext) {
      case 'xlsx':
      case 'xls':
      case 'csv':
        icon = <FileExcelOutlined className="file-type" />;
        break;
      case 'pdf':
        icon = <FilePdfOutlined className="file-type" />;
        break;
      case 'ppt':
      case 'pptx':
        icon = <FilePptOutlined className="file-type" />;
        break;
      case 'doc':
      case 'docx':
        icon = <FileWordOutlined className="file-type" />;
        break;
      case 'txt':
        icon = <FileTextOutlined className="file-type" />;
        break;
      case 'mp4':
        icon = <FileOutlined className="file-type" />;
        break;
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
        return originNode;
      default:
        icon = <FileOutlined className="file-type" />;
        break;
    }

    if (file?.status === 'uploading') {
      return (
        <div className={nodeProps?.className}>
          <Progress showInfo={false} percent={file?.percent} size="small" />
        </div>
      );
    }

    return (
      <div className={nodeProps?.className}>
        <div className="ant-upload-list-item-info ">{icon}</div>
        {newChildren}
      </div>
    );
  };

  const handleBeforeUpload = () => false;

  const handlePreview = async (fileObj) => {
    let preview;
    if (!fileObj?.url && !fileObj?.preview) {
      preview = await getBase64(fileObj?.originFileObj);
    }
    setPreviewVisible(true);
    setPreviewTitle(
      fileObj?.name ||
        fileObj?.label ||
        fileObj?.url?.substring(fileObj?.url?.lastIndexOf('/') + 1),
    );
    setPreviewImage(fileObj?.url || preview);
  };

  useEffect(() => {
    if (editData) {
      if (editData?.[key]?.length > 0) {
        form?.setFieldsValue({
          dynamicFields: {
            [key]: {
              upload: editData?.[key],
            },
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editData]);

  const renderUploadIcon = () => {
    if (allowMultiple) {
      if (fileListLength >= maxFile) {
        return false;
      }
      return true;
    }
    if (!allowMultiple) {
      if (fileListLength >= 1) {
        return false;
      }
      return true;
    }
    return true;
  };

  return (
    <Form.Item
      label={hideLabel ? ' ' : label}
      name={['dynamicFields', key, 'upload']}
      rules={[
        dynamicFieldsUpload({
          fieldRequired,
          fileValue,
          maxFile,
          size,
          limitFileSize,
        }),
      ]}
      hidden={hidden}
      getValueFromEvent={({ fileList }) => fileList}
      valuePropName="fileList"
      tooltip={
        tooltip && {
          title: tooltip,
          icon: <InfoCircleOutlined />,
        }
      }
      className={`render-upload ${tooltip && 'label-with-tooltip'}${
        hidden ? 'd-none' : ''
      }`}
    >
      <Upload
        multiple={allowMultiple}
        listType="picture-card"
        onPreview={fileKey === 'IMAGE' && handlePreview}
        showUploadList={{
          showDownloadIcon: false,
          showPreviewIcon: true,
          showRemoveIcon: true,
        }}
        maxCount={maxFile}
        itemRender={(originNode, file) => (
          <div>
            {customOrigin(originNode, file)}
            <div title={file?.label} className="render-upload-label">
              {file?.name?.split('.')?.slice(0, -1)?.join('.') || file?.label}
            </div>
          </div>
        )}
        disabled={fieldReadOnly}
        beforeUpload={handleBeforeUpload}
      >
        {renderUploadIcon() && (
          <div className="upload-content">
            <span className="icon-header">
              <PlusOutlined />
            </span>
            <span className="upload-title">Upload</span>
            <span className="upload-description">...one or multiple files</span>
          </div>
        )}
      </Upload>
    </Form.Item>
  );
};

export default RenderUpload;
