import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Empty,
  InputNumber,
  Popconfirm,
  Row,
  Tag,
} from 'antd';
import { debounce, filter, forEach, isEmpty, map } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { useLocation, useParams } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import FilterSelectedIconComponent from '../../../app/components/iconComponents/FilterSelectedComponent';
import FilterIcon from '../../../assets/filter.svg';
import { SKIP_RECORD } from '../../../common/constants';
import { formatPrice } from '../../../common/utils';
import LoaderComponent from '../../../components/LoaderComponent';
import PriceComponent from '../../../components/PriceComponent';
import SearchComponent from '../../../components/SearchComponent';
import TableComponent from '../../../components/TableComponent';
import { CREATE_PRODUCT_SUB_ITEM } from '../../productItems/graphql/Mutations';
import {
  LINE_OF_BUSINESS_FILTER,
  MANUFACTURER_FILTER,
  PRODUCT_CATEGORY_FILTER,
  PRODUCT_ITEM_FILTER,
  SUB_AREA_FILTER,
} from '../../productItems/graphql/Queries';
import { INDUSTRY_FILTER, LIST_PRODUCTS_IN_PACKAGE } from '../graphql/Queries';

let scrollDebounce = null;

const ProductItemComponent = ({ refetch, product = null, count }) => {
  const {
    state: { pageSize },
  } = useContext(AppContext);

  const location = useLocation();
  const { id: productId } = useParams();

  const initialPaginationValue = {
    total: 0,
    current: 1,
  };

  const initialProductItemFilter = {
    skip: 0,
    limit: pageSize,
    sortOn: 'createdAt',
    sortBy: 'DESC',
  };

  const filterData = {
    sortOn: 'order',
    sortBy: 'ASC',
    productId,
  };

  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [sortedInfo, setSortedInfo] = useState({});
  const [productItemFilter, setProductItemFilter] = useState(
    initialProductItemFilter,
  );
  const [filters, setFilters] = useState({
    isActive: true,
    packageId: productId,
  });
  const [filtersCopyState, setFiltersCopyState] = useState({
    isActive: true,
    packageId: productId,
  });
  const [filterSearch, setFilterSearch] = useState('');
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterList, setFilterList] = useState([]);
  const [scrollFlag, setScrollFlag] = useState(false);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterIndex, setFilterIndex] = useState(null);
  const [filterIsEnd, setFilterIsEnd] = useState(false);

  const [productItemData, setProductItemData] = useState([]);
  const [recordLoading, setRecordLoading] = useState(null);

  const [createProductSubItem, { loading: createProductLoading }] = useMutation(
    CREATE_PRODUCT_SUB_ITEM,
    {
      onCompleted() {
        refetch({
          variables: { filter: filterData },
        });
        setRecordLoading(null);
      },
      onError() {
        setRecordLoading(null);
      },
    },
  );

  const [listProductsInPackage, { loading }] = useLazyQuery(
    LIST_PRODUCTS_IN_PACKAGE,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        const dataCopy = [];
        forEach(res?.listProductsInPackage?.data, (item) =>
          dataCopy?.push({
            ...item,
            quantity: 1,
          }),
        );
        setProductItemData(dataCopy);
        const pagination = {
          ...paginationProp,
          defaultPageSize: pageSize,
          total: res?.listProductsInPackage?.count,
        };
        setPaginationProp(pagination);
      },
      onError() {},
    },
  );

  const [productItemFilters] = useLazyQuery(PRODUCT_ITEM_FILTER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (scrollFlag) {
        const optionsCopy = [...filterList];
        forEach(res?.productItemFilters?.data, (item) =>
          optionsCopy?.push(item?.[filterIndex]),
        );
        setFilterList(optionsCopy);
        setScrollFlag(false);
      } else {
        const optionsCopy = [];
        forEach(res?.productItemFilters?.data, (item) =>
          optionsCopy?.push(item?.[filterIndex]),
        );
        setFilterList(optionsCopy);
      }
      setFilterIsEnd(res?.productItemFilters?.data?.length < SKIP_RECORD);
      setFilterLoading(false);
    },
    onError: () => {
      setFilterLoading(false);
    },
  });

  const [industryFilters] = useLazyQuery(INDUSTRY_FILTER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (scrollFlag) {
        const optionsCopy = [...filterList];
        forEach(res?.industryFilters?.data, (item) =>
          optionsCopy?.push(item?.label),
        );
        setFilterList(optionsCopy);
        setScrollFlag(false);
      } else {
        const optionsCopy = [];
        forEach(res?.industryFilters?.data, (item) =>
          optionsCopy?.push(item?.label),
        );
        setFilterList(optionsCopy);
      }
      setFilterIsEnd(res?.industryFilters?.data?.length < SKIP_RECORD);
      setFilterLoading(false);
    },
    onError: () => {
      setFilterLoading(false);
    },
  });

  const [lineOfBusinessFilters] = useLazyQuery(LINE_OF_BUSINESS_FILTER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (scrollFlag) {
        const optionsCopy = [...filterList];
        forEach(res?.lineOfBusinessFilters?.data, (item) =>
          optionsCopy?.push(item?.label),
        );
        setFilterList(optionsCopy);
        setScrollFlag(false);
      } else {
        const optionsCopy = [];
        forEach(res?.lineOfBusinessFilters?.data, (item) =>
          optionsCopy?.push(item?.label),
        );
        setFilterList(optionsCopy);
      }
      setFilterIsEnd(res?.lineOfBusinessFilters?.data?.length < SKIP_RECORD);
      setFilterLoading(false);
    },
    onError: () => {
      setFilterLoading(false);
    },
  });

  const [subAreaFilters] = useLazyQuery(SUB_AREA_FILTER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (scrollFlag) {
        const optionsCopy = [...filterList];
        forEach(res?.subAreaFilters?.data, (item) =>
          optionsCopy?.push(item?.labels),
        );
        setFilterList(optionsCopy);
        setScrollFlag(false);
      } else {
        const optionsCopy = [];
        forEach(res?.subAreaFilters?.data, (item) =>
          optionsCopy?.push(item?.label),
        );
        setFilterList(optionsCopy);
      }
      setFilterIsEnd(res?.subAreaFilters?.data?.length < SKIP_RECORD);
      setFilterLoading(false);
    },
    onError: () => {
      setFilterLoading(false);
    },
  });

  const [manufacturerFilters] = useLazyQuery(MANUFACTURER_FILTER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (scrollFlag) {
        const optionsCopy = [...filterList];
        forEach(res?.manufacturerFilters?.data, (item) =>
          optionsCopy?.push(item?.name),
        );
        setFilterList(optionsCopy);
        setScrollFlag(false);
      } else {
        const optionsCopy = [];
        forEach(res?.manufacturerFilters?.data, (item) =>
          optionsCopy?.push(item?.name),
        );
        setFilterList(optionsCopy);
      }
      setFilterIsEnd(res?.manufacturerFilters?.data?.length < SKIP_RECORD);
      setFilterLoading(false);
    },
    onError: () => {
      setFilterLoading(false);
    },
  });

  const [productCategoryFilters] = useLazyQuery(PRODUCT_CATEGORY_FILTER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (scrollFlag) {
        const optionsCopy = [...filterList];
        forEach(res?.productCategoryFilters?.data, (item) =>
          optionsCopy?.push({ label: item?.label, key: item?.key }),
        );
        setFilterList(optionsCopy);
        setScrollFlag(false);
      } else {
        const optionsCopy = [];
        forEach(res?.productCategoryFilters?.data, (item) =>
          optionsCopy?.push({ label: item?.label, key: item?.key }),
        );
        setFilterList(optionsCopy);
      }
      setFilterIsEnd(res?.productCategoryFilters?.data?.length < SKIP_RECORD);
      setFilterLoading(false);
    },
    onError: () => {
      setFilterLoading(false);
    },
  });

  useEffect(() => {
    if (product) {
      listProductsInPackage({
        variables: {
          filter: productItemFilter,
          where: {
            isActive: true,
            packageId: productId,
            industry: [product?.productIndustry?.label],
            lineOfBusiness: [product?.productLob?.label],
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  const getFilterData = (confirm) => {
    listProductsInPackage({
      variables: {
        filter: { ...productItemFilter, skip: 0 },
        ...(filtersCopyState && { where: filtersCopyState }),
      },
    });
    setProductItemFilter({
      ...productItemFilter,
      skip: 0,
    });
    setFilters(filtersCopyState);
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    if (confirm) {
      confirm();
    }
  };

  useEffect(() => {
    if (filterVisible) {
      setFilterList([]);
      setFilterLoading(true);
      switch (filterIndex) {
        case 'industry':
          industryFilters({
            variables: {
              filter: {
                sortOn: 'label',
                sortBy: 'ASC',
                skip: 0,
                limit: 20,
                search: filterSearch,
                getDBField: 'label',
                justShow: true,
              },
            },
          });
          break;

        case 'lineOfBusiness':
          lineOfBusinessFilters({
            variables: {
              filter: {
                sortOn: 'label',
                sortBy: 'ASC',
                skip: 0,
                limit: 20,
                search: filterSearch,
                justShow: true,
              },
            },
          });
          break;

        case 'subArea':
          subAreaFilters({
            variables: {
              filter: {
                sortOn: 'label',
                sortBy: 'ASC',
                skip: 0,
                limit: 20,
                search: filterSearch,
                justShow: true,
                getDBField: 'label',
              },
            },
          });
          break;

        case 'manufacturer':
          manufacturerFilters({
            variables: {
              filter: {
                sortOn: 'name',
                sortBy: 'ASC',
                skip: 0,
                limit: 20,
                search: filterSearch,
                distinct: true,
                getDBField: 'name',
              },
            },
          });
          break;

        case 'productCategories':
          productCategoryFilters({
            variables: {
              filter: {
                sortOn: 'label',
                sortBy: 'ASC',
                skip: 0,
                limit: 20,
                search: filterSearch,
              },
            },
          });
          break;

        default:
          productItemFilters({
            variables: {
              filter: {
                sortOn: filterIndex,
                sortBy: 'ASC',
                skip: 0,
                limit: 20,
                search: filterSearch,
                distinct: true,
                getDBField: filterIndex,
              },
            },
          });
          break;
      }
    }
    if (!filterVisible) {
      setFiltersCopyState(filters);
      setFilterSearch('');
      setFilterIsEnd(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterVisible]);

  const onScroll = (event, dataIndex) => {
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    if (scrollDebounce) {
      scrollDebounce?.cancel();
      scrollDebounce = null;
    }
    scrollDebounce = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (
        scrolledToBottom &&
        filterIndex === dataIndex &&
        filterVisible &&
        !filterIsEnd
      ) {
        setScrollFlag(true);
        switch (filterIndex) {
          case 'industry':
            industryFilters({
              variables: {
                filter: {
                  sortOn: 'label',
                  sortBy: 'ASC',
                  skip: filterList?.length,
                  limit: 20,
                  search: filterSearch,
                  getDBField: 'label',
                  justShow: true,
                },
              },
            });
            break;

          case 'lineOfBusiness':
            lineOfBusinessFilters({
              variables: {
                filter: {
                  sortOn: 'label',
                  sortBy: 'ASC',
                  skip: filterList?.length,
                  limit: 20,
                  search: filterSearch,
                  justShow: true,
                },
              },
            });
            break;

          case 'subArea':
            subAreaFilters({
              variables: {
                filter: {
                  sortOn: 'label',
                  sortBy: 'ASC',
                  skip: filterList?.length,
                  limit: 20,
                  search: filterSearch,
                  justShow: true,
                  getDBField: 'label',
                },
              },
            });
            break;

          case 'manufacturer':
            manufacturerFilters({
              variables: {
                filter: {
                  sortOn: 'name',
                  sortBy: 'ASC',
                  skip: filterList?.length,
                  limit: 20,
                  search: filterSearch,
                  distinct: true,
                  getDBField: 'name',
                },
              },
            });
            break;

          case 'productCategories':
            productCategoryFilters({
              variables: {
                filter: {
                  sortOn: 'label',
                  sortBy: 'ASC',
                  skip: filterList?.length,
                  limit: 20,
                  search: filterSearch,
                },
              },
            });
            break;

          default:
            productItemFilters({
              variables: {
                filter: {
                  sortOn: filterIndex,
                  sortBy: 'ASC',
                  skip: filterList?.length,
                  limit: 20,
                  search: filterSearch,
                  distinct: true,
                  getDBField: filterIndex,
                },
              },
            });
            break;
        }
      }
    }, 500);
    scrollDebounce();
  };

  const handleReset = (clearFilters, dataIndex) => {
    const filtersCopy = {
      ...filters,
      [dataIndex]: [],
    };
    if (dataIndex === 'costPrice' || dataIndex === 'defaultPrice') {
      delete filtersCopy?.[dataIndex];
    }
    setFilters(filtersCopy);
    listProductsInPackage({
      variables: {
        filter: {
          ...productItemFilter,
          skip: 0,
          sortOn: 'createdAt',
          sortBy: 'DESC',
        },
        ...(filtersCopy && { where: filtersCopy }),
      },
    });
    setProductItemFilter({
      ...productItemFilter,
      skip: 0,
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    clearFilters();
    setFilterIndex(dataIndex);
    setFilterVisible(false);
  };

  const changeFilter = (e, dataIndex) => {
    const {
      target: { value = '' },
    } = e;
    let filtersCopy = [];
    if (filtersCopyState?.[dataIndex]?.includes(value)) {
      filtersCopy = {
        ...filtersCopyState,
        [dataIndex]: filter(
          filtersCopyState?.[dataIndex],
          (item) => item !== value,
        ),
      };
    } else {
      filtersCopy = {
        ...filtersCopyState,
        [dataIndex]: filtersCopyState?.[dataIndex]
          ? [...filtersCopyState?.[dataIndex], value]
          : [value],
      };
    }
    setFiltersCopyState(filtersCopy);
  };

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination?.pageSize;
    setSortedInfo(sorter);
    setPaginationProp({ ...paginationProp, ...pagination });
    if (sorter?.column) {
      setProductItemFilter({
        ...productItemFilter,
        skip,
        limit: pagination?.pageSize,
        sortOn: sorter?.field,
        sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC',
      });
      listProductsInPackage({
        variables: {
          filter: {
            ...productItemFilter,
            skip,
            limit: pagination?.pageSize,
            sortOn: sorter?.field,
            sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC',
          },
          ...(filters && { where: filters }),
        },
      });
    } else {
      setProductItemFilter({
        ...productItemFilter,
        skip,
        limit: pagination?.pageSize,
        sortOn: 'createdAt',
        sortBy: 'DESC',
      });
      listProductsInPackage({
        variables: {
          filter: {
            ...productItemFilter,
            skip,
            limit: pagination?.pageSize,
            sortOn: 'createdAt',
            sortBy: 'DESC',
          },
          ...(filters && { where: filters }),
        },
      });
    }
  };

  const handleSearch = (value, dataIndex) => {
    setFilterSearch(value);
    setScrollFlag(false);
    switch (dataIndex) {
      case 'industry':
        industryFilters({
          variables: {
            filter: {
              sortOn: 'label',
              sortBy: 'ASC',
              skip: 0,
              limit: 20,
              search: filterSearch,
              getDBField: 'label',
              justShow: true,
            },
          },
        });
        break;

      case 'lineOfBusiness':
        lineOfBusinessFilters({
          variables: {
            filter: {
              sortOn: 'label',
              sortBy: 'ASC',
              skip: 0,
              limit: 20,
              search: value,
              justShow: true,
            },
          },
        });
        break;

      case 'subArea':
        subAreaFilters({
          variables: {
            filter: {
              sortOn: 'label',
              sortBy: 'ASC',
              skip: 0,
              limit: 20,
              search: value,
              justShow: true,
              getDBField: 'label',
            },
          },
        });
        break;

      case 'manufacturer':
        manufacturerFilters({
          variables: {
            filter: {
              sortOn: 'name',
              sortBy: 'ASC',
              skip: 0,
              limit: 20,
              search: value,
              distinct: true,
              getDBField: 'name',
            },
          },
        });
        break;

      case 'productCategories':
        productCategoryFilters({
          variables: {
            filter: {
              sortOn: 'label',
              sortBy: 'ASC',
              skip: 0,
              limit: 20,
              search: value,
            },
          },
        });
        break;

      default:
        productItemFilters({
          variables: {
            filter: {
              sortOn: dataIndex,
              sortBy: 'ASC',
              skip: 0,
              limit: 20,
              search: value,
              distinct: true,
              getDBField: dataIndex,
            },
          },
        });
        break;
    }
  };

  const handleChangeQuantityValue = (value, index) => {
    if (!value) {
      return;
    }
    const dataCopy = [...productItemData];
    dataCopy[index].quantity = Number(value);
    setProductItemData(dataCopy);
  };

  const handleDeleteFilter = (value, dataIndex) => {
    const filtersCopy = {
      ...filtersCopyState,
      [dataIndex]: filter(
        filtersCopyState?.[dataIndex],
        (item) => item !== value,
      ),
    };
    setFiltersCopyState(filtersCopy);
  };
  const handleChangeAmount = (value, input, dataIndex) => {
    const filtersCopy = {
      ...filtersCopyState,
      [dataIndex]: { ...filtersCopyState?.[dataIndex], [input]: value },
    };

    setFiltersCopyState(filtersCopy);
  };

  const filterPopup = (dataIndex, isPrice = false) => ({
    filterDropdown: ({ confirm, clearFilters }) => {
      if (isPrice) {
        return (
          <div className="custom-filter-dropdown amount-filter-dropdown">
            <Row gutter={[8, 8]}>
              <Col span={12}>
                <PriceComponent
                  value={
                    filtersCopyState?.[dataIndex]
                      ? filtersCopyState?.[dataIndex]?.min
                      : false
                  }
                  onChange={(value) =>
                    handleChangeAmount(value, 'min', dataIndex)
                  }
                  placeholder="min"
                />
              </Col>
              <Col span={12}>
                <PriceComponent
                  value={
                    filtersCopyState?.[dataIndex]
                      ? filtersCopyState?.[dataIndex]?.max
                      : false
                  }
                  onChange={(value) =>
                    handleChangeAmount(value, 'max', dataIndex)
                  }
                  placeholder="max"
                />
              </Col>
            </Row>
            <Divider className="divider-filter" />
            <div className="d-flex justify-center">
              <Button
                size="small"
                className="common-button discard-button filter-button"
                id="date-filter-reset"
                onClick={() => handleReset(clearFilters, dataIndex)}
              >
                Reset
              </Button>
              <Button
                size="small"
                className="common-button filter-button"
                id="date-filter-ok"
                htmlType="submit"
                type="primary"
                onClick={() => getFilterData(confirm, dataIndex)}
              >
                Ok
              </Button>
            </div>
          </div>
        );
      }
      return (
        <div className="custom-filter-dropdown">
          <LoaderComponent spinning={filterLoading} setHeight={35}>
            <SearchComponent
              className="list-search-box filter-search"
              id="search-container-id-roles"
              placeholder="Search..."
              name={dataIndex}
              getData={(value) => handleSearch(value, dataIndex)}
            />
            {filtersCopyState?.[dataIndex]?.length > 0 && (
              <div className="filter-section">
                {map(filtersCopyState?.[dataIndex], (item) => (
                  <Tag
                    key={item?.toString()}
                    closable
                    onClose={() => handleDeleteFilter(item, dataIndex)}
                    className="filter-tag"
                  >
                    {isPrice ? (
                      <span title={formatPrice(item)}>{formatPrice(item)}</span>
                    ) : (
                      <>
                        {dataIndex === 'isActive' ? (
                          <span title={item === true ? 'Active' : 'Inactive'}>
                            {item === true ? 'Active' : 'Inactive'}
                          </span>
                        ) : (
                          <span title={item?.label || item?.toString()}>
                            {item?.label || item?.toString()}
                          </span>
                        )}
                      </>
                    )}
                  </Tag>
                ))}
              </div>
            )}
            <div
              className="filter-checkboxes"
              onScroll={(e) => onScroll(e, dataIndex)}
            >
              {filterList?.length > 0 ? (
                map(filterList, (item) => (
                  <div
                    className="filter-checkbox-section"
                    key={item?.key || item}
                  >
                    <Checkbox
                      value={item?.key || item}
                      checked={filtersCopyState?.[dataIndex]?.includes(
                        item?.key || item,
                      )}
                      key={item?.key || item}
                      onChange={(e) => changeFilter(e, dataIndex)}
                      className="common-checkbox"
                    >
                      {isPrice ? (
                        <span title={formatPrice(item)}>
                          {formatPrice(item)}
                        </span>
                      ) : (
                        <>
                          {dataIndex === 'isActive' ? (
                            <span title={item === true ? 'Active' : 'Inactive'}>
                              {item === true ? 'Active' : 'Inactive'}
                            </span>
                          ) : (
                            <span title={item?.label || item?.toString()}>
                              {item?.label || item?.toString()}
                            </span>
                          )}
                        </>
                      )}
                    </Checkbox>
                  </div>
                ))
              ) : (
                <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
              )}
            </div>
          </LoaderComponent>
          <Divider className="divider-filter" />
          <div className="d-flex justify-center">
            <Button
              size="small"
              className="common-button discard-button filter-button"
              id="roles-filter-reset"
              onClick={() => handleReset(clearFilters, dataIndex)}
            >
              Reset
            </Button>
            <Button
              size="small"
              className="common-button filter-button"
              id="roles-filter-ok"
              type="primary"
              onClick={() => getFilterData(confirm, dataIndex)}
            >
              Ok
            </Button>
          </div>
        </div>
      );
    },
    filterIcon: () =>
      filters?.[dataIndex]?.length > 0 || !isEmpty(filters?.[dataIndex]) ? (
        <FilterSelectedIconComponent className="primary-color" />
      ) : (
        <img src={FilterIcon} alt="filter-icon" width={16} />
      ),
    onFilterDropdownOpenChange: (visible) => {
      setFilterIndex(dataIndex);
      setFilterVisible(visible);
      if (visible) {
        setFiltersCopyState(filters);
      }
    },
  });

  const handleAddProductItems = async (record) => {
    setRecordLoading(record?.id);
    await createProductSubItem({
      variables: {
        data: {
          productId,
          items: [
            {
              productItemId: record?.id,
              quantity: record?.quantity,
              costPrice: record?.costPrice,
              defaultPrice: record?.defaultPrice,
            },
          ],
        },
      },
    });
  };

  const columns = [
    {
      title: 'SKU',
      dataIndex: 'sku',
      key: 'sku',
      sorter: true,
      ellipsis: true,
      fixed: 'left',
      className: 'max-width-column',
      sortOrder: sortedInfo?.columnKey === 'sku' && sortedInfo?.order,
      width: 120,
      render: (sku) => (
        <Highlighter
          title={sku}
          highlightClassName="highlight-words"
          searchWords={[productItemFilter?.search]}
          autoEscape
          textToHighlight={sku || ''}
        />
      ),
    },
    {
      title: 'PRODUCT',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      ellipsis: true,
      fixed: 'left',
      className: 'max-width-column',
      sortOrder: sortedInfo?.columnKey === 'name' && sortedInfo?.order,
      ...filterPopup('name'),
      render: (name) => (
        <Highlighter
          title={name}
          highlightClassName="highlight-words"
          searchWords={[productItemFilter?.search]}
          autoEscape
          textToHighlight={name || ''}
        />
      ),
    },
    {
      title: 'MANUFACTURER',
      dataIndex: 'manufacturerId',
      key: 'manufacturerId',
      sorter: true,
      ellipsis: true,
      sortOrder:
        sortedInfo?.columnKey === 'manufacturerId' && sortedInfo?.order,
      width: 180,
      ...filterPopup('manufacturer'),
      render: (manufacturerId, record = {}) => (
        <Highlighter
          title={record?.manufacturer?.name}
          highlightClassName="highlight-words"
          searchWords={[productItemFilter?.search]}
          autoEscape
          textToHighlight={record?.manufacturer?.name || ''}
        />
      ),
    },
    {
      title: 'INDUSTRY',
      dataIndex: 'industryId',
      key: 'industryId',
      sorter: true,
      ellipsis: true,
      sortOrder: sortedInfo?.columnKey === 'industryId' && sortedInfo?.order,
      width: 190,
      ...filterPopup('industry'),
      render: (industryId, record = {}) => (
        <span>{record?.industry?.label}</span>
      ),
    },
    {
      title: 'LINE OF BUSINESS',
      dataIndex: 'lineOfBusinessId',
      key: 'lineOfBusinessId',
      sorter: true,
      ellipsis: true,
      sortOrder:
        sortedInfo?.columnKey === 'lineOfBusinessId' && sortedInfo?.order,
      width: 190,
      ...filterPopup('lineOfBusiness'),
      render: (lineOfBusinessId, record = {}) => (
        <Highlighter
          title={record?.lineOfBusiness?.label}
          highlightClassName="highlight-words"
          searchWords={[productItemFilter?.search]}
          autoEscape
          textToHighlight={record?.lineOfBusiness?.label || ''}
        />
      ),
    },
    {
      title: 'SERVICE TYPE',
      dataIndex: 'subAreaId',
      key: 'subAreaId',
      sorter: true,
      ellipsis: true,
      sortOrder: sortedInfo?.columnKey === 'subAreaId' && sortedInfo?.order,
      width: 160,
      ...filterPopup('subArea'),
      render: (subAreaId, record = {}) => (
        <Highlighter
          title={record?.subArea?.label}
          highlightClassName="highlight-words"
          searchWords={[productItemFilter?.search]}
          autoEscape
          textToHighlight={record?.subArea?.label || ''}
        />
      ),
    },
    {
      title: 'UNIT OF MEASURE',
      dataIndex: 'unitForMeasureObj',
      key: 'unitForMeasureObj',
      ellipsis: true,
      align: 'right',
      width: 140,
      render: (unitForMeasureObj) => (
        <span title={unitForMeasureObj?.title || '-'}>
          {unitForMeasureObj?.title || '-'}
        </span>
      ),
    },
    {
      title: 'CONVERSION PARAMETER',
      dataIndex: 'unitInputParameterObj',
      key: 'unitInputParameterObj',
      ellipsis: true,
      align: 'right',
      width: 140,
      render: (unitInputParameterObj) => (
        <span title={unitInputParameterObj?.name || '-'}>
          {unitInputParameterObj?.name || '-'}
        </span>
      ),
    },
    {
      title: 'COST',
      dataIndex: 'costPrice',
      key: 'costPrice',
      sorter: true,
      ellipsis: true,
      align: 'right',
      className: 'max-width-column',
      sortOrder: sortedInfo?.columnKey === 'costPrice' && sortedInfo?.order,
      width: 130,
      ...filterPopup('costPrice', true),
      render: (costPrice = null) => (
        <Highlighter
          title={formatPrice(costPrice)}
          highlightClassName="highlight-words"
          searchWords={[productItemFilter?.search]}
          autoEscape
          textToHighlight={formatPrice(costPrice) || ''}
        />
      ),
    },
    {
      title: 'DEF.PRICE',
      dataIndex: 'defaultPrice',
      key: 'defaultPrice',
      sorter: true,
      ellipsis: true,
      align: 'right',
      sortOrder: sortedInfo?.columnKey === 'defaultPrice' && sortedInfo?.order,
      width: 140,
      ...filterPopup('defaultPrice', true),
      render: (defaultPrice = null) => (
        <Highlighter
          title={formatPrice(defaultPrice)}
          highlightClassName="highlight-words"
          searchWords={[productItemFilter?.search]}
          autoEscape
          textToHighlight={formatPrice(defaultPrice) || ''}
        />
      ),
    },
    {
      title: 'QTY',
      dataIndex: 'quantity',
      key: 'quantity',
      ellipsis: true,
      fixed: 'right',
      width: 130,
      render: (quantity, record, index) => (
        <div>
          <InputNumber
            type="number"
            min={1}
            className="quantity-component"
            value={quantity || 1}
            onChange={(e) => handleChangeQuantityValue(e, index)}
          />
        </div>
      ),
    },
    (product?.productType === 'Bundle' ||
      (product?.productType === 'Single' && count < 1)) &&
      !location?.pathname?.includes('/view') && {
        dataIndex: 'id',
        align: 'right',
        width: 10,
        fixed: 'right',
        render: (id, record) => (
          <Popconfirm
            title="Are you sure to add?"
            onConfirm={() => handleAddProductItems(record)}
            okText="Yes"
            cancelText="No"
          >
            <Button
              className="common-button"
              size="small"
              id="productItem-table-add-btn"
              loading={record?.id === recordLoading && createProductLoading}
              type="primary"
            >
              Add
            </Button>
          </Popconfirm>
        ),
      },
  ];

  const onSearchChange = (value) => {
    setProductItemFilter({
      ...productItemFilter,
      skip: value
        ? 0
        : productItemFilter?.limit * (paginationProp?.current - 1),
      search: value,
    });
    listProductsInPackage({
      variables: {
        filter: {
          ...productItemFilter,
          skip: value
            ? 0
            : productItemFilter?.limit * (paginationProp?.current - 1),
          search: value,
        },
        ...(filters && { where: filters }),
      },
    });
  };

  return (
    <>
      <div className="fill-width search-checkbox">
        <SearchComponent
          className="list-search-box modal-search"
          id="search-container-id"
          placeholder="Search all fields"
          name="Products"
          getData={onSearchChange}
        />
      </div>
      <div className="common-table product-item-table">
        <TableComponent
          loadingData={loading}
          columns={[...columns?.filter((item) => item !== false)]}
          fullHeight={false}
          scroll={{ x: 'max-content' }}
          data={productItemData || []}
          onChange={handleTableChange}
          paginationConfig={paginationProp}
          rowKey={(obj) => obj?.id}
        />
      </div>
    </>
  );
};

export default ProductItemComponent;
