import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Card, Checkbox, Col, Form, Row, Switch } from 'antd';
import { map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { formValidatorRules } from '../../../common/utils';
import InputComponent from '../../../components/InputComponent';

const staticFieldOptions = [
  {
    label: 'Manufacturer',
    value: 'MANUFACTURER',
  },
  {
    label: 'Stock',
    value: 'STOCK',
  },
  {
    label: 'Unit Of Measure',
    value: 'UNIT_OF_MEASURE',
  },
  {
    label: 'Product Category',
    value: 'PRODUCT_CATEGORY',
  },
  {
    label: 'Product Warranty',
    value: 'PRODUCT_WARRANTY',
  },
  {
    label: 'Labor Warranty',
    value: 'LABOUR_WARRANTY',
  },
  {
    label: 'Manage Inventory',
    value: 'MANAGE_INVENTORY',
  },
  {
    label: 'Asset',
    value: 'ASSET',
  },
  {
    label: 'Allow PriceChange',
    value: 'ALLOW_PRICE_CHANGE',
  },
];

const { required } = formValidatorRules;

const CompareQuoteVariants = ({
  industryLobSaData = null,
  fetchDynamicFields,
  dynamicFieldsData = [],
  compareConfig = null,
  form = null,
  compareConfigObj = null,
}) => {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    form?.setFieldsValue({
      compareConfig: {
        enable: compareConfigObj?.enable,
        label: compareConfigObj?.label,
        staticKeys: compareConfigObj?.staticKeys,
        dynamicFieldIds: compareConfigObj?.dynamicFields?.map(
          (item) => item?.id,
        ),
        title: compareConfigObj?.title,
      },
    });
    setIsActive(compareConfigObj?.enable);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compareConfigObj]);

  useEffect(() => {
    if (dynamicFieldsData?.length > 0)
      fetchDynamicFields({
        variables: {
          where: {
            fieldType: ['PICK_LIST', 'TEXT', 'BOOLEAN', 'NUMBER'],
            industry: [industryLobSaData?.industryId],
            lineOfBusiness: [industryLobSaData?.lineOfBusinessId],
          },
        },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card className="card-wrapper">
      <div className="setting-content-heading">
        <h5>Compare Quote Variants</h5>
        <span className="panel-description">
          Compare Quote Variants settings of this questionnaire
        </span>
      </div>
      <Row className="setting-content" gutter={10}>
        <div className="d-flex align-center justify-between ml-8 fill-width">
          <h5 className="mb-0">Activate Quote Variants</h5>
          <Form.Item
            layout="inline"
            name={['compareConfig', 'enable']}
            valuePropName="checked"
          >
            <Switch
              className="common-switch"
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              onChange={(value) => {
                setIsActive(value);
                form?.validateFields();
              }}
            />
          </Form.Item>
        </div>

        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Form.Item
            name={['compareConfig', 'label']}
            label="Label"
            rules={
              isActive
                ? [
                    {
                      ...required,
                      message: 'Please Enter Label',
                    },
                  ]
                : []
            }
          >
            <InputComponent allowClear placeholder="Add Label" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Form.Item
            name={['compareConfig', 'title']}
            label="Pop-Up Title"
            rules={
              isActive
                ? [
                    {
                      ...required,
                      message: 'Please Enter Popup Title',
                    },
                  ]
                : []
            }
            className="mt-8"
          >
            <InputComponent allowClear placeholder="Pop-Up Title" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Form.Item
            name={['compareConfig', 'staticKeys']}
            label="Static Fields"
            required={isActive}
            onChange={() => {
              form?.validateFields();
            }}
            validateTrigger="onChange"
            rules={
              isActive
                ? [
                    {
                      async validator(_, value) {
                        if (
                          !value?.length &&
                          !compareConfig?.dynamicFieldIds?.length
                        ) {
                          throw new Error(
                            'Please select at least one Dynamic Field or Static Field',
                          );
                        }
                      },
                    },
                  ]
                : []
            }
            className="mt-8"
          >
            <Checkbox.Group className="common-Checkbox fill-width d-flex flex-wrap ">
              {map(staticFieldOptions, (item) => (
                <div
                  className="dynamic-static-field-checkbox-layer"
                  key={item?.label}
                >
                  <Checkbox key={item?.label} value={item?.value}>
                    {item?.label}
                  </Checkbox>
                </div>
              ))}
            </Checkbox.Group>
          </Form.Item>
        </Col>
        {dynamicFieldsData.length > 0 && (
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Form.Item
              name={['compareConfig', 'dynamicFieldIds']}
              label="Dynamic Fields"
              required={isActive}
              validateTrigger="onChange"
              onChange={() => {
                form?.validateFields();
              }}
              rules={
                isActive
                  ? [
                      {
                        async validator(_, value) {
                          if (
                            !value?.length &&
                            !compareConfig?.staticKeys?.length
                          ) {
                            throw new Error(
                              'Please select at least one Dynamic Field or Static Field',
                            );
                          }
                        },
                      },
                    ]
                  : []
              }
            >
              <Checkbox.Group className="common-Checkbox fill-width d-flex flex-wrap">
                {map(dynamicFieldsData, (item) => (
                  <div
                    className="dynamic-static-field-checkbox-layer"
                    key={item?.label}
                  >
                    <Checkbox key={item?.id} value={item?.id}>
                      {item?.label}
                    </Checkbox>
                  </div>
                ))}
              </Checkbox.Group>
            </Form.Item>
          </Col>
        )}
      </Row>
    </Card>
  );
};

export default CompareQuoteVariants;
