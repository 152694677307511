import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Form, Layout, Menu } from 'antd';
import { flattenDeep, isEmpty, kebabCase, keys, map } from 'lodash';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { AppContext } from '../../../AppContext';
import {
  MODAL_WIDTH,
  POLICY_DEFAULT_VALUE,
  QUESTIONNAIRE_TYPE,
  ROUTES,
  SETTING_MODAL_MENUS,
} from '../../../common/constants';
import {
  cpqRetailUrl,
  objectWithoutKey,
  uploadFile,
} from '../../../common/utils';
import LoaderComponent from '../../../components/LoaderComponent';
import ModalComponent from '../../../components/ModalComponent';
import { GET_BRANDS } from '../../brands/graphql/Queries';
import {
  CREATE_QUESTIONNAIRES,
  UPDATE_QUESTIONNAIRES,
} from '../graphql/Mutations';

import {
  CPQ_INDUSTRIES,
  GET_QUESTIONNAIRE,
  GET_SPONSOR,
  GET_TENANT,
} from '../graphql/Queries';
import AnalyticsForm from './AnalyticsForm';
import BrandingForm from './BrandingForm';
import CutoffTimeForm from './CutoffTimeForm';
import EmailNotification from './EmailNotification';
import GeneralForm from './GeneralForm';
import ProductRankingConfig from './ProductRankingConfig';

const { Sider, Footer } = Layout;

const SettingModal = ({
  showModal,
  setShowModal,
  refetchData,
  activeKeyProps = 'general',
  setActiveKeyProps,
  refetchQuestionnaire,
}) => {
  const [form] = Form?.useForm();
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    state: { currentUser, isSponsor },
    dispatch,
  } = useContext(AppContext);
  const defaultTime = moment('5:30 pm', 'h:mm a');
  const initialValues = {
    brandId: null,
    cutOffTime: {
      monday: {
        checked: false,
        time: defaultTime,
      },
      tuesday: {
        checked: false,
        time: defaultTime,
      },
      wednesday: {
        checked: false,
        time: defaultTime,
      },
      thursday: {
        checked: false,
        time: defaultTime,
      },
      friday: {
        checked: false,
        time: defaultTime,
      },
      saturday: {
        checked: false,
        time: defaultTime,
      },
      sunday: {
        checked: false,
        time: defaultTime,
      },
    },
    isScheduleEnabled: false,
    daysOff: {
      days: [],
      allowBookingOnHolidays: false,
    },
    appointmentWindow: {
      active: false,
      showLabel: true,
      showTime: false,
      slots: [{}],
    },
    logo: 'TENANT',
    emailNotification: [{ name: '', email: '' }],
    quoteSortOn: 'sellingPrice',
    quoteSortBy: 'DESC',
    termsOfUse: {
      termsOfUseUrl: POLICY_DEFAULT_VALUE?.termsOfUseUrl,
      termsOfUseLabel: POLICY_DEFAULT_VALUE?.termsOfUseLabel,
    },
    privacyPolicy: {
      privacyPolicyUrl: POLICY_DEFAULT_VALUE?.privacyPolicyUrl,
      privacyPolicyLabel: POLICY_DEFAULT_VALUE?.privacyPolicyLabel,
    },
  };

  const [generalActivePanelKey, setGeneralActivePanelKey] = useState([
    'title_subdomain',
  ]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [validationTriggered, setValidationTriggered] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [initialValue, setInitialValue] = useState(initialValues);
  const [brandsLoading, setBrandsLoading] = useState(true);
  const [tenantAndSponsorLoading, setTenantAndSponsorLoading] = useState(true);
  const [policyUrlDisable, setPolicyUrlDisabled] = useState({
    termsOfUse: true,
    privacyPolicy: true,
  });
  const [industries, setIndustries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeKey, setActiveKey] = useState('general');
  const [isFormValuesChanged, setIsFormValuesChanged] = useState(false);
  const [isAppointmentEnabled, setIsAppointmentEnabled] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [currentUserData, setCurrentUserData] = useState(null);

  useEffect(() => {
    setActiveKey(activeKeyProps);
  }, [activeKeyProps]);

  const onMenuSelect = (e) => {
    setActiveKey(e?.key);
    if (setActiveKeyProps) {
      setActiveKeyProps(e?.key);
    }
  };

  const [getQuestionnaire] = useLazyQuery(GET_QUESTIONNAIRE, {
    fetchPolicy: 'cache-and-network',
    onCompleted(res) {
      if (res?.questionnaire?.isScheduleEnabled) {
        setShowDatePicker(true);
      }
      if (res?.questionnaire?.appointmentWindow?.active) {
        setIsAppointmentEnabled(true);
      }
      const newStartDate = res?.questionnaire?.startDate
        ? moment(res?.questionnaire?.startDate, 'YYYY-MM-DD')
        : null;
      const newExpiryDate = res?.questionnaire?.endDate
        ? moment(res?.questionnaire?.endDate, 'YYYY-MM-DD')
        : null;
      const questionObj = {
        title: res?.questionnaire?.title,
        uuid: res?.questionnaire?.uuid,
        industryId: res?.questionnaire?.industryId,
      };
      dispatch({ type: 'SET_CPQ_QUESTION_OBJECT', data: questionObj });

      const tempObj = {
        ...res?.questionnaire,
        title: res?.questionnaire?.title,
        link: res?.questionnaire?.link?.split('/')?.[1],
        slug: res?.questionnaire?.slug,
        isActive: res?.questionnaire?.isActive,
        brandId: res?.questionnaire?.brandId?.toString(),
        cutOffTime: {
          monday: {
            checked: res?.questionnaire?.cutOffTime?.monday?.checked,
            time: moment(
              res?.questionnaire?.cutOffTime?.monday?.time,
              'h:mm a',
            ),
          },
          tuesday: {
            checked: res?.questionnaire?.cutOffTime?.tuesday?.checked,
            time: moment(
              res?.questionnaire?.cutOffTime?.tuesday?.time,
              'h:mm a',
            ),
          },
          wednesday: {
            checked: res?.questionnaire?.cutOffTime?.wednesday?.checked,
            time: moment(
              res?.questionnaire?.cutOffTime?.wednesday?.time,
              'h:mm a',
            ),
          },
          thursday: {
            checked: res?.questionnaire?.cutOffTime?.thursday?.checked,
            time: moment(
              res?.questionnaire?.cutOffTime?.thursday?.time,
              'h:mm a',
            ),
          },
          friday: {
            checked: res?.questionnaire?.cutOffTime?.friday?.checked,
            time: moment(
              res?.questionnaire?.cutOffTime?.friday?.time,
              'h:mm a',
            ),
          },
          saturday: {
            checked: res?.questionnaire?.cutOffTime?.saturday?.checked,
            time: moment(
              res?.questionnaire?.cutOffTime?.saturday?.time,
              'h:mm a',
            ),
          },
          sunday: {
            checked: res?.questionnaire?.cutOffTime?.sunday?.checked,
            time: moment(
              res?.questionnaire?.cutOffTime?.sunday?.time,
              'h:mm a',
            ),
          },
        },
        isScheduleEnabled: res?.questionnaire?.isScheduleEnabled,
        startDate: newStartDate,
        expiryDate: newExpiryDate,
        uuid: res?.questionnaire?.uuid,
        industryId: res?.questionnaire?.industryId,
        appointmentWindow: {
          active: res?.questionnaire?.appointmentWindow?.active,
          showLabel: res?.questionnaire?.appointmentWindow?.showLabel,
          showTime: res?.questionnaire?.appointmentWindow?.showTime,
          slots: map(res?.questionnaire?.appointmentWindow?.slots, (slot) => ({
            ...slot,
            from: moment(slot?.from),
            to: moment(slot?.to),
          })),
        },
        daysOff: res?.questionnaire?.daysOff,
        logo: res?.questionnaire?.brandConfig?.logo,
        headerImage: res?.questionnaire?.brandConfig?.headerImage,
        emailNotification: res?.questionnaire?.emailNotification,
      };

      if (res?.questionnaire?.brandConfig?.headerImage) {
        setFileList([res?.questionnaire?.brandConfig?.headerImage]);
      }
      form?.setFieldsValue({ industryId: res?.questionnaire?.industryId });
      form?.setFieldsValue({
        analyticsServiceProviders:
          res?.questionnaire?.analyticsServiceProviders,
      });

      if (res?.questionnaire?.termsOfUse && res?.questionnaire?.privacyPolicy) {
        setPolicyUrlDisabled({
          termsOfUse: !res.questionnaire.termsOfUse?.termsOfUseUrlCheck,
          privacyPolicy: !res.questionnaire.privacyPolicy
            ?.privacyPolicyUrlCheck,
        });
      }

      setInitialValue(tempObj);
      setLoading(false);
    },
  });

  const [brands, { data: brandsData }] = useLazyQuery(GET_BRANDS, {
    fetchPolicy: 'network-only',
    onCompleted: () => {
      setBrandsLoading(false);
    },
    onError: () => {
      setBrandsLoading(false);
    },
  });

  const [fetchTenant] = useLazyQuery(GET_TENANT, {
    fetchPolicy: 'cache-and-network',
    onCompleted(res) {
      setCurrentUserData(res?.tenant);
      setTenantAndSponsorLoading(false);
    },
    onError() {
      setTenantAndSponsorLoading(false);
    },
  });

  const [fetchSponsor] = useLazyQuery(GET_SPONSOR, {
    fetchPolicy: 'cache-and-network',
    onCompleted(res) {
      setCurrentUserData(res?.sponsor);
      setTenantAndSponsorLoading(false);
    },
    onError() {
      setTenantAndSponsorLoading(false);
    },
  });

  const [fetchCpqIndustries] = useLazyQuery(CPQ_INDUSTRIES, {
    variables: {
      filter: {
        skip: 0,
        justShow: true,
      },
      where: {
        isActive: [true],
      },
    },
    fetchPolicy: 'network-only',
    onCompleted(res) {
      setIndustries(res?.industries?.data);
    },
    onError() {},
  });

  useEffect(() => {
    if (industries?.length > 0 && brandsData?.brands?.data?.length > 0) {
      if (!id) {
        const initialObjClone = {
          ...initialValue,
          industryId: industries?.[0]?.id,
          brandId: brandsData?.brands?.data?.[0]?.id,
        };
        setInitialValue(initialObjClone);
        setLoading(false);
      }
    }
  }, [industries, brandsData]);

  useEffect(() => {
    brands({
      variables: {
        filter: {
          sortOn: 'createdAt',
          sortBy: 'DESC',
          defaultBrand: true,
        },
        where: { isActive: true },
      },
    });
    fetchCpqIndustries({
      variables: {
        where: {
          isActive: [true],
        },
      },
    });
    if (id) {
      setLoading(true);
      getQuestionnaire({
        variables: {
          id,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isSponsor) {
      fetchTenant({
        variables: {
          where: {
            id: currentUser?.tenantId,
          },
        },
      });
    } else {
      fetchSponsor({
        variables: {
          where: {
            id: currentUser?.sponsorId,
          },
        },
      });
    }
  }, [isSponsor]);

  useEffect(() => {
    if (!brandsLoading && !loading && !tenantAndSponsorLoading && !id) {
      form?.setFieldsValue({
        emailNotification: [
          {
            name: !isSponsor
              ? currentUserData?.name
              : currentUserData?.businessName,
            email: currentUserData?.email,
            default: true,
          },
        ],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandsLoading, tenantAndSponsorLoading, loading]);

  const [createQuestionnaire] = useMutation(CREATE_QUESTIONNAIRES, {
    onError: () => {
      setSubmitLoading(false);
    },
  });

  const [updateQuestionnaire] = useMutation(UPDATE_QUESTIONNAIRES, {
    onError: () => {
      setSubmitLoading(false);
    },
  });

  const handleSubmit = () => {
    form?.submit();
  };

  const onCancel = () => {
    setShowModal(false);
    setActiveKey('general');
    if (setActiveKeyProps) {
      setActiveKeyProps('general');
    }
  };

  const onFinish = async (values) => {
    setSubmitLoading(true);
    let startDate;
    let endDate;
    const formatCutOffTime = map(keys(values?.cutOffTime), (item) => ({
      [item]: {
        ...values?.cutOffTime?.[item],
        time: moment(values?.cutOffTime?.[item]?.time)?.format('hh:mm A'),
      },
    }));
    const cutOfTimeObj = Object.assign({}, ...formatCutOffTime);
    const analyticsServiceProviders = values?.analyticsServiceProviders?.map(
      (item) => objectWithoutKey(item, '__typename'),
    );
    const emailNotification = values?.emailNotification?.map((item) =>
      objectWithoutKey(item, '__typename'),
    );
    const newLink = `${cpqRetailUrl(currentUserData?.subDomain, isSponsor)}/${
      values?.link
    }`;

    const uuid = values?.uuid || uuidv4();
    let headerImage = null;
    if (values?.headerImage && !values?.headerImage?.url) {
      headerImage = await uploadFile({
        logoObject: values?.headerImage,
        currentUser,
        uuid,
        folder: 'cpq-builder',
      });
    } else {
      headerImage = values?.headerImage;
    }

    if (values?.startDate) {
      startDate = moment(values?.startDate)?.format('YYYY-MM-DD');
    }
    if (values?.expiryDate) {
      endDate = moment(values?.expiryDate)?.format('YYYY-MM-DD');
    }

    if (id) {
      const newValues = {
        ...values,
        isScheduleEnabled: values?.isScheduleEnabled,
        cutOffTime: cutOfTimeObj,
        link: newLink,
        slug: initialValue?.slug,
        isActive: initialValue?.isActive,
        title: values?.title,
        brandId: values?.brandId,
        uuid: initialValue?.uuid,
        brandConfig: {
          logo: values?.logo,
        },
        type: QUESTIONNAIRE_TYPE?.ROQ_BUILDER,
        startDate,
        endDate,
        daysOff: !values?.daysOff?.days
          ? initialValues?.daysOff
          : values?.daysOff,
        emailNotification,
        analyticsServiceProviders,
      };
      if (headerImage) {
        newValues.brandConfig.headerImage = headerImage;
      }
      if (
        isEmpty(values?.appointmentWindow?.slots?.[0]) ||
        !values?.appointmentWindow?.slots?.[0]?.label
      ) {
        newValues.appointmentWindow.slots = [];
      }
      delete newValues?.headerImage;
      delete newValues?.logo;
      delete newValues?.expiryDate;

      const response = await updateQuestionnaire({
        variables: {
          data: newValues,
          where: {
            id,
          },
        },
      });
      if (response?.data?.updateQuestionnaire) {
        setShowModal(false);
        setActiveKey('general');
        if (setActiveKeyProps) {
          setActiveKeyProps('general');
        }
        setSubmitLoading(false);
        if (refetchData) {
          refetchData();
        }
        if (refetchQuestionnaire && id) {
          refetchQuestionnaire();
        }
      }
    } else {
      const newValues = {
        ...values,
        uuid,
        isScheduleEnabled: values?.isScheduleEnabled,
        cutOffTime: cutOfTimeObj,
        link: newLink,
        slug: values?.link,
        title: values?.title,
        brandId: values?.brandId,
        brandConfig: {
          logo: values?.logo,
        },
        type: QUESTIONNAIRE_TYPE?.ROQ_BUILDER,
        startDate,
        endDate,
        emailNotification,
        analyticsServiceProviders,
        termsOfUse: {
          ...values?.termsOfUse,
          termsOfUseUrlCheck: POLICY_DEFAULT_VALUE?.termsOfUseUrlCheck,
        },
        privacyPolicy: {
          ...values?.privacyPolicy,
          privacyPolicyUrlCheck: POLICY_DEFAULT_VALUE?.privacyPolicyUrlCheck,
        },
      };
      if (headerImage) {
        newValues.brandConfig.headerImage = headerImage;
      }
      if (
        isEmpty(values?.appointmentWindow?.slots?.[0]) ||
        !values?.appointmentWindow?.slots?.[0]?.label
      ) {
        newValues.appointmentWindow.slots = [];
      }
      delete newValues?.headerImage;
      delete newValues?.logo;
      delete newValues?.expiryDate;

      const response = await createQuestionnaire({
        variables: {
          data: newValues,
        },
      });
      if (response?.data?.createQuestionnaire) {
        setSubmitLoading(false);
        navigate(
          `${ROUTES?.QUESTIONNAIRES_PRIMARY}/${
            response?.data?.createQuestionnaire?.data?.id
          }${ROUTES?.PAGE}/${kebabCase(
            response?.data?.createQuestionnaire?.data?.pageKey,
          )}?sp=false`,
        );
      }
    }
  };

  const onFinishFailed = (errors) => {
    if (errors?.errorFields?.length > 0) {
      const errorName = flattenDeep(map(errors?.errorFields, 'name'));
      if (errorName?.includes('appointmentWindow')) {
        if (activeKey !== 'business-hours') {
          setActiveKey('business-hours');
        }
      } else if (errorName?.includes('title') || errorName?.includes('link')) {
        setActiveKey('general');
      }
    }
    setValidationTriggered(true);
  };

  const afterOpen = () => {
    // eslint-disable-next-line no-undef
    const modalContent = document.querySelector('.ant-modal-content');
    modalContent.setAttribute('aria-labelledby', 'modal-title');
  };

  return (
    <ModalComponent
      width={MODAL_WIDTH}
      open={showModal}
      onCancel={onCancel}
      footer={null}
      destroyOnClose
      wrapClassName="setting-modal"
      afterOpen={afterOpen}
    >
      <Layout>
        <Sider className="setting-layout">
          <div className="sider-header">
            <span>SETTINGS</span>
          </div>
          <Menu
            items={SETTING_MODAL_MENUS}
            theme="light"
            selectedKeys={activeKey}
            onSelect={onMenuSelect}
          />
        </Sider>
        <Layout className="setting-modal-content">
          <div>
            <div className="content-wrapper">
              {brandsLoading ||
              tenantAndSponsorLoading ||
              loading ||
              !initialValue ? (
                <LoaderComponent setHeight={75} />
              ) : (
                <Form
                  form={form}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  scrollToFirstError={{ behavior: 'smooth', block: 'end' }}
                  name="settingForm"
                  layout="vertical"
                  initialValues={initialValue}
                  validateTrigger={
                    validationTriggered ? 'onChange' : 'onSubmit'
                  }
                  onValuesChange={() => {
                    setIsFormValuesChanged(true);
                  }}
                >
                  <div className={activeKey === 'general' ? '' : 'hide-form'}>
                    <GeneralForm
                      isEdit={!!id}
                      form={form}
                      brandsData={brandsData}
                      tenantData={currentUserData}
                      policyUrlDisable={policyUrlDisable}
                      setShowDatePicker={setShowDatePicker}
                      showDatePicker={showDatePicker}
                      generalActivePanelKey={generalActivePanelKey}
                      setGeneralActivePanelKey={setGeneralActivePanelKey}
                      industriesData={industries}
                    />
                  </div>
                  <div
                    className={
                      activeKey === 'business-hours' ? '' : 'hide-form'
                    }
                  >
                    <CutoffTimeForm
                      form={form}
                      isAppointmentEnabled={isAppointmentEnabled}
                      setIsAppointmentEnabled={setIsAppointmentEnabled}
                    />
                  </div>
                  <div className={activeKey === 'branding' ? '' : 'hide-form'}>
                    <BrandingForm
                      form={form}
                      brandsData={brandsData}
                      fileList={fileList}
                      setFileList={setFileList}
                    />
                  </div>
                  <div
                    className={
                      activeKey === 'email-notification' ? '' : 'hide-form'
                    }
                  >
                    <EmailNotification form={form} />
                  </div>
                  <div
                    className={activeKey === 'quote-config' ? '' : 'hide-form'}
                  >
                    <ProductRankingConfig form={form} />
                  </div>

                  <div
                    className={
                      activeKey === 'analytics-config' ? '' : 'hide-form'
                    }
                  >
                    <AnalyticsForm form={form} />
                  </div>
                </Form>
              )}
            </div>
          </div>
          <Footer>
            <Button
              className="setting-btn fill-width"
              size="small"
              htmlType="submit"
              id="setting-save-btn"
              loading={submitLoading}
              type="primary"
              onClick={handleSubmit}
              disabled={!isFormValuesChanged}
            >
              {id ? 'Update Questionnaire' : 'Create Questionnaire'}
            </Button>
          </Footer>
        </Layout>
      </Layout>
    </ModalComponent>
  );
};

export default SettingModal;
