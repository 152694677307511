import { Card, Col, Form, Row } from 'antd';
import { map } from 'lodash';
import React from 'react';
import {
  PRODUCT_CONFIG_SORT_BY_OPTION,
  PRODUCT_CONFIG_SORT_ON_OPTION,
} from '../../../common/constants';
import SelectComponent from '../../../components/SelectComponent';

const { Option } = SelectComponent;

const ProductRankingConfig = () => (
  <Card className="card-wrapper">
    <div className="setting-content-heading">
      <h5>Results Sort Settings</h5>
      <span className="panel-description">
        Use the QRV to simulate your desired results and to apply the sorting
        use the fields below.
      </span>
    </div>
    <Row className="setting-content" gutter={16}>
      <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
        <Form.Item name="quoteSortBy" label="Sort By">
          <SelectComponent placeholder="Select Sort By" allowClear>
            {map(PRODUCT_CONFIG_SORT_BY_OPTION, (item) => (
              <Option key={item?.value} value={item?.value}>
                {item?.label}
              </Option>
            ))}
          </SelectComponent>
        </Form.Item>
      </Col>
      <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
        <Form.Item name="quoteSortOn" label="Sort on">
          <SelectComponent placeholder="Select Sort On" allowClear>
            {map(PRODUCT_CONFIG_SORT_ON_OPTION, (item) => (
              <Option key={item?.value} value={item?.value}>
                {item?.label}
              </Option>
            ))}
          </SelectComponent>
        </Form.Item>
      </Col>
    </Row>
  </Card>
);

export default ProductRankingConfig;
