import React, { createContext, useReducer } from 'react';
import client from './apollo';
import api from './common/api';
import {
  DASHBOARD,
  DEFAULT_DATE_FORMAT,
  DYNAMIC_SELECTED_COLUMN,
  PACKAGE_RANKINGS,
  PRODUCT_PRICING,
  QUESTIONNAIRE_RESULT_VALIDATION,
  SIGNUP_AUTH,
  TABLE_HEIGHT_SEARCH,
  TOKEN,
  USER,
} from './common/constants';

const getLoggedInUser = () => {
  // eslint-disable-next-line no-undef
  let loggedInUser = localStorage?.getItem(USER);
  loggedInUser = loggedInUser ? JSON?.parse(loggedInUser) : null;

  return loggedInUser;
};

const initialState = {
  currentUser: getLoggedInUser() || {},
  permissions: [],
  permissionRedirect: null,
  // eslint-disable-next-line no-undef
  authToken: localStorage?.getItem(TOKEN),
  pageSize: null,
  onBoardingData: [],
  logoUrl: null,
  loading: true,
  filterData: null,
  globalDateFormat: DEFAULT_DATE_FORMAT,
  // eslint-disable-next-line no-undef
  windowWidth: window?.innerWidth,
  cpqQuestionObject: null,
  cpqQuestionLoading: false,
  productRankingConfig: null,
  dashboardConfig: null,
  productPricingConfig: null,
  firstPricingConfigSet: false,
  showNotificationWarning: false,
  showPrompt: false,
  dimensionFormLoading: false,
  isTenant: process.env.REACT_APP_PORTAL === 'TENANT',
  isSponsor: process.env.REACT_APP_PORTAL === 'SPONSOR',
};

const reducer = (state, action) => {
  switch (action?.type) {
    case 'SET_CURRENT_USER':
      // eslint-disable-next-line no-case-declarations
      const user = action?.data || {};

      // eslint-disable-next-line no-undef
      localStorage?.setItem(
        USER,
        user && Object?.keys(user)?.length ? JSON.stringify(user) : null,
      );
      return { ...state, currentUser: { ...user } };
    case 'SET_CURRENT_ROLE':
      return { ...state, currentRole: action?.data };
    case 'LOGOUT':
      delete api?.defaults?.headers?.common?.Authorization;
      // eslint-disable-next-line no-undef
      localStorage?.removeItem(USER);
      // eslint-disable-next-line no-undef
      localStorage?.removeItem(TOKEN);
      // eslint-disable-next-line no-undef
      localStorage?.removeItem(SIGNUP_AUTH);
      // eslint-disable-next-line no-undef
      localStorage?.removeItem(PRODUCT_PRICING);
      // eslint-disable-next-line no-undef
      localStorage?.removeItem(QUESTIONNAIRE_RESULT_VALIDATION);
      // eslint-disable-next-line no-undef
      localStorage?.removeItem(PACKAGE_RANKINGS);
      // eslint-disable-next-line no-undef
      localStorage?.removeItem(DASHBOARD);
      // eslint-disable-next-line no-undef
      localStorage?.removeItem(DYNAMIC_SELECTED_COLUMN);
      client?.clearStore();
      return {
        ...initialState,
        authenticated: false,
        authToken: null,
        user: {},
      };
    case 'SET_FETCHING_USER_DETAILS':
      return { ...state, fetchingUserDetails: action?.data };
    case 'SET_PERMISSIONS':
      return { ...state, permissions: action?.data };
    case 'SET_AUTHENTICATED':
      return { ...state, authenticated: action?.data };
    case 'SET_SIGNUP_AUTH':
      // eslint-disable-next-line no-undef
      localStorage?.setItem(SIGNUP_AUTH, action?.data);
      return { ...state, signupAuth: action?.data };
    case 'SET_TOKEN':
      // eslint-disable-next-line no-undef
      localStorage?.setItem(TOKEN, action?.data);
      return { ...state, authToken: action?.data };
    case 'SET_INITIAL_SHOW_ALL_FIELDS_STATUS':
      return { ...state, initialShowAllFieldStatus: action?.data };
    case 'SET_PAGE_SIZE':
      return { ...state, pageSize: action?.data };
    case 'SET_ONBOARDING_DATA': {
      let showNotificationWarning = false;
      const {
        lineOfBusinessCreated = true,
        productCreated = true,
        productItemCreated = true,
        regionCreated = true,
        subAreaCreated = true,
      } = action?.data?.data;
      if (
        !lineOfBusinessCreated ||
        !productCreated ||
        !productItemCreated ||
        !regionCreated ||
        !subAreaCreated
      ) {
        showNotificationWarning = true;
      }
      return {
        ...state,
        onBoardingData: action?.data,
        showNotificationWarning,
      };
    }

    case 'SET_LOGO_URL':
      return { ...state, logoUrl: action?.data };
    case 'SET_LOADING':
      return { ...state, loading: action?.data };
    case 'SET_WIDTH':
      return { ...state, windowWidth: action?.data };
    case 'SET_FILTER_DATA':
      return { ...state, filterData: action?.data };
    case 'SET_CPQ_QUESTION_OBJECT':
      return { ...state, cpqQuestionObject: action?.data };
    case 'SET_CPQ_QUESTION_OBJECT_LOADING':
      return { ...state, cpqQuestionLoading: action?.data };
    case 'SET_PRODUCT_RANKING_CONFIG':
      return { ...state, productRankingConfig: action?.data };
    case 'SET_GLOBAL_DATE_FORMAT':
      return { ...state, globalDateFormat: action?.data };
    case 'SET_DASHBOARD_CONFIG':
      return { ...state, dashboardConfig: action?.data };
    case 'SET_PERMISSION_REDIRECT':
      return { ...state, permissionRedirect: action?.data };
    case 'SET_PRODUCT_PRICING_CONFIG':
      return { ...state, productPricingConfig: action?.data };
    case 'SET_FIRST_PRICE_CONFIG_SET':
      return { ...state, firstPricingConfigSet: action?.data };
    case 'SET_SHOW_PROMPT':
      return { ...state, showPrompt: action?.data };
    case 'SET_DIMENSION_LOADING':
      return { ...state, dimensionFormLoading: action?.data };

    default:
      return { ...state };
  }
};

const AppContext = createContext({
  state: initialState,
  dispatch: () => {},
});

function AppContextProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const getToken = () =>
    // eslint-disable-next-line no-undef
    localStorage?.getItem(TOKEN) || null;
  const getCurrentUser = () =>
    // eslint-disable-next-line no-undef
    localStorage?.getItem(USER)
      ? // eslint-disable-next-line no-undef
        JSON.parse(localStorage?.getItem(USER))
      : {};
  const getCurrentUserRole = () => {
    const loggedInUser = getLoggedInUser();
    return loggedInUser?.roles || [];
  };

  const isAuthenticated = () => state?.authenticated;

  const isSignupAuthenticate = () =>
    // eslint-disable-next-line no-undef
    localStorage?.getItem(SIGNUP_AUTH) || false;
  const initializeAuth = (authToken, userData) => {
    const token = authToken || getToken();
    const user = userData || getCurrentUser();
    if (token) {
      api.defaults.headers.common.Authorization = `Bearer ${token}`;
      dispatch({ type: 'SET_TOKEN', data: token });
      dispatch({ type: 'SET_AUTHENTICATED', data: true });
      dispatch({ type: 'SET_CURRENT_USER', data: user });
      if (user?.roles?.[0]) {
        dispatch({ type: 'SET_CURRENT_ROLE', data: user?.roles?.[0] });
      }
    }
  };

  const signUpAuth = (Token, userInfo) => {
    const token = Token;
    const user = userInfo;

    if (token) {
      dispatch({ type: 'SET_SIGNUP_AUTH', data: true });
      dispatch({ type: 'SET_TOKEN', data: token });
      dispatch({ type: 'SET_CURRENT_USER', data: user });
    }
  };

  const defaultPageSize = () => {
    let pageSize = 10;
    // eslint-disable-next-line no-undef
    const height = window?.innerHeight - TABLE_HEIGHT_SEARCH;

    if (height) {
      if (height > 765) {
        pageSize = 15;
      }
      if (height > 1020) {
        pageSize = 20;
      }
      if (height > 2550) {
        pageSize = 50;
      }
      if (height > 5100) {
        pageSize = 100;
      }
    }
    dispatch({ type: 'SET_PAGE_SIZE', data: pageSize });
  };

  const getOnboardingData = () => state?.onBoardingData;

  const value = {
    state,
    dispatch,
    isAuthenticated,
    getToken,
    initializeAuth,
    defaultPageSize,
    getCurrentUserRole,
    getCurrentUser,
    signUpAuth,
    isSignupAuthenticate,
    getOnboardingData,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

const AppContextConsumer = AppContext?.Consumer;

export { AppContext, AppContextConsumer, AppContextProvider };
