import { useLazyQuery } from '@apollo/client';
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Empty,
  Form,
  Row,
  Switch,
  Tooltip,
} from 'antd';
import { debounce, map, uniqBy } from 'lodash';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import { SKIP_RECORD } from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import DatePickerComponent from '../../../components/DatePickerComponent';
import InputComponent from '../../../components/InputComponent';
import LoaderComponent from '../../../components/LoaderComponent';
import ModalComponent from '../../../components/ModalComponent';
import SelectComponent from '../../../components/SelectComponent';
import TextAreaComponent from '../../../components/TextAreaComponent';
import {
  GET_INDUSTRIES,
  GET_TENANTS,
  LINE_OF_BUSINESS,
} from '../graphql/Queries';
import EmailTable from './EmailTable';

let industryScrollDebounceJob;
let lineOfBusinessScrollDebounceJob;
let tenantScrollDebounceJob;

const { required } = formValidatorRules;

const { Option } = SelectComponent;

const industryFilter = {
  sortOn: 'label',
  sortBy: 'ASC',
  skip: 0,
  limit: 20,
  search: '',
  justShow: true,
};

const lineOfBusinessFilter = {
  sortOn: 'label',
  sortBy: 'ASC',
  skip: 0,
  limit: 20,
  search: '',
  justShow: true,
};

const tenantFilter = {
  sortOn: 'name',
  sortBy: 'ASC',
  skip: 0,
  limit: 20,
  search: '',
};

const CreateBatchModal = ({ showModal, setShowModal }) => {
  // other codes
  const [form] = Form?.useForm();
  // eslint-disable-next-line no-unused-vars
  const [isFormValuesChanged, setIsFormValuesChanged] = useState(false);
  const [validationTriggered, setValidationTriggered] = useState(false);
  const {
    state: { currentUser },
  } = useContext(AppContext);
  const [searchValue, setSearchValue] = useState('');

  const [industryLoading, setIndustryLoading] = useState(false);
  const [industrySearchFlag, setIndustrySearchFlag] = useState(false);
  const [industriesData, setIndustriesData] = useState([]);
  const [industryIsEnd, setIndustryIsEnd] = useState(false);
  const [industryDebounceCall, setIndustryDebounceCall] = useState(0);

  const [lineOfBusinessLoading, setLineOfBusinessLoading] = useState(false);
  const [lineOfBusinessSearchFlag, setLineOfBusinessSearchFlag] = useState(
    false,
  );
  const [lineOfBusinessesData, setLineOfBusinessesData] = useState([]);
  const [lineOfBusinessIsEnd, setLineOfBusinessIsEnd] = useState(false);
  const [lineOfBusinessDebounceCall, setLineOfBusinessDebounceCall] = useState(
    0,
  );
  const [disableLob, setDisableLob] = useState(true);

  const [tenantLoading, setTenantLoading] = useState(false);
  const [tenantSearchFlag, setTenantSearchFlag] = useState(false);
  const [tenantsData, setTenantsData] = useState([]);
  const [tenantIsEnd, setTenantIsEnd] = useState(false);
  const [tenantDebounceCall, setTenantDebounceCall] = useState(0);
  const [selectedItems, setSelectedItems] = useState([]);
  const [tenantIds, setTenantIds] = useState([]);
  const [disableTenant, setDisableTenant] = useState(true);

  const industryId = Form?.useWatch('industryId', form);
  const lineOfBusinessId = Form?.useWatch('lineOfBusinessId', form);

  const isSchedule = Form?.useWatch('isSchedule', form);

  const [industries] = useLazyQuery(GET_INDUSTRIES, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setIndustryIsEnd(res?.industries?.data?.length < SKIP_RECORD);
      if (industrySearchFlag) {
        setIndustriesData([...res?.industries?.data]);
      } else {
        // kept for future use
        // if (isEdit) {
        //   const tempIndustry = [
        //     productItemData?.industry,
        //     ...res?.industries?.data,
        //   ];
        //   setIndustriesData(uniqBy([...industriesData, ...tempIndustry], 'id'));
        //   return;
        // }
        setIndustriesData([...industriesData, ...res?.industries?.data]);
      }
      setIndustryLoading(false);
    },
    onError() {
      setIndustryLoading(false);
    },
  });

  const [lineOfBusinesses] = useLazyQuery(LINE_OF_BUSINESS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setLineOfBusinessIsEnd(res?.lineOfBusinesses?.data?.length < SKIP_RECORD);
      if (lineOfBusinessSearchFlag) {
        setLineOfBusinessesData([...res?.lineOfBusinesses?.data]);
      } else {
        // kept for future use
        // if (isEdit) {
        //   if (initialLobRender) {
        //     const tempLineOfBusiness = [
        //       productItemData?.lineOfBusiness,
        //       ...res?.lineOfBusinesses?.data,
        //     ];
        //     setLineOfBusinessesData(
        //       uniqBy([...lineOfBusinessesData, ...tempLineOfBusiness], 'id'),
        //     );
        //   } else {
        //     setLineOfBusinessesData([...res?.lineOfBusinesses?.data]);
        //   }
        //   setInitialLobRender(false);
        //   return;
        // }
        setLineOfBusinessesData([
          ...lineOfBusinessesData,
          ...res?.lineOfBusinesses?.data,
        ]);
      }
      setLineOfBusinessLoading(false);
    },
    onError() {
      setLineOfBusinessLoading(false);
    },
  });

  const [tenants] = useLazyQuery(GET_TENANTS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setIndustryIsEnd(res?.tenants?.data?.length < SKIP_RECORD);
      const allTenantIds = res?.tenants?.data?.map((tenant) => tenant?.id);
      setTenantIds([...tenantIds, ...allTenantIds]); // also may we need to add isEdit condition here
      if (selectedItems?.length > 0) {
        setSelectedItems([...selectedItems, ...allTenantIds]);
        form?.setFieldValue('tenantId', [...tenantIds, ...allTenantIds]);
      }
      if (tenantSearchFlag) {
        setTenantsData([...res?.tenants?.data]);
      } else {
        setTenantsData(uniqBy([...tenantsData, ...res?.tenants?.data], 'id'));
      }
      setTenantLoading(false);
    },
    onError() {
      setTenantLoading(false);
    },
  });

  useEffect(() => {
    if (industryId && lineOfBusinessId) {
      // need to pass lon and industryId here
      setTenantLoading(true);
      setDisableTenant(false);
      tenants({
        variables: {
          filter: tenantFilter,
          where: {
            isActive: true,
          },
        },
      });
    }
  }, [industryId, lineOfBusinessId]);

  useEffect(() => {
    industries({
      variables: {
        filter: industryFilter,
        where: {
          isActive: true,
          sponsorId: currentUser?.sponsorId,
        },
      },
    });
  }, []);

  const onTenantScroll = (event) => {
    setTenantSearchFlag(false);
    if (tenantScrollDebounceJob) {
      tenantScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    tenantScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !tenantIsEnd) {
        setTenantLoading(true);
        setTenantDebounceCall((prevState) => prevState + 1);
        tenants({
          variables: {
            filter: {
              ...tenantFilter,
              skip: (tenantDebounceCall + 1) * SKIP_RECORD,
              search: searchValue,
            },
            where: {
              isActive: true,
            },
          },
        });
      }
    }, 500);

    tenantScrollDebounceJob();
  };

  const handleTenantChange = (value) => {
    setTenantSearchFlag(true);
    setSearchValue(value);
    setTenantLoading(true);
    tenants({
      variables: {
        filter: {
          ...tenantFilter,
          search: value,
        },
        where: {
          isActive: true,
        },
      },
    });
  };

  const debouncedTenantHandler = useCallback(
    debounce(handleTenantChange, 500),
    [],
  );

  const handleTenantClear = () => {
    form?.setFieldsValue({
      industryId: null,
      lineOfBusinessId: null,
    });
    setDisableLob(true);
    setIndustriesData([]);
    setLineOfBusinessesData([]);
    tenants({
      variables: {
        filter: tenantFilter,
        where: {
          isActive: true,
        },
      },
    });
  };

  const handleTenantBlur = () => {
    setSearchValue('');
    setTenantDebounceCall(0);
    setTenantIsEnd(false);
  };

  const onIndustryScroll = (event) => {
    setIndustrySearchFlag(false);
    if (industryScrollDebounceJob) {
      industryScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    industryScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !industryIsEnd) {
        setIndustryLoading(true);
        setIndustryDebounceCall((prevState) => prevState + 1);
        industries({
          variables: {
            filter: {
              ...industryFilter,
              skip: (industryDebounceCall + 1) * SKIP_RECORD,
              search: searchValue,
            },
            where: {
              isActive: true,
              sponsorId: currentUser?.sponsorId,
            },
          },
        });
      }
    }, 500);

    industryScrollDebounceJob();
  };

  const handleIndustryChange = (value) => {
    setIndustrySearchFlag(true);
    setSearchValue(value);
    setIndustryLoading(true);
    industries({
      variables: {
        filter: {
          ...industryFilter,
          search: value,
        },
        where: {
          isActive: true,
          sponsorId: currentUser?.sponsorId,
        },
      },
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedIndustryHandler = useCallback(
    debounce(handleIndustryChange, 500),
    [],
  );

  const handleIndustryClear = () => {
    form?.setFieldsValue({
      industryId: null,
      lineOfBusinessId: null,
    });
    setDisableLob(true);
    setDisableTenant(true);
    setIndustriesData([]);
    setLineOfBusinessesData([]);
    industries({
      variables: {
        filter: industryFilter,
        where: {
          isActive: true,
          sponsorId: currentUser?.sponsorId,
        },
      },
    });
  };

  const handleIndustryBlur = () => {
    setSearchValue('');
    setIndustryDebounceCall(0);
    setIndustryIsEnd(false);
  };

  const handleIndustrySelect = (value) => {
    setDisableLob(false);
    setLineOfBusinessesData([]);
    form?.setFieldsValue({
      lineOfBusinessId: null,
    });
    setLineOfBusinessLoading(true);
    lineOfBusinesses({
      variables: {
        filter: { ...lineOfBusinessFilter, industryId: value },
        where: {
          isActive: true,
        },
      },
    });
  };

  const onLineOfBusinessScroll = (event) => {
    setLineOfBusinessSearchFlag(false);
    if (lineOfBusinessScrollDebounceJob) {
      lineOfBusinessScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    lineOfBusinessScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !lineOfBusinessIsEnd) {
        setLineOfBusinessLoading(true);
        setLineOfBusinessDebounceCall((prevState) => prevState + 1);
        lineOfBusinesses({
          variables: {
            filter: {
              ...lineOfBusinessFilter,
              skip: (lineOfBusinessDebounceCall + 1) * SKIP_RECORD,
              search: searchValue,
              industryId: form?.getFieldValue('industryId'),
            },
            where: {
              isActive: true,
            },
          },
        });
      }
    }, 500);

    lineOfBusinessScrollDebounceJob();
  };

  const handleLineOfBusinessChange = (value) => {
    setLineOfBusinessSearchFlag(true);
    setSearchValue(value);
    setLineOfBusinessLoading(true);
    lineOfBusinesses({
      variables: {
        filter: {
          ...lineOfBusinessFilter,
          industryId: form?.getFieldValue('industryId'),
          search: value,
        },
        where: {
          isActive: true,
        },
      },
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedLineOfBusinessHandler = useCallback(
    debounce(handleLineOfBusinessChange, 500),
    [],
  );

  const handleLineOfBusinessClear = () => {
    form?.setFieldsValue({
      lineOfBusinessId: null,
    });
    setLineOfBusinessesData([]);
    setDisableTenant(true);
    lineOfBusinesses({
      variables: {
        filter: {
          ...lineOfBusinessFilter,
          industryId: form?.getFieldValue('industryId'),
        },
        where: {
          isActive: true,
        },
      },
    });
  };

  const handleLineOfBusinessBlur = () => {
    setSearchValue('');
    setLineOfBusinessDebounceCall(0);
    setLineOfBusinessIsEnd(false);
  };

  const onCancel = () => {
    setShowModal(false);
  };

  const onFinish = async () => {};
  const onFinishFailed = () => {
    setValidationTriggered(true);
  };

  const disabledDate = (current) =>
    // Can not select days before today and today
    current && current < moment().endOf('day');

  const handleSelectAllChange = (e) => {
    if (e?.target?.checked) {
      setSelectedItems([...tenantIds]);
      form?.setFieldValue('tenantId', [...tenantIds]);
    } else {
      setSelectedItems([]);
      form?.setFieldValue('tenantId', null);
    }
  };

  return (
    <ModalComponent
      title="CREATE BATCH"
      width={1300}
      open={showModal}
      onCancel={onCancel}
      footer={
        <div className="d-flex justify-end width-80">
          <Button
            className="common-button cta-btn mr-16"
            onClick={() => setShowModal(false)}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            // loading={salesJunctionLoading} // kept for future use
            className="common-button cta-btn"
            // onClick={form?.submit()} // kept for future use
          >
            Create
          </Button>
        </div>
      }
      destroyOnClose
      wrapClassName="create-batch-modal"
    >
      <Form
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        scrollToFirstError={{ behavior: 'smooth', block: 'end' }}
        name="settingForm"
        layout="vertical"
        // initialValues={initialValue}
        validateTrigger={validationTriggered ? 'onChange' : 'onSubmit'}
        onValuesChange={() => {
          setIsFormValuesChanged(true);
        }}
      >
        <Row className="setting-content">
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Form.Item
              name="name"
              label="Name"
              rules={[{ ...required, message: 'Please Enter Name' }]}
            >
              <InputComponent allowClear placeholder="Enter Name" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label="Description"
              name="description"
              className="label-with-tooltip"
              rules={[{ ...required, message: 'Please Enter Description' }]}
            >
              <TextAreaComponent
                rows={5}
                cols={5}
                placeholder="Enter Description"
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              name="industryId"
              label="Industry"
              rules={[
                {
                  required,
                  message: 'Please Select Industry',
                },
              ]}
            >
              <SelectComponent
                placeholder="Select Industry"
                allowClear
                notFoundContent={
                  industryLoading ? (
                    <LoaderComponent size="small" setHeight={10} />
                  ) : (
                    <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                  )
                }
                onBlur={handleIndustryBlur}
                onSearch={debouncedIndustryHandler}
                onClear={handleIndustryClear}
                onPopupScroll={onIndustryScroll}
                onSelect={handleIndustrySelect}
              >
                {map(industriesData, (item) => (
                  <Option key={item?.id} value={item?.id}>
                    {item?.label}
                  </Option>
                ))}
              </SelectComponent>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="lineOfBusinessId"
              label="Line of Business"
              rules={[
                {
                  required,
                  message: 'Please Select Line Of Business',
                },
              ]}
            >
              <SelectComponent
                showSearch
                placeholder="Select Line Of Business"
                allowClear
                disabled={disableLob}
                notFoundContent={
                  lineOfBusinessLoading ? (
                    <LoaderComponent size="small" setHeight={10} />
                  ) : (
                    <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                  )
                }
                onBlur={handleLineOfBusinessBlur}
                onSearch={debouncedLineOfBusinessHandler}
                onClear={handleLineOfBusinessClear}
                onPopupScroll={onLineOfBusinessScroll}
              >
                {map(lineOfBusinessesData, (item) => (
                  <Option key={item?.id} value={item?.id}>
                    {item?.label}
                  </Option>
                ))}
              </SelectComponent>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="tenantId"
              label="Tenants"
              allowClear
              rules={[
                {
                  required,
                  message: 'Please Select Tenants',
                  type: 'array',
                },
              ]}
            >
              <SelectComponent
                mode="multiple"
                disabled={disableTenant}
                maxTagCount="responsive"
                showSearch
                placeholder="Select Tenants"
                allowClear
                notFoundContent={
                  tenantLoading ? (
                    <LoaderComponent size="small" setHeight={10} />
                  ) : (
                    <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                  )
                }
                onBlur={handleTenantBlur}
                onSearch={debouncedTenantHandler}
                onClear={handleTenantClear}
                onPopupScroll={onTenantScroll}
                // tagRender={tagRender}
                dropdownRender={(menu) => (
                  <>
                    <Checkbox
                      className="common-checkbox select-all-tenants"
                      checked={selectedItems.length === tenantsData.length}
                      indeterminate={
                        selectedItems.length > 0 &&
                        selectedItems.length < tenantsData.length
                      }
                      onChange={handleSelectAllChange}
                    >
                      Select All
                    </Checkbox>
                    {menu}
                  </>
                )}
                maxTagPlaceholder={(omittedValues) => (
                  <Tooltip
                    className="cursor-pointer"
                    title={omittedValues?.map(({ label }) => label)?.join(', ')}
                  >
                    <span>Hover Me</span>
                  </Tooltip>
                )}
              >
                {map(tenantsData, (item) => (
                  <Option key={item?.id} value={item?.id}>
                    {item?.name}
                  </Option>
                ))}
              </SelectComponent>
            </Form.Item>
          </Col>
        </Row>

        <span className="form-divider-text">SCHEDULE</span>
        <Divider className="form-divider" />

        <Row>
          <Col span={24}>
            <span className="description-text">
              Select the future date to schedule this batch
            </span>
          </Col>
          <Col span={24}>
            <Form.Item name="isSchedule">
              <Switch />
            </Form.Item>
          </Col>
          {isSchedule && (
            <Col span={6}>
              <Form.Item
                name="scheduleDate"
                label="Schedule Date"
                rules={[
                  {
                    required,
                    message: 'Please Select Schedule Date',
                  },
                ]}
              >
                <DatePickerComponent
                  format="YYYY-MM-DD HH:mm:ss"
                  disabledDate={disabledDate}
                />
              </Form.Item>
            </Col>
          )}
        </Row>

        <span className="form-divider-text">AUTOMATED EMAIL NOTIFICATIONS</span>
        <Divider className="form-divider" />
        <Row>
          <Col span={24}>
            <span className="description-text">
              Enables automated email notification on job success/failure . A
              maximum of 5 email recipients can be added.
            </span>
          </Col>
          <Col span={24}>
            <Form.Item name="isEmailEnable">
              <Switch />
            </Form.Item>
          </Col>
        </Row>

        <span className="form-divider-text">EMAIL RECIPIENTS</span>
        <Divider className="form-divider" />

        <Row>
          <Col span={24}>
            <span className="description-text">
              These email recipients will be used by both automated and code
              based email notifications.
            </span>
          </Col>
        </Row>

        <EmailTable form={form} />
      </Form>
    </ModalComponent>
  );
};
export default CreateBatchModal;
