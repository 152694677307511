import { InfoCircleOutlined } from '@ant-design/icons';
import { Form, TimePicker } from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef } from 'react';
import {
  DATE_TIME_VALIDATION,
  DEFAULT_DATE_FORMAT,
} from '../../../../common/constants';
import DatePickerComponent from '../../../../components/DatePickerComponent';
import RangePickerComponent from '../../../../components/RangePickerComponent';
import './renderDateTime.less';

const { RangePicker } = TimePicker;
const RenderDateTime = ({ dateTimeData, form, editData = null }) => {
  let defaultValue;
  const rangePickerRef = useRef();
  const datePickerRef = useRef();
  const { config, label = '', tooltip = '', key = '' } = dateTimeData;
  const {
    DATE_TIME: {
      dateDefaultValue,
      rules: {
        hidden,
        hideLabel,
        readOnly: fieldReadOnly,
        required: fieldRequired,
      } = {},
      dateConfig: { exactDate } = {},
      dateTimeType = [],
      format = DEFAULT_DATE_FORMAT,
      allowDateTime = [],
      rangeDefaultValue,
      dateTimeBooleanValue,
      timeDefaultValue,
    } = {},
  } = config || {};

  const disabledTime = () => {
    const currentHour = moment()?.hour();
    const currentMinute = moment()?.minute();
    return {
      disabledHours: () => {
        const hours = [];
        // eslint-disable-next-line no-plusplus
        for (let i = 1; i <= 24; i++) {
          if (
            allowDateTime?.includes(DATE_TIME_VALIDATION.ALLOW_FUTURE) &&
            allowDateTime?.includes(DATE_TIME_VALIDATION.ALLOW_PAST)
          ) {
            return [];
          }
          if (
            allowDateTime?.includes(DATE_TIME_VALIDATION.ALLOW_FUTURE) &&
            i < currentHour
          ) {
            hours?.push(i);
          } else if (
            allowDateTime?.includes(DATE_TIME_VALIDATION.ALLOW_PAST) &&
            i > currentHour
          ) {
            hours?.push(i);
          }
        }
        return hours;
      },
      disabledMinutes: (selectedHour) => {
        const minutes = [];
        if (selectedHour === currentHour) {
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i <= 59; i++) {
            if (
              allowDateTime?.includes(DATE_TIME_VALIDATION.ALLOW_FUTURE) &&
              allowDateTime?.includes(DATE_TIME_VALIDATION.ALLOW_PAST)
            ) {
              return [];
            }
            if (
              allowDateTime?.includes(DATE_TIME_VALIDATION.ALLOW_FUTURE) &&
              i < currentMinute
            ) {
              minutes?.push(i);
            } else if (
              allowDateTime?.includes(DATE_TIME_VALIDATION.ALLOW_PAST) &&
              i > currentMinute
            ) {
              minutes?.push(i);
            }
          }
        }
        return minutes;
      },
    };
  };

  const renderPicker = () => {
    if (dateTimeType?.includes('DATE') && dateTimeType?.includes('TIME')) {
      if (exactDate) {
        if (dateTimeBooleanValue) {
          defaultValue = moment();
        } else {
          defaultValue = null;
        }
        return (
          <DatePickerComponent
            showTime
            dateTimeFormat={format}
            defaultValue={defaultValue}
            disabled={fieldReadOnly}
            use12Hours
            disabledDate={!(allowDateTime?.length > 1)}
            disableFutureDate={allowDateTime?.includes(
              DATE_TIME_VALIDATION.ALLOW_PAST,
            )}
            disablePastDate={allowDateTime?.includes(
              DATE_TIME_VALIDATION.ALLOW_FUTURE,
            )}
            disabledTime={disabledTime}
            isCurrentDateAllowed
          />
        );
      }
      if (rangeDefaultValue) {
        if (allowDateTime?.includes(DATE_TIME_VALIDATION.ALLOW_FUTURE)) {
          defaultValue = [
            moment()?.startOf('day'),
            moment()?.add(rangeDefaultValue, 'days')?.endOf('day'),
          ];
        } else {
          defaultValue = [
            moment()?.startOf('day'),
            moment()?.add(-rangeDefaultValue, 'days')?.endOf('day'),
          ];
        }
      }
      return (
        <RangePickerComponent
          showTime
          className="common-range-picker fill-width"
          popupClassName="common-range-picker"
          disabled={fieldReadOnly}
          defaultValue={defaultValue}
          dateTimeFormat={format}
          disabledDate={!(allowDateTime?.length > 1)}
          disableFutureDate={
            allowDateTime?.includes(DATE_TIME_VALIDATION.ALLOW_PAST) &&
            !allowDateTime?.includes(DATE_TIME_VALIDATION.ALLOW_FUTURE)
          }
          disablePastDate={
            allowDateTime?.includes(DATE_TIME_VALIDATION.ALLOW_FUTURE) &&
            !allowDateTime?.includes(DATE_TIME_VALIDATION.ALLOW_PAST)
          }
          isCurrentDateAllowed
        />
      );
    }
    if (dateTimeType?.includes('DATE')) {
      if (exactDate) {
        if (dateDefaultValue === 'TODAY') {
          defaultValue = moment();
        } else if (dateDefaultValue === 'TOMORROW') {
          defaultValue = moment()?.add(1, 'days');
        } else {
          defaultValue = null;
        }
        return (
          <DatePickerComponent
            datePickerRef={datePickerRef}
            dateFormat={format}
            disabledDate={!(allowDateTime?.length > 1)}
            disableFutureDate={allowDateTime?.includes(
              DATE_TIME_VALIDATION.ALLOW_PAST,
            )}
            disablePastDate={allowDateTime?.includes(
              DATE_TIME_VALIDATION.ALLOW_FUTURE,
            )}
            disabled={fieldReadOnly}
            defaultValue={defaultValue}
            onChange={(value) => {
              datePickerRef.current.value = value;
            }}
            isCurrentDateAllowed
          />
        );
      }
      if (rangeDefaultValue) {
        if (allowDateTime?.includes(DATE_TIME_VALIDATION.ALLOW_FUTURE)) {
          defaultValue = [moment(), moment()?.add(rangeDefaultValue, 'days')];
        } else {
          defaultValue = [moment(), moment()?.add(-rangeDefaultValue, 'days')];
        }
      }

      return (
        <RangePickerComponent
          refs={rangePickerRef}
          dateFormat={format}
          className="common-range-picker fill-width"
          popupClassName="common-range-picker"
          disabledDate={!(allowDateTime?.length > 1)}
          disableFutureDate={
            allowDateTime?.includes(DATE_TIME_VALIDATION.ALLOW_PAST) &&
            !allowDateTime?.includes(DATE_TIME_VALIDATION.ALLOW_FUTURE)
          }
          disablePastDate={
            allowDateTime?.includes(DATE_TIME_VALIDATION.ALLOW_FUTURE) &&
            !allowDateTime?.includes(DATE_TIME_VALIDATION.ALLOW_PAST)
          }
          disabled={fieldReadOnly}
          defaultValue={defaultValue}
          onChange={(values) => {
            const rangeObj = [moment(values?.[0]), moment(values?.[1])];
            rangePickerRef.current.value = rangeObj;
          }}
          isCurrentDateAllowed
        />
      );
    }
    if (dateTimeType?.includes('TIME')) {
      if (exactDate) {
        if (dateTimeBooleanValue) {
          defaultValue = moment();
        } else {
          defaultValue = null;
        }

        return (
          <TimePicker
            className="common-time-picker fill-width custom-picker"
            format={format}
            use12Hours
            disabledTime={disabledTime}
            disabled={fieldReadOnly}
            defaultValue={defaultValue}
          />
        );
      }
      if (timeDefaultValue) {
        defaultValue = [
          moment(timeDefaultValue[0]),
          moment(timeDefaultValue[1]),
        ];
      } else {
        defaultValue = null;
      }
      return (
        <RangePicker
          className="common-range-picker custom-picker"
          use12Hours
          format={format}
          defaultValue={defaultValue}
          disabled={fieldReadOnly}
          disabledTime={disabledTime}
        />
      );
    }
  };

  useEffect(() => {
    if (editData) {
      if (editData?.[key]) {
        if (isEmpty(editData?.[key])) {
          form?.setFieldsValue({
            dynamicFields: {
              [key]: {
                dateTime: {
                  [format]: null,
                },
              },
            },
          });
        } else if (editData?.[key] && editData?.[key]?.length > 1) {
          form?.setFieldsValue({
            dynamicFields: {
              [key]: {
                dateTime: {
                  [format]: [
                    moment(editData?.[key]?.[0]),
                    moment(editData?.[key]?.[1]),
                  ],
                },
              },
            },
          });
        } else if (editData?.[key]) {
          form?.setFieldsValue({
            dynamicFields: {
              [key]: {
                dateTime: {
                  [format]: moment(editData?.[key]?.[0]),
                },
              },
            },
          });
        }
      } else {
        form?.setFieldsValue({
          dynamicFields: {
            [key]: {
              dateTime: {
                [format]: defaultValue,
              },
            },
          },
        });
      }
    } else {
      form?.setFieldsValue({
        dynamicFields: {
          [key]: {
            dateTime: {
              [format]: defaultValue,
            },
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, editData]);

  return (
    <Form.Item
      label={hideLabel ? ' ' : label}
      name={['dynamicFields', key, 'dateTime', format]}
      rules={[{ required: fieldRequired, message: 'Please Enter Date/Time' }]}
      hidden={hidden}
      tooltip={
        tooltip && {
          title: tooltip,
          icon: <InfoCircleOutlined />,
        }
      }
      className={`${tooltip && 'label-with-tooltip'}${hidden ? 'd-none' : ''}`}
      initialValue={defaultValue}
    >
      {renderPicker()}
    </Form.Item>
  );
};

export default RenderDateTime;
