import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, Radio, Row, Select } from 'antd';
import { map } from 'lodash';
import React from 'react';
import InputComponent from '../../../components/InputComponent';
import SelectComponent from '../../../components/SelectComponent';
import TextAreaComponent from '../../../components/TextAreaComponent';

const { Option } = Select;

const analyticsType = [
  {
    label: 'Google',
    value: 'GOOGLE',
  },
  {
    label: 'Hotjar',
    value: 'HOTJAR',
  },
  {
    label: 'Custom',
    value: 'CUSTOM',
  },
];

const appendTo = [
  {
    label: 'Head',
    value: 'HEAD',
  },
  {
    label: 'body',
    value: 'BODY',
  },
];

const AnalyticsFormItems = ({ form, field, remove }) => {
  const getCurrentType = Form?.useWatch(
    ['analyticsServiceProviders', field.name, 'name'],
    form,
  );
  return (
    <Row gutter={[8, 8]} className="setting-content">
      <Form.Item
        {...field}
        label="Code"
        hidden
        name={[field.name, 'name', 'id']}
      >
        <InputComponent />
      </Form.Item>
      <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
        <Form.Item
          {...field}
          label="Type"
          name={[field.name, 'name']}
          rules={[
            {
              required: true,
              message: 'Please Select Type',
            },
          ]}
        >
          <SelectComponent>
            {analyticsType.map((item) => (
              <Option key={item?.value} value={item?.value}>
                {item?.label}
              </Option>
            ))}
          </SelectComponent>
        </Form.Item>
      </Col>
      {getCurrentType === 'CUSTOM' && (
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <Form.Item
            {...field}
            label="Location"
            name={[field.name, 'code', 'location']}
            rules={[
              {
                required: true,
                message: 'Please Select Location',
              },
            ]}
          >
            <Radio.Group className="common-radio">
              {map(appendTo, (item) => (
                <Radio key={item?.value} value={item?.value}>
                  {item?.label}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
        </Col>
      )}
      {getCurrentType !== 'CUSTOM' && (
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <Form.Item
            {...field}
            label={getCurrentType === 'GOOGLE' ? 'GTM Code' : 'Hotjar Site Id'}
            name={[field.name, 'code', 'data']}
            rules={[
              {
                required: true,
                message: 'Please Enter Code',
              },
              // commented for future use
              // getCurrentType === 'GOOGLE' && {
              //   validator(_, value) {
              //     if (!REGEX.GTM_CODE?.test(value)) {
              //       // eslint-disable-next-line prefer-promise-reject-errors
              //       return Promise?.reject('Should Be Valid GTM Code');
              //     }
              //     return Promise?.resolve();
              //   }
              // },
              // getCurrentType === 'HOTJAR' && {
              //   validator(_, value) {
              //     if (!REGEX.HOTJAR_CODE.test(value)) {
              //       // eslint-disable-next-line prefer-promise-reject-errors
              //       return Promise?.reject('Should Be Valid HOTJAR Code');
              //     }
              //     return Promise?.resolve();
              //   }
              // }
            ]}
          >
            <InputComponent
              placeholder={
                getCurrentType === 'GOOGLE' ? 'GTM-XXXXXX' : 'XXXXXXX'
              }
            />
          </Form.Item>
        </Col>
      )}

      <Col className="d-flex align-end justify-end">
        <Button
          onClick={() => remove(field.name)}
          type="primary"
          className="b-0"
        >
          <MinusCircleOutlined />
          Remove
        </Button>
      </Col>
      {getCurrentType === 'CUSTOM' && (
        <Col span={24}>
          <Form.Item
            {...field}
            label="Code"
            name={[field.name, 'code', 'data']}
            className="label-with-tooltip"
            tooltip="Please ensure that no malicious code is included inside the script tag."
            rules={[
              {
                required: true,
                message: 'Please Enter Code',
              },
            ]}
          >
            <TextAreaComponent
              rows={5}
              cols={5}
              placeholder="<script> //some code here</script>"
            />
          </Form.Item>
        </Col>
      )}
    </Row>
  );
};

const AnalyticsForm = ({ form }) => (
  <Card className="card-wrapper">
    <div className="setting-content-heading">
      <h5>Advance Options</h5>
      <span className="panel-description">
        Use the tags and code to add to your questionnaire to track your lead
      </span>
    </div>

    <Form.List name="analyticsServiceProviders">
      {(fields, { add, remove, getFieldValue }) => (
        <>
          {fields?.map((field) => (
            <AnalyticsFormItems
              form={form}
              key={field.key}
              field={field}
              remove={remove}
              getFieldValue={getFieldValue}
            />
          ))}

          {fields?.length < 5 && (
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                className="mt-8"
                icon={<PlusOutlined />}
              >
                Add
              </Button>
            </Form.Item>
          )}
        </>
      )}
    </Form.List>
  </Card>
);

export default AnalyticsForm;
