import { CheckOutlined, CloseOutlined, UpOutlined } from '@ant-design/icons';
import { Collapse, Divider, Form, Radio, Switch } from 'antd';
import { map } from 'lodash';
import React from 'react';
import {
  CUSTOM_WIDGET_PROPS,
  DYNAMIC_FIELDS_DATA,
  DYNAMIC_FIELDS_DATA_KEYS,
  PICK_LIST_STYLE,
} from '../../../../../../common/constants';
import { formValidatorRules } from '../../../../../../common/utils';
import InputComponent from '../../../../../../components/InputComponent';
import SelectComponent from '../../../../../../components/SelectComponent';
import CustomBooleanForm from '../components/boolean/form';
import CustomDateTimeForm from '../components/dateTime/form';
import CustomNumberFieldForm from '../components/number/form';
import CustomPickListForm from '../components/pickList/form';
import CustomTextFieldForm from '../components/text/form';
import CustomUploadForm from '../components/upload/form';

const { requiredWhiteSpaceAllowed } = formValidatorRules;

const CustomWidgetForm = ({
  form,
  setFormValues,
  formValues,
  checkedList,
  setCheckedList,
  validationTriggered,
  setDisableBtn,
  showCtaButton,
}) => {
  const fieldType = Form?.useWatch(['widgetConfiguration', 'fieldType'], form);

  const showDescription = Form?.useWatch(
    ['widgetConfiguration', 'config', 'description'],
    form,
  );

  const isMultiSelectionEnabled = Form?.useWatch(
    ['widgetConfiguration', 'config', 'multiSelection'],
    form,
  );

  const hideContinueButton = Form?.useWatch(
    ['widgetConfiguration', 'config', 'hideContinueButton'],
    form,
  );

  const handleFieldTypeChange = (value) => {
    switch (value) {
      case DYNAMIC_FIELDS_DATA_KEYS?.TEXT:
        form?.setFieldsValue({
          widgetConfiguration: {
            ...formValues?.widgetConfiguration,
            fieldType: value,
            config: {
              ...formValues?.widgetConfiguration?.config,
              rules: {
                readOnly: false,
                required: false,
                hideLabel: false,
              },
              textType: 'SINGLE_LINE',
              textLength: {
                minLength: 1,
                maxLength: 255,
              },
              defaultCheck: false,
              default: '',
              tooltip: false,
              tooltipValue: '',
              description: false,
              descriptionValue: '',
            },
          },
        });
        setFormValues({
          ...formValues,
          widgetConfiguration: {
            ...formValues?.widgetConfiguration,
            fieldType: value,
            config: {
              ...formValues?.widgetConfiguration?.config,
              rules: {
                readOnly: false,
                required: false,
                hideLabel: false,
              },
              textType: 'SINGLE_LINE',
              textLength: {
                minLength: 1,
                maxLength: 255,
              },
              defaultCheck: false,
              default: '',
              tooltip: false,
              tooltipValue: '',
              description: false,
              descriptionValue: '',
            },
          },
        });
        break;
      case DYNAMIC_FIELDS_DATA_KEYS?.DATE_TIME:
        form?.setFieldsValue({
          widgetConfiguration: {
            ...formValues?.widgetConfiguration,
            fieldType: value,
            config: {
              ...formValues?.widgetConfiguration?.config,
              rules: {
                readOnly: false,
                required: false,
                hideLabel: false,
              },
              dateTimeType: {
                DATE: true,
                TIME: false,
              },
              allowDateTime: {
                ALLOW_FUTURE: true,
                ALLOW_PAST: false,
              },
              dateDefaultValue: 'TODAY',
              dateConfig: 'EXACT',
              tooltip: false,
              tooltipValue: '',
              description: false,
              descriptionValue: '',
            },
          },
        });
        setFormValues({
          ...formValues,
          widgetConfiguration: {
            ...formValues?.widgetConfiguration,
            fieldType: value,
            config: {
              ...formValues?.widgetConfiguration?.config,
              rules: {
                readOnly: false,
                required: false,
                hideLabel: false,
              },
              dateTimeType: {
                DATE: true,
                TIME: false,
              },
              allowDateTime: {
                ALLOW_FUTURE: true,
                ALLOW_PAST: false,
              },
              dateDefaultValue: 'TODAY',
              dateConfig: 'EXACT',
              tooltip: false,
              tooltipValue: '',
              description: false,
              descriptionValue: '',
            },
          },
        });
        break;
      case DYNAMIC_FIELDS_DATA_KEYS?.BOOLEAN:
        form?.setFieldsValue({
          widgetConfiguration: {
            ...formValues?.widgetConfiguration,
            fieldType: value,
            config: {
              ...formValues?.widgetConfiguration?.config,
              rules: {
                readOnly: false,
                required: false,
                hideLabel: false,
              },
              trueValue: '',
              falseValue: '',
              tooltip: false,
              tooltipValue: '',
              description: false,
              descriptionValue: '',
              booleanDefaultValue: true,
            },
          },
        });
        setFormValues({
          ...formValues,
          widgetConfiguration: {
            ...formValues?.widgetConfiguration,
            fieldType: value,
            config: {
              ...formValues?.widgetConfiguration?.config,
              rules: {
                readOnly: false,
                required: false,
                hideLabel: false,
              },
              trueValue: '',
              falseValue: '',
              tooltip: false,
              tooltipValue: '',
              description: false,
              descriptionValue: '',
              booleanDefaultValue: true,
            },
          },
        });
        break;
      case DYNAMIC_FIELDS_DATA_KEYS?.PICK_LIST:
        form?.setFieldsValue({
          widgetConfiguration: {
            ...formValues?.widgetConfiguration,
            fieldType: value,
            config: {
              ...formValues?.widgetConfiguration?.config,
              rules: {
                hidden: false,
                readOnly: false,
                required: false,
                hideLabel: false,
              },
              displayStyle: 'TILES',
              label: 'Serial Number',
              images: true,
              imagePreview: true,
              description: true,
              descriptionValue: null,
              multiSelection: false,
              hideContinueButton: false,
              listItems: [
                {
                  image: [],
                  default: false,
                  label: null,
                  blockDescription: null,
                },
              ],
              tooltip: false,
              tooltipValue: '',
              optionsDescription: false,
            },
          },
        });
        setFormValues({
          ...formValues,
          widgetConfiguration: {
            ...formValues?.widgetConfiguration,
            fieldType: value,
            config: {
              ...formValues?.widgetConfiguration?.config,
              rules: {
                hidden: false,
                readOnly: false,
                required: false,
                hideLabel: false,
              },
              displayStyle: 'TILES',
              label: 'Serial Number',
              images: true,
              imagePreview: true,
              description: true,
              descriptionValue: null,
              multiSelection: false,
              hideContinueButton: false,
              listItems: [
                {
                  image: [],
                  default: false,
                  label: null,
                  blockDescription: null,
                },
              ],
              optionsDescription: false,
            },
          },
        });
        break;
      case DYNAMIC_FIELDS_DATA_KEYS?.UPLOAD:
        form?.setFieldsValue({
          widgetConfiguration: {
            ...formValues?.widgetConfiguration,
            fieldType: value,
            config: {
              ...formValues?.widgetConfiguration?.config,
              rules: {
                readOnly: false,
                required: false,
                hideLabel: false,
              },
              fileType: 'DOCS',
              tooltip: false,
              tooltipValue: '',
              description: false,
              descriptionValue: '',
              maxFile: 10,
              size: 50,
              allowMultiple: false,
              limitFileSize: false,
            },
          },
        });
        setFormValues({
          ...formValues,
          widgetConfiguration: {
            ...formValues?.widgetConfiguration,
            fieldType: value,
            config: {
              ...formValues?.widgetConfiguration?.config,
              rules: {
                readOnly: false,
                required: false,
                hideLabel: false,
              },
              fileType: 'DOCS',
              tooltip: false,
              tooltipValue: '',
              description: false,
              descriptionValue: '',
              maxFile: 10,
              size: 50,
              allowMultiple: false,
              limitFileSize: false,
            },
          },
        });
        break;
      case DYNAMIC_FIELDS_DATA_KEYS?.NUMBER:
        form?.setFieldsValue({
          widgetConfiguration: {
            ...formValues?.widgetConfiguration,
            fieldType: value,
            config: {
              ...formValues?.widgetConfiguration?.config,
              rules: {
                readOnly: false,
                required: false,
                hideLabel: false,
              },
              label: '',
              range: {
                maxNumber: 100,
                minNumber: 1,
              },
              decimalPlace: 0,
              defaultCheck: false,
              default: '',
              tooltip: false,
              tooltipValue: '',
              description: false,
              descriptionValue: '',
            },
          },
        });
        setFormValues({
          ...formValues,
          widgetConfiguration: {
            ...formValues?.widgetConfiguration,
            fieldType: value,
            config: {
              ...formValues?.widgetConfiguration?.config,
              rules: {
                readOnly: false,
                required: false,
                hideLabel: false,
              },
              label: '',
              range: {
                maxNumber: 100,
                minNumber: 1,
              },
              decimalPlace: 0,
              defaultCheck: false,
              default: '',
              tooltip: false,
              tooltipValue: '',
              description: false,
              descriptionValue: '',
            },
          },
        });
        break;
      default:
        break;
    }
  };

  const settingCollapseItems = [
    {
      key: 'SETTINGS',
      forceRender: true,
      label: 'Settings',
      children: (
        <>
          {fieldType === DYNAMIC_FIELDS_DATA_KEYS?.TEXT && (
            <CustomTextFieldForm
              form={form}
              setFormValues={setFormValues}
              formValues={formValues}
            />
          )}
          {fieldType === DYNAMIC_FIELDS_DATA_KEYS?.DATE_TIME && (
            <CustomDateTimeForm form={form} />
          )}
          {fieldType === DYNAMIC_FIELDS_DATA_KEYS?.BOOLEAN && (
            <CustomBooleanForm form={form} />
          )}
          {fieldType === DYNAMIC_FIELDS_DATA_KEYS?.PICK_LIST && (
            <CustomPickListForm
              form={form}
              setFormValues={setFormValues}
              formValues={formValues}
              setDisableBtn={setDisableBtn}
            />
          )}
          {fieldType === DYNAMIC_FIELDS_DATA_KEYS?.UPLOAD && (
            <CustomUploadForm
              form={form}
              checkedList={checkedList}
              setCheckedList={setCheckedList}
              validationTriggered={validationTriggered}
              setDisableBtn={setDisableBtn}
            />
          )}
          {fieldType === DYNAMIC_FIELDS_DATA_KEYS?.NUMBER && (
            <CustomNumberFieldForm form={form} />
          )}
        </>
      ),
    },
  ];
  return (
    <>
      <div className="section-header mt-16">
        <span className="section-title">Custom Field Widget</span>
      </div>
      <Form.Item
        label="Field Type"
        name={['widgetConfiguration', 'fieldType']}
        rules={[
          {
            ...requiredWhiteSpaceAllowed,
            message: 'Please Select Field Type',
          },
        ]}
      >
        <SelectComponent
          showSearch={false}
          placeholder="Select Field Type"
          onChange={handleFieldTypeChange}
        >
          {map(DYNAMIC_FIELDS_DATA, (field) => (
            <SelectComponent.Option key={field?.key} value={field?.key}>
              {field?.label}
            </SelectComponent.Option>
          ))}
        </SelectComponent>
      </Form.Item>

      {map(CUSTOM_WIDGET_PROPS, (item) => {
        if (
          fieldType === DYNAMIC_FIELDS_DATA_KEYS?.UPLOAD &&
          item?.key === 'READ_ONLY'
        ) {
          return;
        }
        return (
          <div
            className="d-flex justify-between align-center"
            key={item?.key}
            hidden={!fieldType}
          >
            <span className="primary-color">{item?.label}</span>
            <Form.Item
              key={item?.key}
              name={['widgetConfiguration', 'config', 'rules', `${item?.name}`]}
              className="mb-0"
              valuePropName="checked"
            >
              <Switch
                className="common-switch"
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Form.Item>
          </div>
        );
      })}
      <Divider />
      {fieldType === DYNAMIC_FIELDS_DATA_KEYS?.PICK_LIST && (
        <div id="pick-list-form-items">
          <Form.Item
            label="Display Style"
            name={['widgetConfiguration', 'config', 'displayStyle']}
            rules={[
              {
                required: true,
                message: 'Please Select Display Style',
              },
            ]}
          >
            <Radio.Group className="common-radio">
              {map(PICK_LIST_STYLE, (item) => (
                <Radio key={item?.value} value={item?.value}>
                  {item?.label}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
          <div className="d-flex justify-between align-center">
            <span className="switch-logo">Images</span>
            <Form.Item
              valuePropName="checked"
              className="mb-0"
              name={['widgetConfiguration', 'config', 'images']}
            >
              <Switch
                className="common-switch"
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Form.Item>
          </div>
          <div className="d-flex justify-between align-center">
            <span className="switch-logo">Images Preview</span>
            <Form.Item
              valuePropName="checked"
              className="mb-0"
              name={['widgetConfiguration', 'config', 'imagePreview']}
            >
              <Switch
                className="common-switch"
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Form.Item>
          </div>
          <div className="d-flex justify-between align-center">
            <span className="switch-logo">Descriptions</span>
            <Form.Item
              valuePropName="checked"
              className="mb-0"
              name={['widgetConfiguration', 'config', 'description']}
            >
              <Switch
                className="common-switch"
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Form.Item>
          </div>
          <Form.Item
            name={['widgetConfiguration', 'config', 'descriptionValue']}
            hidden={!showDescription}
            rules={[
              {
                required: showDescription,
                message: 'Please Enter Description',
              },
            ]}
          >
            <InputComponent placeholder="Enter Description" />
          </Form.Item>
          <div className="d-flex justify-between align-center">
            <span className="switch-logo">Multiselection</span>
            <Form.Item
              valuePropName="checked"
              className="mb-0"
              name={['widgetConfiguration', 'config', 'multiSelection']}
            >
              <Switch
                disabled={hideContinueButton || !showCtaButton}
                className="common-switch"
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Form.Item>
          </div>
          <div className="d-flex justify-between align-center">
            <span className="switch-logo">Hide Continue Button</span>
            <Form.Item
              valuePropName="checked"
              className="mb-0"
              name={['widgetConfiguration', 'config', 'hideContinueButton']}
            >
              <Switch
                disabled={isMultiSelectionEnabled}
                className="common-switch"
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Form.Item>
          </div>
        </div>
      )}

      {fieldType && (
        <Collapse
          className="common-collapse questionnaire-collapse custom-collapse"
          expandIconPosition="end"
          expandIcon={({ isActive }) => (
            <UpOutlined rotate={isActive ? 0 : 180} />
          )}
          defaultActiveKey={['SETTINGS']}
          ghost
          items={settingCollapseItems}
        />
      )}
    </>
  );
};

export default CustomWidgetForm;
