import React from 'react';
import AddOnWidgetForm from './widgetComponents/addOnWidget/form';
import AppointmentWidgetForm from './widgetComponents/appointmentWidget/form';
import CartWidgetForm from './widgetComponents/cartWidget/form';
import ContactWidgetForm from './widgetComponents/contactWidget/form';
import ContentWidgetForm from './widgetComponents/contentWidget/form';
import CustomerInfoForm from './widgetComponents/customerInfo/form';
import CustomWidgetForm from './widgetComponents/customWidget/form';
import DimensionWidgetForm from './widgetComponents/dimensionWidget/form';
import PostalWidgetForm from './widgetComponents/postalWidget/form';
import ProjectWidgetForm from './widgetComponents/projectWidget/form';
import QuoteWidgetForm from './widgetComponents/quoteWidget/form';
import SupportWidgetForm from './widgetComponents/supportWidget/form';
import SystemWidgetForm from './widgetComponents/systemFieldWidget/form';

const RenderWidget = ({
  widgetType = '',
  form,
  setSelectedLob,
  selectedLob = null,
  setSelectedTab,
  selectedTab = '',
  selectedAppointmentKey = '',
  setSelectedAppointmentKey,
  fieldObject = null,
  setFieldObject,
  systemFieldData = null,
  setSystemFieldData,
  setFormValues,
  formValues = null,
  checkedList = [],
  setCheckedList,
  validationTriggered,
  setDisableBtn,
  refetchSystemFields = false,
  setRefetchSystemFields,
  setSelectedDimensionTab,
  selectedDimensionTab = null,
  setSelectedDimensionProject,
  selectedDimensionProject = null,
  showCtaButton = true,
  widgetConfiguration = null,
}) => {
  switch (widgetType) {
    case 'POSTAL':
      return <PostalWidgetForm />;

    case 'SUPPORT':
      return <SupportWidgetForm />;

    case 'PROJECT':
      return (
        <ProjectWidgetForm
          form={form}
          setSelectedLob={setSelectedLob}
          selectedLob={selectedLob}
        />
      );

    case 'CART':
      return <CartWidgetForm />;

    case 'CONTACT':
      return <ContactWidgetForm form={form} />;

    case 'CONTENT':
      return <ContentWidgetForm form={form} />;
    case 'CUSTOMER_INFO':
      return <CustomerInfoForm form={form} />;

    case 'QUOTES':
      return (
        <QuoteWidgetForm
          form={form}
          setSelectedTab={setSelectedTab}
          selectedTab={selectedTab}
          formValues={formValues}
          setFormValues={setFormValues}
        />
      );

    case 'APPOINTMENT':
      return (
        <AppointmentWidgetForm
          form={form}
          selectedAppointmentKey={selectedAppointmentKey}
          setSelectedAppointmentKey={setSelectedAppointmentKey}
        />
      );

    case 'DIMENSION':
      return (
        <DimensionWidgetForm
          form={form}
          setSelectedDimensionTab={setSelectedDimensionTab}
          selectedDimensionTab={selectedDimensionTab}
          formValues={formValues}
          setFormValues={setFormValues}
          setSelectedDimensionProject={setSelectedDimensionProject}
          selectedDimensionProject={selectedDimensionProject}
        />
      );

    case 'SYSTEM':
      return (
        <SystemWidgetForm
          form={form}
          fieldObject={fieldObject}
          setFieldObject={setFieldObject}
          systemFieldData={systemFieldData}
          setSystemFieldData={setSystemFieldData}
          refetchSystemFields={refetchSystemFields}
          setRefetchSystemFields={setRefetchSystemFields}
          setDisableBtn={setDisableBtn}
          widgetConfiguration={widgetConfiguration}
          showCtaButton={showCtaButton}
        />
      );

    case 'CUSTOM':
      return (
        <CustomWidgetForm
          form={form}
          setFormValues={setFormValues}
          formValues={formValues}
          checkedList={checkedList}
          setCheckedList={setCheckedList}
          validationTriggered={validationTriggered}
          setDisableBtn={setDisableBtn}
          showCtaButton={showCtaButton}
        />
      );
    case 'ADDON':
      return <AddOnWidgetForm />;

    default:
      return <div className="mt-8 font-10">No data available</div>;
  }
};

export default RenderWidget;
