import { gql } from '@apollo/client';

export const INDUSTRY_FILTER = gql`
  query industryFilters($filter: IndustryFilter!) {
    industryFilters(filter: $filter) {
      count
      data {
        label
        key
        description
        isActive
        tenantId
        referenceId
      }
    }
  }
`;

export const LINE_OF_BUSINESS_FILTER = gql`
  query lineOfBusinessFilters($filter: LineOfBusinessFilter!) {
    lineOfBusinessFilters(filter: $filter) {
      count
      data {
        label
        lobCode
        key
        description
        isActive
        tenantId
        referenceId
      }
    }
  }
`;

export const BATCH_FILTER = gql`
  query batchFilters($filter: BatchFilter!) {
    batchFilters(filter: $filter) {
      count
      data {
        name
        description
        refData
        isSchedule
        scheduleDate
        isEmailNotificationEnabled
        emailNotificationConfig
        started
        completed
        industryId
        lineOfBusinessId
        tenantIds
        sponsorId
        status
        id
      }
    }
  }
`;

export const GET_INDUSTRIES = gql`
  query industries($filter: IndustryFilter!, $where: IndustriesWhereFilter) {
    industries(filter: $filter, where: $where) {
      count
      data {
        label
        alias
        industryCode
        refData
        key
        tenantId
        description
        isActive
        referenceId
        id
      }
    }
  }
`;

export const LINE_OF_BUSINESS = gql`
  query lineOfBusinesses(
    $filter: LineOfBusinessFilter!
    $where: LineOfBusinessWhereFilter
  ) {
    lineOfBusinesses(filter: $filter, where: $where) {
      count
      data {
        id
        label
        lobCode
        key
        description
        isActive
        tenantId
        referenceId
      }
    }
  }
`;

export const GET_TENANTS = gql`
  query tenants($where: TenantsWhereInput, $filter: TenantFilter!) {
    tenants(where: $where, filter: $filter) {
      count
      data {
        id
        name
        email
      }
    }
  }
`;
