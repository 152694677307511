import { Layout } from 'antd';
import React from 'react';

const { Header } = Layout;

const AppHeader = ({ children }) => (
  <Header>
    <div className="header-portal-wrapper d-flex justify-between align-center fill-width">
      <div id="header-left-content" />
      <div id="header-right-content" />
    </div>
    {children}
  </Header>
);
export default AppHeader;
