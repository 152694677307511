import { InfoCircleOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useLoadScript } from '@react-google-maps/api';
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Empty,
  Form,
  InputNumber,
  Popconfirm,
  Row,
  Slider,
  Tabs,
  Tag,
} from 'antd';
import { debounce, find, forEach, map, uniqBy } from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { PatternFormat } from 'react-number-format';
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import { messageContext } from '../../../app/components/AppContextHolder';
import DeleteIconComponent from '../../../app/components/iconComponents/DeleteIconComponent';
import ExpandIconComponent from '../../../app/components/iconComponents/ExpandIconComponent';
import SaveIcon from '../../../assets/save.svg';
import api from '../../../common/api';
import {
  BIG_SCREEN,
  REGEX,
  ROUTES,
  SMALL_TABLET,
  TABLET_BREAKPOINT,
} from '../../../common/constants';
import {
  checkPermissions,
  displayZipCodes,
  formValidatorRules,
  formatPhoneNumberWithoutMask,
} from '../../../common/utils';
import AccessControl from '../../../components/AccessControl';
import EditorComponent from '../../../components/EditorComponent';
import GoBackButton from '../../../components/GoBackButton';
import InputComponent from '../../../components/InputComponent';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import SelectComponent from '../../../components/SelectComponent';
import history from '../../../historyData';
import GoogleAutoComplete from '../../onboarding/components/GoogleAutoComplete';
import GoogleMapComponent from '../../onboarding/components/GoogleMapComponent';
import RegionModal from '../../onboarding/components/RegionModal';
import {
  GET_LOCATION_TYPE,
  GET_ZIP_CODES,
} from '../../onboarding/graphql/Queries';
import { GET_USERS } from '../../users/graphql/Queries';
import { CREATE_REGION, UPDATE_REGION } from '../graphql/Mutations';
import { GET_REGION, GET_REGIONS } from '../graphql/Queries';

let userScrollDebounceJob;
let regionScrollDebounceJob;
let initialValues;

const { required, requiredWhiteSpaceAllowed, address } = formValidatorRules;

const userFilter = {
  skip: 0,
  limit: 20,
  sortOn: 'createdAt',
  sortBy: 'DESC',
  search: '',
  showCurrentUser: true,
};
const regionFilter = {
  skip: 0,
  limit: 20,
  sortOn: 'name',
  sortBy: 'DESC',
  search: '',
  omitParent: true,
};

const libraries = ['places'];

const { Option } = SelectComponent;

let searchDebounce = null;
let scrollDebounce = null;

const RegionForm = ({ isEdit = false }) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = useLocation();

  const {
    state: { windowWidth, permissions },
    dispatch,
  } = useContext(AppContext);

  const [validationTriggered, setValidationTriggered] = useState(false);

  const [userLoading, setUserLoading] = useState(false);
  const [userSearchFlag, setUserSearchFlag] = useState(false);
  const [usersData, setUsersData] = useState([]);

  const [regionLoading, setRegionLoading] = useState(false);
  const [regionSearchFlag, setRegionSearchFlag] = useState(false);
  const [regionsData, setRegionsData] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  const [value, setValue] = useState(2);
  const [sliderValue, setSliderValue] = useState(30);
  const [markerObj, setMarkerObj] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [autoCompleteValue, setAutoCompleteValue] = useState(null);
  const [autoCompleteValueObj, setAutoCompleteValueObj] = useState(null);
  const [stateList, setStateList] = useState([]);
  const [checkBoxValue, setCheckBoxValue] = useState([]);
  const [placesValue, setPlacesValue] = useState('');
  const [zip, setZip] = useState([]);
  const [isData, setIsData] = useState(false);
  const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);
  const [scrollFlag, setScrollFlag] = useState(false);
  const [zipValue, setZipValue] = useState('');
  const [zipValidation, setZipValidation] = useState(false);
  const [zipMarkers, setZipMarkers] = useState([]);
  const [centerMap, setCenterMap] = useState(null);
  const [cityStateMarkerObj, setCityStateMarkerObj] = useState(null);
  const [cityStateZipMarker, setCityStateZipMarker] = useState([]);
  const [loading, setLoading] = useState(true);
  const [mapZoom, setMapZoom] = useState(4);

  const [form] = Form?.useForm();

  const [submitLoading, setSubmitLoading] = useState(false);
  const [createRegion] = useMutation(CREATE_REGION, {
    onError: () => {
      setSubmitLoading(false);
    },
  });

  const [updateRegion] = useMutation(UPDATE_REGION, {
    onError: () => {
      setSubmitLoading(false);
    },
  });

  const [region, { data: regionEditData }] = useLazyQuery(GET_REGION, {
    onCompleted: (res) => {
      const {
        zipCodes = [],
        regionData = null,
        geoLocation = [],
      } = res?.region;
      const geoLocationCopy = [];

      if (res?.region?.regionData?.selectValue) {
        switch (res?.region?.regionData?.selectValue?.type) {
          case 'CITY':
            setMapZoom(13);
            break;

          case 'STATE':
            setMapZoom(6);
            break;

          case 'COUNTY':
            setMapZoom(11);
            break;

          default:
            break;
        }
      }
      if (res?.region?.managerId) {
        setUsersData(uniqBy([res?.region?.manager, ...usersData], 'id'));
      }
      if (res?.region?.parentId) {
        setRegionsData(
          uniqBy([res?.region?.parentRegion, ...regionsData], 'id'),
        );
      }

      setValue(regionData?.radioValue || 1);
      setZip(zipCodes);
      setIsData(true);
      if (geoLocation?.length > 0) {
        forEach(geoLocation, (item) =>
          geoLocationCopy?.push({
            zipCode: item?.zipCode,
            lat: item?.latitude,
            lng: item?.longitude,
          }),
        );
        setCenterMap({
          lat: geoLocation?.[0]?.latitude,
          lng: geoLocation?.[0]?.longitude,
        });
        if (regionData?.radioValue === 1) {
          setZipMarkers(geoLocationCopy);
        } else if (regionData?.radioValue === 2) {
          setCityStateZipMarker(geoLocationCopy);
        } else {
          setZipMarkers(geoLocationCopy);
        }
      }
      if (regionData?.radioValue === 1) {
        setSliderValue(regionData?.radius);
        setPlacesValue(regionData?.addressValue);
        setMapZoom(13);
      } else if (regionData?.radioValue === 2) {
        setAutoCompleteOptions([regionData?.selectValue]);
        setAutoCompleteValue(regionData?.selectValue?.id);
        setAutoCompleteValueObj(regionData?.selectValue);
        switch (regionData?.selectValue?.type) {
          case 'CITY':
            setMapZoom(13);
            break;

          case 'STATE':
            setMapZoom(6);
            break;

          case 'COUNTY':
            setMapZoom(11);
            break;

          default:
            break;
        }
      }
      setLoading(false);
      initialValues = {
        ...res?.region,
        description: res?.region?.description || '',
        parentId: res?.region?.parentId || 'no',
      };

      setTimeout(() => {
        if (regionData?.radioValue === 1) {
          setMarkerObj(regionData?.markerObj);
        } else if (regionData?.radioValue === 2) {
          setCityStateMarkerObj(regionData?.markerObj);
        } else if (!regionData?.radioValue && geoLocation) {
          setMarkerObj(geoLocationCopy);
        }
      }, 1000);
    },
    fetchPolicy: 'network-only',
    onError: () => {
      setLoading(false);
      setSubmitLoading(false);
    },
  });

  const handleSubmit = async (formValues) => {
    if (!zip?.length) {
      setZipValidation(true);
      return;
    }
    let regionDataCopy = null;

    if (markerObj) {
      setValue(1);
      regionDataCopy = {
        radioValue: 1,
        addressValue: placesValue,
        radius: sliderValue,
        markerObj,
      };
    } else {
      setValue(2);
      regionDataCopy = {
        radioValue: 2,
        selectValue: autoCompleteValueObj || null,
        markerObj: cityStateMarkerObj,
      };
    }

    if (!isEdit) {
      try {
        const response = await createRegion({
          variables: {
            data: {
              ...formValues,
              contactNumber: formatPhoneNumberWithoutMask(
                formValues?.contactNumber,
              ),
              parentId:
                formValues?.parentId === 'no' ? null : formValues?.parentId,
              zipCodes: zip?.map((item) => item?.padStart(5, '0')),
              isAgain: false,
              regionData: regionDataCopy,
            },
          },
        });
        if (response?.data?.createRegion) {
          navigate(ROUTES?.REGIONS, {
            state: {
              ...state,
            },
          });
        }
      } catch (error) {
        setSubmitLoading(false);
      }
    } else {
      try {
        const response = await updateRegion({
          variables: {
            data: {
              ...formValues,
              contactNumber: formatPhoneNumberWithoutMask(
                formValues?.contactNumber,
              ),
              managerId: formValues?.managerId || null,
              parentId:
                formValues?.parentId === 'no' ? null : formValues?.parentId,
              zipCodes: zip?.map((item) => item?.padStart(5, '0')),
              isAgain: true,
              regionData: regionDataCopy,
            },
            where: { id: regionEditData?.region?.id },
          },
        });
        if (response?.data?.updateRegion) {
          navigate(ROUTES?.REGIONS, {
            state: {
              ...state,
            },
          });
        }
      } catch (error) {
        setSubmitLoading(false);
      }
    }
  };

  const onFinishFailed = () => {
    if (!zip?.length) {
      setZipValidation(true);
    }
    setValidationTriggered(true);
  };

  const onChange = (e) => {
    setValue(Number(e));
  };

  const [getLocationType] = useLazyQuery(GET_LOCATION_TYPE, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (scrollFlag) {
        const optionsCopy = [...autoCompleteOptions];
        optionsCopy?.push(...res?.getLocationType?.data);
        setAutoCompleteOptions(optionsCopy);
        setScrollFlag(false);
      } else {
        setAutoCompleteOptions(res?.getLocationType?.data);
      }
    },
    onError() {},
  });

  const [users] = useLazyQuery(GET_USERS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (userSearchFlag) {
        setUsersData([...res?.users?.data]);
      } else {
        setUsersData([...usersData, ...res?.users?.data]);
      }
      setUserLoading(false);
    },
    onError() {
      setUserLoading(false);
    },
  });

  const [getZipCodes, { data, loading: zipLoading }] = useLazyQuery(
    GET_ZIP_CODES,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        const zipsCopy = [];
        const zipMarkersCopy = [];
        forEach(res?.getZipCodes?.data, (item) => {
          zipsCopy?.push(item?.zipCode);
          zipMarkersCopy?.push({
            zipCode: item?.zipCode,
            lat: item?.longLat?.coordinates?.[1],
            lng: item?.longLat?.coordinates?.[0],
          });
        });
        setCenterMap({
          lat: res?.getZipCodes?.data?.[0]?.longLat?.coordinates?.[1],
          lng: res?.getZipCodes?.data?.[0]?.longLat?.coordinates?.[0],
        });
        setZip(zipsCopy);
        if (value === 1) {
          setZipMarkers(zipMarkersCopy);
        } else {
          setCityStateZipMarker(zipMarkersCopy);
        }
        setStateList(res?.getZipCodes?.states);
        const DefaultValue = map(res?.getZipCodes?.states, (item) => item);
        setCheckBoxValue(DefaultValue);
        if (res?.getZipCodes?.data?.length > 0) {
          setIsData(true);
        }
      },
      onError() {
        setZip([]);
        setStateList([]);
        setIsData(false);
        setCheckBoxValue([]);
      },
    },
  );

  const onChangeCheckbox = (checkBoxValues) => {
    const zipCopy = [];
    const zipMarkersCopy = [];
    forEach(checkBoxValues, (item) => {
      forEach(data?.getZipCodes?.data, (zipObj) => {
        if (zipObj?.state === item) {
          zipCopy?.push(zipObj?.zipCode);
          zipMarkersCopy?.push({
            zipCode: zipObj?.zipCode,
            lat: zipObj?.longLat?.coordinates?.[1],
            lng: zipObj?.longLat?.coordinates?.[0],
          });
        }
      });
    });
    setCenterMap({
      lat: data?.getZipCodes?.data?.[0]?.longLat?.coordinates?.[1],
      lng: data?.getZipCodes?.data?.[0]?.longLat?.coordinates?.[0],
    });
    setCheckBoxValue(checkBoxValues);
    setZip(zipCopy);
    if (value === 1) {
      setZipMarkers(zipMarkersCopy);
    } else {
      setCityStateZipMarker(zipMarkersCopy);
    }
    if (!zipCopy?.length) {
      setZipValidation(true);
    } else {
      setZipValidation(false);
    }
  };

  const handleChange = (changeValue) => {
    setAutoCompleteValue(changeValue);
    if (changeValue) {
      setCityStateMarkerObj(null);
      setAutoCompleteValueObj(null);
      const locationObj = find(
        autoCompleteOptions,
        (item) => item?.id === changeValue,
      );
      if (locationObj) {
        setAutoCompleteValueObj(locationObj);
        api
          ?.get('/map-api', {
            params: {
              address:
                locationObj?.type === 'STATE'
                  ? locationObj?.name
                  : `${locationObj?.name} (${locationObj?.state})`,
            },
          })
          .then((response) => {
            const { lat, lng } =
              response?.data?.results?.length > 0 &&
              response?.data?.results?.[0]?.geometry?.location;
            setCityStateMarkerObj({ lat, lng });
            setCenterMap({
              lat,
              lng,
            });
            setZip([]);
            switch (locationObj?.type) {
              case 'CITY':
                setMapZoom(13);
                break;

              case 'STATE':
                setMapZoom(6);
                break;

              case 'COUNTY':
                setMapZoom(11);
                break;

              default:
                break;
            }
          })

          .catch((error) => {
            messageContext?.error(error?.response?.data?.message);
          });
      }
    }
    if (!changeValue) {
      setAutoCompleteValueObj(null);
      setIsData(false);
      setZip([]);
      setCityStateMarkerObj(null);
      setMapZoom(4);
    }
  };

  const onScroll = (event) => {
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    if (scrollDebounce) {
      scrollDebounce?.cancel();
      scrollDebounce = null;
    }
    scrollDebounce = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom) {
        setScrollFlag(true);
        getLocationType({
          variables: {
            filter: {
              sortOn: 'name',
              sortBy: 'ASC',
              skip: autoCompleteOptions?.length,
              limit: 20,
              search: searchValue,
            },
          },
        });
      }
    }, 500);
    scrollDebounce();
  };

  const handleCallSelectValue = () => {
    if (autoCompleteValue) {
      getLocationType({
        variables: {
          filter: {
            sortOn: 'name',
            sortBy: 'ASC',
            skip: 0,
            limit: 20,
            search: autoCompleteValueObj?.name,
          },
        },
      });
    } else {
      setAutoCompleteOptions([]);
    }
  };

  const getData = (debounceValue) => {
    if (debounceValue) {
      getLocationType({
        variables: {
          filter: {
            sortOn: 'name',
            sortBy: 'ASC',
            skip: 0,
            limit: 20,
            search: debounceValue,
          },
        },
      });
    }
  };

  const handleChangeAutoComplete = (inputValue) => {
    setScrollFlag(false);
    setSearchValue(inputValue);
    if (searchDebounce) {
      searchDebounce?.cancel();
      searchDebounce = null;
    }
    searchDebounce = debounce(getData, 500);
    searchDebounce(inputValue);
  };

  const handleAddZips = () => {
    setZipValidation(false);
    if (value === 1) {
      setCityStateMarkerObj(null);
      setAutoCompleteValue(null);
      getZipCodes({
        variables: {
          filter: {
            latitude: markerObj?.lat?.toString(),
            longitude: markerObj?.lng?.toString(),
            radius: (sliderValue * 1609.34)?.toString(),
            addInZipCode: zipValue,
          },
        },
      });
    } else {
      setMarkerObj(null);
      setPlacesValue(null);
      const locationObj = find(
        autoCompleteOptions,
        (item) => item?.id === autoCompleteValue,
      );
      if (locationObj) {
        getZipCodes({
          variables: {
            filter: {
              placeName: locationObj?.name,
              type: locationObj?.type,
            },
            where:
              autoCompleteValueObj?.type === 'STATE'
                ? undefined
                : {
                    state: autoCompleteValueObj?.state,
                  },
          },
        });
      }
    }
  };

  const onUserScroll = (event) => {
    setUserSearchFlag(false);
    if (userScrollDebounceJob) {
      userScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    userScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom) {
        setUserLoading(true);
        users({
          variables: {
            filter: {
              ...userFilter,
              skip: usersData?.length,
              search: searchValue,
            },
            where: {
              isActive: true,
            },
          },
        });
      }
    }, 500);

    userScrollDebounceJob();
  };

  const handleUserChange = (userValue) => {
    setUserSearchFlag(true);
    setSearchValue(userValue);
    if (userValue) {
      setUserLoading(true);
      users({
        variables: {
          filter: {
            ...userFilter,
            search: userValue,
          },
        },
        where: {
          isActive: true,
        },
      });
    } else {
      setUserLoading(true);
      users({
        variables: {
          filter: {
            ...userFilter,
            search: userValue,
          },
        },
        where: {
          isActive: true,
        },
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUserHandler = useCallback(debounce(handleUserChange, 500), []);

  const handleUserClear = () => {
    form?.setFieldsValue({
      users: null,
    });
    setUsersData([]);
    users({
      variables: {
        filter: userFilter,
        where: {
          isActive: true,
        },
      },
    });
  };

  const handleUserBlur = () => {
    setSearchValue('');
  };

  const [regions] = useLazyQuery(GET_REGIONS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (regionSearchFlag) {
        setRegionsData([...res?.regions?.data]);
      } else {
        setRegionsData([...regionsData, ...res?.regions?.data]);
      }
      setRegionLoading(false);
    },
    onError() {
      setRegionLoading(false);
    },
  });

  const onRegionScroll = (event) => {
    setRegionSearchFlag(false);
    if (regionScrollDebounceJob) {
      regionScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    regionScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom) {
        setRegionLoading(true);
        regions({
          variables: {
            filter: {
              ...regionFilter,
              skip: regionsData?.length,
              search: searchValue,
            },
          },
        });
      }
    }, 500);

    regionScrollDebounceJob();
  };

  const handleRegionChange = (regionValue) => {
    setRegionSearchFlag(true);
    setSearchValue(regionValue);
    if (regionValue) {
      setRegionLoading(true);
      regions({
        variables: {
          filter: {
            ...regionFilter,
            search: regionValue,
          },
        },
      });
    } else {
      setRegionLoading(true);
      regions({
        variables: {
          filter: {
            ...regionFilter,
            search: regionValue,
          },
        },
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedRegionHandler = useCallback(
    debounce(handleRegionChange, 500),
    [],
  );

  const handleRegionClear = () => {
    form?.setFieldsValue({
      regions: null,
    });
    setRegionsData([]);
    regions({ variables: { filter: regionFilter } });
  };

  const handleRegionBlur = () => {
    setSearchValue('');
  };

  useEffect(() => {
    users({
      variables: {
        filter: userFilter,
        where: {
          isActive: true,
        },
      },
    });
    regions({
      variables: {
        filter: regionFilter,
      },
    });
    if (isEdit) {
      region({ variables: { id } });
    } else {
      initialValues = {
        description: '',
        parentId: 'no',
      };
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const unListen = history?.listen((props) => {
      if (props?.action === 'POP') {
        navigate(props?.location?.pathname, { state: { ...location?.state } });
      }
    });
    return () => {
      if (unListen) {
        unListen();
      }
    };
  }, []);

  const handleClear = () => {
    setAutoCompleteOptions([]);
  };

  if (!isLoaded) {
    return <LoaderComponent spinning={!isLoaded} />;
  }

  if (loading) {
    return <LoaderComponent spinning={loading} />;
  }

  if (!id && isEdit) {
    return <Navigate to={ROUTES?.REGIONS} replace />;
  }

  let showZip;
  if (windowWidth < SMALL_TABLET) {
    showZip = 8;
  } else if (windowWidth < TABLET_BREAKPOINT) {
    showZip = 10;
  } else if (windowWidth < BIG_SCREEN) {
    showZip = 12;
  } else {
    showZip = 16;
  }

  const tabItems = [
    {
      label: 'State, County or City',
      key: '2',
      children: (
        <>
          <GoogleMapComponent
            radioValue={Number(value)}
            loadError={loadError}
            cityStateInitialMarker={cityStateMarkerObj}
            setCityStateMarkerObj={setCityStateMarkerObj}
            setPlacesValue={setPlacesValue}
            radius={sliderValue}
            setZipValue={setZipValue}
            cityStateZipMarkers={cityStateZipMarker}
            centerMap={centerMap}
            mapZoom={mapZoom}
          />
          <Row gutter={16}>
            <Col
              xs={18}
              sm={18}
              md={18}
              lg={18}
              xl={18}
              xxl={18}
              className="address-col"
            >
              <span className="region-label">State OR City OR County</span>
              <SelectComponent
                showSearch
                className="select-width location-dropdown"
                value={autoCompleteValue}
                placeholder="State OR City OR County"
                defaultActiveFirstOption={false}
                suffixIcon={null}
                filterOption={false}
                onSearch={handleChangeAutoComplete}
                onChange={handleChange}
                onBlur={handleCallSelectValue}
                onPopupScroll={onScroll}
                onClear={handleClear}
                notFoundContent={null}
              >
                {map(autoCompleteOptions, (item) => (
                  <Option key={item?.id} value={item?.id}>
                    <span>
                      {item?.type === 'STATE'
                        ? item?.name
                        : `${item?.name} (${item?.state})`}{' '}
                      <Tag className="place-tag">{item?.type}</Tag>
                    </span>
                  </Option>
                ))}
              </SelectComponent>
            </Col>
            <Col
              xs={6}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              xxl={6}
              className="address-col"
            >
              {zip?.length > 0 ? (
                <Popconfirm
                  title="This will overwrite existing postal codes for this region. Do you still want to continue?"
                  trigger="click"
                  onConfirm={() => handleAddZips()}
                  okText="Yes"
                  cancelText="No"
                  disabled={value === 1 ? !placesValue : !autoCompleteValue}
                >
                  <Button
                    type="primary"
                    className="add-button state-add-button"
                    disabled={value === 1 ? !placesValue : !autoCompleteValue}
                    loading={zipLoading}
                  >
                    Add
                  </Button>
                </Popconfirm>
              ) : (
                <Button
                  type="primary"
                  className="add-button state-add-button"
                  disabled={value === 1 ? !placesValue : !autoCompleteValue}
                  loading={zipLoading}
                  onClick={handleAddZips}
                >
                  Add
                </Button>
              )}
            </Col>
          </Row>
          {isData && value === 2 && (
            <div className="state-count-step2">
              {stateList?.length > 1 ? (
                <span className="steps-content-description">
                  {zip?.length} Default postal codes were found for your area
                  across {checkBoxValue?.length} &nbsp; states - continue to
                  accept these or click on the
                  <ExpandIconComponent className="icon-margin" />
                  to edit them.
                </span>
              ) : (
                <span className="steps-content-description">
                  {zip?.length} Default postal codes were found for your area -
                  continue to accept these or click on the
                  <ExpandIconComponent className="icon-margin" />
                  to edit them.
                </span>
              )}
            </div>
          )}
          <Row>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              xxl={24}
              className="address-col"
            >
              <div className="region-label postal-margin">Postal Codes</div>
              <div className={`zip-section ${!isData ? 'justify-center' : ''}`}>
                {!isData && (
                  <span className="no-zip">
                    You haven’t added any Postal codes yet.
                  </span>
                )}
                {isData && (
                  <div className="d-flex justify-between align-center fill-width">
                    <div>
                      {map(zip.slice(0, showZip), (item, index) => (
                        <span key={`${item}_${index}`} className="zip-tags">
                          {displayZipCodes(item)}
                        </span>
                      ))}
                      {zip?.length > showZip && (
                        <span className="zip-dots"> ...</span>
                      )}
                    </div>
                    <div>
                      <span className="modal-icon">
                        <ExpandIconComponent
                          onClick={() => setModalVisible(true)}
                        />
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </>
      ),
    },
    {
      label: 'Area Selection',
      key: '1',
      children: (
        <>
          <GoogleMapComponent
            radioValue={Number(value)}
            loadError={loadError}
            initialMarker={markerObj}
            setMarkerObj={setMarkerObj}
            setPlacesValue={setPlacesValue}
            radius={sliderValue}
            setZipValue={setZipValue}
            zipMarkers={zipMarkers}
            centerMap={centerMap}
          />
          <Row>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              xxl={24}
              className="address-col"
            >
              <span className="region-label">Area Address</span>
              <div className="auto-complete-space">
                <GoogleAutoComplete
                  setMarkerObj={setMarkerObj}
                  initialValue={placesValue}
                  setPlacesValue={setPlacesValue}
                  setZipValue={setZipValue}
                  setZipMarkers={setZipMarkers}
                  setCenterMap={setCenterMap}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col
              xs={8}
              sm={8}
              md={8}
              lg={8}
              xl={15}
              xxl={15}
              className="address-col"
            >
              <Slider
                className="slider-width"
                min={1}
                max={1000}
                onChange={(slideValue) => setSliderValue(slideValue)}
                value={sliderValue}
              />
            </Col>
            <Col
              xs={8}
              sm={8}
              md={8}
              lg={8}
              xl={3}
              xxl={3}
              className="address-col"
            >
              <InputNumber
                type="number"
                className="slider-number"
                min={1}
                max={1000}
                onChange={(slideValue) => setSliderValue(slideValue)}
                value={sliderValue}
              />
              <span className="slider-text">Miles</span>
            </Col>
            <Col
              xs={8}
              sm={8}
              md={8}
              lg={8}
              xl={6}
              xxl={6}
              className="address-col"
            >
              {zip?.length > 0 ? (
                <Popconfirm
                  title="This will overwrite existing postal codes for this region. Do you still want to continue?"
                  trigger="click"
                  onConfirm={() => handleAddZips()}
                  okText="Yes"
                  cancelText="No"
                  disabled={value === 1 ? !placesValue : !autoCompleteValue}
                >
                  <Button
                    type="primary"
                    className="add-button"
                    disabled={value === 1 ? !placesValue : !autoCompleteValue}
                    loading={zipLoading}
                  >
                    Add
                  </Button>
                </Popconfirm>
              ) : (
                <Button
                  type="primary"
                  className="add-button"
                  disabled={value === 1 ? !placesValue : !autoCompleteValue}
                  loading={zipLoading}
                  onClick={handleAddZips}
                >
                  Add
                </Button>
              )}
            </Col>
          </Row>

          {isData && value === 1 && (
            <>
              <div className="state-count">
                {stateList?.length > 1 ? (
                  <span className="steps-content-description">
                    {zip?.length} Default postal codes were found for your area
                    across {checkBoxValue?.length}
                    &nbsp;states - continue to accept these or click on the
                    <ExpandIconComponent className="icon-margin" />
                    to edit them.
                  </span>
                ) : (
                  <span className="steps-content-description">
                    {zip?.length} Default postal codes were found for your area
                    - continue to accept these or click on the
                    <ExpandIconComponent className="icon-margin" />
                    to edit them.
                  </span>
                )}
              </div>
              <div>
                {stateList?.length > 1 && (
                  <Checkbox.Group
                    className="common-checkbox region-checkbox"
                    value={checkBoxValue}
                    onChange={onChangeCheckbox}
                  >
                    {map(stateList, (item) => {
                      if (item !== null && item !== '') {
                        return (
                          <Checkbox value={item} key={item}>
                            {item}
                          </Checkbox>
                        );
                      }
                    })}
                  </Checkbox.Group>
                )}
              </div>
            </>
          )}
          <Row>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              xxl={24}
              className="address-col"
            >
              <div className="region-label postal-margin">Postal Codes</div>
              <div className={`zip-section ${!isData ? 'justify-center' : ''}`}>
                {!isData && (
                  <>
                    <span className="no-zip">
                      You haven’t added any Postal codes yet.
                    </span>
                  </>
                )}
                {isData && (
                  <div className="d-flex justify-between align-center fill-width">
                    <div>
                      {map(zip?.slice(0, showZip), (item, index) => (
                        <span key={`${item}_${index}`} className="zip-tags">
                          {displayZipCodes(item)}
                        </span>
                      ))}
                      {zip?.length > showZip && (
                        <span className="zip-dots"> ...</span>
                      )}
                    </div>
                    <div>
                      <span className="modal-icon">
                        <ExpandIconComponent
                          onClick={() => setModalVisible(true)}
                        />
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </>
      ),
    },
  ];

  const regionTooltipContent = (
    <span>
      In the Path.Pro the format <b>AA-##</b> is naturally used for sorting and
      is the recommended format for regions.&nbsp;
    </span>
  );

  return (
    <div className="content-section">
      <RegionModal
        form={form}
        btnHide
        open={modalVisible}
        setModalVisible={setModalVisible}
        setZip={setZip}
        zip={zip}
        regionLoading={regionLoading}
        zipMarkers={value === 1 ? zipMarkers : cityStateZipMarker}
        setZipMarkers={setZipMarkers}
      />
      {isEdit && (
        <Portal portalId="header-left-content">
          <div className="d-flex align-center">
            <GoBackButton customLink={ROUTES?.REGIONS} />
            {regionEditData?.region?.name && (
              <span className="portal-header">
                {regionEditData?.region?.name}
              </span>
            )}
          </div>
        </Portal>
      )}
      <AccessControl
        allowedPermissions={
          isEdit ? ['FET_REGION_UPDATE'] : ['FET_REGION_CREATE']
        }
      >
        <Portal portalId="header-right-content">
          <Button
            className="common-button discard-button"
            icon={<DeleteIconComponent />}
            size="small"
            id="region-table-discard-btn"
            onClick={() =>
              navigate(ROUTES?.REGIONS, {
                state: {
                  ...state,
                },
              })
            }
          >
            {isEdit ? 'Discard Changes' : 'Cancel'}
          </Button>
          <Button
            className="common-button"
            icon={<img src={SaveIcon} alt="save-icon" width={12} />}
            size="small"
            htmlType="submit"
            id="region-table-save-btn"
            loading={submitLoading}
            type="primary"
            onClick={form?.submit}
          >
            Save
          </Button>
        </Portal>
      </AccessControl>
      <Form
        form={form}
        initialValues={initialValues}
        onValuesChange={() => dispatch({ type: 'SET_SHOW_PROMPT', data: true })}
        layout="vertical"
        validateTrigger={validationTriggered ? 'onChange' : 'onSubmit'}
        onFinish={(values) => {
          dispatch({ type: 'SET_SHOW_PROMPT', data: false });
          handleSubmit(values);
        }}
        onFinishFailed={onFinishFailed}
        scrollToFirstError={{ behavior: 'smooth', block: 'end' }}
      >
        <fieldset
          disabled={
            !checkPermissions(
              permissions,
              isEdit ? ['FET_REGION_UPDATE'] : ['FET_REGION_CREATE'],
            )
          }
        >
          <span className="form-divider-text">MANDATORY</span>
          <Divider className="form-divider" />
          <Row gutter={16} className="required-row">
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                rules={[{ ...required, message: 'Please Enter Region name' }]}
                name="name"
                label="Region Name"
                className="label-with-tooltip"
                tooltip={{
                  title: regionTooltipContent,
                  icon: <InfoCircleOutlined />,
                }}
              >
                <InputComponent
                  allowClear
                  placeholder="For example: IL-00, IL-01, IL-03..."
                />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                name="isActive"
                label="Status"
                rules={[
                  {
                    ...requiredWhiteSpaceAllowed,
                    message: 'Please Select Status',
                  },
                ]}
              >
                <SelectComponent placeholder="Select Status" allowClear>
                  <Option key="active" value>
                    Active
                  </Option>
                  <Option key="inactive" value={false}>
                    InActive
                  </Option>
                </SelectComponent>
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                name="parentId"
                label="Parent Region"
                rules={[
                  {
                    ...requiredWhiteSpaceAllowed,
                    message: 'Please Select Parent Region',
                  },
                ]}
              >
                <SelectComponent
                  placeholder="Select Parent Region"
                  allowClear
                  notFoundContent={
                    regionLoading ? (
                      <LoaderComponent size="small" setHeight={10} />
                    ) : (
                      <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                    )
                  }
                  onBlur={handleRegionBlur}
                  onSearch={debouncedRegionHandler}
                  onClear={handleRegionClear}
                  onPopupScroll={onRegionScroll}
                >
                  <Option key="no" value="no">
                    None
                  </Option>
                  {map(regionsData, (item) => (
                    <Option key={item?.id} value={item?.id}>
                      {item?.name}
                    </Option>
                  ))}
                </SelectComponent>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Tabs
                activeKey={value?.toString()}
                onChange={onChange}
                id="inner-tabs"
                items={tabItems}
              />
            </Col>
            {zipValidation && (
              <span className="postal-error">Please Select Postal Codes</span>
            )}
          </Row>
          <span className="form-divider-text">OPTIONAL</span>
          <Divider className="form-divider" />
          <Row gutter={16}>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item name="managerId" label="Manager">
                <SelectComponent
                  placeholder="Select Manager"
                  allowClear
                  notFoundContent={
                    userLoading ? (
                      <LoaderComponent size="small" setHeight={10} />
                    ) : (
                      <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                    )
                  }
                  onBlur={handleUserBlur}
                  onSearch={debouncedUserHandler}
                  onClear={handleUserClear}
                  onPopupScroll={onUserScroll}
                >
                  {map(usersData, (item) => (
                    <Option key={item?.id} value={item?.id}>
                      {`${item?.firstName} ${item?.lastName}`}
                    </Option>
                  ))}
                </SelectComponent>
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item name="officeName" label="Office Name">
                <InputComponent allowClear placeholder="Enter Office Name" />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                label="Contact Number"
                name="contactNumber"
                rules={[
                  () => ({
                    validator(rule, contactValue) {
                      if (contactValue) {
                        // eslint-disable-next-line no-param-reassign
                        contactValue = contactValue?.split(' ')?.join('');
                        const numberPattern = REGEX?.PHONE;
                        if (!numberPattern?.test(contactValue)) {
                          // eslint-disable-next-line prefer-promise-reject-errors
                          return Promise?.reject(
                            'should be a valid phone number',
                          );
                        }
                      }
                      return Promise?.resolve();
                    },
                  }),
                ]}
              >
                <PatternFormat
                  placeholder="(___) ___-____"
                  format="(###) ###-####"
                  mask="_"
                  customInput={InputComponent}
                />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item name="address" rules={[address]} label="Address">
                <InputComponent allowClear placeholder="Enter Address" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Form.Item name="description" label="Description">
                <EditorComponent placeholder="Enter Description" />
              </Form.Item>
            </Col>
          </Row>
        </fieldset>
      </Form>
    </div>
  );
};

export default RegionForm;
