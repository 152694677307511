import { Card, Tabs } from 'antd';
import React from 'react';
import SearchComponent from '../../../components/SearchComponent';
import ProductItemTab from './ProductItemTab';
import ProductTab from './ProductTab';

const GlobalConditionsForm = ({
  form,
  productTabSearch,
  setProductTabSearch,
  handleProductTabSearch,
  setIsFormValuesChanged,
  selectedListOptions,
  setSelectedListOptions,
  checkedFields,
  setCheckedFields,
  optionsData,
  setOptionsData,
  dynamicFieldDataClone,
  dynamicFieldsLoading,
  disabledFields,
  productItemStaticFieldDataClone,
  setStaticFieldDataClone,
  setStaticFieldOptionData,
  staticFieldOptionData,
  staticFieldSelectedOption,
  setStaticFieldSelectedOption,
  productStaticFieldDataClone,
  productStaticFieldOptionData,
  setProductStaticFieldOptionData,
  productStaticFieldSelectedOption,
  setProductStaticFieldSelectedOption,
  setSelectedTab,
}) => {
  const items = [
    {
      label: 'Product',
      key: 'ProductItem',
      forceRender: true,
      children: (
        <ProductItemTab
          form={form}
          checkedFields={checkedFields}
          setCheckedFields={setCheckedFields}
          setIsFormValuesChanged={setIsFormValuesChanged}
          setSelectedListOptions={setSelectedListOptions}
          optionsData={optionsData}
          disabledFields={disabledFields}
          setOptionsData={setOptionsData}
          selectedListOptions={selectedListOptions}
          dynamicFieldDataClone={dynamicFieldDataClone}
          dynamicFieldsLoading={dynamicFieldsLoading}
          // static data
          productItemStaticFieldDataClone={productItemStaticFieldDataClone}
          setStaticFieldDataClone={setStaticFieldDataClone}
          setStaticFieldOptionData={setStaticFieldOptionData}
          staticFieldOptionData={staticFieldOptionData}
          staticFieldSelectedOption={staticFieldSelectedOption}
          setStaticFieldSelectedOption={setStaticFieldSelectedOption}
        />
      ),
    },
    {
      label: 'Package',
      key: 'Product',
      forceRender: true,
      children: (
        <ProductTab
          form={form}
          checkedFields={checkedFields}
          disabledFields={disabledFields}
          setCheckedFields={setCheckedFields}
          setIsFormValuesChanged={setIsFormValuesChanged}
          productStaticFieldDataClone={productStaticFieldDataClone}
          productStaticFieldOptionData={productStaticFieldOptionData}
          setProductStaticFieldOptionData={setProductStaticFieldOptionData}
          productStaticFieldSelectedOption={productStaticFieldSelectedOption}
          setProductStaticFieldSelectedOption={
            setProductStaticFieldSelectedOption
          }
          dynamicFieldsLoading={dynamicFieldsLoading}
        />
      ),
    },
  ];

  return (
    <Card>
      <div className="setting-content-heading">
        <h5>GLOBAL CONDITIONS</h5>
        <span className="panel-description">Select a Global conditions</span>
        <SearchComponent
          className="list-search-box filter-search mt-16"
          getData={handleProductTabSearch}
          value={productTabSearch}
          setSearchValue={setProductTabSearch}
        />
      </div>
      <div className="setting-content">
        <Tabs items={items} onChange={(tab) => setSelectedTab(tab)} />
      </div>
    </Card>
  );
};

export default GlobalConditionsForm;
