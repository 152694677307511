import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Empty,
  Form,
  Row,
  Typography,
} from 'antd';
import { debounce, find, findLast, map, uniqBy } from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import DeleteIconComponent from '../../../app/components/iconComponents/DeleteIconComponent';
import SaveIcon from '../../../assets/save.svg';
import {
  DYNAMIC_FIELDS_DATA,
  DYNAMIC_FIELDS_PROPS,
  REGEX,
  ROUTES,
  SKIP_RECORD,
} from '../../../common/constants';
import { checkPermissions, formValidatorRules } from '../../../common/utils';
import AccessControl from '../../../components/AccessControl';
import InputComponent from '../../../components/InputComponent';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import PriceComponent from '../../../components/PriceComponent';
import SelectComponent from '../../../components/SelectComponent';
import history from '../../../historyData';
import {
  CREATE_DYNAMIC_FIELD,
  UPDATE_DYNAMIC_FIELD,
} from '../graphql/Mutations';
import { GET_INDUSTRIES } from '../graphql/Queries';
import DynamicFieldFormData from './DynamicFieldFormData';
import PickListModal from './PickListModal';
import PickListTable from './PickListTable';
import PublishModal from './PublishModal';

const LIMIT = 20;

const industryFilter = {
  sortOn: 'label',
  sortBy: 'ASC',
  skip: 0,
  limit: LIMIT,
  search: '',
  justShow: true,
};

let industryScrollDebounceJob = null;

const { number, required, requiredWhiteSpaceAllowed } = formValidatorRules;

const { Option } = SelectComponent;

const { Text } = Typography;

const DynamicFieldsForm = (props) => {
  const {
    state: { permissions, isSponsor },
    dispatch,
  } = useContext(AppContext);
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    dynamicFieldsData = null,
    isEdit = false,
    setSelectedDynamicKey,
    selectedDynamicKey,
    isFieldDisabled = false,
    refetchEditData,
  } = props;
  const [validationTriggered, setValidationTriggered] = useState(false);

  // file_upload states
  const [checkedList, setCheckedList] = useState([]);

  const [searchValue, setSearchValue] = useState('');
  const [industryLoading, setIndustryLoading] = useState(true);
  const [industrySearchFlag, setIndustrySearchFlag] = useState(false);
  const [industriesData, setIndustriesData] = useState([]);
  const [industryIsEnd, setIndustryIsEnd] = useState(false);
  const [industryDebounceCall, setIndustryDebounceCall] = useState(0);

  const [submitLoading, setSubmitLoading] = useState(false);
  const [publishLoading, setPublishLoading] = useState(false);
  const [publishModal, setPublishModal] = useState(false);
  const [publishModalLoader, setPublishModalLoader] = useState(false);
  const [lobData, setLobData] = useState([]);

  // pick_list states
  const [pickListModalVisible, setPickListModalVisible] = useState(false);
  const [pickListRecord, setPickListRecord] = useState(null);
  const [refetchData, setRefetchData] = useState(false);
  const [hasPickListData, setHasPickListData] = useState(false);

  const [form] = Form?.useForm();

  const btnLoader = (loading = false, type = '') => {
    if (publishModal) {
      setPublishModalLoader(loading);
      return;
    }
    if (type !== 'DRAFT' && !isEdit) {
      setPublishLoading(loading);
    } else {
      setSubmitLoading(loading);
    }
  };

  const [createDynamicField] = useMutation(CREATE_DYNAMIC_FIELD);

  const [updateDynamicField] = useMutation(UPDATE_DYNAMIC_FIELD);

  const handleSubmit = async (formValues, type = 'PUBLISH') => {
    if (type !== 'DRAFT' && !isFieldDisabled && !publishModal) {
      if (formValues?.fieldType === 'UPLOAD' && !checkedList?.length) {
        setValidationTriggered(true);
        return;
      }
      setPublishModal(true);
      return;
    }
    dispatch({ type: 'SET_SHOW_PROMPT', data: false });
    btnLoader(true, type);
    const newFormValues = {
      fieldType: formValues?.fieldType,
      sortOrder: formValues?.sortOrder ? Number(formValues?.sortOrder) : null,
      industryId: formValues?.industryId,
      lineOfBusinessIds: formValues?.lineOfBusinessIds,
      label: formValues?.label,
      tooltip: formValues?.tooltip,
      status: type,
      name: formValues?.name,
      ...(!isEdit && { entityType: 'PRODUCT' }),
      config: {
        [selectedDynamicKey]: {
          // common
          rules: {
            required: formValues?.required || false,
            readOnly: formValues?.readOnly || false,
            hidden: formValues?.hidden || false,
            hideLabel: formValues?.hideLabel || false,
          },
          // pick_list
          ...(selectedDynamicKey === 'PICK_LIST' && {
            type: formValues?.type,
          }),
          // text
          ...(selectedDynamicKey === 'TEXT' && {
            textType: formValues?.textType,
            textLength: {
              minLength: Number(formValues?.minLength),
              maxLength: Number(formValues?.maxLength),
            },
            default: formValues?.default,
          }),
          // number
          ...(selectedDynamicKey === 'NUMBER' && {
            range: {
              minNumber: Number(formValues?.minNumber),
              maxNumber: Number(formValues?.maxNumber),
            },
            decimal: Number(formValues?.decimal),
            default: Number(formValues?.defaultValue),
          }),
          // boolean
          ...(selectedDynamicKey === 'BOOLEAN' && {
            booleanDefaultValue: formValues?.booleanDefaultValue,
            trueValue: formValues?.trueValue,
            falseValue: formValues?.falseValue,
          }),
          // date time
          ...(selectedDynamicKey === 'DATE_TIME' && {
            dateTimeType: formValues?.dateTimeType,
            format: formValues?.format,
            dateConfig: {
              exactDate: formValues?.dateType === 'EXACT',
              range: formValues?.dateType === 'RANGE',
            },
            allowDateTime: formValues?.allowDateTime,
            ...(formValues?.dateType === 'EXACT' &&
              !formValues?.dateTimeType?.includes('TIME') &&
              formValues?.dateTimeBooleanValue && {
                dateDefaultValue: formValues?.dateDefaultValue,
              }),
            ...(formValues?.dateType === 'RANGE' &&
              (formValues?.dateTimeType?.includes(['TIME', 'DATE']) ||
                formValues?.dateTimeType?.includes('DATE')) && {
                rangeDefaultValue: Number(formValues?.rangeDefaultValue),
              }),
            ...(formValues?.dateType === 'RANGE' &&
              (formValues?.dateTimeType?.includes(['TIME']) ||
                !formValues?.dateTimeType?.includes('DATE')) && {
                timeDefaultValue: formValues?.timeDefaultValue,
              }),
            ...(formValues?.dateType === 'EXACT' &&
              formValues?.dateTimeType?.includes('TIME') && {
                dateTimeBooleanValue: formValues?.dateTimeBooleanValue,
              }),
          }),
          // upload
          ...(selectedDynamicKey === 'UPLOAD' && {
            fileType: {
              key: formValues?.fileType,
              value: checkedList,
            },
            size: Number(formValues?.size) || 100,
            allowMultiple: formValues?.allowMultiple,
            limitFileSize: formValues?.limitFileSize,
            maxFile: Number(formValues?.maxFile) || 10,
          }),
        },
      },
    };

    if (isEdit) {
      try {
        const response = await updateDynamicField({
          variables: {
            data: { ...newFormValues },
            where: { id },
          },
        });
        if (response?.data?.updateDynamicField) {
          setPublishModal(false);
          btnLoader(false, type);
          refetchEditData();
        }
      } catch (error) {
        btnLoader(false, type);
        return error;
      }
    } else {
      try {
        const response = await createDynamicField({
          variables: { data: { ...newFormValues } },
        });
        if (response?.data?.createDynamicField?.id) {
          navigate(
            `${ROUTES?.DYNAMIC_FIELDS}/edit/${response?.data?.createDynamicField?.id}`,
            {
              state: { ...location?.state },
            },
          );
          setPublishModal(false);
          btnLoader(false, type);
        }
      } catch (error) {
        btnLoader(false, type);
        return error;
      }
    }
  };

  const [industries] = useLazyQuery(GET_INDUSTRIES, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setIndustryIsEnd(res?.industries?.data?.length < SKIP_RECORD);
      if (industrySearchFlag) {
        setIndustriesData([...res?.industries?.data]);
      } else {
        if (isEdit && dynamicFieldsData?.industry) {
          const tempIndustry = [
            dynamicFieldsData?.industry,
            ...res?.industries?.data,
          ];
          const uniqueData = uniqBy([...industriesData, ...tempIndustry], 'id');
          setIndustriesData(uniqueData);
          if (!lobData?.length) {
            const lobDataCopy = findLast(
              tempIndustry,
              (item) => item?.id === dynamicFieldsData?.industryId,
            );

            setLobData(lobDataCopy?.lobSaData);
          }
          return;
        }
        setIndustriesData([...industriesData, ...res?.industries?.data]);
      }
      setIndustryLoading(false);
    },
    onError() {
      setIndustryLoading(false);
    },
  });

  const onIndustryScroll = (event) => {
    setIndustrySearchFlag(false);
    if (industryScrollDebounceJob) {
      industryScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    industryScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !industryIsEnd) {
        setIndustryLoading(true);
        setIndustryDebounceCall((prevState) => prevState + 1);
        industries({
          variables: {
            filter: {
              ...industryFilter,
              skip: (industryDebounceCall + 1) * SKIP_RECORD,
              search: searchValue,
            },
            where: {
              isActive: true,
            },
          },
        });
      }
    }, 500);

    industryScrollDebounceJob();
  };

  const handleIndustryChange = (value) => {
    setIndustrySearchFlag(true);
    setSearchValue(value);
    setIndustryLoading(true);
    industries({
      variables: {
        filter: {
          ...industryFilter,
          search: value,
        },
        where: {
          isActive: true,
        },
      },
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedIndustryHandler = useCallback(
    debounce(handleIndustryChange, 500),
    [],
  );

  const handleIndustryBlur = () => {
    setSearchValue('');
    setIndustryDebounceCall(0);
    setIndustryIsEnd(false);
  };

  const handleIndustrySelect = (value) => {
    const lobDataCopy = find(industriesData, (item) => item?.id === value);
    setLobData(lobDataCopy?.lobSaData);
  };

  useEffect(() => {
    industries({
      variables: {
        filter: industryFilter,
        where: {
          isActive: true,
        },
      },
    });
    if (
      selectedDynamicKey === 'UPLOAD' &&
      isEdit &&
      dynamicFieldsData?.fileTypeArray?.value
    ) {
      setCheckedList([...dynamicFieldsData?.fileTypeArray?.value]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const unListen = history?.listen((historyProps) => {
      if (historyProps?.action === 'POP') {
        navigate(historyProps?.location?.pathname, {
          state: { ...location?.state },
        });
      }
    });
    return () => {
      if (unListen) {
        unListen();
      }
    };
  }, []);

  const onFinishFailed = () => {
    setValidationTriggered(true);
  };

  const handleChangeFieldType = (key) => {
    setSelectedDynamicKey(key);
  };

  const handleChangeTextType = (e) => {
    switch (e?.target?.value) {
      case 'SINGLE_LINE':
        form?.setFieldsValue({ minLength: 1, maxLength: 255 });
        break;
      case 'MULTI_LINE':
        form?.setFieldsValue({ minLength: 1, maxLength: 1000 });
        break;
      case 'RICH_TEXT':
        form?.setFieldsValue({ minLength: 1, maxLength: 5000 });
        break;
      default:
        break;
    }
  };

  const showPublish = () => {
    if (isEdit && selectedDynamicKey === 'PICK_LIST' && hasPickListData) {
      return true;
    }
    if (selectedDynamicKey !== 'PICK_LIST') {
      return true;
    }
    return false;
  };

  const handlePickListModalVisible = (visible = false, record = null) => {
    setPickListModalVisible(visible);
    setPickListRecord(record);
  };

  const pickListType = Form?.useWatch('type', form);

  const fieldNameContent = (
    <span className="line-height">
      Edit the&nbsp;
      <b>
        <i>Field Name</i>
      </b>
      &nbsp; until the draft is published; it becomes fixed after publishing.
    </span>
  );
  return (
    <div className="content-section">
      {publishModal && (
        <PublishModal
          modalVisible={publishModal}
          setModalVisible={setPublishModal}
          submitLoading={publishModalLoader}
          handleSubmit={() => handleSubmit(form?.getFieldsValue(true))}
        />
      )}
      {pickListModalVisible && (
        <PickListModal
          isEdit={isEdit}
          handlePickListModalVisible={handlePickListModalVisible}
          pickListModalVisible={pickListModalVisible}
          setPickListModalVisible={setPickListModalVisible}
          pickListRecord={pickListRecord}
          setPickListRecord={setPickListRecord}
          pickListType={dynamicFieldsData?.type}
          setRefetchData={setRefetchData}
          isFieldDisabled={isFieldDisabled}
        />
      )}
      <AccessControl
        allowedPermissions={
          isEdit ? ['FET_DYNAMIC_FIELD_UPDATE'] : ['FET_DYNAMIC_FIELD_CREATE']
        }
      >
        <Portal portalId="header-right-content">
          <Button
            className="common-button discard-button"
            icon={<DeleteIconComponent />}
            size="small"
            id="user-table-discard-btn"
            disabled={publishLoading || submitLoading}
            onClick={() => {
              navigate(ROUTES?.DYNAMIC_FIELDS, {
                state: {
                  ...location?.state,
                },
              });
            }}
          >
            {isEdit ? 'Discard Changes' : 'Cancel'}
          </Button>
          {!isFieldDisabled ? (
            <>
              <Button
                className="common-button save-draft-btn"
                icon={<img src={SaveIcon} alt="save-icon" width={12} />}
                size="small"
                htmlType="submit"
                id="save-draft-btn"
                loading={submitLoading}
                disabled={publishLoading}
                type="primary"
                onClick={() =>
                  handleSubmit(form?.getFieldsValue(true), 'DRAFT')
                }
              >
                Save Draft
              </Button>
              {showPublish() && (
                <Button
                  className="common-button"
                  icon={<img src={SaveIcon} alt="save-icon" width={12} />}
                  size="small"
                  htmlType="submit"
                  id="publish-all-btn"
                  loading={publishLoading}
                  disabled={submitLoading}
                  type="primary"
                  onClick={form?.submit}
                >
                  Publish to All Products
                </Button>
              )}
            </>
          ) : (
            <Button
              className="common-button save-draft-btn"
              icon={<img src={SaveIcon} alt="save-icon" width={12} />}
              size="small"
              htmlType="submit"
              id="save-draft-btn"
              loading={submitLoading}
              type="primary"
              onClick={form?.submit}
            >
              Save
            </Button>
          )}
        </Portal>
      </AccessControl>
      <Form
        form={form}
        initialValues={dynamicFieldsData}
        layout="vertical"
        validateTrigger={validationTriggered ? 'onChange' : 'onSubmit'}
        preserve={false}
        onFinish={handleSubmit}
        onValuesChange={() => dispatch({ type: 'SET_SHOW_PROMPT', data: true })}
        onFinishFailed={onFinishFailed}
        scrollToFirstError={{ behavior: 'smooth', block: 'end' }}
      >
        <fieldset
          disabled={
            !checkPermissions(
              permissions,
              isEdit
                ? ['FET_DYNAMIC_FIELD_UPDATE']
                : ['FET_DYNAMIC_FIELD_CREATE'],
            )
          }
        >
          <span className="form-divider-text">FIELD TYPE</span>
          <Divider className="form-divider" />
          <Row gutter={16}>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                name="fieldType"
                label="Field Type"
                rules={[
                  {
                    ...requiredWhiteSpaceAllowed,
                    message: 'Please Select Field Type',
                  },
                ]}
                className="label-with-tooltip"
                tooltip="Change Field Type before draft is published; field becomes fixed after publishing. Each type formats product data uniquely – for example, Picklist offers a dropdown, Number is for numbers, Text is for plain text, and more."
              >
                <SelectComponent
                  disabled={isEdit}
                  placeholder="Select Field Type"
                  onChange={handleChangeFieldType}
                  allowClear={false}
                >
                  {map(DYNAMIC_FIELDS_DATA, (item) => (
                    <Option key={item?.key} value={item?.key}>
                      {item?.label}
                    </Option>
                  ))}
                </SelectComponent>
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                rules={[
                  { ...required, message: 'Please Enter Field Name' },
                  () => ({
                    validator(rule, value) {
                      const trimValue = value?.trimLeft()?.trimRight();
                      if (trimValue) {
                        const validName =
                          REGEX?.NON_NUMERIC_STARTING_NO_SPACE_IN_BETWEEN_NO_SPECIAL_CHAR;
                        if (!trimValue?.match(validName)) {
                          // eslint-disable-next-line prefer-promise-reject-errors
                          return Promise?.reject(
                            'Please Enter A Valid Field Name (No Number At Staring & No Special Characters & Space)',
                          );
                        }
                        return Promise?.resolve();
                      }
                      return Promise?.resolve();
                    },
                  }),
                ]}
                name="name"
                label="Field Name"
                className="label-with-tooltip"
                tooltip={fieldNameContent}
              >
                <InputComponent
                  disabled={isFieldDisabled}
                  allowClear
                  placeholder="Enter Field name"
                />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                name="sortOrder"
                label="Order"
                className="label-with-tooltip"
                tooltip="On the Product Page, assign a priority number to this field for its order. Priority 1 is the highest."
                rules={[number]}
              >
                <PriceComponent
                  isPrice={false}
                  decimalScale={false}
                  showPrefix={false}
                  placeholder="Enter Order"
                  allowNegative={false}
                />
              </Form.Item>
            </Col>
            {!isSponsor && dynamicFieldsData?.sponsorName && (
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item name="sponsorName" label="Sponsor">
                  <InputComponent disabled />
                </Form.Item>
              </Col>
            )}
          </Row>

          {/* dynamic form-data section based on field type */}
          <DynamicFieldFormData
            position="top"
            isDisabled={isFieldDisabled}
            hasPickListData={hasPickListData}
            selectedDynamicKey={selectedDynamicKey}
            dynamicFieldsData={dynamicFieldsData}
            handleChangeTextType={handleChangeTextType}
            checkedList={checkedList}
            setCheckedList={setCheckedList}
            validationTriggered={validationTriggered}
            form={form}
            setRefetchData={setRefetchData}
          />

          <Row gutter={16}>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              {map(DYNAMIC_FIELDS_PROPS, (item) => {
                if (
                  selectedDynamicKey === 'UPLOAD' &&
                  (item?.key === 'READ_ONLY' || item?.key === 'HIDDEN')
                ) {
                  return;
                }
                return (
                  <Form.Item
                    key={item?.key}
                    name={item?.name}
                    className="mb-0"
                    valuePropName="checked"
                  >
                    <Checkbox
                      disabled={isFieldDisabled}
                      key={item?.key}
                      className="common-checkbox"
                    >
                      {item?.label}
                    </Checkbox>
                  </Form.Item>
                );
              })}
            </Col>
          </Row>
          <span className="form-divider-text">LINES OF BUSINESS</span>
          <Divider className="form-divider" />
          <Row gutter={16}>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                name="industryId"
                label="Industry"
                rules={[
                  {
                    required,
                    message: 'Please Select Industry',
                  },
                ]}
                className="label-with-tooltip"
                tooltip="This field will only be visible for products that share the same Industry and Line of Business.
                Change Industry before publishing; field becomes fixed afterward."
              >
                <SelectComponent
                  placeholder="Select Industry"
                  allowClear={false}
                  disabled={isFieldDisabled}
                  notFoundContent={
                    industryLoading ? (
                      <LoaderComponent size="small" setHeight={10} />
                    ) : (
                      <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                    )
                  }
                  onBlur={handleIndustryBlur}
                  onSearch={debouncedIndustryHandler}
                  onPopupScroll={onIndustryScroll}
                  onSelect={handleIndustrySelect}
                >
                  {map(industriesData, (item) => (
                    <Option key={item?.id} value={item?.id}>
                      {item?.label}
                    </Option>
                  ))}
                </SelectComponent>
              </Form.Item>
            </Col>
          </Row>
          {lobData?.length > 0 && (
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item
                  name="lineOfBusinessIds"
                  rules={[
                    ({ getFieldValue }) => ({
                      required: getFieldValue('industryId'),
                      message: 'Please Select Lines of Business',
                      type: 'array',
                    }),
                  ]}
                >
                  <Checkbox.Group
                    disabled={isFieldDisabled}
                    className="fill-width"
                  >
                    {map(lobData, (lob) => (
                      <div key={lob?.id}>
                        <Checkbox value={lob?.id} className="common-checkbox">
                          {lob?.label}
                        </Checkbox>
                      </div>
                    ))}
                  </Checkbox.Group>
                </Form.Item>
              </Col>
            </Row>
          )}

          {/* dynamic form-data section based on field type */}
          <DynamicFieldFormData
            position="bottom"
            isDisabled={isFieldDisabled}
            dynamicFieldsData={dynamicFieldsData}
            selectedDynamicKey={selectedDynamicKey}
            hasPickListData={hasPickListData}
            handleChangeTextType={handleChangeTextType}
            checkedList={checkedList}
            setCheckedList={setCheckedList}
            validationTriggered={validationTriggered}
            form={form}
            setRefetchData={setRefetchData}
          />

          <span className="form-divider-text">LABELS</span>
          <Divider className="form-divider" />
          <Row gutter={16}>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                rules={[{ ...required, message: 'Please Enter Label' }]}
                name="label"
                label="Label"
              >
                <InputComponent allowClear placeholder="Enter Label" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                name="tooltip"
                label="Tooltip"
                className="label-with-tooltip"
                tooltip="Use this field to enter text or information that will display as a tooltip when users hover over a specific element."
              >
                <InputComponent allowClear placeholder="Enter Tooltip" />
              </Form.Item>
            </Col>
          </Row>
        </fieldset>
      </Form>
      {selectedDynamicKey === 'PICK_LIST' && (
        <>
          {submitLoading ? (
            <LoaderComponent setHeight={5} />
          ) : (
            <>
              {isEdit ? (
                <>
                  {dynamicFieldsData?.type !== pickListType && (
                    <Text className="d-flex justify-center" strong>
                      In order to add options, please save the changes you've
                      made.
                    </Text>
                  )}
                  {dynamicFieldsData?.type !== pickListType &&
                  !hasPickListData ? null : (
                    <PickListTable
                      limit={LIMIT}
                      handlePickListModalVisible={handlePickListModalVisible}
                      setRefetchData={setRefetchData}
                      refetchData={refetchData}
                      setHasPickListData={setHasPickListData}
                      isFieldDisabled={isFieldDisabled}
                    />
                  )}
                </>
              ) : (
                <Text className="d-flex justify-center" strong>
                  Save the details to add options to the list.
                </Text>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default DynamicFieldsForm;
