import { Card } from 'antd';
import React, { useContext } from 'react';
import { AppContext } from '../../../AppContext';
import { MODULES } from '../../../common/constants';
import Portal from '../../../components/Portal';
import AssignmentJobsTable from '../components/AssignmentJobsTable';

const DataAssignmentList = () => {
  const {
    state: { pageSize },
  } = useContext(AppContext);

  return (
    <Card className="full-height-card card-body-padding">
      <Portal portalId="header-left-content">
        <span className="portal-header">{MODULES?.DATA_ASSIGNMENT}</span>
      </Portal>
      {pageSize && <AssignmentJobsTable />}
    </Card>
  );
};

export default DataAssignmentList;
